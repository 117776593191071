import { faEdit } from '@fortawesome/free-regular-svg-icons';
import {
  faCommentAlt,
  faCheck,
  faExternalLinkAlt,
  faPlus,
  faCopy,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import { emotionEpisodeLink } from '../../api/actions/get-emotion-episode-link';
import { emotionEpisodesSearch } from '../../api/emotion-episode/emotion-episode-search';
import { emotionEpisodesSimilarSearch } from '../../api/emotion-episode/emotion-episode-similar-search';
import { getSavedEmotionEpisodes } from '../../api/actions/get-saved-emotion-episodes';
import { MainLayout } from '../../components/layouts/main-layout';
import { Search } from '../../components/layouts/search';
import { GlobalSearchContext } from '../../components/layouts/search-bar';
import { AddBreadcrumbsItem } from '../../components/shared/breadcrumbs/breadcrumbs';
import { LinkButton } from '../../components/shared/link-button/link-button';
import { Loader } from '../../components/shared/loader/loader';
import {
  AppConfig,
  getCharacterFilePath,
  getAbsoluteUrl,
} from '../../config/app-config';
import { compareText } from '../../helpers/array-helpers';
import { secondsToTimeString } from '../../helpers/datetime-helpers';
import { basePerformError } from '../../helpers/error-helpers';
import { setToClipboard } from '../../helpers/clipboard-helpers';
import { joinPath } from '../../helpers/path-helpers';
import { IEmotionEpisode } from '../../models/emotion-episode';
import { SecuredPage } from '../base/secured-page';
import styles from './segment-tile-result.module.scss';
import { Popup } from '../../components/popup/popup';
import { addSavedEmotionEpisodeToUser } from '../../api/actions/add-saved-emotion-episode-to-user';
import { removeSavedEmotionEpisodeFromUser } from '../../api/actions/remove-saved-emotion-episode-from-user';
import { Select } from '../../components/select/select';
import { IVideoService } from '../../models/video-service';
import { CurrentUserContext } from '../../components/user/current-user-manager';
import { CurrentUserContext as UserContext } from '../../contexts/current-user-context';
import { getCurrentUser } from '../../api/actions/get-current-user';
import { INarrative, ContentAgeRating } from '../../models/narrative';
import { getAllVideoServices } from '../../api/actions/video-service/video-service-get-all';
import { useCurrentUser } from '../../contexts/current-user-context';
import { IComment } from '../../types';
import { removeComment, saveComment } from '../../api/comment/Comment';
import useGoogleAnalytics from '../../hooks/useGoogleAnalytics';
import { BackButton } from '../../components/back-button/back-button';
import { HeaderContent } from '../../components/header-content/header-content';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { SearchBar } from '../../components/layouts/search-bar';
import { ResultsList } from './results-list';
import { toast } from 'react-toastify';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { ReactComponent as GraduateCapIcon } from '../../images/graduate-cap.svg';
import { UserRoles } from '../../models/user-roles-enum';
import useScreenWidth from '../../hooks/useScreenWidth';
import { LoginComponent } from '../login/login-component';
import { SignUpComponent } from '../signup/signup-component';
import { onSuccessLogin, onSuccessSignUp } from '../../utils';
import { useUser } from '../../contexts/user-context';

export function SegmentTile(props: RouteComponentProps) {
  const userContext = React.useContext(UserContext);
  const history = useHistory();
  const location = useLocation();
  const { setUser } = useUser();
  const isSearch = location.pathname === '/search';
  const { dispatch } = useGoogleAnalytics();
  const globalSearchContext = React.useContext(GlobalSearchContext);
  const currentUser = useCurrentUser();
  const limit = AppConfig.searchPageSize as number;
  const screenWidth = useScreenWidth();
  const [eeParam, setEeParam] = useState(
    new URL(window.location.href).searchParams.get('segment') || undefined
  );
  const [narrIdParam, setNarrIdParam] = useState(
    new URL(window.location.href).searchParams.get('narr_id') || undefined
  );

  const [loadingSimilar, setLoadingSimilar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [emotionEpisodes, setEmotionEpisodes] = useState<IEmotionEpisode[]>();
  const [similarEmotionEpisodes, setSimilarEmotionEpisodes] =
    useState<IEmotionEpisode[]>();
  const [notLoginPopup, setnotLoginPopup] = useState(false);
  const [notSigninPopup, setnotSigninPopup] = useState(false);
  const [notChromePopup, setNotChromePopup] = useState(false);
  const [mobilePopup, setMobilePopup] = useState(false);
  const [isExtensionInstalled, setisExtensionInstalled] = useState(false);
  const [mediaUrl, setmediaUrl] = useState('');
  const [skip, setskip] = useState(0);
  const [currentSearchCount, setcurrentSearchCount] = useState(0);
  const [loadingSave, setloadingSave] = useState('');
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [addNotePopup, setaddNotePopup] = useState(false);
  const [noteText, setnoteText] = useState('');
  const [currentEpisode, setcurrentEpisode] = useState<IEmotionEpisode>();
  const [showingEducatorPrompt, setShowingEducatorPrompt] = useState(true);
  const [selectedServiceOption, setSelectedServiceOption] =
    useState<string>('');
  const [selectedRatingOption, setSelectedRatingOption] = useState<string>('');
  const [videoServices, setVideoServices] = useState<IVideoService[]>([]);
  const [filterServiceUid, setFilterServiceUid] = useState<string | undefined>(
    undefined
  );
  const [filterAgeRating, setFilterAgeRating] = useState<number | undefined>(
    undefined
  );

  const [pendingSaveData, setPendingSaveData] = useState<{
    uid: any;
    url: any;
    ee: any;
  } | null>(null);

  const [showLogin, setShowLogin] = useState<boolean>(true);
  const [source] = useState(
    new URL(window.location.href).searchParams.get('source') || undefined
  );
  const currentUserContext = React.useContext(CurrentUserContext);

  //!!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

  const useIsChromeOrEdge = () => {
    // eslint-disable-next-line
    const isChromeOrEdge = React.useMemo(() => {
      const userAgent = navigator.userAgent;
      return (
        userAgent.indexOf('Chrome') !== -1 || userAgent.indexOf('Edg') !== -1
      );
    }, []);

    return isChromeOrEdge;
  };

  const isChrome = useIsChromeOrEdge();

  console.log('isChrome here ', isChrome);
  const isMobile = React.useMemo(
    () => navigator.userAgent.indexOf('Mobi') != -1,
    []
  );

  const shouldShowEducatorPrompt = React.useMemo(
    () =>
      !currentUserContext?.currentUser ||
      (currentUserContext?.currentUser &&
        (currentUserContext?.currentUser?.userPlan?.accessType !== 'full' ||
          currentUserContext?.currentUser?.userPlan?.free)),
    [currentUserContext]
  );

  const shouldShowSimilarResults = React.useMemo(
    () =>
      AppConfig.showSimilarResultsTo === 'all' ||
      (currentUserContext?.currentUser &&
        (currentUserContext?.currentUser.role === UserRoles.admin ||
          currentUserContext?.currentUser.role === UserRoles.editor)),
    [currentUserContext]
  );

  const loadData = React.useCallback(async () => {
    //if(!currentUserContext?.currentUser) return;
    try {
      setVideoServices(
        (await getAllVideoServices()).sort((el1, el2) =>
          compareText(el1, el2, (el) => el.name)
        )
      );
    } catch (err) {
      basePerformError(err, props.history);
    }
  }, [props.history]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      try {
        if (!isSearch) {
          setLoading(true);
          const res = await getSavedEmotionEpisodes();
          setEmotionEpisodes(res);
          setLoading(false);
          setSearchPerformed(true);
        }
      } catch (error) {
        console.error(
          'Failed to fetch data from getSavedEmotionEpisodes()',
          error
        );
      }
    })();
  }, [isSearch]);

  useEffect(() => {
    if (!globalSearchContext?.state && !eeParam) return;
    if (!isSearch) return;
    //if we have a search term, clear the param
    if (globalSearchContext?.state && eeParam) {
      setEeParam(undefined);
      setSimilarEmotionEpisodes(undefined);
      return;
    }

    setLoading(true);

    fetchEmotionEpisode(0, filterServiceUid, filterAgeRating);
  }, [globalSearchContext?.state, eeParam, filterServiceUid, filterAgeRating]);

  const fetchEmotionEpisode = async (
    skipValue?: any,
    service?: any,
    age?: any
  ) => {
    setskip(skipValue);
    setLoading(skipValue == 0);
    setLoadingMore(skipValue > 0);
    //console.log("starting search for service: " + service + "; saved service uid: " + filterServiceUid);

    let serviceData = videoServices.find(
      (ee) => ee.uid === service
    )?.identifier;
    serviceData = serviceData ? String(serviceData).toLowerCase() : '';

    if (globalSearchContext?.state || eeParam) {
      try {
        if (age === '' || age === 'None') age = undefined;

        // get if it's /search or /saved, populate the result based on that, and if it's saved you
        // can skip all the other stuff

        const result = await emotionEpisodesSearch(
          globalSearchContext?.state,
          eeParam,
          skipValue,
          limit,
          serviceData,
          age,
          dispatch
        );

        setcurrentSearchCount(result?.length);
        if (skipValue == 0 && result?.length > 0) {
          setEmotionEpisodes(result);
        } else if (skipValue == 0 && (!result || result?.length == 0)) {
          setEmotionEpisodes([]);
        } else if (skipValue > 0 && emotionEpisodes && result?.length > 0) {
          setEmotionEpisodes([...emotionEpisodes, ...result]);
        }
        setLoading(false);
        setLoadingMore(false);
        setSearchPerformed(true);

        if (eeParam) {
          //we have an em ep, so also fetch similar emeps
          setLoadingSimilar(true);
          const similar = await emotionEpisodesSimilarSearch(eeParam);
          setSimilarEmotionEpisodes(similar);
          setLoadingSimilar(false);
        }
      } catch (err) {
        console.log('Error ', err);
        setEmotionEpisodes([]);
        setLoading(false);
        setLoadingMore(false);
      }
    } else {
      setLoading(false);
      setLoadingMore(false);
    }
  };

  const linkToExternalVideo = async (url: string, uid: string) => {
    if (!Cookies.get(AppConfig.tokenName)) {
      setnotLoginPopup(true);
      setmediaUrl(url);
      return;
    }
    // if(!uid) return;
    try {
      //we ping the backend to set up the position of the video
      //NOTE: if the user hasn't installed the browser extension, we shouldn't make this call
      const result = await emotionEpisodeLink(uid);
      if (!result) {
        console.log(
          'failed to sync with backend when nagivating to external link'
        );
      }

      if (url) {
        //console.log("got emotion episode link: ", url);
        window.open(url);
      }
    } catch (err) {
      basePerformError(err, props.history);
    }
  };

  const getViewName = (link: any) => {
    if (link.includes('netflix')) {
      return 'Netflix';
    } else if (link.includes('disney')) {
      return 'Disney+';
    } else {
      return 'Peacock';
    }
  };

  const updateSavedForEpisode = (uid: any, saveStatus: boolean) => {
    if (!emotionEpisodes) return;

    const emep = emotionEpisodes.find((ee) => ee.uid === uid);
    if (emep) {
      emep.saved = saveStatus;
    }
  };

  const handleCommentSubmit = async () => {
    const comment: IComment = {
      html: noteText,
      entityUid: currentEpisode?.uid,
      entityType: 'emotion_episode',
      parentCommentUid: '',
      resolved: false,
    } as IComment;
    const res = await saveComment(comment);
    if (res) {
      if (currentEpisode) currentEpisode.comment = comment;
      setnoteText('');
      setaddNotePopup(false);
    }
  };

  const handleSaveEpisode = async (uid: any, url: any, ee: any) => {
    setloadingSave(uid);
    const service = getViewName(url).toLocaleLowerCase();
    if (!Cookies.get(AppConfig.tokenName)) {
      setPendingSaveData({ uid, url, ee });
      setloadingSave('');
      setnotSigninPopup(true);
      return;
    }
    if (!ee.saved) {
      try {
        await addSavedEmotionEpisodeToUser(uid, service ? service : '').then(
          (res) => {
            //fetchEmotionEpisode()
            //update the saved status locally
            updateSavedForEpisode(uid, true);
            setloadingSave('');
            toast.info('Episode saved.');
          }
        );
      } catch (error) {
        console.log('Error', error);
        toast.error('Episode could not be saved.');
        setloadingSave('');
      }
    } else {
      try {
        await removeSavedEmotionEpisodeFromUser(uid).then((res) => {
          //fetchEmotionEpisode()
          //update the saved status locally
          updateSavedForEpisode(uid, false);
          setloadingSave('');
          toast.info('Episode removed.');
        });
      } catch (error) {
        console.log('Error', error);
        setloadingSave('');
        toast.error('Episode could not be removed.');
      }
    }
  };

  const getDataUsingFilter = (
    emotionEpisode: IEmotionEpisode[] | undefined = undefined
  ) => {
    if (filterServiceUid) {
      const service = videoServices.find((s) => s.uid === filterServiceUid);
      return emotionEpisode?.filter((n) =>
        n?.scene?.narrative?.externalReferences?.some(
          (er) =>
            er.serviceName &&
            er.serviceName.toLowerCase() === service?.identifier.toLowerCase()
        )
      );
    }
    return emotionEpisode;
  };

  const getResponderName = (ee: IEmotionEpisode) => {
    const responder = getResponder(ee);
    if (responder) {
      const char = responder.character;
      return char.shortName ? char.shortName : char.name;
    }
    return '';
  };

  const clickEmotion = (e: string) => {
    //console.log('clicked emotion');
    globalSearchContext?.setState(e);
    if (isSearch) {
      setLoading(true);
    } else {
      history.push('/search');
    }
  };

  const getResponderEmotions = (ee: IEmotionEpisode) => {
    const responder = getResponder(ee);
    if (responder && responder.emotions?.length > 0) {
      const emotion = responder.emotions[0];
      if (emotion && emotion.description) {
        const emotions = emotion.description.split(',');
        const last_em = emotions[emotions.length - 1];

        return (
          <>
            {emotions &&
              emotions.map((e) => (
                <span
                  className="underline cursor-pointer"
                  onClick={() => clickEmotion(e)}
                >
                  {e.toLowerCase() + (e != last_em ? ', ' : '')}
                </span>
              ))}
          </>
        );
      } else if (emotion && emotion.category?.label) {
        return (
          <span
            className="underline cursor-pointer"
            onClick={() =>
              clickEmotion(emotion.category?.label.toLowerCase() || '')
            }
          >
            {emotion.category?.label.toLowerCase()}
          </span>
        );
      }
    }
    return '';
  };

  const getResponderIntensity = (ee: IEmotionEpisode) => {
    const responder = getResponder(ee);
    if (responder && responder.emotions?.length > 0) {
      const emotion = responder.emotions[0];
      if (emotion) return emotion.intensity;
    }
    return '';
  };

  const getResponder = (ee: IEmotionEpisode) => {
    const responders = ee.participants?.filter(
      (pp) => !!pp.emotions && pp.emotions.length > 0
    );
    if (responders && responders.length === 1) {
      return responders[0];
    } else if (responders && responders.length > 1) {
      let participant = responders.find(
        (r) => r.character.importance === 'major'
      );
      if (!participant)
        participant = responders.find(
          (r) => r.character.importance === 'supporting'
        );
      if (!participant) return responders[0];
    }
    return null;
  };

  const getEmEpLink = (uid: any) => {
    const url = getAbsoluteUrl('search');
    return url + '?segment=' + uid;
  };

  const copyShareLink = (uid: any) => {
    const fullPath = getEmEpLink(uid);
    setToClipboard(fullPath);
  };

  const handleVideoLink = (url: any, uid: any) => {
    if (Cookies.get(AppConfig.tokenName)) {
      if (currentUser?.extensionInstalled && isChrome && !isMobile) {
        linkToExternalVideo(url, uid);
      } else if (isMobile) {
        setmediaUrl(url);
        setMobilePopup(true);
      } else if (!isChrome) {
        setmediaUrl(url);
        setNotChromePopup(true);
      } else if (!currentUser?.extensionInstalled) {
        setmediaUrl(url);
        setisExtensionInstalled(true);
      }
    } else {
      setnotLoginPopup(true);
      setmediaUrl(url);
    }
  };

  const handleToggle = () => {
    setShowLogin(!showLogin);
  };

  const handleSetUserContext = async () => {
    const usr = await getCurrentUser();
    userContext?.setState(usr);
    return usr;
  };

  const handleSavePendingData = () => {
    if (pendingSaveData) {
      const { uid, url, ee } = pendingSaveData;
      setPendingSaveData(null); // Clear the pending save data
      void handleSaveEpisode(uid, url, ee); // Retry the save action
    }
  };

  const handleSuccessLogin = async (configurationFinished: boolean) => {
    setnotLoginPopup(false);
    setnotSigninPopup(false);

    const usr = handleSetUserContext();
    const res = await onSuccessLogin(
      configurationFinished,
      props.history,
      setUser,
      '/search',
      usr
    );

    console.log('res from onSuccessLogin ', res);
    handleSavePendingData();
  };

  const handleSuccessSignUp = async (destination: string) => {
    setnotLoginPopup(false);
    setnotSigninPopup(false);

    const usr = handleSetUserContext();
    await onSuccessSignUp(destination, props.history, setUser, source, usr);
    handleSavePendingData();
  };

  type handleServiceFiltering = (id: any) => void;

  const handleServiceFiltering = (id: any) => {
    id === 'any' ? (id = undefined) : null;
    setFilterServiceUid(id);
    setskip(0);
    fetchEmotionEpisode(0, id, filterAgeRating);
  };

  type handleAgeFiltering = (age: any) => void;

  const handleAgeFiltering = (age: any) => {
    age === '999' ? (age = undefined) : null;
    setFilterAgeRating(age);
    setskip(0);
    fetchEmotionEpisode(0, filterServiceUid, age);
  };

  const handleServiceOptionDisplay = (option: string) => {
    setSelectedServiceOption(option);
  };

  const handleRatingOptionDisplay = (option: string) => {
    setSelectedRatingOption(option);
  };

  const buttonListNoteModal = [
    {
      title: 'Submit',
      onClick: () => handleCommentSubmit(),
    },
    {
      title: 'Cancel',
      onClick: () => setaddNotePopup(false),
    },
  ];

  const goToBlankSearch = () => {
    setEeParam(undefined);
    setSearchPerformed(false);
    setEmotionEpisodes(undefined);
    history.push('/search');
  };

  const handleCommentDelete = async () => {
    if (currentEpisode?.comment?.uid) {
      const res = await removeComment(currentEpisode?.comment?.uid);
      if (res) {
        setnoteText('');
        setaddNotePopup(false);
        //fetchEmotionEpisode()
        if (currentEpisode) currentEpisode.comment = undefined;
      }
    }
  };

  const deleteSegment = async (emotionEpisode: IEmotionEpisode) => {
    try {
      await removeSavedEmotionEpisodeFromUser(emotionEpisode.uid);

      const ees = emotionEpisodes;
      setEmotionEpisodes([]);
      const index = ees && ees.indexOf(emotionEpisode);
      if (typeof index === 'number') {
        ees && ees.splice(index, 1);
      }
      setEmotionEpisodes(ees);
    } catch (err) {
      basePerformError(err);
    }
  };

  const goToGetInTouchLink = () => {
    window.open('mailto:' + AppConfig.supportEmail);
  };

  const narrativeClicked = (narrative_id: string) => {
    globalSearchContext?.setState('narrative_id:' + narrative_id);
    if (isSearch) {
      setLoading(true);
    } else {
      history.push('/search');
    }
    //fetchEmotionEpisode(0, filterServiceUid, filterAgeRating);
  };

  const characterClicked = (character_id: string) => {
    globalSearchContext?.setState('char_id:' + character_id);
    if (isSearch) {
      setLoading(true);
    } else {
      history.push('/search');
    }
    //fetchEmotionEpisode(0, filterServiceUid, filterAgeRating);
  };

  const emepDetailView = (emep_uid: string) => {
    const emepUrl = getEmEpLink(emep_uid);
    window.open(emepUrl);
  };

  const getRatingsString = (ee: IEmotionEpisode) => {
    return ee.scene?.narrative?.minAgeRating >= 7 &&
      ee.scene?.narrative?.minAgeRating < 13
      ? `Older Kids ${ee.scene?.narrative?.minAgeRating}+`
      : ee.scene?.narrative?.minAgeRating >= 13 &&
        ee.scene?.narrative?.minAgeRating < 16
      ? `Teens ${ee.scene?.narrative?.minAgeRating}+`
      : ee.scene?.narrative?.minAgeRating >= 16 &&
        ee.scene?.narrative?.minAgeRating < 18
      ? `Young Adults ${ee.scene?.narrative?.minAgeRating}+`
      : ee.scene?.narrative?.minAgeRating >= 18
      ? `Adults ${ee.scene?.narrative?.minAgeRating}+`
      : 'G';
  };

  const getEducatorPrompt = () => {
    if (Cookies.get(AppConfig.tokenName)) {
      return (
        <div className="flex flex-col sm:flex-row items-center justify-evenly">
          <GraduateCapIcon className="hidden sm:block" />
          <div className="w-full sm:w-8/12">
            <div className="font-bold text-lg">Are you an educator?</div>
            ThinkHumanTV educator plans offer a practitioner guide, student
            licenses, and group analytics. &nbsp;
            <span
              className="cursor-pointer underline underline-offset-4"
              onClick={() => window.open(AppConfig.educatorLearnMoreURL)}
            >
              Learn more
            </span>
            &nbsp;or{' '}
            <span
              className="cursor-pointer underline underline-offset-4"
              onClick={() => window.open(AppConfig.scheduleDemoURL)}
            >
              schedule a demo.
            </span>
          </div>
          <div className="w-full mt-4 sm:mt-0 sm:w-auto">
            <button
              type="button"
              className="w-full cursor-pointer border-solid border border-white text-white font-semibold bg-banner-blue px-6 py-3 rounded-md"
              onClick={() => window.open(AppConfig.educatorLearnMoreURL)}
            >
              Learn More
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex flex-col sm:flex-row items-center justify-evenly">
          <div className="w-full sm:w-9/12">
            <div className="font-bold text-lg">Are you an educator?</div>
            ThinkHumanTV offers a unique K12 social emotional learning program
            based on popular movies and shows. &nbsp;
            <span
              className="cursor-pointer underline underline-offset-4"
              onClick={() => window.open(AppConfig.programLearnMoreURL)}
            >
              Learn more
            </span>
            . You can also{' '}
            <span
              className="cursor-pointer underline underline-offset-4"
              onClick={() => window.open('signup')}
            >
              sign up
            </span>{' '}
            to try it free or{' '}
            <span
              className="cursor-pointer underline underline-offset-4"
              onClick={() => window.open(AppConfig.scheduleDemoURL)}
            >
              schedule
            </span>{' '}
            a demo.
          </div>
          <div className="w-full mt-4 sm:mt-0 sm:w-auto">
            <button
              type="button"
              className="w-full cursor-pointer border-solid border border-white text-white font-semibold bg-banner-blue px-6 py-3 rounded-md"
              onClick={() => window.open(AppConfig.programLearnMoreURL)}
            >
              Learn More
            </button>
          </div>
        </div>
      );
    }
  };

  const getEpisodeActions = (ee: IEmotionEpisode) => {
    return (
      <div>
        <LinkButton
          className="green"
          disabled={loadingSave === ee.uid}
          onClick={() => {
            if (ee.mediaUrls) {
              handleSaveEpisode(ee.uid, ee.mediaUrls[0], ee);
            }
          }}
        >
          <FontAwesomeIcon icon={ee.saved ? faCheck : faPlus} />{' '}
          {loadingSave === ee.uid ? 'Saving...' : ee.saved ? 'Saved' : 'Save'}
        </LinkButton>
        <br />
        {ee.saved && (
          <>
            <LinkButton
              className="green"
              onClick={() => {
                setcurrentEpisode(ee);
                setaddNotePopup(true);
                if (ee.comment) {
                  setnoteText(ee.comment.html);
                } else {
                  setnoteText('');
                }
              }}
            >
              <FontAwesomeIcon icon={faCommentAlt} />{' '}
              {ee.comment ? 'Edit Note' : 'Add Note'}
            </LinkButton>
            <br />
          </>
        )}
        {ee.mediaUrls &&
          ee.mediaUrls.map((link, id) => (
            <>
              <LinkButton
                onClick={() => handleVideoLink(link, ee.uid)}
                className="green"
              >
                <FontAwesomeIcon icon={faExternalLinkAlt} /> View on{' '}
                {getViewName(link)}
              </LinkButton>
              <br />
            </>
          ))}
        <>
          <LinkButton onClick={() => copyShareLink(ee.uid)} className="green">
            <FontAwesomeIcon icon={faCopy} /> Copy Link
          </LinkButton>
          <br />
        </>
        {!isSearch && (
          <>
            <LinkButton onClick={() => deleteSegment(ee)} className="gray">
              <FontAwesomeIcon icon={faTrash} /> Delete
            </LinkButton>
          </>
        )}
      </div>
    );
  };

  return (
    // <SecuredPage pageName="search" {...props} >
    <>
      <AddBreadcrumbsItem title="Home" url="/" />
      <AddBreadcrumbsItem title="Search" url="/search" />
      <MainLayout
        hideSearch={isSearch ? true : false}
        noPaddings={screenWidth < 768 ? true : false}
      >
        {!isSearch && (
          <>
            <BackButton>
              <LinkButton to={'/analytics'}>
                <FontAwesomeIcon icon={faArrowLeft} /> Back to Profile
              </LinkButton>
            </BackButton>
            <HeaderContent>Saved Segments</HeaderContent>
          </>
        )}
        <div className="max-w-1200 mx-auto">
          {loading ? (
            <Loader />
          ) : (
            <div className={`pt-${eeParam !== undefined ? '20' : '12'}`}>
              <div className="relative">
                {isSearch && eeParam === undefined && (
                  <SearchBar
                    handleServiceFiltering={handleServiceFiltering}
                    handleAgeFiltering={handleAgeFiltering}
                    handleServiceOptionDisplay={handleServiceOptionDisplay}
                    handleRatingOptionDisplay={handleRatingOptionDisplay}
                    selectedServiceOption={selectedServiceOption}
                    selectedRatingOption={selectedRatingOption}
                  />
                )}
                {isSearch &&
                  shouldShowEducatorPrompt &&
                  searchPerformed &&
                  showingEducatorPrompt && (
                    <div className="text-white bg-banner-blue mt-1 sm:mt-2 sm:rounded p-4">
                      {getEducatorPrompt()}
                      <XMarkIcon
                        className={`h-5 w-5 text-white cursor-pointer absolute ${
                          screenWidth < 400 ? 'top-[276px]' : 'top-52'
                        } right-2.5`}
                        aria-hidden="true"
                        onClick={() => setShowingEducatorPrompt(false)}
                      />
                    </div>
                  )}
              </div>

              {!searchPerformed && !loading && (
                <div className={`${styles.centerBlock} p-6 text-center`}>
                  <div className={styles.hint}>
                    Search our database of 1000s of scenes featuring emotional
                    reactions across multiple streaming services.
                    <br />
                    Try using emotion labels such as joy, fear, sadness, or
                    gratitude.
                  </div>
                </div>
              )}

              {searchPerformed && !emotionEpisodes?.length && (
                <div className={styles.centerBlock}>
                  <span>
                    No results found. Please try another search term or filter.
                  </span>
                </div>
              )}

              {emotionEpisodes && (
                <ResultsList
                  results={emotionEpisodes}
                  getResponder={getResponder}
                  getResponderName={getResponderName}
                  getResponderIntensity={getResponderIntensity}
                  getResponderEmotions={getResponderEmotions}
                  getRatingsString={getRatingsString}
                  handleVideoLink={handleVideoLink}
                  getViewName={getViewName}
                  handleSaveEpisode={handleSaveEpisode}
                  copyShareLink={copyShareLink}
                  setcurrentEpisode={setcurrentEpisode}
                  setaddNotePopup={setaddNotePopup}
                  setnoteText={setnoteText}
                  narrativeClickedHandler={narrativeClicked}
                  characterClickedHandler={characterClicked}
                  emepDetailView={emepDetailView}
                  showDetailViewOption={!eeParam ? true : false}
                />
              )}
            </div>
          )}
          <div className={styles.centerBlock}>
            <div className={styles.showMore}>
              {!loading &&
                currentSearchCount == limit &&
                !!emotionEpisodes &&
                emotionEpisodes?.length > 0 && (
                  <LinkButton
                    disabled={loadingMore}
                    onClick={() => {
                      fetchEmotionEpisode(
                        emotionEpisodes?.length,
                        filterServiceUid,
                        filterAgeRating
                      );
                    }}
                  >
                    {loadingMore ? 'Loading...' : 'Show More'}
                  </LinkButton>
                )}
            </div>
            {isSearch &&
              !eeParam &&
              !loading &&
              currentSearchCount < limit &&
              !!emotionEpisodes &&
              emotionEpisodes?.length > 0 && (
                <div className={styles.noMoreResults}>
                  No more results to show
                </div>
              )}
            {eeParam && !loading && (
              <LinkButton
                onClick={() => {
                  goToBlankSearch();
                }}
              >
                {'Start New Search'}
              </LinkButton>
            )}
          </div>

          {shouldShowSimilarResults && eeParam && (
            <>
              <div className="mt-10">Similar Segments</div>
            </>
          )}

          {shouldShowSimilarResults && eeParam && similarEmotionEpisodes && (
            <>
              <ResultsList
                results={similarEmotionEpisodes}
                getResponder={getResponder}
                getResponderName={getResponderName}
                getResponderIntensity={getResponderIntensity}
                getResponderEmotions={getResponderEmotions}
                getRatingsString={getRatingsString}
                handleVideoLink={handleVideoLink}
                getViewName={getViewName}
                handleSaveEpisode={handleSaveEpisode}
                copyShareLink={copyShareLink}
                setcurrentEpisode={setcurrentEpisode}
                setaddNotePopup={setaddNotePopup}
                setnoteText={setnoteText}
                narrativeClickedHandler={narrativeClicked}
                characterClickedHandler={characterClicked}
                showDetailViewOption={true}
                emepDetailView={emepDetailView}
              />
            </>
          )}

          {isSearch && AppConfig.searchInBeta && (
            <div className={styles.betaMessage}>
              Search is currently in beta. Please get in touch at{' '}
              <span
                className={styles.link}
                onClick={() => goToGetInTouchLink()}
              >
                support@thinkhuman.tv
              </span>{' '}
              to let us know how to improve it.
            </div>
          )}
        </div>

        {mobilePopup && (
          <Popup
            title="THTV Works Better on Desktop"
            onClose={() => setMobilePopup(false)}
            buttons={[
              {
                title: 'Go to video URL',
                onClick: () => window.open(mediaUrl),
              },
              {
                title: 'Cancel',
                onClick: () => setMobilePopup(false),
              },
            ]}
          >
            <div className={styles.termsTextContainer}>
              <p>
                To be able to go DIRECTLY to the clip, you will need to switch
                to a computer browser.
              </p>
              <p>
                You can still go to the URL of the video and find the clip
                manually.
              </p>
            </div>
          </Popup>
        )}

        {notChromePopup && (
          <Popup
            title="THTV Works Better in Chrome"
            onClose={() => setNotChromePopup(false)}
            buttons={[
              {
                title: 'Go to video URL',
                onClick: () => window.open(mediaUrl),
              },
              {
                title: 'Cancel',
                onClick: () => setNotChromePopup(false),
              },
            ]}
          >
            <div className={styles.termsTextContainer}>
              <p>
                To be able to go DIRECTLY to the clip, you will need to switch
                to Chrome or Edge.
              </p>
              <p>
                You can still go to the URL of the video and find the clip
                manually.
              </p>
            </div>
          </Popup>
        )}

        {(notLoginPopup || notSigninPopup) && (
          <Popup
            title={showLogin ? 'Login' : 'Sign up'}
            onClose={() => {
              setnotLoginPopup(false);
              setnotSigninPopup(false);
            }}
            buttons={[
              {
                title: 'Go to video URL',
                onClick: () => window.open(mediaUrl),
              },
            ]}
          >
            <div>
              {showLogin ? (
                <LoginComponent
                  handleToggle={handleToggle}
                  onSuccessLogin={handleSuccessLogin}
                  introMessage="To be able to got DIRECTLY to the clip, log in. Or you can go to the URL of the video and find the clip manually."
                />
              ) : (
                <SignUpComponent
                  handleToggle={handleToggle}
                  onSuccessSignUp={handleSuccessSignUp}
                  introMessage="To be able to got DIRECTLY to the clip, log in. Or you can go to the URL of the video and find the clip manually."
                  destination="/search"
                />
              )}
            </div>
          </Popup>
        )}

        {isExtensionInstalled && (
          <Popup
            title="Install Extension"
            onClose={() => setisExtensionInstalled(false)}
            buttons={[
              {
                title: 'Install Extension',
                onClick: () => window.open(AppConfig.extensionURL),
              },
              {
                title: 'Go to video URL',
                onClick: () => window.open(mediaUrl),
              },
              {
                title: 'Cancel',
                onClick: () => setisExtensionInstalled(false),
              },
            ]}
          >
            <div className={styles.termsTextContainer}>
              To go DIRECTLY to the clip, you need to install the ThinkHumanTV
              Chrome browser extension.
              <br /> Or you can go to the URL of the video and find the clip
              manually.
              <br />
            </div>
          </Popup>
        )}

        {addNotePopup && (
          <Popup
            title={currentEpisode?.comment ? 'Edit Note' : 'Add Note'}
            onClose={() => setaddNotePopup(false)}
            buttons={
              currentEpisode?.comment
                ? [
                    ...buttonListNoteModal,
                    {
                      title: 'Delete',
                      onClick: () => handleCommentDelete(),
                    },
                  ]
                : [
                    {
                      title: 'Submit',
                      onClick: () => handleCommentSubmit(),
                    },
                    {
                      title: 'Cancel',
                      onClick: () => setaddNotePopup(false),
                    },
                  ]
            }
          >
            <div className={styles.termsTextContainer}>
              <textarea
                className={styles.commentField}
                onChange={(ev) => setnoteText(ev.target.value)}
                value={noteText}
                placeholder={'Please comment here...'}
              />
            </div>
          </Popup>
        )}
      </MainLayout>
      {/* // </SecuredPage> */}
    </>
  );
}
