import * as React from 'react';
import { isNotNullOrEmpty } from '../../helpers/common-helpers';
import styles from './group-buttons.module.scss';

interface IProps {
    children: React.ReactElement | Array<React.ReactElement | false | null | string>;
}

export function GroupButtons(props: IProps) {
    return (
        <div className={styles.groupButtons}>
            { Array.isArray(props.children) ? props.children?.filter(isNotNullOrEmpty) : props.children }
        </div>
    );
}
