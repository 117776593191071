import { FetchResult, gql } from 'apollo-boost';
import { removeFields } from '../../helpers/common-helpers';
import { IBookmark } from '../../models/bookmark';
import { MultiError } from '../../types/multi-error';
import { graphqlClient } from '../graphql-client';

const QUERY = gql`
    {
        bookmarks_marked_by_user {
            uid,
            description,
            start,
            end,
            createdAt: created_at,
            service: service,
            narrativeTitle: narrative_title,
            episodeTitle: episode_title,
            numLikes: num_likes,
            numComments: num_comments,
            shareLevel: share_level,

            author: created_by {
                  uid,
                  firstName: first_name,
                  lastName: last_name
            }
        }
    }
`;

export async function getBookmarks(): Promise<IBookmark[]> {
    const result: FetchResult<{ bookmarks_marked_by_user: IBookmark[] }> = await graphqlClient.query({
        query: QUERY,
        variables: {},
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return removeFields(result.data.bookmarks_marked_by_user, ['__typename']);
}
