import { FetchResult, gql } from 'apollo-boost';
import { MultiError } from '../../types/multi-error';
import { graphqlClient } from '../graphql-client';

const MUTATION = gql`
    mutation UserLogout {
        user_logout {
            success,
        }
    }
`;

export async function logout(): Promise<boolean> {
    const result: FetchResult<{ user_logout: { success: boolean }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        errorPolicy: 'all',
    });

    if(!result.data || (result.errors && result.errors.length)) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.user_logout.success;
}
