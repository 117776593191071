import {
  faArrowRight,
  faBatteryHalf,
  faHeart,
  faSun,
  faTimes,
  faFilm,
  faBook,
  faCircleInfo,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo, useState } from 'react';
import { getUserUnitStats } from '../../../../api/actions/get-user-unit-stats';
import { LinkButton } from '../../../../components/link-button/link-button';
import { useCurrentUser } from '../../../../contexts/current-user-context';
import { onlyUnique, compareText } from '../../../../helpers/array-helpers';
import { basePerformError } from '../../../../helpers/error-helpers';
import {
  joinPath,
  getParentPath,
  imagesPath,
} from '../../../../helpers/path-helpers';
import { isNotNullOrEmpty } from '../../../../helpers/common-helpers';
import { IUser } from '../../../../models/user';
import { IUserStats } from '../../../../models/user-stats';
import { IUserUnitStats } from '../../../../models/user-unit-stats';
import { ICurriculum } from '../../../../models/curriculum';
import { IUserProfile } from '../../../../models/user-profile';
import { AppConfig } from '../../../../config/app-config';
import moment from 'moment';
import styles from './user-unit-stats-component.module.scss';

interface IProps {
  user?: IUser;
  unitStats?: IUserUnitStats[];
  profile?: IUserProfile;
}

export function UserUnitStatsComponent(props: IProps) {
  const [unitStats, setUnitStats] = React.useState<IUserUnitStats[]>();
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    if (loading) return;

    (async () => {
      try {
        if (!props.unitStats || !props.unitStats?.length) {
          setLoading(true);
          const unitStats = await getUserUnitStats(
            props.user?.uid,
            props.profile?.uid
          );
          if (unitStats) {
            setUnitStats(unitStats);
          }
          setLoading(false);
        } else {
          setUnitStats(props.unitStats);
        }
      } catch (err) {
        setLoading(false);
        basePerformError(err);
        return;
      }
    })().catch(() => {
      /** */
    });
  }, [props]);

  const startedCurriculums = React.useMemo(
    () =>
      unitStats
        ?.map((s) => s.curriculum)
        .filter(isNotNullOrEmpty)
        .filter((cel, i, self) => onlyUnique(cel, i, self, (el) => el.uid))
        .sort((c1, c2) => compareText(c1, c2, (cu: ICurriculum) => cu.name)),
    [unitStats]
  );

    const getBestScore = (unitUid: string) => {
       const sortedStats = getUnitData(unitUid)?.sort((u1, u2) => u2.unitScore - u1.unitScore);;
       if (!sortedStats) return 0;

       const bestScore = sortedStats[0].unitScore;
       if(bestScore) return bestScore;
       return 0;
    }

  const getUnitsFromStats = (curriculumUid: string) => {
    const units = unitStats
      ?.filter((s) => s.curriculum?.uid === curriculumUid)
      .map((s) => s.unit)
      .filter(isNotNullOrEmpty)
      .sort((u1, u2) => u2.index - u1.index);

    return units ? units : [];
  };

  const getUnitData = (unitUid: string) => {
    return unitStats?.find((s) => s.unitUid === unitUid)?.data;
  };

  return (
    <>
      <div className={styles.chartContainer}>
        <div className={styles.header}>
          <div className={styles.left}>
            <div className={styles.chartTitle}>Training History</div>
            {startedCurriculums &&
              startedCurriculums?.map((curriculum) => (
                <div key={curriculum.uid}>
                  <div className={styles.curriculumName}>
                    <span>Curriculum:</span> {curriculum.name}
                  </div>
                  {getUnitsFromStats(curriculum.uid).map((unit) => (
                    <div className={styles.unitContainer} key={unit.uid}>
                      <div className={styles.unitName}>
                        <span>Unit:</span> <b>#{unit.index}</b> | {unit.name} | Best score: {getBestScore(unit.uid)}
                      </div>
                      {getUnitData(unit.uid)?.map((r) => (
                        <div
                          key={`stat-${unit.uid}-${r.datetime}`}
                          className={styles.statItem}
                        >
                          <div className={styles.dateTime}>{r.datetime}</div>
                          <div className={styles.points}>
                            <span>Points:</span> {r.points}
                          </div>
                          <div className={styles.time}>
                            <span>Time Spent:</span>
                            {moment()
                              .startOf('day')
                              .add(r.timeSeconds, 'seconds')
                              .format('HH:mm:ss')}
                          </div>
                          <div className={styles.score}>
                              <span>Score:</span> {r.unitScore}
                          </div>

                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              ))}
            {(!startedCurriculums || startedCurriculums.length === 0) && (
              <>No training history to show.</>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
