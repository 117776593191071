import * as React from 'react';
import styles from './circular-progress.module.scss';

interface IProps {
    value: number;
}

export function CircularProgress(props: IProps) {
    return (
        <div className={styles.container}>
            <svg viewBox="0 0 40 40" className={`${styles.circularProgress} ${styles.green}`}>
                <path className={styles.circleBg}
                    d="M18 0
                    a 18.9155 18.9155 0 0 1 2 37.831
                    a 18.9155 18.9155 0 0 1 -2 -37.831"
                />
                <path className={styles.circle}
                    strokeDasharray={`${props.value}, 100`}
                    d="M18 3.0845
                    a 15.9155 15.9155 0 0 1 2 31.831
                    a 15.9155 15.9155 0 0 1 -2 -31.831"
                />
                <text x="19" y="22" textAnchor="middle" className={styles.percentage}>{props.value}%</text>
            </svg>
        </div>
    );
}
