import { FetchResult, gql } from 'apollo-boost';
import moment from 'moment';
import { IAchievement } from '../../models/achievement';
import { MultiError } from '../../types/multi-error';
import { graphqlClient } from '../graphql-client';

const MUTATION = gql`
    mutation UserStatsAddCompletedUnit($data: AddCompletedUnitToUserInput!){
        user_stats_add_completed_unit (request_data: $data) {
            success,

            achievements {
                uid,
                name,
                description,
                achievementType: achievement_type,
                index,
                iconFileName: icon_file_name,
            }
        }
    }
`;

export async function addCompletedUnitToUser(
    unitUid: string,
    timeSeconds: number,
    points: number,
    questions: number,
    correctAnswers: number,
    checkedQuestions: number,
): Promise<{ success: boolean, achievements?: IAchievement[] }> {
    const result: FetchResult<{ user_stats_add_completed_unit: { success: boolean, achievements?: IAchievement[] } }> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: {
            data: {
                unit_uid: unitUid,
                time_seconds: timeSeconds,
                points,
                user_local_datetime: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
                questions_number: questions,
                checked_questions_number: checkedQuestions,
                correct_answers_number: correctAnswers,
            },
        },
        errorPolicy: 'all',
    });

    if(!result.data || (result.errors && result.errors.length)) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.user_stats_add_completed_unit;
}
