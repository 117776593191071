import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { HorizontalScrollItem } from './horizontal-scroll-item';
import styles from './horizontal-scroller.module.scss';


interface IProps {
    items: any[];
    itemComponent: React.ComponentClass | React.FC<any>;
    itemEvents: any;
    height?: string;
}

interface IState {
    itemWidth?: number;
    showScrollButtons?: boolean;
}

export class HorizontalScroller extends React.Component<IProps, IState> {

    state: IState = {};

    itemBaseWidth?: number;

    scrollContainerRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
        this.setItemSize();
        this.onResize();
        window.addEventListener('resize', this.onResize);
        if(this.scrollContainerRef.current) {
            this.scrollContainerRef.current.addEventListener('wheel', this.onWheel);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
        if(this.scrollContainerRef.current) {
            this.scrollContainerRef.current.removeEventListener('wheel', this.onWheel);
        }
    }

    componentDidUpdate(oldProps: IProps) {
        if(oldProps.items !== this.props.items) {
            this.setItemSize();
            this.onResize();
        }
    }

    render() {
        return (
            <div className={styles.container}>
                { this.state.showScrollButtons && (<>
                    <div className={styles.buttonLeft} onClick={this.scrollLeft}>
                        <FontAwesomeIcon icon={faArrowLeft}/>
                    </div>
                    <div className={styles.buttonRight} onClick={this.scrollRight}>
                        <FontAwesomeIcon icon={faArrowRight}/>
                    </div>
                </>)}
                <div ref={this.scrollContainerRef} className={styles.scrollContainer}>
                    <div className={styles.subcontainer} style={{ height: this.props.height }}>
                        { this.props.items.map((item, i: number) => (
                            <HorizontalScrollItem
                                key={i}
                                component={this.props.itemComponent}
                                data={item}
                                events={this.props.itemEvents}
                                width={this.state.itemWidth}
                            />
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    private setItemSize = () => {
        if(this.scrollContainerRef.current) {
            const item = this.scrollContainerRef.current.querySelector('.item');
            if(item) {
                this.itemBaseWidth = item.clientWidth;
            }


        }
    }

    private onResize = () => {
        if(this.scrollContainerRef.current) {
            const container = this.scrollContainerRef.current;
            const itemWidth = Math.floor(container.clientWidth / Math.floor(container.clientWidth / this.itemBaseWidth!));
            this.setState(
                { itemWidth },
                () => {
                    this.setState({
                        showScrollButtons:
                        (this.scrollContainerRef.current?.scrollWidth || 0)
                        - (this.scrollContainerRef.current?.parentElement?.clientWidth || 0)
                        > itemWidth / 2,
                    });
                },
            );
        }
    }

    private onWheel = (event: any) => {
        this.scroll(event.wheelDelta > 0 ? 1 : -1);

    }

    private scrollLeft = () => {
        this.scroll(-2);
    }

    private scrollRight = () => {
        this.scroll(2);
    }

    private scroll(val: number) {
        if(this.scrollContainerRef.current && this.state.itemWidth) {
            let scrollLeft = this.scrollContainerRef.current.scrollLeft + val * this.state.itemWidth;
            if(scrollLeft < 0) scrollLeft = 0;
            if(scrollLeft > this.scrollContainerRef.current.scrollWidth) {
                scrollLeft = this.scrollContainerRef.current.scrollWidth - 2 * this.state.itemWidth;
            }
            this.scrollContainerRef.current.scrollLeft = scrollLeft;
        }
    }
}
