import * as React from 'react';
import styles from './multi-level-menu.module.scss';

export interface IMultiLevelMenuItem {
    title: string | React.ReactElement;
    onClick: () => void;
    subItems?: IMultiLevelMenuItem[];
}

interface IProps {
    items: IMultiLevelMenuItem[];
}

export function MultiLevelMenu(props: IProps) {

    return (
        <div className={styles.container}>
            { props.items.map((item: IMultiLevelMenuItem, i: number) => (
                <div key={i} className={styles.item}>
                    <div className={styles.itemTitle} onClick={item.onClick}>{item.title}</div>
                    { item.subItems && item.subItems.length && (
                        <MultiLevelMenu
                            items={item.subItems}
                        />
                    )}
                </div>
            ))}
        </div>
    );
}
