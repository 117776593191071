export enum UserRelationshipTypes {
  child = 'child',
  grandchild = 'grandchild',
  parent = 'parent',
  grandparent = 'grandparent',
  partner = 'spouse or partner',
  sibling = 'sibling',
  friend_colleague = 'friend or colleague',
  student = 'student',
  client = 'client',
  other = 'other'
}
