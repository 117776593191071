import * as React from 'react';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RouteComponentProps } from 'react-router';
import { getCurrentUser } from '../../api/actions/get-current-user';
import { MainLayout } from '../../components/layouts/main-layout';
import { BackButton } from '../../components/back-button/back-button';
import { HeaderContent } from '../../components/header-content/header-content';
import { LinkButton } from '../../components/link-button/link-button';
import { CurrentUserContext } from '../../contexts/current-user-context';
import { basePerformError } from '../../helpers/error-helpers';
import { SecuredPage } from '../base/secured-page';
import { ProfileMenuComponent } from '../user/analytics/components/profile-menu-component';
import { OrganizationComponent } from './components/organization-component';
import styles from './organization-page.module.scss';

export function OrganizationPage(props: RouteComponentProps<{ uid: string }>) {
  const currentUserContext = React.useContext(CurrentUserContext);
  React.useEffect(() => {
    (async () => {
      try {
        const usr = await getCurrentUser();
        currentUserContext?.setState(usr);
      } catch (err) {
        basePerformError(err, props.history);
        return;
      }
    })().catch(() => {
      /** */
    });
  }, [props]);

  return (
    <SecuredPage pageName="journals" {...props}>
      <MainLayout>
        <HeaderContent>Organization</HeaderContent>
        <div className={styles.container}>
          <div className={styles.content}>
            {currentUserContext?.state?.uid && (
              <OrganizationComponent
                user={currentUserContext?.state}
                uid={props.match.params.uid}
              />
            )}
          </div>
        </div>
      </MainLayout>
    </SecuredPage>
  );
}
