import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../button/button';
import styles from './search.module.scss';
import { GlobalSearchContext } from './search-bar';

interface IProps {
  isMaximized?: boolean;
  onFocus(): void;
  onBlur(): void;
}

export function Search(props: IProps) {
  const history = useHistory();

  const globalSearchContext = React.useContext(GlobalSearchContext);
  const textBoxRef = useRef<HTMLInputElement>(null);

  const [searchText, setSearchText] = useState<string>(
    globalSearchContext?.state || ''
  );

  const onKeyPress = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.code.toLowerCase() === 'enter') {
      onSearch();
    }
  };
  const onSearch = () => {
    if (!searchText || searchText.length < 2) return;
    console.log(searchText);
    globalSearchContext?.setState(searchText);
    history.push('/search');
  };

  const clearSearchText = () => {
    setSearchText('');
    globalSearchContext?.setState(null);
    textBoxRef.current?.focus();
  };

  return (
    <div className={`${styles.search} ${props.isMaximized && styles.active}`}>
      <div>
        <FontAwesomeIcon icon={faSearch} className={styles.searchIcon} />
        <input
          ref={textBoxRef}
          className={styles.searchField}
          type="text"
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          onKeyPress={onKeyPress}
          onChange={(ev) => setSearchText(ev.target.value)}
          value={searchText}
          placeholder={props.isMaximized ? 'Search for emotion scenes' : ''}
        />
        {searchText && (
          <FontAwesomeIcon
            icon={faTimes}
            className={styles.clearSearchFieldBtn}
            onClick={clearSearchText}
          />
        )}
      </div>
      {props.isMaximized && (
        <div className={styles.mobileResult}>
          <Button
            onClick={() => {
              onSearch();
            }}
            className={styles.searchBtn}
          >
            Search
          </Button>
        </div>
      )}
    </div>
  );
}
