import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { getAnswersPaged } from '../../../../api/actions/answers/answers-get-paged';
import { Button } from '../../../../components/button/button';
import { Grid } from '../../../../components/grid/grid';
import { Popup } from '../../../../components/popup/popup';
import { SearchField } from '../../../../components/search-field/search-field';
import { basePerformError } from '../../../../helpers/error-helpers';
import { LinkButton } from '../../../../components/link-button/link-button';
import { IAnswer } from '../../../../models/answer';
import { getParentPath, joinPath } from '../../../../helpers/path-helpers';
import { RouteComponentProps } from 'react-router-dom';
import { IUser } from '../../../../models/user';
import moment from 'moment';
import styles from './responses-component.module.scss';


interface IProps {
    user: IUser,
    url?: string,
    asComponent: boolean
}

export function ResponsesComponent(props: IProps) {
    const history = useHistory();
    const [searchText, setSearchText] = useState<string | undefined>();
    const loadingRef = useRef(false);
    const [loading, setLoading] = useState(false);

    const loadData = async (page: number, pageSize: number) => {
        if (!loading) {

            //loadingRef.current = true; 
            try {
                //setLoading(true); 

                const uid = props.user.uid;
                const orderBy = undefined;

                const result = await getAnswersPaged({
                    page,
                    uid,
                    searchText,
                    orderBy,
                    pageSize
                });
                //setLoading(false);
                return result;
            }
            catch(err) {
                //setLoading(false);
                basePerformError(err, history);
            }
        }
    }

    const gridContainerRenderer = React.useCallback(
        (content, pager) => (<>
            <div className={styles.pager}>
                {pager}
            </div>
            <table cellPadding="0" cellSpacing="0" className={`list ${styles.list}`}>
                <tbody>
                    <tr>
                        <th>#</th>
                        <th>Submission</th>
                        <th>Submitted At</th>
                        <th>Upvotes</th>
                        <th>Downvotes</th>
                        <th>Actions</th>
                    </tr>
                    {content}
                </tbody>
            </table>
            <div className={styles.pager}>{pager}</div>
        </>),
        [],
    );

    const gridItemRenderer = React.useCallback(
        (answer: IAnswer, i: number, count: number, reloadData: () => void) => (
            <tr key={answer.uid}>
                <td>
                    <div className={styles.markContainer}>
                        <div className={`${styles.mark} ${answer.flagStatus?.toLowerCase() !== 'open' ? '' : styles.disabled}`}>&nbsp;</div>
                        <div className={styles.title}>
                            {i + 1}
                        </div>
                    </div>
                </td>
                <td>{answer.answer}</td>
                <td>{moment(answer.createdAt).format('MM/DD/YYYY h:mm a')}</td>
                <td>{answer.upvoteCount}</td>
                <td>{answer.downvoteCount}</td>
                <td>
                    <LinkButton to={joinPath(props.url, answer.uid)} className="orange">
                        View
                    </LinkButton><br />
                </td>
            </tr>
        ),
        [],
    );

    return (<>

        <Grid<IAnswer>
            getPageData={loadData}
            containerRenderer={gridContainerRenderer}
            itemRenderer={gridItemRenderer}
            />
        </>)
}
