import { ICurriculum } from './curriculum';
import { IUnitSection } from './unit-section';

export enum UnitTypes {
    standard = 'Standard',
    review = 'Review',
    ongoing_practice = 'Ongoing Practice'
}

export interface IUnit {
    uid: string;
    name: string;
    description: string;
    index: number;
    educatorGuide?: string;
    hasEducatorGuide?: boolean;
    worksheet?: string;
    available?: boolean;
    accessibleWithCurrentPlan:boolean;
    finalEmotionModelState?: string;
    iconFileName?: string;
    type?: string;

    modelFile?: File;
    iconFile?: File;

    completed?: boolean;
    hasContent?: boolean;

    curriculum?: ICurriculum;
    unitSections: IUnitSection[];
}
