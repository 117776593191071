import { FetchResult, gql } from 'apollo-boost';
import { IUserGroup } from '../../../models/user-group';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query GetUserGroup($uid: String!) {
        user_group(uid: $uid) {
            uid,
            title,
            description,
            address,
            city,
            state,
            zipCode: zipcode,
            groupType: group_type,
            groupSubtype: group_subtype,
            domain,
            enabled,
            expired,
            unlisted,
            emailConfirmationRequired: email_confirmation_required
            createdAt: created_at,
            limitContentByAge: limit_content_by_age,
            progressSync: progress_sync,

            sharingEnabled: sharing_enabled,
            userCount: user_count,
            inviteCount: invite_count,
            maxAgeRating: max_age_rating,
            maxUsers: max_users,
            inviteCode: invite_code,
            rewardSchema: reward_schema_ref,
            userPlan: user_plan_ref,
            userPermissions: user_permissions,

            owner {
                uid,
                email,
                firstName: first_name,
                lastName: last_name
            },

            curriculums {
                uid,
                name,
                description,
                available,
            },

            narratives {
                uid,
                title,
            },

            userProfile: profile {
                uid,
                name
            }

        }
    }
`;

export async function getUserGroup(uid: string): Promise<IUserGroup> {
    const result: FetchResult<{ user_group: IUserGroup }> = await graphqlClient.query({
        query: QUERY,
        variables: { uid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.user_group;
}
