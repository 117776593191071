import React, { useContext, useState } from 'react';
import { IUser } from '../../models/user';
import { IUserGroup } from '../../models/user-group';
import { Link } from 'react-router-dom';
import { UserIcon } from '@heroicons/react/20/solid';
import {
  PencilSquareIcon,
  XCircleIcon,
  CalendarDaysIcon,
} from '@heroicons/react/20/solid';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { TwPopup } from '../../components/popup/tw-popup';
import {
  formatDate,
  hasMotivation,
  hasInstitutionalMotivation,
} from '../../utils.js';
import { ReactComponent as Logo } from '../../images/logo.svg';
import { PopupContext } from '../../contexts/popup-context';
import { removeUserFromUserGroup } from '../../api/actions/user-groups/user-group-user-remove';
import { userGroupDelete } from '../../api/actions/user-groups/user-group-delete';
import { toast } from 'react-toastify';
import { useCurrentUserSetState } from '../../contexts/current-user-context';
import { getCurrentUser } from '../../api/actions/get-current-user';

interface ClassListProps {
  currentUser: IUser | undefined;
}

export const ClassList: React.FC<ClassListProps> = ({ currentUser }) => {
  const setCurrentUser = useCurrentUserSetState();
  const [classToLeave, setClassToLeave] = useState<IUserGroup | undefined>();
  const [groupToDelete, setGroupToDelete] = useState<IUserGroup | undefined>();
  const myClasses = currentUser?.userGroups?.filter(
    (ug) =>
      ug.groupType === 'user' && ug.default !== true && ug.enabled === true && ug.unlisted !== true
  );
  const userGroups = currentUser?.userGroups;
  const hasOrg =
    userGroups?.some((ug) => ug.groupType === 'organization') ?? false;

  const hasPermissions =
    userGroups?.some(
      (userGroup) =>
        Array.isArray(userGroup.userPermissions) &&
        userGroup.userPermissions.length > 0
    ) ?? false;

  const history = useHistory();
  const { pathname } = useLocation();
  const isMyClasses = pathname === '/my-classes';
  const hasClasses = Array.isArray(myClasses) && myClasses.length > 0;

  const popupContext = useContext(PopupContext);
  if (!popupContext) throw new Error('Use TwPopup within a <PopupProvider>');
  const { setOpenPopup } = popupContext;

  const handleRedirect = () => {
    const hasOrganizationGroupType = userGroups?.some(
      (userGroup) => userGroup.groupType === 'organization'
    );

    if (hasOrganizationGroupType) {
      history.push('/group-select', { from: 'class-list' });
    } else {
      setOpenPopup('orgSelect');
    }
  };

  const isClassOwner = (user: IUser, classGroup: IUserGroup) => {
    if (user.uid === classGroup.owner?.uid) {
      return true;
    }
  };

  const handleEditOrLeaveClass = (user: IUser, classGroup: IUserGroup) => {
    if (isClassOwner(user, classGroup)) {
      history.push('/my-classes/edit', classGroup);
    } else {
      setOpenPopup('leaveClass');
      setClassToLeave(classGroup);
    }
  };

  const handleDeleteClassMessaging = (user: IUser, classGroup: IUserGroup) => {
    if (classGroup.userCount > 1) {
      toast.info(
        'Unable to delete group with members. Please contact support for further assistance.'
      );
    } else if (isClassOwner(user, classGroup)) {
      setOpenPopup('delete-class');
      setGroupToDelete(classGroup);
    }
  };

  const handleDelete = async () => {
    if (
      groupToDelete &&
      groupToDelete.uid &&
      currentUser &&
      isClassOwner(currentUser, groupToDelete)
    ) {
      setOpenPopup(null);
      try {
        const success = await userGroupDelete(groupToDelete.uid);
        if (success) {
          setCurrentUser(await getCurrentUser());
          toast.success('Class Deleted.');
        }
      } catch (error) {
        console.error('Error deleting user group:', error);
        toast.error(`Error: ${error}`);
      }
    } else {
      console.error('Error: groupToDelete is undefined or missing uid');
    }
  };

  const handleLeaveClass = async () => {
    if (
      !currentUser ||
      !currentUser.uid ||
      !classToLeave ||
      !classToLeave.uid
    ) {
      toast.error('An unexpected error occurred');
      return;
    }

    try {
      const res = await removeUserFromUserGroup(
        classToLeave.uid,
        currentUser.uid
      );
      if (res.success) toast.success('You have left the class');
      setCurrentUser(await getCurrentUser());
    } catch (error) {
      console.error(error);
      toast.error(`Something went wrong: ${error}`);
    }
  };

  const getClassesToShow = () => {
    if (!isMyClasses && myClasses?.length && myClasses.length > 2) {
      return myClasses?.slice(0, 2);
    } else {
      return myClasses;
    }
  };

  const classesToShow = getClassesToShow();

  return (
    <>
      <div className="flex items-center justify-between content-baseline">
        <h3>My Classes/Groups</h3>
        {!isMyClasses && hasClasses && (
          <Link to="/my-classes" className="no-underline text-th-blue -mb-2">
            See all
          </Link>
        )}
      </div>
      <div className="border-solid border border-thin-gray grid grid-cols-1 sm:grid-cols-3 gap-4 rounded-lg p-4">
        {classesToShow?.map((c) => (
          <div className="relative" key={c.uid}>
            <Link
              key={c.uid}
              className="no-underline"
              to={{
                pathname: `/analytics/groups-statistics/${c.uid}`,
                state: { fromLink: true },
              }}
            >
              <div className="bg-th-blue rounded-lg p-4 h-28 border-solid border border-th-blue">
                <div className="flex w-full justify-between">
                  <div className="text-white text-lg mb-2 leading-tight w-10/12">
                    {c.title}
                  </div>
                </div>

                <div className="flex items-center text-white opacity-50">
                  <UserIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                  {c.userCount}
                </div>
                {c.startDate && c.endDate && (
                  <div className="flex items-center text-white opacity-50">
                    <CalendarDaysIcon
                      className="h-5 w-5 mr-2"
                      aria-hidden="true"
                    />
                    {formatDate(c.startDate, 'month-day')} to{' '}
                    {formatDate(c.endDate, 'month-day')}
                  </div>
                )}
              </div>
            </Link>
            {(isClassOwner(currentUser!, c) ||
              hasMotivation(currentUser?.motivation) ||
              hasPermissions) && (
              <div className="absolute top-2 right-2.5">
                <PencilSquareIcon
                  className="h-4 w-4 text-white opacity-50 cursor-pointer"
                  aria-hidden="true"
                  onClick={() => handleEditOrLeaveClass(currentUser!, c)}
                />
                {isClassOwner(currentUser!, c) && (
                  <XCircleIcon
                    className="ml-1 h-4 w-4 text-white opacity-50 cursor-pointer"
                    aria-hidden="true"
                    onClick={() => handleDeleteClassMessaging(currentUser!, c)}
                  />
                )}
              </div>
            )}
            <TwPopup
              popupName="leaveClass"
              icon={<Logo />}
              actionButton={
                <button
                  type="button"
                  className="border-0 cursor-pointer mt-3 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 sm:col-start-2 bg-th-blue"
                  onClick={handleLeaveClass}
                >
                  Leave
                </button>
              }
            >
              <div className="text-sm text-gray-500 text-center">
                Leave class/group
              </div>
            </TwPopup>
          </div>
        ))}
        {hasInstitutionalMotivation(currentUser?.motivation) ||
        hasPermissions ||
        !hasClasses ||
        hasOrg ? (
          <div
            onClick={handleRedirect}
            className="h-28 p-4 rounded-lg flex items-center justify-center border-solid border border-th-blue cursor-pointer"
          >
            <p className="text-th-blue">+ Add Class/Group</p>
          </div>
        ) : (
          <div className="flex flex-col col-span-full">
            {!hasClasses && (
              <div>No class content to display.</div>
             )} 
            <div className="text-sm">
              If your class/group is not shown, please check with your
              instructor or contact{' '}
              <a
                href="https://www.thinkhuman.tv/support"
                target="_blank"
                rel="noopener noreferrer"
              >
                support
              </a>
              .
            </div>
          </div>
        )}
      </div>
      <TwPopup
        popupName="orgSelect"
        icon={<Logo />}
        actionButton={
          <button
            type="button"
            className="border-0 cursor-pointer mt-3 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 sm:col-start-2 bg-th-blue"
            onClick={() => {
              history.push('/org-select');
              setOpenPopup(null);
            }}
          >
            Go
          </button>
        }
      >
        <div className="text-sm text-gray-500 text-center">
          To add a new class or group, please choose or create an organization.
        </div>
      </TwPopup>
      <TwPopup
        popupName="delete-class"
        icon={<Logo />}
        actionButton={
          <button
            type="button"
            className="border-0 cursor-pointer mt-3 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 sm:col-start-2 bg-th-blue"
            onClick={() => handleDelete()}
          >
            Delete
          </button>
        }
      >
        <div className="text-sm text-gray-500 text-center">
          Are you sure you want to delete this class/group? This can’t be
          undone.
        </div>
      </TwPopup>
    </>
  );
};
