import * as React from 'react';
import { useState, useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { MainLayout } from '../../components/layouts/main-layout';
import { PublicPage } from '../base/public-page';
import { SignUpComponent } from './signup-component';
import { useUser } from '../../contexts/user-context';
import { onSuccessSignUp } from '../../utils';
import { PopupContext } from '../../contexts/popup-context';

export function SignUpPage(props: RouteComponentProps) {
  const popupContext = useContext(PopupContext);
  if (!popupContext) throw new Error('Use TwPopup within a <PopupProvider>');
  const { setOpenPopup } = popupContext;

  const [code] = useState(
    new URL(window.location.href).searchParams.get('code') || undefined
  );
  const [source] = useState(
    new URL(window.location.href).searchParams.get('source') || undefined
  );
  const { setUser } = useUser();

  const handleSuccessSignUp = async (destination: string) => {
    await onSuccessSignUp(destination, props.history, setUser, source);
    if (destination === '/analytics') setOpenPopup('signIn');
  };

  return (
    <PublicPage pageName="login">
      <MainLayout>
        <SignUpComponent
          groupInviteCode={code}
          source={source}
          onSuccessSignUp={handleSuccessSignUp}
          extendedForm={false}
        />
      </MainLayout>
    </PublicPage>
  );
}
