import { FetchResult, gql } from 'apollo-boost';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation userGroupPrivateInviteActivate($code: String!){
        user_group_private_invite_activate(code: $code) {
            success,
            notRegistered: not_registered,
            notLoggedIn: not_logged_in,
            groupNotExists: group_not_exists,
            email,
            alreadyAccepted: already_accepted
        }
    }
`;

interface IResult {
    success: boolean,
    notRegistered: boolean,
    notLoggedIn: boolean,
    groupNotExists: boolean,
    email?: string,
    alreadyAccepted: boolean
}

export async function userGroupPrivateInviteActivate(code: string): Promise<IResult> {
    const result: FetchResult<{ user_group_private_invite_activate: IResult }> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: {
            code,
        },
        errorPolicy: 'all',
    });

    if(!result.data || (result.errors && result.errors.length)) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.user_group_private_invite_activate;
}
