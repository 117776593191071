import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { getCurrentUser } from '../../../api/actions/get-current-user';
import { MainLayout } from '../../../components/layouts/main-layout';
import { CurrentUserContext } from '../../../contexts/current-user-context';
import { basePerformError } from '../../../helpers/error-helpers';
import { SecuredPage } from '../../base/secured-page';
import { ProfileMenuComponent } from './components/profile-menu-component';
import { UserStatsComponent } from './components/user-stats-component';

export function AnalyticsPage(props: RouteComponentProps) {
  const currentUserContext = React.useContext(CurrentUserContext);
  React.useEffect(() => {
    (async () => {
      try {
        const usr = await getCurrentUser();
        currentUserContext?.setState(usr);
      } catch (err) {
        basePerformError(err, props.history);
        return;
      }
    })().catch(() => {
      /** */
    });
  }, [props]);

  return (
    <SecuredPage pageName="profile" {...props}>
      <MainLayout>
        <ProfileMenuComponent>
          {currentUserContext?.state?.uid && (
            <UserStatsComponent
              user={currentUserContext?.state}
              unitStats={currentUserContext?.state.completedUnitsStats}
            />
          )}
        </ProfileMenuComponent>
      </MainLayout>
    </SecuredPage>
  );
}
