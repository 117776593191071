import { FetchResult, gql } from 'apollo-boost';
import { INarrative } from '../../models/narrative';
import { MultiError } from '../../types/multi-error';
import { graphqlClient } from '../graphql-client';

const QUERY = gql`
    {
        narratives {
            uid,
            title,
            fileName: file_name,
            available,
            mediaType: media_type,
            preferredSourceName: preferred_source_name,
            preferredSourceUrl: preferred_source_url,
            episodes: episodes_enabled {
                uid,
                name,
                season,
                numberInSeason: number_in_season
            }            
        }
    }
`;

export async function getNarratives(): Promise<INarrative[]> {
    const result: FetchResult<{ narratives: INarrative[] }> = await graphqlClient.query({
        query: QUERY,
        errorPolicy: 'all',
    });

    if(!result.data || (result.errors && result.errors.length)) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.narratives;
}
