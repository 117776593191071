import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { ReactElement, useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import * as Yup from 'yup';
import { emailConfirmationCodeCheck } from '../../api/actions/emal-confirmation-code-check';
import { Button } from '../../components/button/button';
import { MainLayout } from '../../components/layouts/main-layout';
import { Loader } from '../../components/loader/loader';
import { isNullOrEmpty } from '../../helpers/common-helpers';
import { IDictionary } from '../../types/dictionary';
import { PublicPage } from '../base/public-page';
import { MultiError } from '../../types/multi-error';
import styles from './email-confirmation.module.scss';
import { useUser } from '../../contexts/user-context';
import { getCurrentUser } from '../../api/actions/get-current-user';
import { resendConfirmationEmailByUserUid } from '../../api/actions/user/resend-confirmation-email';
import { toast } from 'react-toastify';
import { hasPDMotivation } from '../../utils.js';


interface IForm {
  code: string;
}

export function EmailConfirmationPage(props: RouteComponentProps) {
  const form = React.useRef<FormikProps<IForm>>(null);
  const { user, setUser } = useUser();
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState<{
    firstName: string;
    lastName: string;
  }>();

  const checkCode = async (code: string) => {
    setLoading(true);
    try {
      setUserInfo(await emailConfirmationCodeCheck(code));

      setTimeout(() => {
        (async () => {
          const updatedUser = await getCurrentUser();
          setUser(updatedUser);
        })();
      }, 1500);
    } catch (err) {
      form.current?.setErrors({
        code: (err as MultiError).message,
      });
    }
    setLoading(false);
  };

  const code = new URL(window.location.href).searchParams.get('code');

  useEffect(() => {
    if (!isNullOrEmpty(code)) {
      checkCode(code).catch(() => {
        /** */
      });
    }
  }, [code]);

  useEffect(() => {
    void form.current?.submitForm();
  }, []);

  const handleSubmit = async (
    values: IForm,
    {
      setSubmitting,
      setErrors,
    }: {
      setSubmitting: (status: boolean) => void;
      setErrors: (errors: IDictionary<string>) => void;
    }
  ) => {
    await checkCode(values.code);
    setSubmitting(false);
  };

  const getValidationSchema = () => {
    return Yup.object({
      code: Yup.string().label('Confirmation code').required().min(30).max(250),
    });
  };

  const handleRedirect = () => {
    let destination = '/analytics';
    let routeState = null;

    const isMemberOfAnOrgWithChildren = user?.userGroups?.some(
      (ug) =>
        ug.enabled &&
        ug.groupType === 'organization' &&
        ug.children &&
        ug.children.length > 0
    );

    if (user && hasPDMotivation(user?.motivation)) {
          destination = '/account';
    }
    else if (isMemberOfAnOrgWithChildren) {
      destination = '/group-select';
      routeState = { orgTitle: user?.userGroups?.[0].title };
    }
    props.history.push(destination, routeState);
  };

  const handleResend = async () => {
    if (user && user.uid) {
      try {
        const res = await resendConfirmationEmailByUserUid(user.uid);
        res && toast.success('Confirmation email resent. Go to your inbox.');
      } catch (error) {
        console.error('Failed to resend confirmation email:', error);
        toast.error('Unable to resend confirmation email');
      }
    }
  };

  const renderLoginForm = ({
    errors,
    touched,
    submitForm,
  }: FormikProps<IForm>): ReactElement => {
    return (
      <>
        <h1>Email confirmation</h1>
        <Form noValidate>
          <div className="form-item"></div>
          <div className="form-item">
            <label>
              <div className="form-label required">Confirmation code:</div>
              <Field component="textarea" name="code" rows="7" />
            </label>
            <div className="errors">{touched.code && errors.code}</div>
          </div>

          <div className="form-buttons single">
            {loading ? (
              <Loader />
            ) : (
              <>
                {!errors && (
                  <Button onClick={submitForm}>
                    <span>Confirm</span>
                  </Button>
                )}

                <span className="mr-2 ml-2"></span>
                <Button onClick={handleResend}>Resend Confirmation</Button>
              </>
            )}
          </div>
        </Form>
      </>
    );
  };

  return (
    <PublicPage pageName="login">
      <MainLayout>
        <div className={styles.container}>
          <div className={styles.subcontainer}>
            {!userInfo && (
              <Formik
                innerRef={form}
                initialValues={{
                  code: code || '',
                }}
                validationSchema={getValidationSchema}
                onSubmit={handleSubmit}
              >
                {renderLoginForm}
              </Formik>
            )}
            {userInfo && (
              <>
                <h1>
                  Successfully confirmed!
                  <br />
                  <br />
                  <span>
                    <br />
                    Hi {userInfo.firstName} {userInfo.lastName}, your email
                    address has been successfully confirmed.
                    <br />
                    <br />
                    Thank you!
                    <br />
                  </span>
                  <br />
                  <br />
                  <br />
                </h1>
                <Button onClick={handleRedirect}>
                  <span>Continue</span>{' '}
                  <FontAwesomeIcon icon={faChevronRight} />
                </Button>
              </>
            )}
          </div>
        </div>
      </MainLayout>
    </PublicPage>
  );
}
