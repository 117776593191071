import { ICharacter } from './character';
import { IEpisode } from './episode';
import { IScene } from './scene';
import { IExternalReference } from './external-reference';
import { ITopic } from './topic';
import { PlatformTypes } from './platform-types';

export enum NarrativeTypes {
    book = 'book',
    film = 'film',
    series = 'series',
}

export const ContentAgeRating: Record<number, string> = {
    0: 'G (0+)',
    7: 'Older Kids (7+)',
    13: 'Teens (13+)',
    16: 'Young Adults (16+)',
    18: 'Adults (18+)',
};

export interface INarrative {
    uid: string,
    title: string,
    description: string,
    mediaType: NarrativeTypes,
    platformType: PlatformTypes,
    fileName?: string,
    imageFile?: File,
    contentUrl?: string,
    available?: boolean,
    minAgeRating: number,
    comingSoon?: boolean,
    status?: string,

    tasksAvailableForUserCount?: number,

    episodes?: IEpisode[],
    characters?: ICharacter[],
    scenes?: IScene[],
    topics?: ITopic[],

    externalReferences?: IExternalReference[],

    selected?: boolean,

    preferredSourceName?: string;
    preferredSourceUrl?: string;

}
