import { Field, Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import React, { ReactElement, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { userGroupSendPrivateInvite } from '../../../../api/actions/user-groups/user-group-send-private-invite';
import { Button } from '../../../../components/button/button';
import { Loader } from '../../../../components/loader/loader';
import { IUserGroup } from '../../../../models/user-group';
import { IUserInvite } from '../../../../models/user-invite';
import { UserRelationshipTypes } from '../../../../models/enums/user-relationship-types';
import { IDictionary } from '../../../../types/dictionary';
import { SelectField } from '../../../../components/select/select';
import { MultiError } from '../../../../types/multi-error';

enum FormFields {
    email = 'email',
    firstName = 'firstName',
    lastName = 'lastName',
    relationship = 'relationship',
}

interface IForm {
    [FormFields.email]: string,
    [FormFields.firstName]?: string,
    [FormFields.lastName]?: string,
    [FormFields.relationship]?: string,
}

interface IProps {
    invite?: IUserInvite,
    onSuccess(): void,
}

export function UserGroupCreateInviteComponent(props: IProps) {

    const [loading, setLoading] = useState(false);

    const handleSubmit = async (
        values: IForm,
        { setSubmitting, setErrors }: { setSubmitting: (status: boolean) => void, setErrors: (errors: IDictionary<string>) => void },
    ) => {
        setLoading(true);
        try {
            //here don't send the group uid: it will be set on backend
            await userGroupSendPrivateInvite("", values.email, undefined, values.firstName, values.lastName, values.relationship);
            toast.success('Invite has been successfully sent');
            props.onSuccess();
        }
        catch (err) {
            //Todo: display error in some more visible way
            setSubmitting(false);
            setLoading(false);
            toast.error((err as MultiError).message);
        }
    };

    const getValidationSchema = () => {
        return Yup.object({
            email: Yup.string().label('Email').required().email(),
            firstName: Yup.string().label('First Name').min(2).max(50),
            lastName: Yup.string().label('First Name').min(2).max(50),
            relationship: Yup.string().label('Relationship').required()
        });
    };

    const renderLoginForm = ({ errors, touched, submitForm, values, setFieldValue }: FormikProps<IForm>): ReactElement => {
        return (
            <Form noValidate >
                <div className="form-item">
                </div>
                <div className="form-item">
                    <label>
                        <div className="form-label required">
                            Email
                        </div>
                        <Field type="email" name={FormFields.email} />
                    </label>
                    <div className="errors">{touched.email && errors.email}</div>
                </div>
                <div className="form-item">
                    <label>
                        <div className="form-label">
                            First Name
                        </div>
                        <Field type="text" name={FormFields.firstName} />
                    </label>
                    <div className="errors">{touched.firstName && errors.firstName}</div>
                </div>
                <div className="form-item">
                    <label>
                        <div className="form-label">
                            Last Name
                        </div>
                        <Field type="text" name={FormFields.lastName} />
                    </label>
                    <div className="errors">{touched.lastName && errors.lastName}</div>
                </div>
                <div className="form-item">
                  <label>
                  <div className="form-label required">
                      This person is my
                  </div>
                  <Field
                      component={SelectField}
                      name={FormFields.relationship}
                      emptyTitle=""
                      data={Object.keys(UserRelationshipTypes).map((t: string) => ({ uid: t, name: UserRelationshipTypes[t].toUpperCase() }))}
                  />
                  </label>
                  <div className="errors">{touched.relationship && errors.relationship}</div>
                </div>
                <div className="form-buttons">
                    <Button onClick={props.onSuccess} className="gray"><span>Close</span></Button>
                    { loading
                    ? (<Loader />)
                    : (<Button onClick={submitForm}><span>{props.invite ? "Resend" : "Send"} Invite</span></Button>)
                    }
                </div>
            </Form>
        );
    };

    return (
        <Formik
            initialValues={{
              email: props.invite ? props.invite.inviteeEmail : '',
              firstName: props.invite ? props.invite.inviteeFirstName : '',
              lastName: props.invite ? props.invite.inviteeLastName : '',
              relationship: props.invite && props.invite.relationship ? props.invite.relationship :  '',
            }}
            validationSchema={getValidationSchema}
            onSubmit={handleSubmit}
        >
            {renderLoginForm}
        </Formik>
    )
}
