import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { MainLayout } from '../../components/layouts/main-layout';
import { PublicPage } from '../base/public-page';
import { LoginComponent } from './login-component';
import { onSuccessLogin } from '../../utils';
import { useUser } from '../../contexts/user-context';

export function LoginPage(props: RouteComponentProps) {
  const { setUser } = useUser();

  const handleSuccessLogin = async (configurationFinished: boolean) => {
    await onSuccessLogin(configurationFinished, props.history, setUser);
  };

  return (
    <PublicPage pageName="login">
      <MainLayout>
        <LoginComponent onSuccessLogin={handleSuccessLogin} />
      </MainLayout>
    </PublicPage>
  );
}
