import * as React from 'react';
import { Link } from 'react-router-dom';
import styles from './link-button.module.scss';

interface IProps {
    children: string | Array<string | React.ReactElement>,
    onClick?: () => void,
    to?: string,
    className?: string,
}

export function LinkButton(props: IProps) {
    return props.to
    ? (
        <Link to={props.to} className={`${styles.link} ${props.className || ''}`}>
            {props.children}
        </Link>
    )
    :(
        <button type="button" onClick={props.onClick} className={`${styles.link} ${props.className || ''}`}>
            {props.children}
        </button>
    );
}
