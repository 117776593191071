import { BaseTypes } from '../types';
import { AppConfig } from '../config/app-config';

type TSomething = string | number | boolean | symbol | Record<string, unknown> | unknown;

export function openExtensionUrl() {
  //open the extension page
  if(confirm("Go to Chrome Store to install ThinkHumanTV browser extension? (This is necessary for using the service for EQ training.)")){
      window.open(AppConfig.extensionURL);
  }
}

export function isNullOrEmpty(v: any): v is undefined | null | [] | '' {
    return v === undefined
        || v === null
        || (typeof v === BaseTypes.string && !v.length)
        || (Array.isArray(v) && !v.length);
}

export function isNotNullOrEmpty<T extends TSomething>(v: T | undefined | null): v is T {
    return !isNullOrEmpty(v);
}

export function removeFields<T extends TSomething>(obj: T, fields: string[], recursively = true) {
    fields.forEach(fn => delete((obj as any)[fn]));
    if(recursively) {
        Object.keys((obj as any)).forEach(key => {
            if((obj as any)[key] && typeof (obj as any)[key] === BaseTypes.object) {
                removeFields((obj as any)[key], fields, true);
            }
        })
    }
    return obj;
}


function nextChainStep<D, R>(operation: (d: D) => R, data: D) {
    const result = operation(data);
    return {
        c: <R1>(fn: (d: R) => R1) => nextChainStep(fn, result),
        result,
    };
}

export function chain<R>(operation: () => R) {
    const result = operation();
    return {
        c: <R1>(fn: (d: R) => R1) => nextChainStep(fn, result),
        result,
    };
}
