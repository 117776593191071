import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { ReactText } from 'react';
import { Rnd } from 'react-rnd';
import { BaseTypes } from '../../types';
import { Button } from '../button/button';
import { PopupManagerContext } from './popup-manager';
import styles from './popup.module.scss';

interface IButtonConfig {
    title: string | React.ReactElement | Array<string | React.ReactElement>,
    className?: string,
    onClick?: (param?: any) => void,
    disabled?: boolean,
    param?: any,
}


interface IProps {
    title?: string | React.ReactElement | Array<string | React.ReactElement>,
    children: string
        | ReactText[]
        | React.ReactElement
        | Array<string | React.ReactElement>
        | ((moveToCenter: () => void) => string | ReactText[] | React.ReactElement | Array<string | React.ReactElement>),
    className?: string,
    zIndex?: number,
    buttons?: IButtonConfig[],
    onClose?: () => void,
    modal?: boolean,
    dragable?: boolean,
    resizable?: boolean,
    top?: number,
    left?: number,
}

interface IState {
    left?: number,
    top?: number,
}

class Popup extends React.Component<IProps & { addPopup?: (poupup: any) => string, removePopup?: (uid: string) => void, onUpdate?: () => void }, IState> {

    static defaultProps: Partial<IProps> = {
        dragable: true,
        modal: true,
    };

    popupUid: string;

    rndRef?: Rnd | null;

    observableDisableFor = 0;
    mutationObserver: null | MutationObserver = null;
    minWidth = 320;
    minHeight = 150;

    componentDidMount() {
        if(this.props.addPopup) {
            this.popupUid = this.props.addPopup(this);
        }
        if(this.rndRef) {
            this.onResize();
            // this.mutationObserver = new MutationObserver(this.onResize);
            // this.mutationObserver.observe(this.rndRef.current.getSelfElement(), { attributes: true, childList: true, subtree: true });
        }
    }

    componentWillUnmount() {
        if(this.props.removePopup && this.popupUid) {
            this.props.removePopup(this.popupUid);
        }
        // if(this.mutationObserver) {
        //     this.mutationObserver.disconnect();
        //     this.mutationObserver = null;
        // }
    }

    componentDidUpdate(prevProps: IProps) {
        if(prevProps.title !== this.props.title || prevProps.children !== this.props.children || prevProps.buttons !== this.props.buttons) {
            this.props.onUpdate?.();
        }
    }

    moveToCenter = () => {
        const top = document.documentElement.scrollTop;
        const left = document.documentElement.scrollLeft;

        const windowHeight = window.innerHeight;
        const windowWidth = window.innerWidth;

        const element = this.rndRef?.getSelfElement();
        if(element) {
            let elTop: number;
            let elLeft: number;
            const height = element.offsetHeight;
            const width = element.offsetWidth;
            elTop = Math.floor(top + ((windowHeight - height) / 2));
            if(elTop <= top) elTop = top + 20;

            elLeft = Math.floor(left + ((windowWidth - width) / 2));
            if(elLeft <= left) elLeft = left;

            this.rndRef?.updatePosition({ x: elLeft, y: elTop });
        }
    }

    renderPopup() {
        return (
            <>
                { this.props.modal && (
                    <div className={styles.locker} style={{ ...(this.props.zIndex ? { zIndex: (this.props.zIndex - 1)} : {})}}></div>
                )}
                <Rnd
                    ref={(instance) => { this.rndRef = instance; this.onResize(); }}
                    default={{
                        x: window.innerWidth - this.minWidth > 100 ? (window.innerWidth - this.minWidth) / 3 : 0,
                        y: (window.innerHeight - this.minHeight) / 4,
                        width: window.innerWidth < 400 ? window.innerWidth - 20 : 'auto',
                        height: 'auto',
                    }}
                    onDragStart={this.onDraggedOrResized}
                    onResizeStart={this.onDraggedOrResized}
                    className={`${styles.popup} ${this.props.className}`}
                    style={{ ...(this.props.zIndex ? { zIndex: this.props.zIndex} : {})}}
                    dragHandleClassName={this.props.title ? styles.header : styles.popup}
                    minWidth={this.minWidth}
                    minHeight={this.minHeight}
                    enableResizing={
                        this.props.resizable
                        ? {
                            top: true,
                            right: true,
                            bottom: true,
                            left: true,
                            topRight: true,
                            bottomRight: true,
                            bottomLeft: true,
                            topLeft:  true,
                        }
                        : {}
                    }
                    disableDragging={!this.props.dragable}
                >
                    {this.props.title && (<div className={styles.header}>
                        <div className={styles.title}>{this.props.title}</div>
                    </div>) }

                    { this.props.onClose && (<div onClick={this.props.onClose} className={styles.close}>
                            <FontAwesomeIcon icon={faTimes} />
                    </div>)}
                    <div className={styles.body}>
                    {
                        typeof this.props.children === BaseTypes.function
                        ? (this.props.children as (moveToCenter: () => void) => any)(this.moveToCenter)
                        : this.props.children
                    }
                    </div>
                    { this.props.buttons && (
                        <div className={`${styles.buttons} ${this.props.buttons && this.props.buttons.length === 1 ? styles.single : ''}`}>
                            { this.props.buttons.map((btn, i) => (
                                <Button
                                    key={i}
                                    className={`${styles.button} small ${btn.className}`}
                                    onClick={btn.onClick}
                                    disabled={btn.disabled}
                                >
                                    {btn.title}
                                </Button>
                            ))}
                        </div>
                    )}
                </Rnd>
            </>
        );
    }

    render() {
        return this.props.addPopup && this.props.removePopup
            ? (<></>)
            : this.renderPopup();
    }

    private onDraggedOrResized = () => {
        if(this.mutationObserver) {
            this.mutationObserver.disconnect();
            this.mutationObserver = null;
        }
    }

    private onResize = () => {
        // setTimeout(() => {
            if(this.rndRef) {
                this.moveToCenter();
            }
        // }, 1000);
    }
}



export function PopupContainer(props: IProps) {
    return (
        <PopupManagerContext.Consumer>
            { value => (<Popup {...props} addPopup={value?.addPopup} removePopup={value?.removePopup} onUpdate={value?.onUpdate} />)}
        </PopupManagerContext.Consumer>
    )
}

export { PopupContainer as Popup };