type Getter<T> = (obj: T) => string | number | null | undefined


export function onlyUnique<T>(value: T, index: number, self: T[], getter: (v: T) => any = (v: T) => v) {
    return self.map(getter).indexOf(getter(value)) === index;
}

export function compareNumbersDescending<T>(el1: T, el2: T, getter: Getter<T>) {
  return (!el1 && !el2) || getter(el1) === getter(el2)
      ? 0
      : (
        (!el1 && el2) || (el1 && el2 && ((getter(el1) || 0) < (getter(el2) || 0)))
        ? 1
        : -1
      );
}

export function compareText<T>(el1: T, el2: T, getter: Getter<T>) {
    return (!el1 && !el2) || getter(el1) === getter(el2)
        ? 0
        : (
            (!el1 && el2) || (el1 && el2 && (getter(el1) || '') < (getter(el2) || ''))
            ? -1
            : 1
        );
}

export function repeat(n: number, callback: () => any) {
    return Array(n).fill(1).map(callback);
}
