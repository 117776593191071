import Cookies from 'js-cookie';
import React from 'react';
import { Button } from '../../../../components/button/button';
import { LinkButton } from '../../../../components/link-button/link-button';
import { AppConfig } from '../../../../config/app-config';
import { UserRoles } from '../../../../models/user-roles-enum';
import { useCurrentUser } from '../../../../contexts/current-user-context';
import { logout } from '../../../../api/actions/logout-action';
import {
  hasMotivation,
  hasInstitutionalMotivation,
  hasPDMotivation,
} from '../../../../utils.js';

import styles from './profile-menu-component.module.scss';

export function ProfileMenuComponent(props: { children: any }) {
  const currentUser = useCurrentUser();
  const hasClasses = currentUser?.userGroups?.some(
    (ug) => ug.groupType === 'user'
  );
  const invitesAllowedForUser = React.useMemo(
    () =>
      !!(
        currentUser &&
        currentUser?.numInvitesAllowed &&
        currentUser?.numInvitesAllowed > 0
      ),
    [currentUser]
  );
  const curriculumAvailable = React.useMemo(
    () =>
      currentUser?.userGroups?.find(
        (ug) => ug.enabled && ug.curriculums?.some((c) => c.available)
      ),
    [currentUser]
  );
  const pipsAvailable = React.useMemo(
    () => !!currentUser?.canEarnPips,
    [currentUser]
  );
  const partialPlan = React.useMemo(
    () => currentUser?.userPlan?.accessType !== 'full',
    [currentUser]
  );

  const logOut = async () => {
    try {
      await logout();
    } catch {
      /** */
    }

    Cookies.remove(AppConfig.tokenName);
    document.location.href = '/login';
  };

  const support = () => {
    window.open(AppConfig.supportURL);
  };

  return (
    <div className={styles.container}>
      <div className={styles.menu}>
        <div className={styles.menuSubcontainer}>
          <LinkButton
            to="/analytics"
            className={
              location.pathname === '/analytics' || location.pathname === '/'
                ? styles.active
                : ''
            }
          >
            Profile
          </LinkButton>

          {curriculumAvailable && (
            <LinkButton
              to="/curricula"
              className={
                location.pathname === '/curricula' ? styles.active : ''
              }
            >
              Curricula
            </LinkButton>
          )}
          {hasClasses && (
            <LinkButton
              to="/my-classes"
              className={
                location.pathname === '/my-classes' ||
                location.pathname === '/my-classes/create' ||
                location.pathname.includes('/analytics/groups-statistics')
                  ? styles.active
                  : ''
              }
            >
              Classes/Groups
            </LinkButton>
          )}
          <LinkButton
            to="/account"
            className={location.pathname === '/account' ? styles.active : ''}
          >
            Account
          </LinkButton>
          {((invitesAllowedForUser && curriculumAvailable) ||
            currentUser?.role === UserRoles.admin ||
            currentUser?.role === UserRoles.editor) && (
            <LinkButton
              to="/send-invites"
              className={
                location.pathname === '/send-invites' ? styles.active : ''
              }
            >
              Invite Others
            </LinkButton>
          )}
          
          <LinkButton to="/search">Media Search</LinkButton>
            
          <div className={styles.separator} />
          {(!curriculumAvailable || partialPlan) && (
            <LinkButton
              to="/invite-code"
              className={
                location.pathname === '/invite-code' ? styles.active : ''
              }
            >
              Enter Code
            </LinkButton>
          )}
          <LinkButton onClick={support}>Support</LinkButton>
          <LinkButton onClick={logOut}>Logout</LinkButton>
        </div>
        <div className={styles.extensionBanner}>
          <strong>Getting Started with ThinkHumanTV</strong>
          <br />
          <br />
          {!currentUser || !currentUser.extensionInstalled ? (
            <div>
              Welcome! Please install the Chrome extension and go to Netflix,
              Disney+ or Peacock to begin
              {pipsAvailable && <b> training and earning PIPS</b>}.
              <br />
              <br />
              <Button to={AppConfig.extensionURL}>Install Extension</Button>
              <br />
              <br />
              <LinkButton
                onClick={() => window.open(AppConfig.gettingStartedURL)}
              >
                More help getting started
              </LinkButton>
            </div>
          ) : (
            <div>
              Welcome! You can now use the Chrome extension to
              {!hasPDMotivation(currentUser.motivation) && !hasMotivation(currentUser.motivation) && (<> build your EQ on:</>)}
              {(hasPDMotivation(currentUser.motivation) || hasMotivation(currentUser.motivation)) && (<> begin using THTV on:</>)}
              <br />
              <br />
              <Button to={AppConfig.disneyPlusURL}>Disney+</Button>
              <br />
              <br />
              <Button to={AppConfig.netflixURL}>Netflix</Button>
              <br />
              <br />
              <Button to={AppConfig.peacockURL}>Peacock</Button>
            </div>
          )}
        </div>
      </div>

      <div className={styles.content}>{props.children}</div>
    </div>
  );
}
