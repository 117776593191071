import { FetchResult, gql } from 'apollo-boost';
import { removeFields } from '../../../helpers/common-helpers';
import { IListResult } from '../../../models/list-result';
import { IUserGroup } from '../../../models/user-group';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
query UserGroupsGetPaged($page: Int, $page_size: Int, $onlyRoot: Boolean, $searchText: String, $orderBy: String, $groupType: String, $parentGroupUid: String) {
    user_groups_get_paged(page: $page, page_size: $page_size, only_root: $onlyRoot search_text: $searchText, order_by: $orderBy, group_type: $groupType, parent_group_uid: $parentGroupUid) {
        count,
        page,
        pageSize: page_size,
        data {
            uid,
            title,
            description,
            domain,
            emailConfirmationRequired: email_confirmation_required
            groupType: group_type,
            enabled,
            inviteCodeExpirationDate: invite_code_expiration_date,
            address
            city
            state
            zipcode
        }
    }
}
`;

export async function getUserGroupsPaged(params:
    {
        page: number,
        onlyRoot?: boolean,
        parentGroupUid?: string,
        searchText?: string,
        orderBy?: string[],
        groupType?: string,
        pageSize?: number,
    }
): Promise<IListResult<IUserGroup>> {
    const result: FetchResult<{ user_groups_get_paged: IListResult<IUserGroup> }> = await graphqlClient.query({
        query: QUERY,
        variables: {
            page: params.page,
            onlyRoot: params.onlyRoot,
            parentGroupUid: params.parentGroupUid,
            searchText: params.searchText,
            orderBy: params.orderBy?.join(';'),
            groupType: params.groupType,
            pageSize: params.pageSize,
        },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
      console.log(result)
      throw new MultiError(result.errors || ['Unknown error']);
    }

    return removeFields(result.data.user_groups_get_paged, ['__typename']);
}
