import { FetchResult, gql } from 'apollo-boost';
import { IUserGroupStats } from '../../../models/user-group-stats';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query UserGroupGetStatistics($userGroupUid: String!) {
        user_group_get_statistics(user_group_uid: $userGroupUid) {
            uid,
            title,

            members,
    		    avgPoints: avg_points,
    		    totalUnits: total_units,
    		    avgPassedUnits: avg_passed_units,
            avgAchievedLevel: avg_achieved_level,
            achievementUid: achievement_uid,
    		    achievementName: achievement_name,
            achievementIconFileName: achievement_icon_file_name,

            skillsAnswersTotal: skills_answers_total,
            skillsAnswersCorrect: skills_answers_correct,

            conceptsAnswersTotal: concepts_answers_total,
            conceptsAnswersCorrect: concepts_answers_correct,

            users {
                uid,
                firstName: first_name,
                lastName: last_name,
                currentLevel: current_level,
                lastEngagement: last_engagement,
                userGroups: user_groups {
                  uid
                  userPermissions: user_permissions,
                  owner {
                    uid
                  }
                }
                lastUnit: last_unit
            },
        }
    }
`;

export async function getUserGroupStats(userGroupUid: string): Promise<IUserGroupStats> {
    const result: FetchResult<{ user_group_get_statistics: IUserGroupStats }> = await graphqlClient.query({
        query: QUERY,
        variables: {
            userGroupUid,
        },
        errorPolicy: 'all',
    });

    if(!result.data || (result.errors && result.errors.length)) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    /*
      if(result.data.user_group_get_statistics?.users?.length) {
        result.data.user_group_get_statistics.users.forEach(user => {
          if(user.completedUnitsStats?.length) {
            user.completedUnitsStats.forEach(stats => {
                if(stats.data) {
                    try {
                      stats.data = JSON.parse(stats.data as any);
                      if (stats.data && stats.data.length) {
                        stats.data.forEach(el => { el.timeSeconds = (el as any).time_seconds; })
                      }
                    } catch (err) {
                      //there's something wrong with the units data
                      //todo: display via toast?
                      console.log("Error prepping user unit data: " + (err as MultiError).message);
                      user.completedUnitsStats = [];
                    }

                }
            })
          }
        })
      } */

    return result.data.user_group_get_statistics;
}
