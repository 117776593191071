import { FetchResult, gql } from 'apollo-boost';
import { IJournal } from '../../../models/journal';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query Journal($journalUid: String!) {
        journal(uid: $journalUid) {
            uid,
            content

            author {
              uid
            }
        }
    }
`;

export async function getJournal(journalUid: string): Promise<IJournal> {
    const result: FetchResult<{ journal: IJournal }> = await graphqlClient.query({
        query: QUERY,
        variables: { journalUid },
        errorPolicy: 'all',
    });

    if(!result.data || (result.errors && result.errors.length)) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.journal;
}
