import { FetchResult, gql } from 'apollo-boost';
import { IUser } from '../../models/user';
import { MultiError } from '../../types/multi-error';
import { graphqlClient } from '../graphql-client';

const MUTATION = gql`
    mutation UserConfigSetConfigurationFinished {
        user_config_set_configuration_finished {
            user {
                uid,
                email,
                configurationFinished: configuration_finished,
            }
        }
    }
`;

export async function setUserConfigurationFinished(): Promise<IUser> {
    const result: FetchResult<{ user_config_set_configuration_finished: { user: IUser }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        errorPolicy: 'all',
    });

    if(!result.data || (result.errors && result.errors.length)) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.user_config_set_configuration_finished.user;
}
