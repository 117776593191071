import {
  faArrowLeft,
  faTrashAlt,
  faUnlock,
  faLock,
} from '@fortawesome/free-solid-svg-icons';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { XCircleIcon } from '@heroicons/react/24/outline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { useCallback, useEffect, useState, useContext } from 'react';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Button } from '../../../components/button/button';
import { LinkButton } from '../../../components/link-button/link-button';
import { Loader } from '../../../components/loader/loader';
import { Block } from '../../../components/block/block';
import { DeleteQuestionPopup } from '../../../components/popup/delete-question-popup';
import { basePerformError } from '../../../helpers/error-helpers';
import { getParentPath, joinPath } from '../../../helpers/path-helpers';
import { CKEditorField } from '../../../components/ckeditor/ckeditor';
import { IUser } from '../../../models/user';
import { IUserGroup } from '../../../models/user-group';
import { IUserGroupStats } from '../../../models/user-group-stats';
import { IDictionary } from '../../../types/dictionary';
import { getCurrentUser } from '../../../api/actions/get-current-user';
import { getUserGroup } from '../../../api/actions/user-groups/user-group-get';
import { getUserGroupStats } from '../../../api/actions/user-groups/user-group-get-statistics';
import { userGroupSave } from '../../../api/actions/user-groups/user-group-save';
import { BackButton } from '../../../components/back-button/back-button';
import { SelectField } from '../../../components/select/select';
import styles from './organization-component.module.scss';
import additionalStyles from '../../user/analytics/groups-statistics/groups-statistics-page.module.scss';
import { MultiError } from '../../../types/multi-error';
import { addUserToUserGroup } from '../../../api/actions/user-group-user-add';
import { userGroupRequestEdPermissions } from '../../../api/actions/user-groups/user-group-request-permissions';
import { hasMotivation } from '../../../utils';
import { useHistory } from 'react-router-dom';
import { MembersGrid } from '../../user/analytics/components/members-grid';
import { PopupContext } from '../../../contexts/popup-context';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { usePreviousRoutes } from '../../../contexts/previous-routes-context';

enum FormFields {
  title = 'title',
  address = 'address',
  city = 'city',
  state = 'state',
  zipCode = 'zipCode',
}

interface IForm {
  [FormFields.title]: string;
  [FormFields.address]: string;
  [FormFields.city]: string;
  [FormFields.state]: string;
  [FormFields.zipCode]: string;
}

interface IProps {
  user: IUser;
  uid: string;
}

export function OrganizationComponent(props: IProps) {
  const [previousRoute, secondPreviousRoute, thirdPreviousRoute] =
    usePreviousRoutes();
  console.log(previousRoute, secondPreviousRoute, thirdPreviousRoute);
  const [loading, setLoading] = useState(false);
  const [group, setGroup] = useState<IUserGroup>();
  const [groupStats, setGroupStats] = useState<IUserGroupStats>();
  const [user, setUser] = useState<IUser>();
  const [editGroup, setEditGroup] = useState(false);
  const shouldShowPermissionRequestOption = React.useMemo(
    () =>
      group &&
      !group?.userPermissions?.length &&
      hasMotivation(props.user.motivation),
    [group, props]
  );
  const history = useHistory();

  const popupContext = useContext(PopupContext);
  if (!popupContext) throw new Error('Use TwPopup within a <PopupProvider>');
  const { setOpenPopup } = popupContext;

  const [hasSeenPopup, setHasSeenPopup] = useLocalStorage(
    'orientation-popup',
    false,
    user?.uid
  );

  const states = [
    { uid: 'AL', name: 'AL' },
    { uid: 'AK', name: 'AK' },
    { uid: 'AZ', name: 'AZ' },
    { uid: 'AR', name: 'AR' },
    { uid: 'AS', name: 'AS' },
    { uid: 'CA', name: 'CA' },
    { uid: 'CO', name: 'CO' },
    { uid: 'CT', name: 'CT' },
    { uid: 'DE', name: 'DE' },
    { uid: 'DC', name: 'DC' },
    { uid: 'FL', name: 'FL' },
    { uid: 'GA', name: 'GA' },
    { uid: 'GU', name: 'GU' },
    { uid: 'HI', name: 'HI' },
    { uid: 'ID', name: 'ID' },
    { uid: 'IL', name: 'IL' },
    { uid: 'IN', name: 'IN' },
    { uid: 'IA', name: 'IA' },
    { uid: 'KS', name: 'KS' },
    { uid: 'KY', name: 'KY' },
    { uid: 'LA', name: 'LA' },
    { uid: 'ME', name: 'ME' },
    { uid: 'MD', name: 'MD' },
    { uid: 'MA', name: 'MA' },
    { uid: 'MI', name: 'MI' },
    { uid: 'MN', name: 'MN' },
    { uid: 'MS', name: 'MS' },
    { uid: 'MS', name: 'MS' },
    { uid: 'MO', name: 'MO' },
    { uid: 'MT', name: 'MT' },
    { uid: 'NE', name: 'NE' },
    { uid: 'NV', name: 'NV' },
    { uid: 'NH', name: 'NH' },
    { uid: 'NJ', name: 'NJ' },
    { uid: 'NM', name: 'NM' },
    { uid: 'NY', name: 'NY' },
    { uid: 'NC', name: 'NC' },
    { uid: 'ND', name: 'ND' },
    { uid: 'MP', name: 'MP' },
    { uid: 'OH', name: 'OH' },
    { uid: 'OK', name: 'OK' },
    { uid: 'OR', name: 'OR' },
    { uid: 'PA', name: 'PA' },
    { uid: 'PR', name: 'PR' },
    { uid: 'RI', name: 'RI' },
    { uid: 'SC', name: 'SC' },
    { uid: 'SD', name: 'SD' },
    { uid: 'TN', name: 'TN' },
    { uid: 'TX', name: 'TX' },
    { uid: 'TT', name: 'TT' },
    { uid: 'UT', name: 'UT' },
    { uid: 'VT', name: 'VT' },
    { uid: 'VA', name: 'VA' },
    { uid: 'VI', name: 'VI' },
    { uid: 'WA', name: 'WA' },
    { uid: 'WV', name: 'WV' },
    { uid: 'WI', name: 'WI' },
    { uid: 'WY', name: 'WY' },
  ];

  const userHasAccess = (u: IUser, g: IUserGroup) => {
    if (g.owner?.uid === u?.uid) return true;

    const accessible = u?.userGroups?.find(
      (gr) => gr.uid === g.uid || gr.parent?.uid === g.uid
    );
    if (accessible) return true;
    return false;
  };

  const orgOwner = (group?: IUserGroup) => {
    return group?.owner && group.owner.uid === user?.uid;
  };

  const requestEducatorPermissions = async (g: IUserGroup) => {
    if (!g) return;

    try {
      const result = await userGroupRequestEdPermissions(g.uid);
      if (result) toast.success('Successfully sent request!');
    } catch (err) {
      basePerformError(err);
      toast.error(err);
    }
  };

  const forwardUserAndSetPopup = () => {
    const route =
      thirdPreviousRoute === '/signup' ? '/analytics' : thirdPreviousRoute;
    history.push(route || '');
    if (!hasSeenPopup) {
      setOpenPopup('signIn');
      setHasSeenPopup(true);
    }
  };

  const loadData = useCallback(
    async (uid?: string) => {
      try {
        let u;
        if (!user) {
          if (props.user) u = props.user;
          else u = await getCurrentUser();
        } else u = user;
        setUser(u);
        if (uid || props.uid !== '_') {
          const g = await getUserGroup(uid || props.uid);
          if (g && userHasAccess(u, g)) setGroup(g);
          else if (g && !userHasAccess(u, g)) {
            toast.error("You don't have access to this group.");
          }
          const stats = await getUserGroupStats(uid || props.uid);
          if (stats) setGroupStats(stats);
        } else {
          setGroup({} as IUserGroup);
        }
      } catch (err) {
        basePerformError(err);
      }
    },
    [props]
  );

  useEffect(() => {
    loadData().catch(() => {
      /** */
    });
  }, []);

  const handleSubmit = async (
    values: IForm,
    {
      setSubmitting,
    }: {
      setSubmitting: (status: boolean) => void;
      setErrors: (errors: IDictionary<string>) => void;
    }
  ) => {
    setLoading(true);
    try {
      const newOrg: Partial<IUserGroup> = {
        uid: group?.uid,
        title: values.title,
        address: values.address,
        city: values.city,
        state: values.state,
        zipCode: values.zipCode,
        groupType: 'organization',
      };

      const uid = await userGroupSave(newOrg);

      if (user && user.uid) await handleAddUser(uid, user.uid);
      await loadData(uid);
      toast.success('Organization has been successfully saved');
      forwardUserAndSetPopup();
    } catch (err) {
      //toast.error('Error: ' + (err as MultiError).message);
      basePerformError(err);
    }
    setSubmitting(false);
    setLoading(false);
  };

  const handleAddUser = async (orgUid: string, userUid: string) => {
    try {
      if (orgUid && userUid) {
        const res = await addUserToUserGroup(orgUid, userUid);
        console.log(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderForm = ({
    errors,
    touched,
    submitForm,
  }: FormikProps<IForm>): React.ReactElement => {
    return (
      <div className={styles.formContainer}>
        {group?.uid && (
          <div className="text-right">
            <XCircleIcon
              className="w-7 h-7 cursor-pointer"
              onClick={() => setEditGroup(false)}
            />
          </div>
        )}
        <Form noValidate>
          <div className="form-item">
            <label>
              <div className="form-label required">Organization Name</div>
              <Field type="text" name={FormFields.title} />
            </label>
            <div className="errors">{touched.title && errors.title}</div>
          </div>

          <div className="form-item">
            <label>
              <div className="form-label">Address</div>
              <Field component="textarea" name={FormFields.address} />
            </label>
            <div className="errors">{touched.address && errors.address}</div>
          </div>

          <div className="form-item">
            <label>
              <div className="form-label">City</div>
              <Field component="textarea" name={FormFields.city} />
            </label>
            <div className="errors">{touched.city && errors.city}</div>
          </div>

          <div className="form-item">
            <label>
              <div className="form-label">State</div>
              <Field
                component={SelectField}
                name={FormFields.state}
                emptyTitle=""
                data={states}
              />
            </label>
            <div className="errors">{errors.state}</div>
          </div>

          <div className="form-item">
            <label>
              <div className="form-label">Zip code</div>
              <Field component="textarea" name={FormFields.zipCode} />
            </label>
            <div className="errors">{touched.zipCode && errors.zipCode}</div>
          </div>

          <div className="form-buttons">
            {loading ? (
              <Loader />
            ) : (
              <>
                <Button onClick={submitForm}>
                  <span>Save</span>
                </Button>
                <Button onClick={forwardUserAndSetPopup}>
                  <span>Cancel</span>
                </Button>
              </>
            )}
          </div>
        </Form>
      </div>
    );
  };
  return !group || !user ? null : (
    <>
      {(props.uid === '_' || editGroup) && (
        <>
          <Block className={styles.editor}>
            <Formik
              initialValues={{
                title: group?.title || '',
                address: group?.address || '',
                city: group?.city || '',
                state: group?.state || '',
                zipCode: group?.zipCode || '',
              }}
              onSubmit={handleSubmit}
            >
              {renderForm}
            </Formik>
          </Block>
        </>
      )}
      {group.uid && !editGroup && (
        <>
          <div className={styles.basicInfo}>
            {group.owner?.uid === user.uid && (
              <div className={`${styles.basicItem} relative`}>
                <PencilSquareIcon
                  className="w-6 h-6 absolute top-1 right-2 cursor-pointer"
                  onClick={() => setEditGroup(true)}
                />
              </div>
            )}
            <div className={styles.basicItem}>
              <div className={styles.basicItemName}>Organization:</div>
              <div className={styles.basicItemValue}>{group.title}</div>
            </div>
            <div className={styles.basicItem}>
              <div className={styles.basicItemName}>Address:</div>
              <div className={styles.basicItemValue}>{group.address}</div>
            </div>
            <div className={styles.basicItem}>
              <div className={styles.basicItemName}>City:</div>
              <div className={styles.basicItemValue}>{group.city}</div>
            </div>
            <div className={styles.basicItem}>
              <div className={styles.basicItemName}>State:</div>
              <div className={styles.basicItemValue}>{group.state}</div>
            </div>

            <div className={styles.basicItem}>
              <div className={styles.basicItemName}>Zip Code:</div>
              <div className={styles.basicItemValue}>{group.zipCode}</div>
            </div>
            {shouldShowPermissionRequestOption && (
              <>
                <div className={styles.basicItem}>
                  <LinkButton onClick={() => requestEducatorPermissions(group)}>
                    Request educator permissions
                  </LinkButton>
                </div>
                <div className={styles.hint}>
                  (Educator permissions enable group/class creation)
                </div>
              </>
            )}
          </div>
          {orgOwner(group) && (
            <div
              className={[additionalStyles.groups, styles.basicInfo].join(' ')}
            >
              <div
                className={`${additionalStyles.groupContainer} ${
                  group.uid && additionalStyles.active
                }`}
              >
                <div className={additionalStyles.statsContainer}>
                  <MembersGrid groupStats={groupStats} />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
