import { FetchResult, gql } from 'apollo-boost';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation userGroupSendPrivateInvite($userGroupUid: String!, $email: String!, $expirationDate: DateTime, $firstName: String, $lastName: String, $relationship: String){
        user_group_send_private_invite (user_group_uid: $userGroupUid, email: $email, first_name: $firstName, last_name: $lastName, relationship: $relationship, expiration_date: $expirationDate) {
            success
        }
    }
`;

export async function userGroupSendPrivateInvite(
    userGroupUid: string,
    email: string,
    expirationDate?: string,
    firstName?: string,
    lastName?: string,
    relationship?: string
): Promise<{ success: boolean }> {
    const result: FetchResult<{ user_group_send_private_invite: { success: boolean }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: {
            userGroupUid,
            email,
            expirationDate,
            firstName,
            lastName,
            relationship,
        },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.user_group_send_private_invite;
}
