import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { MainLayout } from '../../components/layouts/main-layout';
import { LinkButton } from '../../components/link-button/link-button';
import { SecuredPage } from '../base/secured-page';

export function HomePage(props: RouteComponentProps) {

    return (
        <SecuredPage pageName="home" {...props} >
            <MainLayout>
                Home<br />
                <LinkButton to="/user-config/narratives" >Configure <FontAwesomeIcon icon={faArrowRight} /></LinkButton> <br />
                <LinkButton to="/curricula" >Curricula <FontAwesomeIcon icon={faArrowRight} /></LinkButton>
            </MainLayout>
        </SecuredPage>
    );
}
