import { FetchResult, gql } from 'apollo-boost';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation UserGroupUserAdd($userGroupUid: String!, $userUid: String!){
        user_group_user_add (user_group_uid: $userGroupUid, user_uid: $userUid) {
            success,
        }
    }
`;

export async function addUserToUserGroup(userGroupUid: string, userUid: string | undefined): Promise<{ success: boolean }> {
    if (!userUid) {
      throw new MultiError(['Undefined user when adding user to group']);
    }
    
    const result: FetchResult<{ user_group_user_add: { success: boolean } }> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { userGroupUid, userUid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.user_group_user_add;
}
