import { FetchResult, gql } from 'apollo-boost';
import { IUserPlan } from '../../../models/user-plan';
import { IUser } from '../../../models/user';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation UserManagePlan($planUid: String!){
        user_manage_plan (planUid: $planUid) {
            success,
            manage_url,
            user {
                uid,
            },
        }
    }
`;

export interface IResultManageUserPlan {
    success: boolean;
    manage_url: string;
    user: IUser;
}

export async function manageUserPlan(planUid: string): Promise<string> {
    const result: FetchResult<{ user_manage_plan: IResultManageUserPlan }> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { planUid },
        errorPolicy: 'all',
    });

    if(!result.data || (result.errors && result.errors.length)) {
        throw new MultiError(result.errors || ['Unknown error']);
    }
    console.log("result data: ", result.data.user_manage_plan);

    return result.data.user_manage_plan.manage_url;
}
