import * as React from 'react';

import styles from './horizontal-scroller.module.scss';

interface IProps {
    onClick?: (uid: number) => void;
    component: React.ComponentClass | React.FC<any>;
    data: any;
    events: any;
    width?: number;
}

export function HorizontalScrollItem(props: IProps) {
    return (
        <div
            className={`item ${styles.item}`}
            style={{ width: props.width ? `${props.width}px` : '' }}
        >
            <props.component {...props.data} events={props.events} />
        </div>
    );
}
