import { FetchResult, gql } from 'apollo-boost';
import { IScore } from '../../models/score';
import { MultiError } from '../../types/multi-error';
import { graphqlClient } from '../graphql-client';

const QUERY = gql`
    query UserGetScoresByUserUid($userUid: String) {
        user_get_scores_by_user_uid(uid: $userUid) {
            uid,
            totalAttempted: total_attempted,
            totalCorrect: total_correct,
            subscores,

            skill {
                uid,
                parent,
            },

            concept {
                uid,
                parent,
            },
        }
    }
`;

export async function getUserScores(userUid?: string): Promise<IScore[]> {
    const result: FetchResult<{ user_get_scores_by_user_uid: IScore[] }> = await graphqlClient.query({
        query: QUERY,
        variables: { userUid },
        errorPolicy: 'all',
    });

    if(!result.data || (result.errors && result.errors.length)) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.user_get_scores_by_user_uid;
}
