import { FetchResult, gql } from 'apollo-boost';
import { removeFields } from '../../helpers/common-helpers';
import { IEmotionEpisode } from '../../models/emotion-episode';
import { MultiError } from '../../types/multi-error';
import { graphqlClient } from '../graphql-client';
import useGoogleAnalytics from '../../hooks/useGoogleAnalytics';

const QUERY = gql`
    query emotionEpisodesSimilarSearch($uid: String!){
        emotion_episode_similar_search(uid: $uid) {
            uid,
            description,
            start,
            end,
            absoluteStart: absolute_start,
            mediaUrls: media_urls,
            available,
            saved,
            comment {
                uid,
                html,
                entity_uid,
            },
            participants {
              uid,

              character {
                uid,
                name,
                importance,
                gender,
                shortName: short_name,
                fileName: file_name,
              }

              emotions {
                uid,
                description,
                intensity,
                category {
                    uid,
                    label,
                }
              }
            },

            contentTags: content_tags {
                uid,
                name,
                tagType: tag_type
            },
            scene {
                uid,
                description,

                narrative {
                    uid,
                    title,
                    minAgeRating: min_age_rating,
                    externalReferences: external_references {
                      serviceName: service_name
                    }
                    fileName: file_name
                }

                episode {
                    uid,
                    name,
                    season,
                    numberInSeason: number_in_season,
                }
            }
        }
    }
`;


export async function emotionEpisodesSimilarSearch(
    // eslint-disable-next-line @typescript-eslint/ban-types
    uid: string): Promise<IEmotionEpisode[]> {
    
    const result: FetchResult<{ emotion_episode_similar_search: IEmotionEpisode[] }> = await graphqlClient.query({
        query: QUERY,
        variables: { uid },
        errorPolicy: 'all',
    });

    if (!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    if (result.data.emotion_episode_similar_search)
        return removeFields(result.data.emotion_episode_similar_search, ['__typename']);
    else return []
}
