import { FetchResult, gql } from 'apollo-boost';
import { IUnit } from '../../models/unit';
import { IUnitSection } from '../../models/unit-section';
import { MultiError } from '../../types/multi-error';
import { graphqlClient } from '../graphql-client';

const QUERY = gql`
    query Unit($unitUid: String!) {
        unit(unitUid: $unitUid) {
            uid,
            name,
            description,
            available,
            accessibleWithCurrentPlan: accessible_with_current_plan,
            type,
            educatorGuide: educator_guide,
            finalEmotionModelState: final_emotion_model_state,

            unitSections: unit_sections {
                uid,
                name,
                index,
                description,
                available,
                type,
                episodeCount: episode_count,
                exerciseParams: exercise_params,

                learningChunks: learning_chunks {
                    uid,
                    index,
                    content,
                    available,
                    type,

                    exercise {
                        uid,
                        question,
                        heading,
                        correctResponse: correct_response,
                        fileName: file_name,

                        responses {
                            uid,
                            text,
                            parent,
                            answersCount: answers_count,
                            feedback,
                        },

                        skill {
                            uid,
                        },

                        concept {
                            uid,
                        },
                    },
                },
            },

            curriculum {
                uid,
                minAgeRating: min_age_rating
            },
        }
    }
`;

export async function getUnit(unitUid: string): Promise<IUnit> {
    const result: FetchResult<{ unit: IUnit }> = await graphqlClient.query({
        query: QUERY,
        variables: { unitUid },
        errorPolicy: 'all',
    });

    if(!result.data || (result.errors && result.errors.length)) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    if(result.data.unit.unitSections) {
        result.data.unit.unitSections.map((u: IUnitSection) => (u.exerciseParams = JSON.parse(u.exerciseParams as any)));
    }

    return result.data.unit;
}
