import { History } from 'history';
import { toast } from 'react-toastify';
import { MultiError } from '../types/multi-error';

export function basePerformError(err: any, history?: History) {

    if(history && [401, 403].includes((err as MultiError).code || 0)) {
        history.push('/login');
    }
    else {
        toast.error((err as MultiError).message);
    }
}
