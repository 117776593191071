import { FetchResult, gql } from 'apollo-boost';
import { MultiError } from '../../types/multi-error';
import { graphqlClient } from '../graphql-client';

const MUTATION = gql`
    mutation UserRemoveSavedEmotionEpisode($episodeUid: String!){
        user_remove_saved_emotion_episode (episodeUid: $episodeUid) {
            success,
        }
    }
`;

export async function removeSavedEmotionEpisodeFromUser(episodeUid: string): Promise<{success: boolean}> {
    const result: FetchResult<{ user_remove_saved_emotion_episode: {success: boolean} }> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { episodeUid },
        errorPolicy: 'all',
    });

    if(!result.data || (result.errors && result.errors.length)) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.user_remove_saved_emotion_episode;
}
