import { faBatteryHalf, faHeart, faSun, faTrophy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { getUserStats } from '../../../../api/actions/get-user-stats';
import { IUserStats } from '../../../../models/user-stats';
import styles from './header-statistics.module.scss';

interface IProps {
    additionalPoints: number,
}

export function HeaderStatistics(props: IProps) {
    const [stats, setStats] = React.useState<IUserStats>();

    React.useEffect(() => {
        getUserStats().then(setStats).catch(() => {/** */});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return !stats ? null : (
        <div className={styles.status}>
            <div>
                <FontAwesomeIcon icon={faHeart} className={styles.icon} /> {(stats.points || 0) + props.additionalPoints}
            </div>
            <div>
                <FontAwesomeIcon icon={faSun} className={styles.icon} /> 1 streak days
            </div>
            <div>
                <FontAwesomeIcon icon={faTrophy} className={styles.icon} /> Level 1
            </div>
            <div>
                <FontAwesomeIcon icon={faBatteryHalf} className={styles.icon} />
                {stats && stats.totalUnits && Math.round((stats.completedUnits || 0) / (stats.totalUnits || 1) * 100)}%
            </div>
        </div>
    );
}
