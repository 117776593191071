import * as React from 'react';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RouteComponentProps } from 'react-router';
import { getCurrentUser } from '../../../api/actions/get-current-user';
import { MainLayout } from '../../../components/layouts/main-layout';
import { BackButton } from '../../../components/back-button/back-button';
import { HeaderContent } from '../../../components/header-content/header-content';
import { LinkButton } from '../../../components/link-button/link-button';
import { CurrentUserContext } from '../../../contexts/current-user-context';
import { basePerformError } from '../../../helpers/error-helpers';
import { SecuredPage } from '../../base/secured-page';
import { ProfileMenuComponent } from '../analytics/components/profile-menu-component';
import { BookmarksComponent } from './components/bookmarks-component';
import styles from './bookmarks-page.module.scss';

export function BookmarksPage(props: RouteComponentProps) {


    const currentUserContext = React.useContext(CurrentUserContext);
    React.useEffect(() => {
        (async () => {
            try {
                const usr = await getCurrentUser();
                currentUserContext?.setState(usr);

            }
            catch(err) {
                basePerformError(err, props.history);
                return;
            }
        })().catch(() => {/** */});
    }, [props]);

    return (
        <SecuredPage pageName="profile" {...props} >
            <MainLayout>
                <BackButton>
                    <LinkButton to={'/analytics'}>
                        <FontAwesomeIcon icon={faArrowLeft} /> Back to Profile
                    </LinkButton>
                </BackButton>
                <HeaderContent>
                    Marked Moments
                </HeaderContent>
                <div className={styles.container}>
                    <div className={styles.content}>
                    {currentUserContext?.state?.uid && (
                        <BookmarksComponent user={currentUserContext?.state} />
                    )}
                    </div>
                </div>
            </MainLayout>
        </SecuredPage>
    );
}
