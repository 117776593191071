import { FetchResult, gql } from 'apollo-boost';
import { MultiError } from '../../types/multi-error';
import { graphqlClient } from '../graphql-client';

const MUTATION = gql`
    mutation UserEmailConfirmationCodeCheck($code: String!){
        user_email_confirmation_code_check(code: $code) {
            firstName: first_name,
            lastName: last_name,
        }
    }
`;

export async function emailConfirmationCodeCheck(code: string): Promise<{ firstName: string, lastName: string }> {
    const result: FetchResult<{ user_email_confirmation_code_check: { firstName: string, lastName: string }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { code },
        errorPolicy: 'all',
    });

    if(!result.data || (result.errors && result.errors.length)) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.user_email_confirmation_code_check;
}
