import React from "react";
import { getCurrentUser } from "../api/actions/get-current-user";
import { createCustomContext } from "../components/context-manager/context-manager";
import { IUser } from "../models/user";

const { context: CurrentUserContext, container: CurrentUserContextContainer } = createCustomContext<IUser>(getCurrentUser);

export { CurrentUserContext, CurrentUserContextContainer };

export function useCurrentUser() {
    const currentUserContext = React.useContext(CurrentUserContext);
    return currentUserContext?.state;
}

export function useCurrentUserSetState() {
    const context = React.useContext(CurrentUserContext);
    if (!context) {
      throw new Error('useCurrentUserSetState must be used within a CurrentUserProvider');
    }
    return context.setState;
  }