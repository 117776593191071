import { FetchResult, gql } from 'apollo-boost';
import { IExercise } from '../../../models/exercise';
import { IExerciseGroup } from '../../../models/exercise-group';
import { BaseTypes } from '../../../types';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
        query ExercisesForEpisode( $id: String!, $unitSectionID: String!, $profileUid: String){
            exercises_for_episode(episodeUid:$id, unitSectionUid:$unitSectionID, profileUid: $profileUid) {
              count_as_shallow_mapped_only,
              exercises {
                    type,
                    subtype,
                    heading,
                    initialPrompt: initial_prompt,
                    question,
                    user_answer_reference,
                    terminology,
                    userInputOptional: user_input_optional,
                    previousAnswerDependent: previous_answer_dependent,
                    example,
                    responses {
                        uid,
                        text,
                        altText: alt_text,
                        definition,
                        imageFileName: image_file_name,
                        answers_count,
                        feedback,
                        color,
                        index,
                        parent,
                        upvoteCount: upvote_count,
                        downvoteCount: downvote_count,
                        userVote: user_vote,
                        upvoteChoice: upvote_choice,
                        downvoteChoice: downvote_choice,
                        parentSelectable: parent_selectable,
                        friend_answers {
                          firstName: first_name,
                          lastName: last_name,
                          uid
                        }
                    },
                    correctResponse: correct_response,
                    character_uid,
                    skill {
                        uid
                    },
                    concept {
                        uid
                    }
                  }
            }
        }
`;

export async function getExercisesForEpisode(segmentID: string, unitSectionID: string, profileUid: string | null): Promise<IExerciseGroup> {
    const result: FetchResult<{ exercises_for_episode: IExerciseGroup }> = await graphqlClient.query({
        query: QUERY,
        variables: {
            id: segmentID,
            unitSectionID: unitSectionID,
            profileUid: profileUid
        },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.exercises_for_episode;
}
