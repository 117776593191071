import React, { ReactElement, useCallback, useState } from 'react';
import { IUser } from '../../../../models/user';
import { faExternalLinkAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { compareText, compareNumbersDescending } from '../../../../helpers/array-helpers';
import { secondsToTimeString } from '../../../../helpers/datetime-helpers';
import { SelectField } from '../../../../components/select/select';
import { Button } from '../../../../components/button/button';
import { Loader } from '../../../../components/loader/loader';
import { LinkButton } from '../../../../components/link-button/link-button';
import { IJournal } from '../../../../models/journal';
import { UserRoles } from '../../../../models/user-roles-enum';
import { IDictionary } from '../../../../types/dictionary';
import { basePerformError } from '../../../../helpers/error-helpers';
import { AppConfig } from '../../../../config/app-config';
import { joinPath } from '../../../../helpers/path-helpers';
import { RouteComponentProps } from 'react-router-dom';
import * as Yup from 'yup';

import { toast } from 'react-toastify';
import { getJournals } from '../../../../api/actions/journals/get-journals';
import { deleteJournal } from '../../../../api/actions/journals/delete-journal';
import moment from 'moment';

import styles from './journals-component.module.scss';

interface IProps {
    user: IUser,
    url?: string,
    asComponent: boolean
}

export function JournalsComponent(props: IProps) {

  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [journals, setJournals] = useState<IJournal[]>([]);
  const [error, setError] = React.useState<string>();

  const isSuperUser = React.useMemo(() => props.user && props.user.role && props.user.role !== UserRoles.user, [props]);

    React.useEffect(() => {
      (async () => {
          try {
            //console.log("fetching saved journals for user");
            setJournals(await getJournals(props.user.uid));
          }
          catch(err) {
              basePerformError(err);
              return;
          }

      })().catch(() => {/** */});
    }, [props]);

    const goToJournal = (uid: string) => {
      //console.log("go to journal");
      location.href = joinPath(props.url, uid);
    }

    const getJournalPreview = (content: string) => {
      return "Preview";
    }

    const renderButtons = () => (
        <div className={styles.buttons}>
            <Button className="orange" to={joinPath(props.url, '_')}>New Journal</Button>
        </div>
    );

    return (<>
      <div>
        {loading ? (<Loader />) : (<>
            <div className="list-title">
                <div>Journals: <strong>{ (journals?.length) || 0 }</strong></div>
            </div>
            <div>
              {!props.asComponent && renderButtons()}
            </div>
            { !journals?.length ? null : (
                <table cellPadding="0" cellSpacing="0" className={`list ${styles.list}`}>
                    <thead>
                        <tr>
                            <th>Submitted</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            journals?.sort((ee1, ee2) => compareNumbersDescending(ee1, ee2, (ee) => parseInt(ee.createdAt || '0')))
                            .map((ee: IJournal) => (
                                <tr key={ee.uid}>
                                    <td>
                                        <div className={styles.markContainer}>
                                            <div className={styles.title}>
                                                <div>
                                                  {moment(ee.createdAt).format('MM/DD/YYYY h:mm a')}
                                                </div>
                                            </div>
                                        </div>
                                    </td>

                                    <td>
                                    <LinkButton onClick={() => goToJournal(ee.uid)} className='green'
                                    >
                                        <FontAwesomeIcon icon={faExternalLinkAlt} /> View
                                    </LinkButton><br/>

                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            )}
        </>)}
    </div>
    </>);
}
