import React, { useState, useContext, useEffect } from 'react';
import { Grid } from '../../../../components/grid/grid';
import { IUser } from '../../../../models/user';
import { IUserGroup } from '../../../../models/user-group';
import { compareText } from '../../../../helpers/array-helpers';
import { IUserGroupStats } from '../../../../models/user-group-stats';
import styles from '../groups-statistics/groups-statistics-page.module.scss';
import { isNotNullOrEmpty } from '../../../../helpers/common-helpers';
import { LinkButton } from '../../../../components/link-button/link-button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie, faUser, faTrash } from '@fortawesome/free-solid-svg-icons';
import { UserStatsComponent } from './user-stats-component';
import { TwPopup } from '../../../../components/popup/tw-popup';
import { PopupContext } from '../../../../contexts/popup-context';
import { ReactComponent as Logo } from '../../../../images/logo.svg';
import { toast } from 'react-toastify';
import { MultiError } from '../../../../types/multi-error';
import { userGrantGroupPermissions } from '../../../../api/actions/user/user-grant-group-permissions';
import { userRevokeGroupPermissions } from '../../../../api/actions/user/user-revoke-group-permissions';
import { UserPermissionsForGroupsEnum } from '../../../../models/enums/user-permissions-for-group-enum';
import { removeUserFromUserGroup } from '../../../../api/actions/user-groups/user-group-user-remove';

interface MembersGridProps {
  groupStats: IUserGroupStats | undefined;
}

const getUserRole = (usr: IUser, uid: string) => {
  const userGroup = usr.userGroups?.find((group) => group.uid === uid);
  if (userGroup) {
    return userGroup.userPermissions && userGroup.userPermissions.length >= 1
      ? 'Instructor'
      : 'Learner';
  }
  return 'Learner';
};

const orgOwner = (usr: IUser, uid: string | undefined) => {
  if (!uid) return false;

  const userGroup = usr.userGroups?.find((group) => group.uid === uid);
  return userGroup?.owner && userGroup.owner.uid === usr?.uid;
};

export const MembersGrid: React.FC<MembersGridProps> = ({ groupStats }) => {
  const [member, setMember] = useState<IUser>();
  const [localMembers, setLocalMembers] = useState<IUser[]>([]);
  const [isConfirming, setIsConfirming] = useState(false);
  const popupContext = useContext(PopupContext);
  if (!popupContext) throw new Error('Use TwPopup within a <PopupProvider>');
  const { setOpenPopup, openPopup } = popupContext;

  useEffect(() => {
    if (openPopup !== 'adjust-user-roles') {
      setIsConfirming(false);
    }
  }, [openPopup]);

  useEffect(() => {
    if (groupStats?.users) {
      setLocalMembers(groupStats.users);
    }
  }, [groupStats]);

  const optimisticallyUpdateMember = (
    member: IUser,
    action: string,
    groupId?: string
  ) => {
    setLocalMembers((prevMembers) => {
      if (action === 'remove') {
        return prevMembers.filter((usr) => usr.uid !== member.uid);
      }

      return prevMembers.map((usr) => {
        if (usr.uid === member.uid) {
          const updatedUser = { ...usr };
          const userGroup = updatedUser.userGroups?.find(
            (group) => group.uid === groupId
          );
          if (userGroup) {
            if (action === 'granted') {
              userGroup.userPermissions = [
                'users_management',
                'group_stats',
                'users_stats',
              ] as UserPermissionsForGroupsEnum[];
            } else {
              userGroup.userPermissions = [];
            }
          }
          return updatedUser;
        }
        return usr;
      });
    });
  };

  const handleAdjustRole = async (
    member: IUser | undefined,
    groupId: string
  ) => {
    if (!member) return;

    const mutations = {
      granted: userGrantGroupPermissions,
      revoked: userRevokeGroupPermissions,
    };

    const fields = {
      granted: 'already_granted',
      revoked: 'already_revoked',
    };

    const action =
      getUserRole(member, groupId) === 'Learner' ? 'granted' : 'revoked';

    try {
      let result;
      if (member.uid && groupId) {
        result = await mutations[action](member.uid, groupId);
        optimisticallyUpdateMember(member, action, groupId);
      }

      if (result && result.success) {
        const message = result[fields[action]]
          ? `You have already ${action} permissions to this user`
          : `Permissions successfully ${action}`;
        toast.success(message);
      }

      if (result && result.invalid_user_or_group) {
        const errMessage = 'Invalid user or group';
        toast.error(`ERROR: ${errMessage}`);
      }

      setOpenPopup(null);
    } catch (err) {
      toast.error(`ERROR: ${(err as MultiError).message}`);
    }
  };

  const handleRemoveMember = async (
    member: IUser | undefined,
    groupId: string
  ) => {
    if (!member) return;
    try {
      const res = await removeUserFromUserGroup(groupId, member.uid || '');
      if (res.success) {
        toast.success('User has been removed.');
        optimisticallyUpdateMember(member, 'remove');
      }
    } catch (error) {
      console.error(error);
      toast.error(`Something went wrong: ${error}`);
    }
    setOpenPopup(null);
  };

  return (
    <>
      <h3>Members</h3>
      <Grid<IUser>
        getPageData={(page, pageSize) => {
          const data = localMembers.sort((u1, u2) =>
            compareText(u1, u2, (u) => `${u.firstName} ${u.lastName}`)
          );

          return Promise.resolve({
            page,
            count: data?.length || 0,
            data: data?.slice(pageSize * (page - 1), pageSize * page),
          });
        }}
        containerRenderer={(content, pager) => (
          <>
            <div className={styles.usersListPager}>{pager}</div>
            <table className={styles.usersList}>
              <tbody>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Role</th>
                  <th>Last Finished Unit</th>
                  <th>Last Active</th>
                  <th>Actions</th>
                </tr>
                {content}
              </tbody>
            </table>
            <div className={styles.usersListPager}>{pager}</div>
          </>
        )}
        itemRenderer={(usr, i) => {
          return (
            <>
              <tr key={usr.uid}>
                <td>{i + 1}</td>
                <td>
                  {(isNotNullOrEmpty(
                    `${usr.firstName || ''}${usr.lastName || ''}`
                  )
                    ? `${usr.firstName} ${usr.lastName}`
                    : '--undefined--') +
                    (orgOwner(usr, groupStats?.uid) ? ' (Owner)' : '')}
                </td>
                <td>{getUserRole(usr, groupStats?.uid || '')}</td>
                <td>{usr.lastUnit}</td>
                <td>{usr.lastEngagement}</td>
                <td>
                  <LinkButton
                    className="orange"
                    onClick={() => {
                      setMember(usr);
                      setOpenPopup('user-stats');
                    }}
                  >
                    <FontAwesomeIcon icon={faChartPie} /> Stats
                  </LinkButton>

                  {!orgOwner(usr, groupStats?.uid) && (
                    <>
                      <LinkButton
                        className="orange"
                        onClick={() => {
                          setMember(usr);
                          setOpenPopup('adjust-user-roles');
                        }}
                      >
                        <FontAwesomeIcon icon={faUser} className="pl-[3px]" />{' '}
                        <span className="pl-px">User Role</span>
                      </LinkButton>

                      <LinkButton
                        className="orange"
                        onClick={() => {
                          setMember(usr);
                          setOpenPopup('remove-user');
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} className="pl-[3px]" />
                        <span className="pl-1.5">Remove</span>
                      </LinkButton>
                    </>
                  )}
                </td>
              </tr>
            </>
          );
        }}
      />
      <TwPopup
        icon={<Logo />}
        popupName="adjust-user-roles"
        actionButton={
          isConfirming && groupStats ? (
            <button
              type="button"
              className="border-0 mt-3 inline-flex w-full justify-center rounded-md bg-th-blue px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 cursor-pointer"
              onClick={() => handleAdjustRole(member, groupStats.uid)}
            >
              Confirm
            </button>
          ) : (
            <button
              type="button"
              className="border-0 mt-3 inline-flex w-full justify-center rounded-md bg-th-blue px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 cursor-pointer"
              onClick={() => setIsConfirming(true)}
            >
              Make{' '}
              {member &&
              getUserRole(member, groupStats?.uid || '') === 'Learner'
                ? 'Instructor'
                : 'Learner'}
            </button>
          )
        }
      >
        {member && (
          <>
            <div className="text-xl font-bold text-center mb-6">
              {member?.firstName} {member?.lastName} - User Role:{' '}
              {getUserRole(member, groupStats?.uid || '')}
            </div>
          </>
        )}

        <div
          className={
            isConfirming ? 'opacity-100 visible' : 'opacity-0 invisible'
          }
        >
          You are about to make this user{' '}
          {member && getUserRole(member, groupStats?.uid || '') === 'Learner'
            ? 'an Instructor'
            : 'a Learner'}
          . Is this correct?
        </div>
      </TwPopup>

      <TwPopup icon={<Logo />} popupName="user-stats">
        <div className="text-xl font-bold text-center mb-6">
          Statistics of {member?.firstName} {member?.lastName}
        </div>
        {member && (
          <UserStatsComponent
            asComponent={true}
            user={member}
            unitStats={member.completedUnitsStats}
            displayedFromPopup={true}
          />
        )}
      </TwPopup>

      <TwPopup
        icon={<Logo />}
        popupName="remove-user"
        actionButton={
          groupStats && (
            <button
              type="button"
              className="border-0 mt-3 inline-flex w-full justify-center rounded-md bg-th-blue px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 cursor-pointer"
              onClick={() => handleRemoveMember(member, groupStats.uid)}
            >
              Confirm
            </button>
          )
        }
      >
        <div className="text-xl font-bold text-center mb-6">
          Remove - {member?.firstName} {member?.lastName}
        </div>
        Are you sure you want to remove this user from this group /
        organization?
      </TwPopup>
    </>
  );
};
