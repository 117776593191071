/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useState } from 'react';
import useCalendarDates from '../../hooks/useCalendar';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

function classNames(...classes: (string | boolean)[]) {
  return classes.filter(Boolean).join(' ');
}

interface DatePickerProps {
  dateType: string;
  handleStartAndEndDates: (date: string, dateType: string) => void;
}

export const DatePicker: React.FC<DatePickerProps> = ({
  dateType,
  handleStartAndEndDates,
}) => {
  const {
    days,
    handleDateIncrement,
    handleDateDecrement,
    handleDateClick,
    currentMonth,
  } = useCalendarDates();

  // const handleDateClick = (date) => console.log(date)

  return (
    <div>
      <div className="flex items-center text-gray-900">
        <button
          onClick={handleDateDecrement}
          type="button"
          className="-m-1.5 border-0 bg-transparent flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Previous month</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <div className="flex-auto text-center font-semibold">
          {currentMonth}
        </div>
        <button
          onClick={handleDateIncrement}
          type="button"
          className="-m-1.5 border-0 bg-transparent flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Next month</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <div className="mt-6 grid grid-cols-7 text-xs text-center leading-6 text-gray-500">
        <div>M</div>
        <div>T</div>
        <div>W</div>
        <div>T</div>
        <div>F</div>
        <div>S</div>
        <div>S</div>
      </div>
      <div className="my-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
        {days.map(
          (
            day: {
              date: string;
              isCurrentMonth: boolean;
              isSelected?: boolean;
              isToday?: boolean;
            },
            dayIdx: number
          ) => (
            <button
              onClick={() => handleStartAndEndDates(day.date, dateType)}
              key={day.date}
              type="button"
              className={classNames(
                'py-1.5 hover:bg-gray-100 focus:z-10 border-0',
                day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
                // @ts-ignore
                (day.isSelected || day.isToday) && 'font-semibold',
                day.isSelected && 'text-white',
                !day.isSelected &&
                  day.isCurrentMonth &&
                  !day.isToday &&
                  'text-gray-900',
                !day.isSelected &&
                  !day.isCurrentMonth &&
                  !day.isToday &&
                  'text-gray-400',
                day.isToday && !day.isSelected && 'text-th-blue font-semibold',
                dayIdx === 0 && 'rounded-tl-lg',
                dayIdx === 6 && 'rounded-tr-lg',
                dayIdx === days.length - 7 && 'rounded-bl-lg',
                dayIdx === days.length - 1 && 'rounded-br-lg'
              )}
            >
              <time
                dateTime={typeof day.date === 'string' ? day.date : ''}
                className={classNames(
                  'mx-auto flex h-7 w-7 items-center justify-center rounded-full',
                  // @ts-ignore
                  day.isSelected && day.isToday && 'bg-indigo-600',
                  day.isSelected && !day.isToday && 'bg-gray-900'
                )}
              >
                {day.date
                  ? (day.date.split('-').pop() || '').replace(/^0/, '')
                  : ''}
              </time>
            </button>
          )
        )}
      </div>
    </div>
  );
};
