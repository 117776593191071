import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import { inviteCodeActivate } from '../../../api/actions/invite-activation';
import { getInvitesByUser } from '../../../api/actions/get-invites-by-user';
import { Button } from '../../../components/button/button';
import { MainLayout } from '../../../components/layouts/main-layout';
import { SecuredPage } from '../../base/secured-page';
import { ProfileMenuComponent } from '../analytics/components/profile-menu-component';
import { UserGroupCreateInviteComponent } from './components/user-group-create-invite-component';
import { IUser } from '../../../models/user';
import { IUserInvite } from '../../../models/user-invite';
import { IUserGroup } from '../../../models/user-group';
import { UserRoles } from '../../../models/user-roles-enum';
import { Popup } from '../../../components/popup/popup';
import { LinkButton } from '../../../components/link-button/link-button';
import { useCurrentUser } from '../../../contexts/current-user-context';
import { basePerformError } from '../../../helpers/error-helpers';
import styles from './send-invites-page.module.scss';

export function SendInvitesPage(props: RouteComponentProps) {

    const currentUser = useCurrentUser();

    const [inviteFormVisible, setInviteFormVisible] = React.useState(false);
    const [invites, setInvites] = React.useState<IUserInvite[]>();
    const [targetInvite, setTargetInvite] = React.useState <IUserInvite | undefined>();

    const numInvitesAllowed = React.useMemo(() => currentUser && currentUser?.numInvitesAllowed ? currentUser?.numInvitesAllowed : 0, [currentUser]);
    const userGroup = React.useMemo(() => currentUser?.userGroups?.find(ug => ug.enabled && ug.curriculums?.some(c => c.available)), [currentUser]);
    const isSuperUser = React.useMemo(() => currentUser && currentUser.role && currentUser.role !== UserRoles.user, [currentUser]);
    const isGroupAdminUser = React.useMemo(() => currentUser?.userGroups?.some(ug => ug.enabled && ug.userPermissions?.length), [currentUser]);

    const loadData = async () => {
      try {
              const invites = await getInvitesByUser();
              setInvites(invites);
          }
      catch(err) {
          basePerformError(err, props.history);
      }
    };

    const resendInvite = (invite: IUserInvite) => {
        setTargetInvite(invite);
        setInviteFormVisible(true);
    };

    const onInviteSent = async () => {
      setInviteFormVisible(false);
      setTargetInvite(undefined);
      try {
        await loadData();
      } catch (err) { /**/ }
    };

    React.useEffect(() => {
      loadData();
    }, []);

    return (
        <SecuredPage pageName="profile" {...props} >
            <MainLayout>
                <ProfileMenuComponent>
                    <div className={styles.content}>
                        <div>
                          <h1>Invite others to ThinkHumanTV<br /><br />
                              <span>You have sent {invites ? invites.length : 0} of {!isSuperUser && !isGroupAdminUser ? numInvitesAllowed : 999} invites. {invites && invites.length >= numInvitesAllowed && numInvitesAllowed > 0 ? "Nice work!" : invites && invites.length > 0 ? "Keep it up!" : ""}</span><br />
                              <span>{numInvitesAllowed > 0 && (!invites || invites.length == 0) && (currentUser?.role === UserRoles.user) && !isGroupAdminUser ? "What are you waiting for?!" : ""}</span><br />
                              <Button className="orange" onClick={() => setInviteFormVisible(true)} disabled={!isSuperUser && !isGroupAdminUser && invites && invites.length >= numInvitesAllowed}>
                                  <span>Send Invite</span> <FontAwesomeIcon icon={faChevronRight} />
                              </Button>
                          </h1>
                        </div>
                    </div>

                    { !!invites?.length && (
                        <table cellPadding="0" cellSpacing="0" className={`list ${styles.list}`}>
                            <thead>
                                <tr>
                                <th>#</th>
                                <th>Email</th>
                                <th>First</th>
                                <th>Last</th>
                                <th>Sent On</th>
                                <th>Status</th>
                                <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    invites.map((t: IUserInvite, i: number) => (
                                        <tr key={t.uid}>
                                        <td>
                                            <div className={styles.titleContainer}>
                                                <div className={`${styles.mark} ${t.status == 'accepted' ? '' : styles.disabled}`}>&nbsp;</div>
                                                <div className={styles.title}>
                                                    {i + 1}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                                {t.inviteeEmail}
                                            </td>
                                            <td>
                                                { t.inviteeFirstName }
                                            </td>
                                            <td>
                                                { t.inviteeLastName }
                                            </td>

                                            <td>{t.lastSentAt}</td>
                                            <td>{t.expired ? 'Expired' : (t.status === 'sent' ? 'pending' : t.status)}</td>
                                            <td>
                                            { t.status != 'accepted' && (<>
                                                <LinkButton onClick={() => resendInvite(t)} className="orange">
                                                    Resend
                                                </LinkButton><br />
                                            </>)}
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    )}

                    {inviteFormVisible && (
                        <Popup
                            title={targetInvite ? "Resend Invite" : "Send Invite"}
                            onClose={() => setInviteFormVisible(false)}
                        >
                            <UserGroupCreateInviteComponent invite={targetInvite} onSuccess={onInviteSent} />
                        </Popup>
                    )}

                </ProfileMenuComponent>
            </MainLayout>
        </SecuredPage>
    );
}
