import { FetchResult, gql } from 'apollo-boost';
import { ICharacter } from '../../models/character';
import { MultiError } from '../../types/multi-error';
import { graphqlClient } from '../graphql-client';

const QUERY = gql`
    query Characters($narrative_uids: [String]){
        characters(narrative_uids: $narrative_uids) {
            uid,
            name,
            fileName: file_name,
        }
    }
`;

export async function getCharactersByNarratives(narrativeUids: string[]): Promise<ICharacter[]> {
    const result: FetchResult<{ characters: ICharacter[]}> = await graphqlClient.query({
        query: QUERY,
        variables: { narrative_uids: narrativeUids },
        errorPolicy: 'all',
    });

    if(!result.data || (result.errors && result.errors.length)) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.characters;
}
