import * as React from 'react';
import { getExercisesGetFiltered } from '../../../../api/actions/exercise/exercises-get-filtered';
import { getNarratives } from '../../../../api/actions/get-narratives';
import { getExercisesForEpisode } from '../../../../api/actions/exercise/exercises-get-for-episode';
import { getEmotionEpisodesForNarrative } from '../../../../api/emotion-episode/emotion-episode-get-all';
import { getEmotionEpisodesForEpisode } from '../../../../api/emotion-episode/emotion-episode-get-for-episode';
import { getAllSkills } from '../../../../api/actions/skills-get-all';
import { Button } from '../../../../components/button/button';
import { IEmotionEpisode } from '../../../../models/emotion-episode';
import { IEpisode } from '../../../../models/episode';
import { INarrative, NarrativeTypes } from '../../../../models/narrative';
import { ICurriculum } from '../../../../models/curriculum';
import { IExercise, buildResponseTree } from '../../../../models/exercise';
import { IExerciseGroup } from '../../../../models/exercise-group';
import { IExerciseResponse } from '../../../../models/exercise-response';
import { IUnitSection, IUnitSectionExerciseParam } from '../../../../models/unit-section';
import { ContentAgeRating } from '../../../../models/narrative';
import { MultiError } from '../../../../types/multi-error';
import { repeat, compareText } from '../../../../helpers/array-helpers';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Select } from '../../../../components/select/select';
import { secondsToTimeString } from '../../../../helpers/datetime-helpers';
import { useEffect, useState } from 'react';
import styles from './test-section.module.scss';

interface IProps {
    unitSection?: IUnitSection;
    curriculum?: ICurriculum;
}

export function EmotionalEpisodeExercisesTester(props: IProps) {

    const [paramIndex, setParamIndex] = useState<number>(0)
    const [params, setParams] = useState<{ emotionalEpisodeUid: string, exerciseType?: string, terminology?: string, skill?: string, age: number }>();
    const [exercises, setExercises] = useState<IExercise[]>();
    const [currentExercise, setCurrentExercise] = useState<IExercise>();
    const [error, setError] = useState<string>()
    const [narratives, setNarratives] = useState<INarrative[]>();
    const [emotionEpisodes, setEmotionEpisodes] = useState<IEmotionEpisode[]>();
    const [selectedNarrativeUid, setSelectedNarrativeUid] = useState<string>();
    const [selectedEmotionEpisodeUid, setSelectedEmotionEpisodeUid] = useState<string>();
    const [selectedEpisodeUid, setSelectedEpisodeUid] = useState<string>();
    const [showEpisodes, setShowEpisodes] = useState<boolean>();
    const [episodes, setEpisodes] = useState<IEpisode[]>();


    const [loading, setLoading] = useState(false)

    useEffect(() => {
        (async () => {
            const narrativesResult = (await getNarratives())?.sort((n1, n2) => compareText(n1, n2, (n) => n.title));
            setNarratives(narrativesResult);
        })().catch(() => {/** */});

    }, [])


  const printLevel = (categories?: IExerciseResponse[], level = 0) =>
    categories &&
    categories.map((ec) => (
        <React.Fragment key={ec.text} >
            <div className={styles.answer}>
            {!!level &&
              repeat(level, () => (
                <span>
                  &raquo; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              ))}
            {ec.text}
            </div>
            {printLevel(ec.children, level + 1)}
        </React.Fragment>
    ));

    const prevParamIndexExists = () => {
        if (paramIndex <= 0) return false;
        return true;
    }

    const nextParamIndexExists = () => {
        if (!props.unitSection || !exercises || paramIndex >= exercises?.length - 1) return false;
        return true;
    }

    const goToPrevExercise = () => {
        const prevIndex = getPrevParamIndex();
        if (prevIndex != undefined && prevIndex >= 0) {
            setParamIndex(prevIndex);
            setCurrentExercise(exercises ? exercises[prevIndex]: undefined);
        }
    }

    const goToNextExercise = () => {
        const nextIndex = getNextParamIndex();
        if (nextIndex != undefined && nextIndex >= 0) {
            setParamIndex(nextIndex);
            setCurrentExercise(exercises ? exercises[nextIndex]: undefined);
        }
    }

    const getPrevParamIndex = () => {
        if (!prevParamIndexExists()) return undefined;

        return paramIndex - 1;
    }

    const getNextParamIndex = () => {
        if(!nextParamIndexExists()) return undefined;

        return paramIndex + 1;
    }

    const onChangeNarrative = async (v?: string) => {
        setSelectedNarrativeUid(v);
        setEmotionEpisodes(undefined);
        setEpisodes(undefined);
        setSelectedEmotionEpisodeUid(undefined);
        setSelectedEpisodeUid(undefined);
        
        const narrative = narratives?.find((n) => n.uid === v);

        //fetch emotion episodes
        if(narrative?.mediaType === NarrativeTypes.film && v) {
            setShowEpisodes(false);
            //console.log("narrative is a film");
            const emeps = await getEmotionEpisodesForNarrative(v);
            setEmotionEpisodes(emeps);
        } else if(narrative?.mediaType === NarrativeTypes.series && v) {
            //show seasons and episodes
            console.log("narrative is a series");
            setEpisodes(narrative?.episodes?.sort((e1, e2) => compareText(e1, e2, (e) => `${e.season}${e.numberInSeason}${e.name}`)));
        } else {
            console.log("unkown media type; uid: " + v);
        }
        
    }

    const onChangeEpisode = async (v?: string) => {
        setSelectedEpisodeUid(v);
        setEmotionEpisodes(undefined);
        setSelectedEmotionEpisodeUid(undefined);      

        if(v) {
            const emeps = await getEmotionEpisodesForEpisode(v);
            setEmotionEpisodes(emeps);
        }
    }


    const onChangeEmotionEpisode = (v?: string) => {
        setSelectedEmotionEpisodeUid(v);
        setParamIndex(0);
    }

    const getExerciseTypeExplainer = (ex: IExercise) => {
        if(ex.type === 'open_response') {
            return '(This is an open response exercise: users are able to submit their own answer.)'
        }
        return '';
    }

    const getDisclaimer = (ex: IExercise) => {
        let disclaimer = ''
        let standardDisclaimer = false;
        if(ex.type === 'open_response' && ex.responses && ex.responses.length > 0) {
            standardDisclaimer = true;
        } 
        else if (ex.type === 'mult_choice' && (ex.skill?.uid === 'belief_identification' || 
            ex.skill?.uid === 'goal_identification' || ex.skill?.uid === 'appraisal_identification' || 
            ex.skill?.uid === 'reappraisal_other' || ex.skill?.uid === 'various_emotion_causes')) {
            standardDisclaimer = true;
        }
        else if(ex.type === 'mult_choice' && ex.skill?.uid === 'appraisal_assessment') {
            disclaimer = 'Please note, content displayed during training for this type of exercise may vary.'
        }

        if(standardDisclaimer) {
            disclaimer = 'Please note, responses displayed during training for this type of exercise may vary.'
        }
        return disclaimer;
    }

    const load = React.useCallback(async () => {
        if(props.unitSection && selectedEmotionEpisodeUid) {
            //console.log("fetching exercises");
            try {
                setLoading(true);

                const exerciseGroup: IExerciseGroup | undefined = await getExercisesForEpisode(selectedEmotionEpisodeUid, props.unitSection.uid, null);
                let loadedExercises = exerciseGroup?.exercises;

                loadedExercises = loadedExercises?.filter(exercise => exercise?.question);
                //console.log("exercises: " + loadedExercises);
                setExercises(loadedExercises.length ? loadedExercises : undefined);
                setCurrentExercise(loadedExercises.length ? loadedExercises[0]: undefined);
                setParamIndex(0);

                setError(undefined);
            }
            catch(err) {
                setExercises(undefined);
                setError((err as MultiError).message);
            }
            setLoading(false);
        } else {
            setExercises(undefined);
            setCurrentExercise(undefined);
        }
    }, [selectedEmotionEpisodeUid]);

    React.useEffect(() => {
        load();
    }, [load, props]);

    return (<div className={styles.exerciseEditor}>

        <div className={styles.error}>
            {error}
        </div>

         <div className={styles.formSelectors}>
            Narrative:
            <Select<INarrative, string>
                onChange={event => onChangeNarrative(event.currentTarget.value)}
                data={narratives}
                titleExtractor={(x: any) => (x.title)}
                value={selectedNarrativeUid}
            />

            {episodes && (<>
                Episode:
                <Select<IEpisode, string>
                    onChange={event => onChangeEpisode(event.currentTarget.value)}
                    data={episodes}
                    titleExtractor={(x: any) => ('S' + x.season + 'E' + x.numberInSeason + ":" + x.name)}
                    value={selectedEpisodeUid}
                />
            </>)}


            {emotionEpisodes && (<>
                Training Segment:
                <Select<IEmotionEpisode, string>
                    onChange={event => onChangeEmotionEpisode(event.currentTarget.value)}
                    data={emotionEpisodes}
                    titleExtractor={(x: any) => (secondsToTimeString(x.start, true) + " " + x.description)}
                    value={selectedEmotionEpisodeUid}
                />


            </>)}

        </div>

        {loading ? (<>
             <div className={styles.questions}>
                <div className={styles.nothing}>
                    Loading...
                </div>
            </div>
        </>) : (<>

        {currentExercise && (
        <div className={styles.exIndex}>
                {(paramIndex + 1) + ' of ' + exercises?.length}
        </div>)}

         <div className={styles.formButtons}>
                            {prevParamIndexExists()
                                ? (<Button
                                    onClick={() => {
                                        goToPrevExercise()
                                    }}
                                    className="small outline gray"
                                >
                                    <FontAwesomeIcon icon={faArrowLeft} /> <span>Prev</span>
                                </Button>)
                                : (<div></div>)
                            }
                            {nextParamIndexExists()
                                ? (<Button
                                    onClick={() => {
                                       goToNextExercise()
                                    }}
                                    className="small outline gray"
                                >
                                    <span>Next</span> <FontAwesomeIcon icon={faArrowRight} />
                                </Button>)
                                : (<div></div>)
                            }
                        </div>
        <div className={styles.questions}>
            {!error && !exercises?.length && (
                <div className={styles.nothing}>
                    Nothing to display
                </div>
            )}
            { currentExercise && (
                <div key={currentExercise.question} className={styles.question}>
                    {currentExercise.heading && (
                        <div>
                            <strong>Heading:</strong> {currentExercise.heading}
                        </div>
                    )}
                    {currentExercise.initialPrompt && (
                        <div>
                            <strong>Initial Prompt:</strong> {currentExercise.initialPrompt}
                        </div>
                    )}
                    <div className={styles.text}>
                        <strong>Question:</strong> {currentExercise.question}
                    </div>

                    <div className={styles.text}>
                        <strong>{getExerciseTypeExplainer(currentExercise)}</strong>
                    </div>

                    <div className={styles.answers}>
                        <div className={styles.answersHeader}>{(currentExercise.type === 'open_response') ? 'Previous User Answers (Upvote/Downvote):' : 'Answers:'}</div>
                        {(currentExercise?.type === 'mult_choice' || (currentExercise?.skill?.uid !== 'emotion_labeling' && currentExercise?.skill?.uid !== 'emotion_blend_labeling' && (currentExercise?.skill?.uid !== 'regulation_strategy_self' || props.curriculum?.minAgeRating === 0))) 
                            && !!currentExercise.responses?.length && currentExercise.responses.map(response => (
                            <div key={response.text + response.uid} className={styles.answer}>
                                {response.text} {currentExercise.correctResponse === response.text && (<span className={styles.correct}>Correct response</span>)}
                                {response.feedback && (
                                    <div className={styles.answerFeedback}>
                                        Feedback: {response.feedback}
                                    </div>
                                )}
                            </div>
                        ))}

                        {(currentExercise?.type !== 'mult_choice') && (currentExercise?.skill?.uid === 'emotion_labeling' || currentExercise?.skill?.uid === 'emotion_blend_labeling' || (currentExercise?.skill?.uid === 'regulation_strategy_self' && props.curriculum?.minAgeRating !== 0 )) 
                            && currentExercise.responses?.length && 
                            printLevel(buildResponseTree(currentExercise.responses))}
                    </div>
                    <div className={styles.disclaimer}>
                        {getDisclaimer(currentExercise)}
                    </div>
                </div>
            )}
        </div>
        </>)}    

    </div>);
}
