import { FetchResult, gql } from 'apollo-boost';
import { MultiError } from '../../types/multi-error';
import { graphqlClient } from '../graphql-client';

const MUTATION = gql`
    mutation UserAddSavedEmotionEpisode($episodeUid: String!, $service: String){
        user_add_saved_emotion_episode (episodeUid: $episodeUid, service: $service) {
            success,
        }
    }
`;

export async function addSavedEmotionEpisodeToUser(episodeUid: string, service: string): Promise<{success: boolean}> {
    const result: FetchResult<{ user_add_saved_emotion_episode: {success: boolean} }> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { episodeUid, service },
        errorPolicy: 'all',
    });

    if(!result.data || (result.errors && result.errors.length)) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.user_add_saved_emotion_episode;
}
