import { FetchResult, gql } from 'apollo-boost';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation UserResetPassword($psw: String!){
        user_reset_password(psw: $psw) {
            success,
        }
    }
`;

interface IResult {
    success: boolean,
}

export async function resetPasswordRequest(password: string, code: string): Promise<IResult> {
    const result: FetchResult<{ user_reset_password_request: IResult }> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: {
            psw: password,
        },
        context: {
            headers: {
                authorization: code,
            },
        },
        errorPolicy: 'all',
    });

    if(!result.data || (result.errors && result.errors.length)) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.user_reset_password_request;
}
