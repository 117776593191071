import { FetchResult, gql } from 'apollo-boost';
import { IJournal } from '../../../models/journal';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation JournalSave($journal: JournalSaveInput!){
        journal_save (journal_data: $journal) {
            uid
        }
    }
`;

export async function saveJournal(journal: Partial<IJournal>): Promise<string> {
    const result: FetchResult<{ journal_save: { uid: string }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { journal: {
            uid: journal.uid,
            content: journal.content,
        } },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.journal_save?.uid;
}
