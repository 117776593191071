import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const PreviousRoutesContext = createContext({
  previousRoutes: [null, null, null],
});

export const PreviousRoutesProvider = ({ children }) => {
  const location = useLocation();
  const [previousRoutes, setPreviousRoutes] = useState([null, null, null]);

  useEffect(() => {
    // Update the previous routes before the location changes
    setPreviousRoutes(([prev1, prev2]) => [location.pathname, prev1, prev2]);
  }, [location]);

  return (
    <PreviousRoutesContext.Provider value={{ previousRoutes }}>
      {children}
    </PreviousRoutesContext.Provider>
  );
};

export const usePreviousRoutes = () => {
  return useContext(PreviousRoutesContext).previousRoutes;
};
