import React, { FC } from 'react';
import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { IVideoService } from '../../models/video-service';

type SelectProps = {
  selecting: string;
  ratings?: Record<number, string>;
  grades?: Record<string, string>;
  selectedOption?: string;
  video?: IVideoService[];
  handleFiltering?: (filterParam: any) => void;
  handleOptionDisplay?: (option: string) => void;
  width?: string;
  isDisabled?: boolean;
};

const SearchFilter: FC<SelectProps> = ({
  selecting,
  ratings,
  video,
  handleFiltering,
  handleOptionDisplay,
  selectedOption,
  width,
  grades,
  isDisabled,
}) => {
  let videoServices;
  if (video) {
    videoServices = video.reduce((acc, obj) => {
      acc[obj.uid] = obj.name;
      return acc;
    }, {});
  }

  let selectValues = {};
  if (ratings) selectValues = { ...ratings, 999: 'Any age rating' };
  if (video) selectValues = { ...videoServices, any: 'Any streaming service' };
  if (grades) selectValues = grades;

  const options = Object.entries(selectValues).map(([key, value]) => {
    return (
      <Menu.Item key={value as React.Key}>
        {({ active }) => (
          <a
            onClick={() => {
              handleOptionDisplay && handleOptionDisplay(value as string);
              handleFiltering && handleFiltering(key);
            }}
            href="#"
            className={classNames(
              active
                ? 'no-underline bg-gray-100 text-gray-900'
                : 'text-gray-700',
              'block px-4 py-2 text-xs sm:text-sm no-underline'
            )}
          >
            {value}
          </a>
        )}
      </Menu.Item>
    );
  });

  return (
    <Menu as="div" className="relative text-left">
      <div>
        <Menu.Button
          className={`inline-flex border-0 w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 ${
            width ? width : ''
          } ${isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
          disabled={isDisabled}
        >
          {selectedOption?.length ? selectedOption : selecting}
          <ChevronDownIcon
            className="-mr-1 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>
      <Transition
        as="div"
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">{options}</div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default SearchFilter;
