import { FetchResult, gql } from 'apollo-boost';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation JournalDelete($journalUid: String!){
        journal_delete (uid: $journalUid) {
            success,
        }
    }
`;

export async function deleteJournal(journalUid: string): Promise<{success: boolean}> {
    const result: FetchResult<{ journal_delete: {success: boolean} }> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { journalUid },
        errorPolicy: 'all',
    });

    if(!result.data || (result.errors && result.errors.length)) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.journal_delete;
}
