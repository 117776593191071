import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { isAbsoluteUrl } from '../../helpers/path-helpers';
import styles from './button.module.scss';

interface IProps extends RouteComponentProps {
  children: string | React.ReactElement | Array<string | React.ReactElement>;
  onClick?: () => void;
  to?: string;
  disabled?: boolean;
  type?: 'submit';
  className?: string;
  openInNewTab?: boolean;
}

function Button(props: IProps) {
  const navigate = () => {
    if (props.to && isAbsoluteUrl(props.to || '/')) {
      if (props.openInNewTab) {
        window.open(props.to, '_blank', 'noopener,noreferrer');
      } else {
        location.href = props.to;
      }
    } else {
      props.history.push(props.to || '/');
    }
  };

  return (
    <button
      className={`button ${styles.button} ${props.className || ''}`}
      disabled={props.disabled}
      type={props.type || 'button'}
      onClick={
        props.disabled ? undefined : props.onClick ? props.onClick : navigate
      }
    >
      {props.children}
    </button>
  );
}

const ButtonWithHistory = withRouter(Button);

export { ButtonWithHistory as Button };
