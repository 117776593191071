import { AppConfig } from '../config/app-config';
import { isNotNullOrEmpty, isNullOrEmpty } from './common-helpers';


export function isDataUrl(url: string) {
    return url && url.indexOf('data:') === 0;
}

export function isUrl(url?: string) {
    return url && /^https?:\/\//.test(url);
}

export function isAbsoluteUrl(url: string) {
    return url && /^https?:\/\//.test(url);
}


export const joinPath = (...pathParts: Array<string | null | undefined>): string => {
    if(!pathParts.length) return '';

    return pathParts.filter(isNotNullOrEmpty).map(
        (part: string, i: number) => !i ? part.replace(/(\/$)/g, '') : part.replace(/(^\/)|(\/$)/g, ''),
    ).join('/');
};

export function rootPath(path: string): string {
    return joinPath(AppConfig.path, path);
}

export function getParentPath(path: string, stepsUp: number) {
    const el = path.split('/');

    return el.slice(0, -1 * stepsUp).join('/') || "/";
}

export function imagesPath(path: string): string {
  return joinPath(AppConfig.path, 'images', path);
}

export function getFullFileName(f: string) {
    const parts = f.split(/(\/|\\)/);
    return parts[parts.length - 1];
}

export function getBasicFileName(f: string) {
    return f.replace(/--[0-9]+\./ig, '.');
}

export function getFileName(fullFileName: string): string {
    fullFileName = getFullFileName(fullFileName);
    return fullFileName.replace(/\.[^.]*$/ig, '');
}

export function getFileExt(fullFileName: string): string {
    const parts = fullFileName.split('.');
    return parts[parts.length - 1];
}
