import React, { FC, useState, useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import SearchFilter from '../../pages/search/search-filter';
import { createCustomContext } from '../context-manager/context-manager';
import { ContentAgeRating } from '../../models/narrative';
import { getAllVideoServices } from '../../api/actions/video-service/video-service-get-all';
import { IVideoService } from '../../models/video-service';

type SearchBarProps = {
  selectedServiceOption: string;
  selectedRatingOption: string;
  handleServiceFiltering: (id: any) => void;
  handleAgeFiltering: (age: any) => void;
  handleServiceOptionDisplay: (option: string) => void;
  handleRatingOptionDisplay: (option: string) => void;
};

export const {
  context: GlobalSearchContext,
  container: GlobalSearchContextContainer,
} = createCustomContext<string | null>(() => Promise.resolve(null));

export const SearchBar: FC<SearchBarProps> = ({
  handleServiceFiltering,
  handleAgeFiltering,
  handleServiceOptionDisplay,
  handleRatingOptionDisplay,
  selectedServiceOption,
  selectedRatingOption,
}) => {
  const globalSearchContext = useContext(GlobalSearchContext);
  const [videoServices, setVideoServices] = useState<IVideoService[]>([]);
  const [searchText, setSearchText] = useState<string>(
    globalSearchContext?.state || ''
  );

  const onSearch = () => {
    if (!searchText || searchText.length < 2) return;
    globalSearchContext?.setState(searchText);
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      try {
        const videoServices = await getAllVideoServices();
        setVideoServices(videoServices);
      } catch (error) {
        console.error('Failed to fetch data ', error);
      }
      return () => {
        globalSearchContext?.setState('');
      };
    })();
  }, []);

  return (
    <>
      <div className="bg-mustard sm:mt-11 sm:rounded bg-opacity-10 max-w-full block sm:flex sm:flex-nowrap sm:justify-between sm:items-center px-3 sm:px-10 pb-10 pt-20 sm:pt-10">
        <div className="mr-8 mb-2 sm:mb-0">
          <label
            htmlFor="search"
            className="text-base font-medium leading-6 text-gray-900"
          >
            Search for
          </label>
        </div>
        <div className="relative flex-grow rounded-md shadow-sm">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <FontAwesomeIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
              icon={faMagnifyingGlass}
            />
          </div>
          <input
            onChange={(ev) => setSearchText(ev.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onSearch();
              }
            }}
            value={searchText}
            type="text"
            name="seach"
            id="search"
            className="w-10/12 sm:w-full rounded-md border-0 py-2 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:ring-transparent sm:text-base sm:leading-6"
            placeholder="Type to seach emotions..."
          />
          <p className="absolute text-sm text-filter-blue top-8 left-3">
            <span className="text-gray-500 italic">E.g:</span> joy,
            gratitude
          </p>
          <div className="sm:hidden absolute inset-y-1 right-3.5">
            <button
              onClick={() => onSearch()}
              type="button"
              className="rounded-md border-none cursor-pointer ml-16 bg-mustard px-4 sm:px-8 py-1 sm:py-2 text-base text-white"
            >
              Search
            </button>
          </div>
        </div>
        <div className="hidden sm:block">
          <button
            onClick={() => onSearch()}
            type="button"
            className="rounded-md border-none cursor-pointer ml-16 bg-mustard px-8 py-2 text-base text-white"
          >
            Search
          </button>
        </div>
      </div>
      <div className="flex justify-center sm:justify-start items-center sm:px-10 py-4 mt-1 sm:mt-2 bg-filter-blue bg-opacity-10 sm:rounded">
        <div className="hidden sm:mr-16 sm:block">Filters</div>
        <div className="mr-2">
          <SearchFilter
            video={videoServices}
            selecting="Any streaming service"
            handleFiltering={handleServiceFiltering}
            handleOptionDisplay={handleServiceOptionDisplay}
            selectedOption={selectedServiceOption}
            width={'w-52'}
          />
        </div>
        <div>
          <SearchFilter
            handleFiltering={handleAgeFiltering}
            ratings={ContentAgeRating}
            selecting="Max age rating"
            handleOptionDisplay={handleRatingOptionDisplay}
            selectedOption={selectedRatingOption}
            width={'w-40'}
          />
        </div>
      </div>
    </>
  );
};
