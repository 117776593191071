import * as React from 'react';
import { RouteComponentProps, Switch, Route } from 'react-router';
import { MainLayout } from '../../components/layouts/main-layout';
import { ProfileMenuComponent } from '../user/analytics/components/profile-menu-component';
import { SecuredPage } from '../base/secured-page';
import { useCurrentUser } from '../../contexts/current-user-context';
import { ClassList } from './class-list';
import { CreateClasses } from './classes-create';

export const ClassesListPage: React.FC<RouteComponentProps> = (props) => {
  const currentUser = useCurrentUser();
  return (
    <SecuredPage pageName="my-classes" {...props}>
      <MainLayout>
        <ProfileMenuComponent>
          <Switch>
            <Route path="/my-classes/create">
              <CreateClasses />
            </Route>
            <Route path="/my-classes/edit">
              <CreateClasses />
            </Route>
            <Route path="/my-classes">
              <ClassList currentUser={currentUser} />
            </Route>
          </Switch>
        </ProfileMenuComponent>
      </MainLayout>
    </SecuredPage>
  );
};
