import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { getCurrentUser } from '../../api/actions/get-current-user';
import { MainLayout } from '../../components/layouts/main-layout';
import { CurrentUserContext } from '../../contexts/current-user-context';
import { basePerformError } from '../../helpers/error-helpers';
import { Button } from '../../components/button/button';
import { resendConfirmationEmailByUserUid } from '../../api/actions/user/resend-confirmation-email';
import { toast } from 'react-toastify';

export function OnboardingConfirmEmailPage(props: RouteComponentProps) {
  const currentUserContext = React.useContext(CurrentUserContext);
  React.useEffect(() => {
    (async () => {
      try {
        const usr = await getCurrentUser();
        currentUserContext?.setState(usr);
      } catch (err) {
        basePerformError(err, props.history);
        return;
      }
    })().catch(() => {
      /** */
    });
  }, [props]);

  const handleResend = async () => {
    if (currentUserContext?.state?.uid) {
      try {
        const res = await resendConfirmationEmailByUserUid(
          currentUserContext.state.uid
        );
        res && toast.success('Confirmation email resent.');
      } catch (error) {
        console.error('Failed to resend confirmation email:', error);
        toast.error('Unable to resend confirmation email');
      }
    }
  };

  return (
    <MainLayout hideSearch={true} hideMenu={true}>
      <div className="flex justify-center">
        <div className="flex flex-col items-center w-96 pt-8">
          {currentUserContext?.state?.uid && (
            <div className="mt-10 flex flex-col items-center">
              <div className="text-xl">
                Please check your inbox to confirm your email. 
              </div>
              <Button
                className="mt-16 text-sm font-normal"
                onClick={handleResend}
              >
                Resend Confirmation Email
              </Button>
            </div>
          )}
        </div>
      </div>
    </MainLayout>
  );
}
