import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { getCurrentUser } from '../../../api/actions/get-current-user';
import { CurrentUserContext } from '../../../contexts/current-user-context';
import { basePerformError } from '../../../helpers/error-helpers';
import { SecuredPage } from '../../base/secured-page';
import { SegmentTile } from '../../search/segment-tile';
import { Route } from 'react-router-dom';
import styles from './saved-segments-page.module.scss';

export function SavedSegmentsPage(props: RouteComponentProps) {

    const currentUserContext = React.useContext(CurrentUserContext);
    React.useEffect(() => {
        (async () => {
            try {
                const usr = await getCurrentUser();
                currentUserContext?.setState(usr);

            }
            catch(err) {
                basePerformError(err, props.history);
                return;
            }
        })().catch(() => {/** */});
    }, [props]);

    return (
        <SecuredPage pageName="profile" {...props} >
            <div className={styles.container}>
                <div className={styles.content}>
                {currentUserContext?.state?.uid && (
                    <Route path="/saved" component={SegmentTile} />
                )}
                </div>
            </div>  
        </SecuredPage>
    );
}
