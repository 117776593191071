import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { MainLayout } from '../../components/layouts/main-layout';
import { SecuredPage } from '../base/secured-page';
import { ProfileMenuComponent } from '../user/analytics/components/profile-menu-component';
import { CurriculumsList } from './curriculums-list';
import { CurrentUserContext } from '../../contexts/current-user-context';
import { getCurrentUser } from '../../api/actions/get-current-user';
import { useUser } from '../../contexts/user-context';
import { basePerformError } from '../../helpers/error-helpers';
import { Loader } from '../../components/shared/loader/loader';

export const CurriculumsPage: React.FC<RouteComponentProps> = (props) => {
  console.log(props);
  const currentUserContext = React.useContext(CurrentUserContext);
  React.useEffect(() => {
    (async () => {
      try {
        const usr = await getCurrentUser();
        currentUserContext?.setState(usr);
      } catch (err) {
        basePerformError(err, props.history);
        return;
      }
    })().catch(() => {
      /** */
    });
  }, [props]);

  const { user } = useUser();
  return (
    <SecuredPage pageName="curriculums-page-new" {...props}>
      <MainLayout>
        <ProfileMenuComponent>
          {(currentUserContext?.state?.uid) ? <CurriculumsList currentUser={currentUserContext?.state} /> : <Loader />}
        </ProfileMenuComponent>
      </MainLayout>
    </SecuredPage>
  );
};
