import {
  faBatteryHalf,
  faChartPie,
  faChevronDown,
  faChevronRight,
  faHeart,
  faTimes,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState, useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';
import { getUserGroupStats } from '../../../../api/actions/user-groups/user-group-get-statistics';
import { getUserUnitStats } from '../../../../api/actions/get-user-unit-stats';
import { getUserStats } from '../../../../api/actions/get-user-stats';
import { Grid } from '../../../../components/grid/grid';
import { MainLayout } from '../../../../components/layouts/main-layout';
import { LinkButton } from '../../../../components/link-button/link-button';
import { Loader } from '../../../../components/loader/loader';
import { Popup } from '../../../../components/popup/popup';
import { getAchievementFilePath } from '../../../../config/app-config';
import { useCurrentUser } from '../../../../contexts/current-user-context';
import { compareText, onlyUnique } from '../../../../helpers/array-helpers';
import { isNotNullOrEmpty } from '../../../../helpers/common-helpers';
import { joinPath } from '../../../../helpers/path-helpers';
import { UserPermissionsForGroupsEnum } from '../../../../models/enums/user-permissions-for-group-enum';
import { AchievementTypes } from '../../../../models/enums/achievement-types';
import { IUser } from '../../../../models/user';
import { IUserGroupStats } from '../../../../models/user-group-stats';
import { IUserUnitStats } from '../../../../models/user-unit-stats';
import { IUserStats } from '../../../../models/user-stats';
import { SecuredPage } from '../../../base/secured-page';
import { ProfileMenuComponent } from '../components/profile-menu-component';
import { UserStatsComponent } from '../components/user-stats-component';
import { UserUnitStatsComponent } from '../components/user-unit-stats-component';
import styles from './groups-statistics-page.module.scss';
import { CalendarDaysIcon } from '@heroicons/react/20/solid';
import { formatDate } from '../../../../utils';
import { ContentAgeRating } from '../../../../models/narrative';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { useHistory } from 'react-router-dom';
import { CurriculumsList } from '../../../curriculums/curriculums-list';
import { MembersGrid } from '../components/members-grid';

type RouteParams = {
  uid?: string;
};

interface LocationState {
  fromLink?: boolean;
}

export function GroupsStatisticsPage(props: RouteComponentProps) {
  const { uid } = useParams<RouteParams>();
  const history = useHistory();
  const currentUser = useCurrentUser();
  const location = useLocation<LocationState>();
  const isShowingClass = true; // location.state && location.state.fromLink;
  let groups: any[] | undefined = [];
  let hasPermissions: boolean;

  if (isShowingClass) {
    groups = currentUser?.userGroups?.filter(
      (ug) => ug.groupType === 'user' && ug.uid === uid
    );
    if (groups) {
      hasPermissions = groups[0].userPermissions.length > 0;
    }
  } else {
    groups = currentUser?.userGroups
      ?.filter((ug) => ug.enabled && ug.userPermissions?.length)
      .sort((g1, g2) => compareText(g1, g2, (g) => g.title));
  }

  const [selectedGroupUid, setSelectedGroupUid] = useState(
    uid || groups?.[0]?.uid
  );
  const [groupStats, setGroupStats] = useState<IUserGroupStats>();
  const [selectedUser, setSelectedUser] = useState<IUser>();
  const [profileStats, setProfileStats] = useState<IUserStats>();
  const [unitStats, setUnitStats] = React.useState<IUserUnitStats[]>();

  const [loading, setLoading] = useState(false);

  const groupOwner = (usr: IUser | undefined, uid: string | undefined) => {
    if (!uid || !usr) return false;

    const userGroup = usr.userGroups?.find((group) => group.uid === uid);
    return userGroup?.owner && userGroup.owner.uid === usr?.uid;
  };

  useEffect(() => {
    if (!selectedGroupUid && groups?.[0]?.uid) {
      setSelectedGroupUid(groups?.[0]?.uid);
    }

    (async () => {
      if (selectedGroupUid && groupStats?.uid !== selectedGroupUid) {
        setGroupStats(undefined);
        setUnitStats(undefined);

        const userGroup = groups?.find((ug) => ug.uid === selectedGroupUid);
        //console.log("group profile: " + userGroup?.profile?.uid);
        const uStats = await getUserUnitStats(
          currentUser?.uid,
          userGroup?.profile?.uid
        );
        if (uStats) setUnitStats(uStats);

        const profileStats = await getUserStats(
          currentUser?.uid,
          userGroup?.profile?.uid
        );
        if (profileStats) setProfileStats(profileStats);

        const stats = await getUserGroupStats(selectedGroupUid);
        //console.log("got user stats with uid: " + stats?.uid);
        setGroupStats(stats || {});

        //setLoading(false);
      }
    })().catch(() => {
      console.log('error fetching stats');
    });
    setGroupStats({} as IUserGroupStats);
    //setLoading(false);
  }, [currentUser, selectedGroupUid]);

  const achievements = useMemo(
    () =>
      profileStats?.achievementsStats
        ?.filter((a, i, self) =>
          onlyUnique(a, i, self, (ach) => ach.achievementUid)
        )
        .map((ach) => ({
          uid: ach.achievementUid,
          name: ach.achievementName,
          achievementType: ach.achievementType,
          available: ach.achievementAvailable,
          index: ach.achievementIndex,
          iconFileName: ach.achievementIconFileName,
        }))
        .sort((a1, a2) => a1.index - a2.index),
    [profileStats?.achievementsStats]
  );

  const getAchievementCount = (uid: string) => {
    let count = 0;
    if (
      !profileStats?.achievementsStats ||
      profileStats?.achievementsStats?.length === 0
    )
      return 0;

    for (const ach of profileStats?.achievementsStats) {
      if (ach.achievementUid === uid) {
        count = count + ach.count;
      }
    }
    return count;
  };

  return (
    <SecuredPage pageName="groups-statistics" {...props}>
      <MainLayout>
        <ProfileMenuComponent>
          <div className={styles.groups}>
            {groups?.map((group) => (
              <div
                key={group.uid}
                className={`${styles.groupContainer} ${
                  group.uid == selectedGroupUid && styles.active
                }`}
              >
                <div
                  className={styles.groupInfo}
                  onClick={() => setSelectedGroupUid(group.uid)}
                >
                  {group.title}
                  {(isShowingClass && groupOwner(currentUser, group.uid)) ? (
                    <PencilSquareIcon
                      className="w-6 h-6"
                      onClick={() => history.push('/my-classes/edit', group)}
                    />
                  ) : group.uid === selectedGroupUid ? (
                    <FontAwesomeIcon icon={faChevronDown} />
                  ) : (
                    <FontAwesomeIcon icon={faChevronRight} />
                  )}
                </div>
                {group.uid == selectedGroupUid && (
                  <div className={styles.statsContainer}>
                    {!groupStats && !unitStats && <Loader />}
                    {isShowingClass && (
                      <div className="pl-3 pt-3">
                        <div>
                          {' '}
                          {group.startDate && group.endDate ? (
                            <div className="flex items-center">
                              <CalendarDaysIcon
                                className="h-5 w-5 mr-2"
                                aria-hidden="true"
                              />
                              {formatDate(group.startDate)} to{' '}
                              {formatDate(group.endDate)}
                            </div>
                          ) : (
                            'No start and end dates'
                          )}
                        </div>
                        <div>
                          {group.gradeLevel
                            ? `Grade level: ${group.gradeLevel}`
                            : `Age rating:  ${
                                ContentAgeRating[group.maxAgeRating]
                              }`}
                        </div>
                      </div>
                    )}
                    {groupStats &&
                    !groupStats?.uid &&
                    (!unitStats || unitStats.length === 0) ? (
                      <>
                        <div className={styles.notAvailable}>
                          No statistics are available for this group
                        </div>
                      </>
                    ) : (
                      <h3>Group Metrics</h3>
                    )}

                    {!!achievements && !!achievements?.length && (
                      <div className={styles.topBlock}>
                        <div className={styles.stats}>
                          <h3>Achievements</h3>
                          <div className={styles.achievementsContainer}>
                            {achievements?.map((achievement) => (
                              <div
                                key={achievement.uid}
                                className={styles.achievement}
                              >
                                {achievement.achievementType ===
                                  AchievementTypes.badge && (
                                  <div className={styles.achievementIconBadge}>
                                    {getAchievementCount(achievement.uid)}
                                  </div>
                                )}
                                {!!achievement?.iconFileName && (
                                  <img
                                    className={styles.achievementIcon}
                                    alt={achievement.name}
                                    src={joinPath(
                                      getAchievementFilePath(achievement.uid),
                                      achievement.iconFileName
                                    )}
                                  />
                                )}
                                <div className={styles.achievementName}>
                                  {achievement.name}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}

                    {unitStats && unitStats.length > 0 && (
                      <UserUnitStatsComponent
                        user={currentUser}
                        unitStats={unitStats}
                      />
                    )}
                    {groupStats?.uid && hasPermissions && (
                      <>
                        <div className={styles.stats}>
                          <div className={styles.statsItem}>
                            <div className={styles.statsItemName}>Members</div>
                            <div className={styles.statsItemVal}>
                              <FontAwesomeIcon
                                icon={faUsers}
                                className={styles.icon}
                              />{' '}
                              {groupStats.members}
                            </div>
                          </div>
                          {currentUser?.userGroups
                            ?.find((ug) => ug.uid === selectedGroupUid)
                            ?.userPermissions?.includes(
                              UserPermissionsForGroupsEnum.groupStats
                            ) && (
                            <>
                              <div className={styles.statsItem}>
                                <div className={styles.statsItemName}>
                                  Avg. Experience Points
                                </div>
                                <div className={styles.statsItemVal}>
                                  <FontAwesomeIcon
                                    icon={faHeart}
                                    className={styles.icon}
                                  />{' '}
                                  {Math.round(groupStats.avgPoints)}
                                </div>
                              </div>
                              <div className={styles.statsItem}>
                                <div className={styles.statsItemName}>
                                  Avg. Curriculum Completed
                                </div>
                                <div className={styles.statsItemVal}>
                                  <FontAwesomeIcon
                                    icon={faBatteryHalf}
                                    className={styles.icon}
                                  />
                                  {Math.round(
                                    ((groupStats.avgPassedUnits || 0) /
                                      (groupStats.totalUnits || 1)) *
                                      100
                                  )}
                                  %
                                </div>
                              </div>

                              <div className={styles.statsItem}>
                                <div className={styles.statsItemName}>
                                  Avg. Skills Rating
                                </div>
                                <div className={styles.statsItemVal}>
                                  {Math.round(
                                    ((groupStats.skillsAnswersCorrect || 0) /
                                      (groupStats.skillsAnswersTotal || 1)) *
                                      100
                                  )}
                                  %
                                </div>
                              </div>
                              <div className={styles.statsItem}>
                                <div className={styles.statsItemName}>
                                  Avg. Knowledge Rating
                                </div>
                                <div className={styles.statsItemVal}>
                                  {Math.round(
                                    ((groupStats.conceptsAnswersCorrect || 0) /
                                      (groupStats.conceptsAnswersTotal || 1)) *
                                      100
                                  )}
                                  %
                                </div>
                              </div>
                            </>
                          )}
                        </div>

                        {currentUser?.userGroups
                          ?.find((ug) => ug.uid === selectedGroupUid)
                          ?.userPermissions?.includes(
                            UserPermissionsForGroupsEnum.usersStats
                          ) && (
                          <MembersGrid
                            groupStats={groupStats}
                            // setSelectedUser={setSelectedUser}
                          />
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
          {isShowingClass && (
            <CurriculumsList
              currentUser={currentUser}
            />
          )}
          {selectedUser?.uid && (
            <Popup
              title={`Statistics of ${selectedUser.firstName} ${selectedUser.lastName}`}
              onClose={() => setSelectedUser(undefined)}
              buttons={[
                {
                  title: (
                    <>
                      <FontAwesomeIcon
                        icon={faTimes}
                        className={styles.timesIcon}
                      />{' '}
                      <span>CLOSE</span>
                    </>
                  ),
                  onClick: () => setSelectedUser(undefined),
                  className: 'gray',
                },
              ]}
            >
              <UserStatsComponent
                asComponent={true}
                user={selectedUser}
                unitStats={selectedUser.completedUnitsStats}
                displayedFromPopup={true}
              />
            </Popup>
          )}
        </ProfileMenuComponent>
      </MainLayout>
    </SecuredPage>
  );
}
