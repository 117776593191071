import React, { ReactElement, useCallback, useState, useMemo, useContext } from 'react';
import moment from 'moment';
import {
  faChevronDown,
  faChevronRight,
  faCircleInfo,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IUser } from '../../../../models/user';
import { hasMotivation, hasPDMotivation } from '../../../../utils.js';
import { Field, Form, Formik, FormikProps } from 'formik';
import { compareText, onlyUnique } from '../../../../helpers/array-helpers';
import { SelectField } from '../../../../components/select/select';
import { Button } from '../../../../components/button/button';
import { PopupContext } from '../../../../contexts/popup-context';
import { Loader } from '../../../../components/loader/loader';
import { Popup } from '../../../../components/popup/popup';
import { LinkButton } from '../../../../components/link-button/link-button';
import { IUserPlan } from '../../../../models/user-plan';
import { IAchievement } from '../../../../models/achievement';
import { IUserGroup } from '../../../../models/user-group';
import { IDictionary } from '../../../../types/dictionary';
import { basePerformError } from '../../../../helpers/error-helpers';
import { AppConfig } from '../../../../config/app-config';
import { UserRoles } from '../../../../models/user-roles-enum';
import { Elements } from '@stripe/react-stripe-js';
import { UserPlanTypes } from '../../../../models/enums/user-plan-types';
import { UserPlanSectorTypes } from '../../../../models/enums/user-plan-sector-types';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../../../../images/logo.svg';
import { loadStripe } from '@stripe/stripe-js';
import * as Yup from 'yup';

import { toast } from 'react-toastify';
import { getAvailablePlansForUser } from '../../../../api/actions/user-plan/user-plan-get-available';
import { updateUserPlan } from '../../../../api/actions/user-plan/user-plan-update';
import { manageUserPlan } from '../../../../api/actions/user-plan/user-plan-manage';

import styles from './user-account-component.module.scss';

interface IForm {
  userPlan: string;
}

interface IProps {
  user: IUser;
}

export function UserAccountComponent(props: IProps) {

  const [loading, setLoading] = useState(false);
  const [userPlans, setUserPlans] = useState<IUserPlan[]>([]);
  const [certificationInfo, setCertificationInfo] = React.useState<string | undefined>(undefined);
  const [certificationInfoVisible, setCertificationInfoVisible] = useState<IAchievement | undefined>(undefined);
  const [error, setError] = React.useState<string>();
  const [selectedPlan, setSelectedPlan] = useState(
    props.user.userPlan ? props.user.userPlan.uid : ''
  );
  const [selectedGroupUid, setSelectedGroupUid] = useState<
    string | undefined
  >();
  const hasOrgs = props.user.userGroups && props.user.userGroups.length;
  
  const getCertifications = (user: IUser | undefined) => {
    const all_certs = [] as IAchievement[];
    if (!user || (!user.completedCertifications && !user.userPlan?.certifications)) return all_certs;

    const completed = user.completedCertifications ? user.completedCertifications : [] as IAchievement[];
    const available = user.userPlan?.certifications ? user.userPlan?.certifications : [] as IAchievement[];

    let results = all_certs.concat(completed, available).filter((a, i, self) => onlyUnique(a, i, self, (ach) => ach.uid));

    return results;
  }

  const certifications = useMemo(
    () => getCertifications(props.user),
    [props]
  );

  const getOrganizations = (groups: IUserGroup[] | undefined) => {
    const results = [] as IUserGroup[];
    if (!groups) return results;

    groups.forEach((group) => addOrgToArray(results, group));
    return results;
  };

  const addOrgToArray = (results: IUserGroup[], group: IUserGroup) => {
    if (
      group.groupType === 'organization' &&
      !results.find((ug) => ug.uid === group.uid)
    ) {
      results.push(group);
    } else if (
      group.parent?.groupType === 'organization' &&
      !results.find((ug) => ug.uid === group.parent?.uid)
    ) {
      results.push(group.parent);
    }
  };

  const organizations = useMemo(
    () => getOrganizations(props.user.userGroups),
    [props]
  );
  const setupCallRequired = false; /*React.useMemo(
    () =>
      props.user?.userPlan?.type === UserPlanTypes.individual &&
      props.user?.userPlan?.sector ===
        UserPlanSectorTypes.education_nonprofit &&
      !props.user?.userGroups?.some(
        (g) => g.parent || g.groupType === 'organization'
      ),
    [props]
  );*/

  React.useEffect(() => {
    (async () => {
      try {
        //console.log("fetching available plans for user");
        setUserPlans(
          (await getAvailablePlansForUser()).sort((el1, el2) =>
            compareText(el1, el2, (el) => el.name)
          )
        );
      } catch (err) {
        basePerformError(err);
        return;
      }
    })().catch(() => {
      /** */
    });
  }, [props]);

  const manageBilling = async () => {
    let billing_portal_url;
    try {
      //console.log("update billing clicked");
      billing_portal_url = await manageUserPlan(
        props.user.userPlan ? props.user.userPlan.uid : ''
      );
      if (billing_portal_url && billing_portal_url.length > 0) {
        //redirect to manage url
        //window.open(billing_portal_url);
        //props.history.push(billing_portal_url);
        document.location.href = billing_portal_url;
      }
    } catch (err) {
      /**/
    }
  };

  const handleUpdatePlanSubmit = async (
    values: IForm,
    {
      setSubmitting,
      setErrors,
    }: {
      setSubmitting: (status: boolean) => void;
      setErrors: (errors: IDictionary<string>) => void;
    }
  ) => {
    setLoading(true);
    let payment_url;
    try {
      //console.log("submitting plan update");
      payment_url = await updateUserPlan(values.userPlan);
      if (payment_url && payment_url.length > 0) {
        //redirect to payment url
        //window.open(payment_url);
        //props.history.push(payment_url);
        document.location.href = payment_url;
      }
    } catch (err) {
      /**/
    }
    setSubmitting(false);
    setLoading(false);
  };

  const getPlanDescription = (planUid: string) => {
    const plans = userPlans?.filter((p) => p.uid === planUid);
    if (plans.length > 0) {
      return plans[0].description;
    }
    return '';
  };

  const getCompletionDate = (cert: IAchievement) => {
    if(!props.user.completedCertifications || props.user.completedCertifications.length == 0) {
      return false;
    }

    const certification = props.user.completedCertifications.find((c) => c.uid == cert.uid);
    if (certification && certification.dateAchieved) {
      return moment(certification.dateAchieved).format('MM/DD/YYYY');
    }
    return ''
  }

  const isCertificationCompleted = (cert: IAchievement) => {
    if(!props.user.completedCertifications || props.user.completedCertifications.length == 0) {
      return false;
    }

    return props.user.completedCertifications.some((c) => c.uid == cert.uid);
  }

  const getValidationSchema = () => {
    return Yup.object({
      userPlan: Yup.string(),
    });
  };

  const viewGroup = (group: IUserGroup) => {
    console.log('view group');
  };

  const orgOwner = (group: IUserGroup) => {
    return group.owner && group.owner.uid === props.user.uid;
  };

  const toggleGroupSelection = (groupUid: string | undefined) => {
    if (groupUid === selectedGroupUid) {
      setSelectedGroupUid(undefined);
    } else {
      setSelectedGroupUid(groupUid);
    }
  };

  const getCertificationInfo = (cert: IAchievement | undefined) => {
    if (!cert) {
      return 'No completion information available';
    }
    let description = cert.description;
    if(props.user.availableCertifications) {
      const curriculum_cert = props.user.availableCertifications.find((curr_cert) => curr_cert.achievement?.uid === cert.uid);
      if (curriculum_cert && curriculum_cert.completionInfo) {
        description = description + ' ' + curriculum_cert.completionInfo
      }
    }
    return description;
  }

  const renderPlanUpdateForm = ({
    errors,
    touched,
    submitForm,
    values,
  }: FormikProps<IForm>): ReactElement => {
    return (
      <>
        {setupCallRequired && (
          <div className={styles.info}>
            <div className={styles.message}>
              In order to access all of ThinkHumanTV functionality please
              schedule a setup and training session using this
              <LinkButton
                onClick={() => window.open(AppConfig.scheduleCallURL)}
              >
                link.
              </LinkButton>
            </div>
          </div>
        )}

        <div className={styles.container}>
          <div className={styles.subcontainer}>


            {hasPDMotivation(props.user.motivation) && (props.user.userPlan?.free || !props.user.userPlan?.certifications || props.user.userPlan?.certifications.length == 0) && (<>
            <div className={styles.centered}>
              <div className={styles.vspaced}>
                For professional development certifications, please choose the program you wish to complete.
               </div> 
            </div>
            </>)}

            <Form noValidate>
              <div className="form-item">
                <label>
                  <div className="form-label">Program or Subscription Type</div>
                  <Field
                    component={SelectField}
                    name="userPlan"
                    emptyTitle=""
                    data={userPlans
                      ?.sort((a1, a2) =>
                        a1.name > a2.name ? 1 : a1.name < a2.name ? -1 : 0
                      )
                      .map((a: IUserPlan) => ({
                        uid: a.uid,
                        name:
                          a.name +
                          (props.user.userPlan &&
                          a.uid === props.user.userPlan.uid
                            ? props.user.subscriptionStatus &&
                              !props.user.userPlan.free
                              ? ' (' + props.user.subscriptionStatus + ')'
                              : ' (active)'
                            : ''),
                      }))}
                  />
                </label>
                <div className="errors">
                  {touched.userPlan && errors.userPlan}
                </div>
                {values.userPlan && (
                  <div>{getPlanDescription(values.userPlan)}</div>
                )}
              </div>
              <div className="form-buttons single">
                {loading ? (
                  <Loader />
                ) : (
                  <Button
                    onClick={submitForm}
                    disabled={
                      (!values.userPlan) ||
                      (props.user.userPlan &&
                        values.userPlan === props.user.userPlan.uid)
                    }
                  >
                    Update
                  </Button>
                )}
              </div>

              {props.user.userPlan && !props.user.userPlan.free && (
              <div className={styles.centered}>
                <div className={styles.extraVspaced}>
                  <LinkButton onClick={manageBilling} className="">
                    Manage Billing
                  </LinkButton>
                </div>
              </div>
              )}

            <div className={styles.centered}>
              <div className={styles.vspaced}>
                <Button
                  onClick={() => {
                    window.open(AppConfig.comparePlansURL);
                  }}
                >
                  Compare Plans
                </Button>
              </div>
            </div>

            {hasMotivation(props.user.motivation) && (<>
            <div className={styles.centered}>
                If you are an educator, administrator or mental health professional, please get in touch with us for discounted pricing options: contact@thinkhuman.tv. 
            </div>
            <div className={styles.centered}>
              <div className={styles.vspaced}>
                <LinkButton onClick={() => {window.open('mailto:' + AppConfig.contactEmail)}}>
                  Get in touch
                </LinkButton>
              </div>
            </div>
            </>)}

            </Form>

          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {props.user && (
        <div className={styles.container}>
          <div className={styles.basicInfo}>
            <div className={styles.basicItem}>
              <div className={styles.basicItemName}>Name:</div>
              <div className={styles.basicItemValue}>
                {props.user.firstName + ' ' + props.user.lastName}
              </div>
            </div>
            <div className={styles.basicItem}>
              <div className={styles.basicItemName}>Email:</div>
              <div className={styles.basicItemValue}>{props.user.email}</div>
            </div>

              {(certifications && certifications.length > 0) && (<>
                  {certifications?.map((certification) => (            
                      <div className={styles.basicItem}>
                        <div className={styles.basicItemName}>Certification:</div>
                        <div className={styles.basicItemValue}>
                          {certification.name + (isCertificationCompleted(certification) ? (' (Completed ' + getCompletionDate(certification) + ')') : '')}
                          {!isCertificationCompleted(certification) && (
                            <LinkButton onClick={() => setCertificationInfoVisible(certification)}>
                               (Incomplete) <FontAwesomeIcon icon={faCircleInfo} />
                            </LinkButton>
                          )}
                        </div>
                      </div>
                  ))}
                </>)}

            {!organizations.length && (
              <div className="flex justify-end p-4">
                <Link to="/org-select" className={`${styles.link}`}>
                  Add Organization
                </Link>
              </div>
            )}
            <div className={styles.groups}>
              {organizations?.map((group) => (
                <div
                  key={group.parent ? group.parent.uid : group.uid}
                  className={`${styles.groupContainer} `}
                >
                  <div className={styles.groupInfo}>
                    {'Organization: '}
                    <div className={styles.groupName}>
                      <div>
                        {group.parent ? group.parent.title : group.title}
                      </div>
                      <Link
                        to={
                          'org/' + (group.parent ? group.parent.uid : group.uid)
                        }
                        className={`${styles.link}`}
                      >
                        {orgOwner(group.parent ? group.parent : group)
                          ? 'View/Edit'
                          : 'View'}
                      </Link>
                    </div>
                  </div>

                  {props.user.userGroups
                    ?.filter(
                      (ug) =>
                        ug.enabled &&
                        ug.parent?.uid ===
                          (group.parent ? group.parent.uid : group.uid)
                    )
                    .map((subgroup) => (
                      <div key={subgroup.uid} className={styles.basicItem}>
                        <div className={styles.basicItemName}></div>
                        <div className={styles.basicItemValuePlain}>
                          {subgroup.title +
                            (!subgroup.userPermissions ||
                            subgroup.userPermissions?.length === 0
                              ? ' (Member)'
                              : ' (Admin)')}
                        </div>
                      </div>
                    ))}
                </div>
              ))}

              {(!organizations || organizations.length === 0) && (
                <div className={`${styles.groupContainer}`}>
                  <div className={styles.groupInfo}>
                    {'Organization: ' +
                      (props.user.role === UserRoles.admin ||
                      props.user.role === UserRoles.editor
                        ? 'ThinkHumanTV'
                        : 'None')}
                  </div>
                </div>
              )}

        
        {certificationInfoVisible && ( <Popup
          title={certificationInfoVisible.name}
          onClose={() => setCertificationInfoVisible(undefined)}
          buttons={[
            {
              title: (
                <>
                  <span>CLOSE</span>
                </>
              ),
              onClick: () => setCertificationInfoVisible(undefined),
              className: 'gray',
            },
          ]}
        >
          {getCertificationInfo(certificationInfoVisible) || ''}
        </Popup>)}

            </div>
          </div>
        </div>

      )}

      <br />
      <Formik
        initialValues={{
          userPlan: props.user.userPlan ? props.user.userPlan.uid : '',
        }}
        validationSchema={getValidationSchema}
        onSubmit={handleUpdatePlanSubmit}
      >
        {renderPlanUpdateForm}
      </Formik>
    </>
  );
}
