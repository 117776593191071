import React from 'react';
import { TwPopup } from './tw-popup';
import { Button } from '../button/button';
import { ReactComponent as Logo } from '../../images/logo.svg';
import ilyaImg from '../../images/ilya.jpg';
import { AppConfig } from '../../config/app-config';
import { useCurrentUser } from '../../contexts/current-user-context';
import { hasMotivation, hasPDMotivation } from '../../utils';
import useLocalStorage from '../../hooks/useLocalStorage';

export const PopUpSignin = () => {
  const currentUser = useCurrentUser();
  const isFacilitator =
    currentUser?.motivation &&
    (hasMotivation(currentUser?.motivation) ||
      hasPDMotivation(currentUser?.motivation));
  const [hasSeenPopup, setHasSeenPopup] = useLocalStorage(
    'orientation-popup',
    false,
    currentUser?.uid
  );

  const handlePopupClose = () => {
    if (!hasSeenPopup) setHasSeenPopup(true);
  };

  return (
    <>
      <TwPopup
        width="w-full sm:w-[65rem]"
        icon={<Logo className="h-12 w-12" />}
        cancelBtn={false}
        popupName="signIn"
        onClose={handlePopupClose}
      >
        <div>
          <header className="text-2xl text-center mb-6">
            Get the most out of your account!
          </header>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 rounded-md">
            <div className="bg-th-blue-acqua-light rounded-md p-4 sm:p-10 text-center">
              <header className="text-lg mb-4">
                {isFacilitator
                  ? 'A guide to help you get started'
                  : 'Watch a short introductory video'}
              </header>
              <div className="bg-white rounded-md p-4 grid grid-rows-3 gap-2">
                <div>
                  <Logo className="h-20 w-20" />
                </div>
                <div className="font-semibold">
                  How to start using <br /> ThinkHumanTV
                </div>
                <div>
                  <Button
                    onClick={() =>
                      window.open(
                        isFacilitator
                          ? AppConfig.gettingStartedURL
                          : AppConfig.orientationVideoURL
                      )
                    }
                    className="rounded-lg text-white text-base bg-th-pink font-light"
                  >
                    {isFacilitator
                      ? 'Check out the Getting Started Guide'
                      : 'Watch video'}
                  </Button>
                </div>
              </div>
            </div>
            {currentUser?.motivation &&
              (hasMotivation(currentUser.motivation) ||
                hasPDMotivation(currentUser.motivation)) && (
                <div className="bg-th-blue-acqua-light rounded-md p-4 sm:p-10 text-center">
                  <header className="text-lg mb-4">A human who can help</header>
                  <div className="rounded-md p-4 grid grid-rows-3 gap-2">
                    <div>
                      <img
                        className="h-20 w-20 rounded-full border-solid border-2 border-white border-box"
                        src={ilyaImg}
                        alt="image of person"
                      />
                    </div>
                    <div className="font-semibold">
                      Speak with a team member to see how ThinkHumanTV can best
                      be used in your organization
                    </div>
                    <div>
                      <Button
                        onClick={() => window.open(AppConfig.scheduleDemoURL)}
                        className="rounded-lg text-white text-base bg-th-pink font-light"
                      >
                        Schedule a consultation
                      </Button>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </TwPopup>
    </>
  );
};
