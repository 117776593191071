import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { getCurrentUser } from '../../../api/actions/get-current-user';
import { MainLayout } from '../../../components/layouts/main-layout';
import { CurrentUserContext } from '../../../contexts/current-user-context';
import { basePerformError } from '../../../helpers/error-helpers';
import { SecuredPage } from '../../base/secured-page';
import { OrgFinder } from '../../../components/org-finder/org-finder';
import { useUser } from '../../../contexts/user-context';

export function OrgSelectPage(props: RouteComponentProps) {
  const currentUserContext = React.useContext(CurrentUserContext);
  const [selectedOrg, setSelectedOrg] = React.useState<string>('');
  const { user } = useUser();
  React.useEffect(() => {
    (async () => {
      try {
        const usr = await getCurrentUser();
        currentUserContext?.setState(usr);
      } catch (err) {
        basePerformError(err, props.history);
        return;
      }
    })().catch(() => {
      /** */
    });
  }, [props]);

  return (
    <SecuredPage pageName="org-select" {...props}>
      <MainLayout>
        <div className="flex justify-center">
          <div className="flex flex-col items-center w-96 pt-8">
            {(user?.uid || currentUserContext?.state?.uid) && (
              <OrgFinder
                user={user ? user : currentUserContext?.state}
                selectedOrg={selectedOrg}
                setSelectedOrg={setSelectedOrg}
              />
            )}
          </div>
        </div>
      </MainLayout>
    </SecuredPage>
  );
}
