import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Button } from '../../../../components/button/button';
import { GroupButtons } from '../../../../components/group-buttons/group-buttons';
import { getLearningChunkFilePath, LEARNING_CHUNK_FILE_URL_PLACEHOLDER } from '../../../../config/app-config';
import { escapeRegexpString } from '../../../../helpers/regexp-helpers';
import { ILearningChunk } from '../../../../models/learning-chunk';
import { UnitSectionTypes } from '../../../../models/unit-section';
import styles from './learning-section.module.scss';



interface IProps {
    learningChunks: ILearningChunk[];
    chunkIndex: number;
    onNextChunk: () => void;
    onPrevChunk: () => void;
    nextSectionType?: UnitSectionTypes;
}

export function LearningSection(props: IProps) {

    const chunk = props.learningChunks[props.chunkIndex];

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                { chunk && chunk.content &&
                    <div
                        className={`${styles.text} cke-content`}
                        dangerouslySetInnerHTML={{
                            __html: chunk.content.replace(
                                new RegExp(escapeRegexpString(LEARNING_CHUNK_FILE_URL_PLACEHOLDER), 'ig'),
                                getLearningChunkFilePath(chunk.uid) || '',
                            ),
                        }}
                    ></div>
                }
                { chunk && !chunk.content &&
                    <div className={styles.question}>Practical</div>
                }
                { !chunk &&
                    <div>Empty unit</div>
                }
            </div>
            <div className={styles.buttons}>
                <GroupButtons>
                    <Button
                        className="groupButton"
                        disabled={props.chunkIndex <= 0}
                        onClick={props.onPrevChunk}
                    >
                        <FontAwesomeIcon icon={faChevronLeft} /><span>Previous</span>
                    </Button>
                    { props.chunkIndex < props.learningChunks.length - 1
                        ? (<Button
                            className="groupButton"
                            disabled={props.chunkIndex >= props.learningChunks.length - 1}
                            onClick={props.onNextChunk}
                        >
                            <span>Continue</span> <FontAwesomeIcon icon={faChevronRight} />
                        </Button>)
                        : ( props.nextSectionType === UnitSectionTypes.learning
                            ? (
                                <Button className="groupButton orange" onClick={props.onNextChunk}>
                                    <span>Continue</span> <FontAwesomeIcon icon={faChevronRight} />
                                </Button>
                            )
                            : (props.nextSectionType === UnitSectionTypes.practice
                                ? (<Button className="groupButton orange" onClick={props.onNextChunk}>
                                    <span>Continue</span> <FontAwesomeIcon icon={faChevronRight} />
                                </Button>)
                                : ( <Button className="groupButton orange" onClick={props.onNextChunk}>
                                    <span>Finish</span><FontAwesomeIcon icon={faChevronRight} />
                                </Button>)
                            )
                        )
                    }
                </GroupButtons>
            </div>
        </div>
    );
}
