import { FetchResult, gql } from 'apollo-boost';
import { ILearningChunk } from '../../models/learning-chunk';
import { MultiError } from '../../types/multi-error';
import { graphqlClient } from '../graphql-client';
import { BaseTypes } from '../../types';

const QUERY = gql`
    query PracticalUnitSectionLearningChunks($unitSectionUid: String!) {
        practical_unit_section_learning_chunks(unitSectionUid: $unitSectionUid) {
            uid,
            index,
            type,
            content,
            available,

            exercise {
                type,
                subtype,
                mediaType: media_type,
                contentReference: content_reference,
                heading,
                initialPrompt: initial_prompt,
                question,
                correctResponse: correct_response,
                responses {
                    uid,
                    text,
                    parent,
                    answersCount: answers_count,
                    feedback,
                },

                character {
                    uid,
                    name,
                    fileName: file_name,
                },

                skill {
                    uid,
                },

                episode {
                    uid,
                    description,
                    start,
                    end,
                    absoluteStart: absolute_start,
                    complexity,

                    participants {
                        character {
                            uid,
                            name,
                            fileName: file_name,
                            description,
                        },
                    },

                    scene {
                        uid,
                        description,
                        fileName: file_name,
                        start,

                        narrative {
                            uid,
                            title,
                            fileName: file_name,
                            preferredSourceName: preferred_source_name,
                            preferredSourceUrl: preferred_source_url                                        
                        },

                        episode {
                            uid,
                            name,
                            season,
                            numberInSeason: number_in_season,
                        }
                    },
                }
            }
        }
    }
`;


export async function getPracticalUnitSectionLearningChunks(unitSectionUid: string): Promise<ILearningChunk[]> {
    const result: FetchResult<{ practical_unit_section_learning_chunks: ILearningChunk[] }> = await graphqlClient.query({
        query: QUERY,
        variables: { unitSectionUid },
        errorPolicy: 'all',
    });

    if(!result.data || (result.errors && result.errors.length)) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    result.data.practical_unit_section_learning_chunks.forEach((chunk: ILearningChunk) => {
        if(typeof chunk.exercise.responses === BaseTypes.string) {
            chunk.exercise.responses = JSON.parse(chunk.exercise.responses as any);
            chunk.exercise.responses.forEach(el => {
                if((el as any).answers_count !== undefined) (el as any).answersCount = (el as any).answers_count;
            })
        }
    });

    return result.data.practical_unit_section_learning_chunks;
}
