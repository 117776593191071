export enum BaseTypes {
  string = 'string',
  number = 'number',
  function = 'function',
  object = 'object',
}

export interface IComment {
  uid: string,
  html: string,
  createdAt?: string,
  entityUid: string,
  parentCommentUid?: string,
  entityType?: string,
  resolved?: boolean,
  numLikes: number,
  userLiked: boolean,
}