import _ from 'lodash';
import { Children } from 'react';
import Cookies from 'js-cookie';
import { AppConfig } from '../../config/app-config';
import { IComment } from '../../types';
import Comment_Queries from './Comment_Queries';
import { graphqlClient } from '../graphql-client';
import { FetchResult } from 'apollo-boost';
import { MultiError } from '../../types/multi-error';
import { removeFields } from '../../helpers/common-helpers';

const API_URL='https://api.dev.affectifi.com/graphql'


export async function saveComment(comment:IComment): Promise<IComment[]> {
  const result = await graphqlClient.mutate({
      mutation: Comment_Queries.saveComment,
      variables: {
        comment_data: {
          uid: comment.uid,
          html: comment.html,
          entity_uid: comment.entityUid,
          entity_type: comment.entityType,
          parent_comment_uid: comment.parentCommentUid,
          resolved: comment.resolved
        }
      },
      errorPolicy: 'all',
  });

  if(!result.data || result.errors && result.errors.length) {
      throw new MultiError(result.errors || ['Unknown error']);
  }

  return result.data;
}

export async function removeComment(uid: string): Promise<{success: boolean}> {
  const result: FetchResult<{ comment_delete: {success: boolean} }> = await graphqlClient.mutate({
      mutation: Comment_Queries.deleteComment,
      variables: { uid },
      errorPolicy: 'all',
  });

  if(!result.data || (result.errors && result.errors.length)) {
      throw new MultiError(result.errors || ['Unknown error']);
  }

  return result.data.comment_delete;
}
