import React, { useEffect, useState } from 'react';
import { processShareLink } from '../../api/actions/process-share-link';
import { Loader } from '../../components/loader/loader';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { PublicPage } from '../base/public-page';
import { MainLayout } from '../../components/layouts/main-layout';
import { LoginComponent } from '../login/login-component';
import { SignUpComponent } from '../signup/signup-component';
import { AppConfig } from '../../config/app-config';
import { LinkButton } from '../../components/link-button/link-button';
import { Link } from 'react-router-dom';
import { MultiError } from '../../types/multi-error';
import styles from './share-page.module.scss';

export function SharePage(props: RouteComponentProps<{ uid: string }>) {

  const history = useHistory();
  const message = "To view the shared content, please login or sign up.";

  const [loading, setLoading] = useState<string | undefined>('Please wait...');
  const [error, setError] = useState<string | undefined>();

  const [signedUp, setSignedUp] = useState(false);
  const [signUpVisible, setSignUpVisible] = useState(false);
  const [loginVisible, setLoginVisible] = useState(false);
  const [linkCardVisible, setLinkCardVisible] = useState(false);
  const [serviceLink, setServiceLink] = useState<string | undefined>();
  const [needsChrome, setNeedsChrome] = useState(false);
  const [needsPC, setNeedsPC] = useState(false);

  useEffect(() => {
    processShare();
  }, []);

  const showSignup = () =>
  {
    setLoginVisible(false);
    setSignUpVisible(true);
  }

  const showLogin = () =>
  {
    setLoginVisible(true);
    setSignUpVisible(false);
  }

  const signupSuccess = (userId: string) => {
      console.log("signup success");
      //setSignedUp(true);
      processShare();
  }

  const processShare = async () =>
  {
    const item_uid = props.match.params.uid;
    if(!item_uid) {
        setLoading(undefined);
        setError('Invalid share URL');
        return;
    }

    const isChrome = (navigator.userAgent.indexOf("Chrome") != -1); //!!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    const isMobile = (navigator.userAgent.indexOf("Mobi") != -1);

    try {
        const result = await processShareLink(item_uid);

        if(!isMobile && isChrome && result.success && !result.noExtension && result.url) {
            document.location.href = result.url;
        }
        else if(result.notLoggedIn) {
          if((!loginVisible && !signUpVisible)) {
            setLoginVisible(true);
          } else if(signedUp) {
            setSignUpVisible(false);
            setLoginVisible(true);
          }
        }
        else if(result.noExtension) {
            setLoginVisible(false);
            setSignUpVisible(false);
            setLinkCardVisible(true);
        }
        else if(!isChrome || isMobile) {
          setLoginVisible(false);
          setSignUpVisible(false);
          setNeedsChrome(!isChrome);
          setNeedsPC(isMobile);
          setLinkCardVisible(true);
        }
        else if(result.invalidId) {
            setError('Oops. Unrecognized share id.');
            setLinkCardVisible(false);
        }
        else {
            setError('Oops. Unknown error.');
            setLinkCardVisible(false);
        }
    }
    catch(err) {
        setError((err as MultiError).message);
        toast.error(`ERROR: ${(err as MultiError).message}`);
    }

    setLoading(undefined);
  }

  return loading ? (<Loader text={loading} />) : (<>
      <PublicPage pageName="invite_page">
          <MainLayout>
              <>
                  {error && (
                    <div className={styles.container}>
                        <div className={styles.subcontainer}>
                            <div className={styles.shareCardContainer}>
                              <div className={`${styles.error} ${"errors"}`}>{error}</div>
                            </div>
                        </div>
                    </div>
                  )}

                  {loginVisible && (
                      <LoginComponent onSuccessLogin={processShare} signupLinkCallback={showSignup} introMessage={message} />
                  )}

                  {signUpVisible && (
                      <SignUpComponent onSuccessSignUp={signupSuccess} source={'share'} loginLinkCallback={showLogin} introMessage={message} />
                  )}

                  {linkCardVisible && (
                    <div className={styles.container}>
                        <div className={styles.subcontainer}>
                            <div className={styles.shareCardContainer}>
                              <h3>You are almost there! To view the shared clip:
                              </h3>
                              {(!needsChrome && !needsPC) ? (<>
                                <div className={styles.instructionsContainer}>
                                  <span className={styles.instructions}>1. Install the ThinkHumanTV<button type="button" onClick={() => {window.open(AppConfig.extensionURL)}} className={styles.link}>Chrome extension</button>(just 2 clicks!)</span>
                                  <br/><span className={styles.instructions}>2. Log into the extension while on Netflix, Disney+, or Peacock</span>
                                  <br/><span className={styles.instructions}>3. Refresh this page or click<button type="button" className={styles.link} onClick={processShare}>here</button></span>
                                </div>
                                </>) : (<>
                                  <div className={styles.instructionsContainer}>
                                    <span className={styles.centered_instructions}>Please open this link{needsChrome ? ' in Google Chrome' : ''}{needsPC ? ' on a laptop or desktop computer' : ''}.</span>
                                  </div>
                                </>)}
                            </div>

                          </div>
                        </div>
                  )}
              </>
          </MainLayout>
      </PublicPage>
  </>);

}
