import { faArrowDown, faArrowRight, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { getCurrentUser } from '../../../api/actions/get-current-user';
import { getUnits } from '../../../api/actions/get-units';
import { Button } from '../../../components/button/button';
import { LinkButton } from '../../../components/link-button/link-button';
import { Link } from 'react-router-dom';
import { HeaderContent } from '../../../components/header-content/header-content';
import { MainLayout } from '../../../components/layouts/main-layout';
import { Loader } from '../../../components/loader/loader';
import { getUnitFilePath } from '../../../config/app-config';
import { basePerformError } from '../../../helpers/error-helpers';
import { imagesPath, joinPath } from '../../../helpers/path-helpers';
import { IUnit, UnitTypes } from '../../../models/unit';
import { SecuredPage } from '../../base/secured-page';
import { HeaderStatistics } from './components/header-statistics';
import { UnitCharacters } from './unit-characters';
import { EmotionalSchema } from './emotional-schema/emotional-schema';

import styles from './units-list-page.module.scss';
import { IUser } from '../../../models/user';
import { ICurriculum } from '../../../models/curriculum';
import { compareText } from '../../../helpers/array-helpers';
import { UserRoles } from '../../../models/user-roles-enum';
import { getCurriculums } from '../../../api/actions/get-curriculums';
import { getCurriculaForEditing } from '../../../api/actions/get-curricula-editing';
import { getAllCurriculaCopies } from '../../../api/actions/get-curricula-all-copies';
import { AppConfig } from '../../../config/app-config';

export const DEFAULT_CURRICULUM_UID = 'prototype_curriculum';

export function UnitsListPage(props: RouteComponentProps<{ uid: string }>) {

    const [units, setUnits] = useState<IUnit[]>();
    const [completedVisible, setCompletedVisible] = useState(false);
    const [isTester, setIsTester] = useState(false);
    const [userCanNavigateFreely, setUserCanNavigateFreely] = useState(false);
    const [isSuperUser, setIsSuperUser] = useState(false);
    const [currentCurriculumTitle, setCurrentCurriculumTitle] = useState('');

    const getUserCurriculums = (usr?: IUser) => {
        if(!usr) return [];

        return usr.disabled ? undefined : usr.userGroups?.reduce<ICurriculum[]>((curriculums, userGroup) => !userGroup.enabled ? curriculums : [
                ...curriculums,
                ...userGroup.curriculums?.filter(c => c.available && !curriculums.some(cr => cr.uid === c.uid)) || []
            ], [])
            .sort((c1, c2) => compareText(c1, c2, (c) => c.name));
    };

    useEffect(() => {
        (async () => {
            try {
                const usr = await getCurrentUser();
                let curriculums: ICurriculum[] | undefined;
                if(usr.role === UserRoles.admin) {
                    curriculums = await getAllCurriculaCopies()
                    setIsSuperUser(true);
                }
                else {
                    curriculums = getUserCurriculums(usr);
                    if(usr.role === UserRoles.tester) {
                      setIsTester(true);
                    }
                    else if(usr.role == UserRoles.editor) {
                      setIsSuperUser(true);
                    }
                }
                if(!curriculums?.length || (curriculums.length > 1 && !curriculums.some(c => c.uid === props.match.params.uid))) {
                    props.history.replace('/curricula');
                }
                else {
                    const curriculum_uid = props.match.params.uid || curriculums[0]?.uid;
                    //TODO: check for specific permissions

                    const hasNavPermissions = usr.userGroups?.some(ug => ug.enabled && ug.userPermissions?.length && ug.curriculums?.some(c => c.uid === curriculum_uid));
                    if (hasNavPermissions) {
                      setUserCanNavigateFreely(true);
                    }

                    const curCurriculum = curriculums.find(c => c.uid === curriculum_uid);
                    if (curCurriculum) {
                      setCurrentCurriculumTitle(curCurriculum.name);
                    }
                    let unitsResult = await getUnits(curriculum_uid);
                    unitsResult = unitsResult.filter(u => u.available);
                    unitsResult.forEach((u: IUnit) => {
                        u.completed = (u.type !== UnitTypes.ongoing_practice)
                        && usr.completedUnits
                        && usr.completedUnits.some((unts: IUnit) => u.uid === unts.uid);
                        u.hasContent = u.unitSections.length > 0;
                    });

                    setUnits(unitsResult);
                    if(unitsResult.every(ur => ur.completed)) {
                        setCompletedVisible(true);
                    }
                    //console.log("user can navigate freely? " + userCanNavigateFreely + "; curriculum uid: " + curriculum_uid + "; groups: " + usr.userGroups);
                }
            }
            catch(err) {
                basePerformError(err, props.history);
            }
        })().catch(() => {/** */});
    }, [props.match.params.uid, props]);

    let isPrevCompleted = true;

    const completedExists = (): boolean => {
        return !!units?.some(u => u.completed);
    };

    const worksheetClicked = (worksheet: string | undefined) => {
        if(!worksheet) return;

        window.open(worksheet);
    }

    const unitNumber = (unit?: IUnit) => {
        if (!unit || !units) return 0;

        const index = units.indexOf(unit);
        if(index >= 0)
            return index + 1;
        return 0;
    }

    const renderListItem = (u: IUnit, isLast?: boolean) => {
        const item = (<React.Fragment key={u.uid}>
                {!userCanNavigateFreely && !isSuperUser && !completedVisible && isPrevCompleted && !u.completed && completedExists() && (
                    <div key="btn-show-completed" className={styles.showHideCompletedBtnContainer}>
                        <div className={styles.showHideCompletedBorder}>
                        </div>
                        <div className={styles.showHideCompletedGradient}>
                        </div>

                        <Button key="btn-show-hide" className="outline gray" onClick={() => setCompletedVisible(true)}>
                            <span>Review Past Modules</span>
                            <FontAwesomeIcon icon={faArrowDown} />
                        </Button>
                    </div>
                )}
                {!userCanNavigateFreely && !isSuperUser && completedVisible && isPrevCompleted && (!u.completed) && completedExists() && (


                    <Button key="btn-show-hide" className="outline gray" onClick={() => setCompletedVisible(false)}>
                        <span>Hide Past Modules</span>
                        <FontAwesomeIcon icon={faArrowUp} />
                    </Button>
                )}
                {!userCanNavigateFreely && !isSuperUser && isPrevCompleted && !isLast && !u.completed && (
                    <div key={'title-up-next'} className={styles.nextItemsTitle}>Up Next</div>
                )}

                <div
                    key={u.uid}
                    className={
                        `${styles.item} ${u.completed && styles.completed} ${!isSuperUser && !u.accessibleWithCurrentPlan && styles.new}`
                        + ` ${!u.completed && u.accessibleWithCurrentPlan && styles.current} ${!userCanNavigateFreely && !isSuperUser && u.completed && !completedVisible && styles.hidden}`
                        }
                    >
                    <div className={styles.itemSubcontainer}>
                        <div className={styles.icon}>
                            { u.iconFileName && (
                                <img src={getUnitFilePath(u.uid, u.iconFileName)} alt="icon" className={styles.customIcon} />
                            )}
                            { !u.iconFileName && (<>
                                <img src={imagesPath('/icon-brain.png')} alt="icon" className={styles.brain} />
                                <img src={imagesPath('/icon-hands.png')} alt="icon" className={styles.hands} />
                                <img src={imagesPath('/icon-pencil.png')} alt="icon" className={styles.pencil} />
                            </>)}
                        </div>
                        <div className={styles.itemInfo}>
                            <div className={styles.title}>
                                {(unitNumber(u) > 0 ? unitNumber(u) + '. ' : '') + u.name}
                            </div>
                            <div className={styles.description}>
                                {u.description}
                            </div>
                            <div className={styles.description}>
                            {isPrevCompleted && !u.hasContent && (
                              "Content coming soon."
                            )}
                            {!u.accessibleWithCurrentPlan && (<>
                                <div className={styles.alert}>
                                    This module is not accessible with your current plan.
                                </div>
                                <Link to='/account'>Upgrade</Link> 
                            </>)}
                            </div>
                        </div>
                    </div>
                    <div className={styles.buttons}>
                        <Button
                            to={joinPath('/curriculum', props.match.params.uid, 'unit', u.uid)}
                            className={(u.completed) ? 'text small' : ''}
                            disabled={!u.hasContent || (isTester && u.index > 5) }
                        >
                            <>
                                <span>{(!u.completed) ? 'View' : 'Review' }</span>
                                {!u.completed && (<FontAwesomeIcon icon={faArrowRight} />)}
                            </>
                        </Button>
                        {(isSuperUser || userCanNavigateFreely) && !!u.hasEducatorGuide && (
                            <Button
                                to={joinPath('/curriculum', props.match.params.uid, 'unit', u.uid, 'guide')}
                                className={'text small'}
                            >
                                <>
                                    <span>Guide</span>
                                </>
                            </Button>
                        )}
                        {(isSuperUser || userCanNavigateFreely) && !!u.worksheet && (
                            <Button
                                onClick={() => worksheetClicked(u.worksheet)}
                                className={'text small'}
                            >
                                <>
                                    <span>Worksheet</span>
                                </>
                            </Button>
                        )}

                    </div>

                </div>

            </React.Fragment>
        );
        if(isPrevCompleted && !u.completed) {
            isPrevCompleted = false;
        }

        return item;
    };

    return (
        <SecuredPage pageName="units" {...props} >
            <MainLayout>
                { !units &&
                    <Loader />
                }
                { units && (
                    <>
                        {!isSuperUser && AppConfig.videoTrainingEnabled ? (<>
                            <HeaderContent>
                              <HeaderStatistics additionalPoints={0} />
                              </HeaderContent>
                        </>) :
                        (<>
                              <HeaderContent>
                                {currentCurriculumTitle}
                              </HeaderContent>
                          </>)}

                        <div className={styles.container}>
                            <div className={styles.list}>
                              <span className={styles.hintText}>
                                You can view the learning modules below. 
                                To complete the modules, use the ThinkHumanTV<LinkButton onClick={() => window.open(AppConfig.extensionURL)}>browser extension</LinkButton>on 
                                Netflix, Disney+ or Peacock. See<LinkButton onClick={() => window.open(AppConfig.gettingStartedURL)}>Getting Started</LinkButton>for more information.
                              </span>

                                { units.filter(u => u.available).map(
                                    (u: IUnit, i: number, arr: IUnit[]) => renderListItem(u, i === arr.length - 1),
                                )}
                                { units && isSuperUser && (
                                  <div key={'title-emotion-system'} className={styles.emotionSystem}>
                                    <h3>The Emotion System</h3>

                                    <div><EmotionalSchema units={units} /></div>
                                  </div>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </MainLayout>
        </SecuredPage>
    );
}
