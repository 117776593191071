import { faArrowLeft, faArrowRight, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { addNarrativeToUser } from '../../../api/actions/add-narrative-to-user';
import { getCurrentUser } from '../../../api/actions/get-current-user';
import { getNarratives } from '../../../api/actions/get-narratives';
import { removeNarrativeFromUser } from '../../../api/actions/remove-narrative-from-user';
import { setUserConfigurationFinished } from '../../../api/actions/set-user-configuration-finished';
import { Button } from '../../../components/button/button';
import { LinkButton } from '../../../components/link-button/link-button';
import { Loader } from '../../../components/loader/loader';
import { getNarrativeFilePath } from '../../../config/app-config';
import { compareText } from '../../../helpers/array-helpers';
import { basePerformError } from '../../../helpers/error-helpers';
import { INarrative } from '../../../models/narrative';
import { IUser } from '../../../models/user';
import styles from './narratives-list.module.scss';

export function NarrativeList(props: RouteComponentProps) {
    const [narratives, setNarratives] = useState<INarrative[]>();
    const [configFinished, setConfigFinished] = useState(false);

    useEffect(() => {
        (async () => {
            let user: IUser;
            try {
                user = await getCurrentUser();
            }
            catch(err) {
                props.history.replace('/login');
                return;
            }

            const narrativesResult = (await getNarratives())?.sort((n1, n2) => compareText(n1, n2, (n) => n.title));
            if(user.narratives) {
                user.narratives?.forEach(el => {
                    const narrative = narrativesResult.find(n => n.uid === el.uid);
                    if(narrative) narrative.selected = true;
                });
            }
            setNarratives(narrativesResult);
            setConfigFinished(user.configurationFinished || false);
        })().catch(() => {/** */});
    }, [props.history]);

    const onItemClick = async (data: INarrative) => {
        try {
            let user;
            if(data.selected) {
                user = await removeNarrativeFromUser(data.uid);
            }
            //only select the narrative if it's available
            else if(data.available) {
                user = await addNarrativeToUser(data.uid);
            }
            if(user) {
              updateNarratives(user);
            }
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };

    const updateNarratives = (user: IUser) => {
        if(!narratives) return;
        narratives?.forEach( n => { n.selected = user.narratives && user.narratives.some(un => n.uid === un.uid); });
        setNarratives([...narratives]);
    };

    const getSelectedNarratives = () => narratives?.filter(n => n.selected);

    const goToCharactersList = () => props.history.push('./characters');
    
    const onFinishWithoutCharacters = async () => {
        await setUserConfigurationFinished();
        props.history.push('/curriculum');
    };

    const isConfigFinished = () => configFinished;

    return (
        <>
            { isConfigFinished() && (<LinkButton to="/analytics"><FontAwesomeIcon icon={faArrowLeft} /> Profile</LinkButton>)}
            <h1 className={styles.headerText}>
                Select Stories You Know and Enjoy<br />
                <div>
                    Choose at least one film or show you&apos;ve <b>seen before</b> and enjoyed. <br />
                    {!configFinished && !narratives?.filter(n => n.selected).length && (
                        <>Don&apos;t see any titles you know and like? <LinkButton onClick={onFinishWithoutCharacters}>Click to skip</LinkButton></>
                    )}
                </div>
            </h1>
            <div className={styles.listContainer}>
                <div className={styles.listSubContainer}>
                    { narratives?.length
                        ? (narratives.map(narrative => (
                            <NarrativeItem key={narrative.uid} {...narrative} events={{ onClick: onItemClick }} />
                        )))
                        : (<Loader />)
                    }
                </div>
            </div>
            <div className={styles.buttonContainer}>
                <Button
                    disabled={!getSelectedNarratives()?.length}
                    onClick={onFinishWithoutCharacters}
                >
                    <span>Finish</span> <FontAwesomeIcon icon={faArrowRight} />
                </Button>
            </div>
        </>
    );
}

function NarrativeItem(props: INarrative & { events: any }) {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(false);
    }, [props.selected]);
    return (
        <div
            className={`${styles.narrative} ${props.available ? '' : styles.locked} ${props.selected ? styles.selected : ''}`}
            onClick={() => { setLoading(!!props.available); props.events.onClick(props); }}
        >
            <div className={styles.selectionContainer} >
                { loading && (<Loader />) }
                <div className={styles.imageContainer}>
                    { !props.available &&
                        <div className={styles.locker}>
                            <FontAwesomeIcon icon={faLock} />
                        </div>
                    }
                    <img src={getNarrativeFilePath(props.uid, props.fileName)} alt={props.title} />
                </div>
            </div>
            <div className={styles.title}>{props.title}</div>
        </div>
    );
}
