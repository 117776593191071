import { Field, Form, Formik, FormikProps } from 'formik';
import React, { ReactElement, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { resetPasswordRequest } from '../../api/actions/forgot-password/reset-password-request';
import { Button } from '../../components/button/button';
import { MainLayout } from '../../components/layouts/main-layout';
import { Loader } from '../../components/loader/loader';
import { isNullOrEmpty } from '../../helpers/common-helpers';
import { IDictionary } from '../../types/dictionary';
import { MultiError } from '../../types/multi-error';
import { PublicPage } from '../base/public-page';
import styles from './reset-password.module.scss';

interface IForm {
    server?: string,
    password: string,
    confirmPassword: string,
}

export function ResetPasswordPage() {
    const history = useHistory();
    const [code] = useState((new URL(window.location.href)).searchParams.get('code') || undefined);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(!code) {
            history.push('/login');
        }
    }, []);

    const handleSubmit = async (
        values: IForm,
        { setSubmitting, setErrors }: { setSubmitting: (status: boolean) => void, setErrors: (errors: IDictionary<string>) => void },
    ) => {
        if(!code) return;

        setLoading(true);
        let isSuccess = false;
        try {
            await resetPasswordRequest(values.password, code);
            isSuccess = true;
            toast.success('Your password has been successfully updated');
        }
        catch (err) {
            const error = (err as MultiError).errors[0];
            toast.error(error.message);
            setErrors({
                server: error.message,
                ...((err as MultiError).errors ? Object.keys((err as MultiError).errors).reduce((errors, field) => { errors[field] = (err as MultiError).errors[field];  return errors; }, {}) : {}),
            });
        }
        setSubmitting(false);
        setLoading(false);

        if(isSuccess) {
            history.push('/login');
        }
    };

    const getValidationSchema = () => {
        return Yup.object({
            password: Yup.string().label('Password').trim().min(8).max(50),
            confirmPassword: Yup.mixed<string>().label('Confirm Password').when(
                'password',
                {
                    is: (password: string) => password && !isNullOrEmpty(password.trim()),
                    then: Yup.string().label('Confirm Password').trim().min(8).max(50).required()
                        .test(
                            'passwordsShouldMatch',
                            'Password and Confirm Password should be match',
                            function(item) {
                                return this.parent.password === item;
                            }
                        ),
                }
            ),
        });
    };

    const renderLoginForm = ({ errors, touched, submitForm }: FormikProps<IForm>): ReactElement => {
        return (
            <div className={styles.container}>
                <div className={styles.subcontainer}>
                    <h1>
                        Create a new Password
                    </h1>
                    <Form noValidate>
                        <div className="form-item">
                            <div className="errors">{errors.server}</div>
                        </div>

                        <div className="form-item">
                            <label>
                                <div className="form-label required">
                                    Password
                                </div>
                                <Field type="password" name="password" />
                            </label>
                            <div className="errors">{touched.password && errors.password}</div>
                        </div>
                        <div className="form-item">
                            <label>
                                <div className="form-label required">
                                    Confirm Password
                                </div>
                                <Field type="password" name="confirmPassword" />
                            </label>
                            <div className="errors">{touched.confirmPassword && errors.confirmPassword}</div>
                        </div>

                        <div className="form-buttons single">
                            { loading
                            ? (<Loader />)
                            : (<Button onClick={submitForm}><span>Save</span></Button>)
                            }
                        </div>
                        <div className="form-item">
                            return to <Link to="/login">Login</Link>
                        </div>
                    </Form>
                </div>
            </div>
        );
    };

    return (
        <PublicPage pageName="reset_password">
            <MainLayout>
                <Formik
                    initialValues={{
                        password: '',
                        confirmPassword: '',
                    }}
                    validationSchema={getValidationSchema}
                    onSubmit={handleSubmit}
                >
                    {renderLoginForm}
                </Formik>
            </MainLayout>
        </PublicPage>
    );
}
