import * as React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { MainLayout } from '../../components/layouts/main-layout';
import { SecuredPage } from '../base/secured-page';
import { CharactersList } from './parts/characters-list';
import { NarrativeList } from './parts/narratives-list';

export function UserConfigPage(props: RouteComponentProps) {
    return (
        <SecuredPage pageName="user-config" {...props} >
            <MainLayout>
                <Route path={`${props.match.path}/narratives`} component={NarrativeList} />
                <Route path={`${props.match.path}/characters`} component={CharactersList} />
            </MainLayout>
        </SecuredPage>
    );
}
