import { faArrowLeft, faTrashAlt, faUnlock, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { deleteJournal } from '../../../../api/actions/journals/delete-journal';
import { getJournal } from '../../../../api/actions/journals/get-journal';
import { saveJournal } from '../../../../api/actions/journals/save-journal';
import { Button } from '../../../../components/button/button';
import { LinkButton } from '../../../../components/link-button/link-button';
import { Loader } from '../../../../components/loader/loader';
import { Block } from '../../../../components/block/block';
import { DeleteQuestionPopup } from '../../../../components/popup/delete-question-popup';
import { basePerformError } from '../../../../helpers/error-helpers';
import { getParentPath, joinPath } from '../../../../helpers/path-helpers';
import { CKEditorField } from '../../../../components/ckeditor/ckeditor';
import { IJournal } from '../../../../models/journal';
import { IUser } from '../../../../models/user';
import { IDictionary } from '../../../../types/dictionary';
import { getCurrentUser } from '../../../../api/actions/get-current-user';
import styles from './journal-editor.module.scss';
import { MultiError } from '../../../../types/multi-error';


enum FormFields {
    content = 'content'
}

interface IForm {
    [FormFields.content]: string,
}

export function JournalEditor(props: RouteComponentProps<{uid: string}>) {

    const [loading, setLoading] = useState(false);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [journal, setJournal] = useState<IJournal>();
    const [user, setUser] = useState<IUser>();

    const loadData = useCallback(async (uid?: string) => {
        try {
            if(!user) {
              setUser(await getCurrentUser());
            }

            if(uid || props.match.params.uid !== '_') {
                setJournal(await getJournal(uid || props.match.params.uid));
            }
            else {
                setJournal({} as IJournal);
            }
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    }, [props.history, props.match.params.uid]);

    useEffect(() => {
        loadData().catch(() => {/** */});
    }, []);

    const handleSubmit = async (
        values: IForm,
        { setSubmitting }: { setSubmitting: (status: boolean) => void, setErrors: (errors: IDictionary<string>) => void },
    ) => {
        setLoading(true);
        try {
            console.log("attempting to save journal; content: ", values.content );
            const newJournal: Partial<IJournal> = {
                uid: journal?.uid,
                content: values.content
            };

            const uid = await saveJournal(newJournal);
            if(!journal?.uid) {
                props.history.push(joinPath(props.match.url.replace(/\/_\/?$/, ''), uid));
            }
            await loadData(uid);
            toast.success('Item has been successfully saved');
        }
        catch (err) {
            toast.error('Error: ' + (err as MultiError).message);
            basePerformError(err, props.history);
        }
        setSubmitting(false);
        setLoading(false);
    };


    const deleteAction = async () => {
        if(!journal?.uid) return;
        await deleteJournal(journal?.uid);
        setDeleteDialogVisible(false);
    };

    const renderForm = ({ errors, touched, submitForm }: FormikProps<IForm>): React.ReactElement => {
        return (
            <div className={styles.formContainer}>
                <Form noValidate>

                    <div className="form-buttons">

                        <div></div>
                        { journal?.uid && (
                            <LinkButton onClick={() => setDeleteDialogVisible(true)} className="red"><FontAwesomeIcon icon={faTrashAlt} /> Delete</LinkButton>
                        )}
                    </div>

                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Journal Content
                            </div>
                            <Field component={CKEditorField} name={FormFields.content} filebrowserBrowseUrl="/ckeditor/browse" />
                        </label>
                        <div className="errors">{touched.content && errors.content}</div>
                    </div>

                    <div className="form-buttons">
                        <Button className="gray" to={getParentPath(props.match.url, 1)}>
                            <FontAwesomeIcon icon={faArrowLeft} /> <span>Back</span>
                        </Button>
                        { loading
                            ? (<Loader />)
                            : (<Button onClick={submitForm}><span>Save</span></Button>)
                        }
                    </div>
                </Form>
            </div>
        );
    };

    return !journal || !user ? null : (<>
      {(!journal.uid || journal.author?.uid === user.uid) && (
        <Block className={styles.editor}>
            <Formik
                initialValues={{
                    content: journal?.content || '',
                }}
                onSubmit={handleSubmit}
            >
                {renderForm}
            </Formik>
        </Block>)}

        {journal.uid && journal.author?.uid !== user.uid && (
          <div className={styles.container}>
              <div className={styles.content}>
                  { journal && journal.content &&
                      <div
                          className={`${styles.text} cke-content`}
                          dangerouslySetInnerHTML={{
                              __html: journal.content
                          }}
                      ></div>
                  }
              </div>

              <Button className="yellow" to={getParentPath(props.match.url, 2)}>
                  <FontAwesomeIcon icon={faArrowLeft} /> <span>Back</span>
              </Button>

            </div>
        )}

        {deleteDialogVisible && (
            <DeleteQuestionPopup
                title="Journal Deletion"
                question={<>Are you sure want to delete this journal?</>}
                onClose={() => setDeleteDialogVisible(false)}
                onDelete={deleteAction}
                onFinish={() => { props.history.push(getParentPath(props.match.url, 1)) }}
            />
        )}
    </>);
}
