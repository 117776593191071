import { InMemoryCache } from 'apollo-boost';
import ApolloClient, { OperationVariables, QueryOptions } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import Cookies from 'js-cookie';
import { AppConfig } from '../config/app-config';
import { sha3_256 } from 'js-sha3';
import moment from 'moment';

const config = {
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    } as Partial<QueryOptions<OperationVariables>>,
    mutate: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    } as Partial<QueryOptions<OperationVariables>>,
};

const httpLink = createHttpLink({
    uri: `${AppConfig.apiProtocol}://${AppConfig.apiHost}${AppConfig.apiPort ? ':' + AppConfig.apiPort : ''}${AppConfig.apiPath}`,
});

const authLink = setContext((_, { headers }: { headers?: Record<string, string | number>}) => {

    const token = Cookies.get(AppConfig.tokenName);
    const preauth_token = AppConfig.preauthToken;
    const hour_string = moment.utc().format('YYYY-MM-DD HH:mm')
    const encoded_preauth_token = sha3_256(preauth_token + '__' + hour_string );

    //console.log("encoded preauth token: " + encoded_preauth_token + "; hour string: " + hour_string);

    return {
        headers: {
            ...headers,
            ...(headers?.authorization ? {} : { authorization: token }),
            ...({ 'X-preauth': token ? null : encoded_preauth_token }),
        },
    };
  
  });

const graphqlClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
});

graphqlClient.defaultOptions = {...graphqlClient.defaultOptions, ...config};


export { graphqlClient };
