import { FetchResult, gql } from 'apollo-boost';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation userGroupRequestEdPermissions($userGroupUid: String!){
        user_group_request_educator_permissions (user_group_uid: $userGroupUid) {
            success
        }
    }
`;

export async function userGroupRequestEdPermissions(
    userGroupUid: string | undefined,
): Promise<{ success: boolean }> {
    const result: FetchResult<{ user_group_request_educator_permissions: { success: boolean }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: {
            userGroupUid,
        },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.user_group_request_educator_permissions;
}
