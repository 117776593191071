import { FetchResult, gql } from 'apollo-boost';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation UserGroupDelete($uid: String!) {
        user_group_delete(uid: $uid) {
            success
        }
    }
`;

export async function userGroupDelete(uid: string): Promise<boolean> {
    const result: FetchResult<{ user_group_delete: { success: boolean } }> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { uid },
        errorPolicy: 'all',
    });

    if (!result.data || (result.errors && result.errors.length)) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.user_group_delete.success;
}
