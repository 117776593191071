import { isUrl, joinPath } from '../helpers/path-helpers';

export const AppConfig = {
    isDebug: !process.env.REACT_APP_ENV || (process.env.REACT_APP_ENV.toLowerCase() !== 'production' && process.env.REACT_APP_ENV.toLowerCase() !== 'demo'),

    // preauthToken: process.env.REACT_APP_PREAUTH_TOKEN || 'dummytoken',
    preauthToken: process.env.REACT_APP_PREAUTH_TOKEN || 'Dev_ThinkHumanTV_PreauthToken_2023',
    gaTrackingID: process.env.REACT_APP_GA_TRACKING || 'G-LTEQXDG8TF',

    searchInBeta: process.env.REACT_APP_SEARCH_IN_BETA || true,
    searchImagesEnabled: process.env.REACT_APP_SEARCH_IMAGES_ENABLED || true,
    videoTrainingEnabled: process.env.REACT_APP_VIDEO_TRAINING_ENABLED || false,
    inviteOnSignupEnabled: process.env.REACT_APP_INVITE_ON_SIGNUP_ENABLED ?? false,
    signupGroupCode: process.env.REACT_APP_SIGNUP_GROUP_CODE || 'CD6647D9-8DE0-4305-B598-F6C9477323B6',

    apiProtocol: process.env.REACT_APP_API_PROTOCOL ?? 'http',
    // apiHost: process.env.REACT_APP_API_HOST ?? 'affectifi.local',
    // apiPort: process.env.REACT_APP_API_PORT ?? 8080,
    apiHost: process.env.REACT_APP_API_HOST ?? 'api.dev.affectifi.com',
    apiPort: process.env.REACT_APP_API_PORT ?? '',
    apiPath: process.env.REACT_APP_API_PATH ?? '/graphql',

    protocol: process.env.REACT_APP_PROTOCOL ?? 'http',
    host: process.env.REACT_APP_HOST ?? 'affectifi.local',
    port: process.env.REACT_APP_PORT ?? 8080,
    path: process.env.REACT_APP_PATH ?? '/',
    isSearchEnable:process.env.REACT_APP_PUBLIC_SEARCH_ON ?? true,
    filesServer: process.env.REACT_APP_FILES_SERVER ?? 'https://s3.amazonaws.com/resource.dev.affectifi.com/',

    testUserEmail: 'test@affectifi123.tt',

    tokenName: 'accessToken',

    showSimilarResultsTo: process.env.REACT_APP_SIMILAR_RESULTS_ACCESS || "all",
    exercisePreviewAccess: process.env.REACT_APP_EXERCISE_PREVIEW_ACCESS || "all",
    searchPageSize: process.env.REACT_APP_SEARCH_PAGE_SIZE ?? 20,

    pipsBaseURL: process.env.REACT_APP_PIPS_BASE_URL || "https://stage.pipsrewards.com/partnerlogin",
    pipsPartnerID: "thinkhumantv",
    pipsInfoURL: process.env.REACT_APP_PIPS_INFO_URL || "https://thinkhuman.tv/pips",

    comparePlansURL: process.env.REACT_APP_PRICING_URL || 'https://www.thinkhuman.tv/sign-up',
    termsURL: process.env.REACT_APP_TERMS_URL || 'https://www.thinkhuman.tv/terms-of-use',
    extensionURL: "https://chrome.google.com/webstore/detail/affectifi-browser-extensi/llmpamjbjeaenkphbfleajgpfdoneefk",
    disneyPlusURL: "https://disneyplus.com",
    netflixURL: "https://netflix.com",
    peacockURL: "https://peacocktv.com",

    scheduleCallURL: process.env.REACT_APP_ONBOARDING_CALL_URL || "https://calendly.com/thinkhumantv/onboarding",
    scheduleDemoURL: process.env.REACT_APP_SCHEDULE_DEMO_URL || "https://calendly.com/thinkhumantv/demo",
    orientationVideoURL: process.env.REACT_APP_ORIENTATION_VIDEO_URL || "https://youtu.be/uwlB18WIdEo",
    programLearnMoreURL: process.env.REACT_APP_PROGRAM_LEARN_MORE_URL || 'https://www.thinkhuman.tv/in-school',
    educatorLearnMoreURL: process.env.REACT_APP_EDUCATOR_LEARN_MORE_URL || 'https://www.thinkhuman.tv/sign-up',
    supportURL: process.env.REACT_APP_SUPPORT_URL || 'https://support.thinkhuman.tv',
    gettingStartedURL: process.env.REACT_APP_GETTING_STARTED_URL || 'https://support.thinkhuman.tv/getting-started-with-thtv/',
    supportEmail: process.env.REACT_APP_SUPPORT_EMAIL || "support@thinkhuman.tv",
    contactEmail:  process.env.REACT_APP_CONTACT_EMAIL ||"contact@thinkhuman.tv"
};

export const LEARNING_CHUNK_FILE_URL_PLACEHOLDER = '%LEARNING_CHUNK_FILE_URL%';

export function getAbsoluteUrl(...path: string[]) {
    const appUrl = AppConfig.protocol + '://' + AppConfig.host + (AppConfig.port ? `:${AppConfig.port}` : '') + AppConfig.path;
    return joinPath(appUrl, ...path);
}


export function getAchievementFilePath(uid: string, file?: string) {
    if(isUrl(file)) return file;
    return joinPath(AppConfig.filesServer, 'static/files/achievements', uid, file);
}

export function getSceneFilePath(uid: string, file?: string) {
    if(isUrl(file)) return file;
    return joinPath(AppConfig.filesServer, 'static/files/scenes', uid, file);
}

export function getNarrativeFilePath(uid: string, file?: string) {
    if(isUrl(file)) return file;
    return joinPath(AppConfig.filesServer, 'static/files/narratives', uid, file);
}

export function getCharacterFilePath(uid?: string, file?: string) {
    if(!uid) return '';
    if(isUrl(file)) return file;
    return joinPath(AppConfig.filesServer, 'static/files/characters', uid, file);
}

export function getUnitFilePath(uid: string, file?: string) {
    if(isUrl(file)) return file;
    return joinPath(AppConfig.filesServer, 'static/files/units', uid, file);
}

export function getLearningChunkFilePath(uid: string, file?: string) {
    if(isUrl(file)) return file;
    return joinPath(AppConfig.filesServer, 'static/files/learning_chunks', uid, file);
}

export function getExerciseFilePath(uid: string, file?: string) {
    if(isUrl(file)) return file;
    return joinPath(AppConfig.filesServer, 'static/files/exercises', uid, file);
}
