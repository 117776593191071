import { Field, Form, Formik, FormikProps } from 'formik';
import Cookies from 'js-cookie';
import moment from 'moment';
import React, { ReactElement, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { getCurrentUser } from '../../api/actions/get-current-user';
import { login } from '../../api/actions/login-action';
import { Button } from '../../components/button/button';
import { LinkButton } from '../../components/link-button/link-button';
import { Loader } from '../../components/loader/loader';
import { AppConfig } from '../../config/app-config';
import { CurrentUserContext } from '../../contexts/current-user-context';
import { IDictionary } from '../../types/dictionary';
import styles from './login-page.module.scss';
import { toast } from 'react-toastify';

interface IForm {
  email: string;
  password: string;
}

interface IProps {
  email?: string;
  onSuccessLogin?(configurationFinished: boolean): void;
  signupLinkCallback?: any;
  introMessage?: string;
  handleToggle?: () => void;
}

export function LoginComponent(props: IProps) {
  Cookies.remove(AppConfig.tokenName);

  const currentUserContext = React.useContext(CurrentUserContext);
  currentUserContext?.setState(undefined);

  const [pips] = useState(
    new URL(window.location.href).searchParams.get('pipssource') || undefined
  );
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const signup = () => {
    if (!props.signupLinkCallback) {
      history.replace('/signup');
    } else {
      props.signupLinkCallback();
    }
  };

  const handleSubmit = async (
    values: IForm,
    {
      setSubmitting,
      setErrors,
    }: {
      setSubmitting: (status: boolean) => void;
      setErrors: (errors: IDictionary<string>) => void;
    }
  ) => {
    setLoading(true);
    try {
      const { accessToken, configurationFinished } = await login(
        values.email,
        values.password
      );

      Cookies.set(AppConfig.tokenName, accessToken, {
        domain: new URL(location.href).hostname,
        expires: moment().add(100, 'days').toDate(),
      });

      // currentUserContext?.setState(await getCurrentUser());

      props.onSuccessLogin?.(configurationFinished);
    } catch (err) {
      setSubmitting(false);
      setErrors({
        password:
          'The password or email you entered is incorrect. Please try again.',
      });
      setLoading(false);
      toast.error(`Error ${err}`);
    }
  };

  const getValidationSchema = () => {
    return Yup.object({
      email: Yup.string()
        .required('Please enter your email address')
        .email('Please enter valid email address'),
      password: Yup.string().required('Please enter your password'),
    });
  };

  const renderLoginForm = ({
    errors,
    touched,
    submitForm,
  }: FormikProps<IForm>): ReactElement => {
    return (
      <div className={styles.container}>
        <div className={styles.subcontainer}>
          <h1>
            ThinkHumanTV
            <br />
            {!props.introMessage ? (
              <>
                <span>
                  <br />
                  Boost emotional skills and wellness <br />
                  while watching Netflix, Disney+ and Peacock.
                </span>
              </>
            ) : (
              <>
                <span>
                  <br />
                  {props.introMessage}
                </span>
              </>
            )}
          </h1>
          <Form noValidate>
            {pips && (
              <div>
                Log in or sign up with your organization&apos;s (e.g. college)
                email to earn PIPs Rewards.
              </div>
            )}
            <div className="form-item"></div>
            <div className="form-item">
              <label>
                <div className="form-label required">Email</div>
                <Field type="email" name="email" disabled={!!props.email} />
              </label>
              <div className="errors">{touched.email && errors.email}</div>
            </div>
            <div className="form-item">
              <label>
                <div className="form-label required">Password</div>
                <Field type="password" name="password" />
              </label>
              <div className="errors">
                {touched.password && errors.password}
              </div>
              <Link to="/forgot-password">Forgot password</Link>
            </div>

            <div className="form-buttons single">
              {loading ? (
                <Loader />
              ) : (
                <Button onClick={submitForm}>
                  <span>Login</span>
                </Button>
              )}
            </div>

            <div className="form-buttons single">
              <LinkButton
                onClick={() => {
                  if (props.handleToggle) {
                    props.handleToggle();
                  } else {
                    signup();
                  }
                }}
              >
                Sign up
              </LinkButton>
            </div>
          </Form>
        </div>
      </div>
    );
  };

  return (
    <>
      <Formik
        initialValues={{
          email: props.email
            ? props.email
            : AppConfig.isDebug
            ? AppConfig.testUserEmail
            : '',
          password: '',
        }}
        validationSchema={getValidationSchema}
        onSubmit={handleSubmit}
      >
        {renderLoginForm}
      </Formik>
    </>
  );
}
