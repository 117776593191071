import { FetchResult, gql } from 'apollo-boost';
import { removeFields } from '../../helpers/common-helpers';
import { IEmotionEpisode } from '../../models/emotion-episode';
import { MultiError } from '../../types/multi-error';
import { graphqlClient } from '../graphql-client';

const QUERY = gql`
    query GetEmotionEpisodesForNarrative($narrativeUid: String!){
        emotion_episodes_for_narrative(narrative_uid: $narrativeUid) {
            uid,
            description,
            start,
            end,
            status,
            absoluteStart: absolute_start,
            available,
        }
    }
`;

export async function getEmotionEpisodesForNarrative(narrativeUid: string): Promise<IEmotionEpisode[]> {
    const result: FetchResult<{ emotion_episodes_for_narrative: IEmotionEpisode[] }> = await graphqlClient.query({
        query: QUERY,
        variables: { narrativeUid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return removeFields(result.data.emotion_episodes_for_narrative, ['__typename']);
}
