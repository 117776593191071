import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useEffect, useState, useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { getPracticalUnitSectionLearningChunks } from '../../../../api/actions/get-practical-unit-section-chunks';
import { EmotionalEpisodeExercisesTester } from './emotion-episode-exercises-tester';
import { Button } from '../../../../components/button/button';
import { Loader } from '../../../../components/loader/loader';
import { Video } from '../../../../components/video/video';
import { AppConfig } from '../../../../config/app-config';
import { getNarrativeFilePath, getSceneFilePath } from '../../../../config/app-config';
import { basePerformError } from '../../../../helpers/error-helpers';
import { ILearningChunk } from '../../../../models/learning-chunk';
import { INarrative } from '../../../../models/narrative';
import { IScene } from '../../../../models/scene';
import { IUnitSection, UnitSectionTypes } from '../../../../models/unit-section';
import { IUnit } from '../../../../models/unit';
import { SceneInfo } from '../scene-info';
import { TestSectionQuestionView } from './test-section-question-view';
import styles from './test-section.module.scss';
import { isAbsoluteUrl, getFileName } from '../../../../helpers/path-helpers';
import { Popup } from '../../../../components/popup/popup';
import { useCurrentUser } from '../../../../contexts/current-user-context';
import { UserRoles } from '../../../../models/user-roles-enum';

interface IProps extends RouteComponentProps {
    unit: IUnit,
    section: IUnitSection,
    updateUpdatedTime: () => void,
    chunkIndex: number,
    onNextChunk: () => void,
    onPrevChunk: () => void,
    nextSectionType?: UnitSectionTypes,
}

function TestSection(props: IProps) {

    const currentUser = useCurrentUser();
    const [scene, setScene] = useState<IScene>();

    const [introVisible, setIntroVisible] = useState(true);
    const [videoVisible, setVideoVisible] = useState(false);
    const [testExercisesPopupVisible, setTestExercisesPopupVisible] = useState(false);

    const exercisePreviewVisible = useMemo(() => currentUser?.role === UserRoles.admin || currentUser?.role === UserRoles.editor || 
        AppConfig.exercisePreviewAccess.toLowerCase() === 'all', [currentUser]
  );

    const getAbsoluteStart = (lc: ILearningChunk) => lc.exercise.episode.absoluteStart
        || ((lc.exercise.episode.scene?.start || 0) + lc.exercise.episode.start);


    const chunk = props.section.learningChunks?.[props.chunkIndex];

    useEffect(() => {
        (async () => {
            // Hide intro and video screen if it havn't episode or have type = Learning
            if((chunk && !chunk?.exercise?.episode) || props.section.type === UnitSectionTypes.learning) {
                setIntroVisible(false);
                setVideoVisible(false);
            }
            if(props.section.learningChunks) return;
            try {
                let learningChunksResult = await getPracticalUnitSectionLearningChunks(props.section.uid);
                if(!learningChunksResult.length) {
                   if(!AppConfig.videoTrainingEnabled) {
                     setIntroVisible(true);
                   } else {
                     props.onNextChunk();
                   }
                   return;
                }

                learningChunksResult = learningChunksResult.sort(
                    (c1, c2) => getAbsoluteStart(c1) - getAbsoluteStart(c2),
                );
                props.section.learningChunks = learningChunksResult;
                props.updateUpdatedTime();
                setIntroVisible(true);
            }
            catch(err) {
                basePerformError(err, props.history);
                return;
            }
        })().catch(() => {/** */});
    }, [props, props.section.uid, props.history, props.section.learningChunks, chunk]);

    useEffect(() => {
        if(introVisible) return;
        if(chunk?.exercise?.episode && (!scene || scene.uid !== chunk.exercise.episode.scene?.uid)) {
            setScene(chunk.exercise.episode.scene);
            setVideoVisible(true);
        }
    }, [chunk, introVisible, scene])

    const getNarratives = () => {
        const nrtvs = props.section.learningChunks?.map(
            lc => lc.exercise?.episode?.scene?.narrative
        )
        .reduce<INarrative[]>((arr, el) => !el || arr.some(n => n.uid === el.uid) ? arr : [...arr, el], [])
        .slice(0, 3);
        return nrtvs && nrtvs.length ? nrtvs : [];
    };

    const renderIntroScreen = () => {
        return !AppConfig.videoTrainingEnabled ? (<>
          <div className={styles.introContainer}>
              <div className={styles.introTitle}>
                  Practice Section
              </div>
              <div className={styles.introHintText}>
                  <p>
                      Users complete exercises while watching content on a streaming site.
                  </p>
                  <p>
                      Min number of training segments in section: {props.section.episodeCount}
                  </p>
              </div>

              {exercisePreviewVisible && (<>
                <div className={styles.centeredBlock}>
                    <Button className="orange" onClick={() => setTestExercisesPopupVisible(true)}><span>Preview Exercises</span></Button>
                </div>
              </>)}

              {!currentUser || !currentUser.extensionInstalled ? (<>
                    <br/><br/>
                    <div className={styles.introHintText}>
                      Please install the ThinkHumanTV Chrome extension and go to Netflix, Disney+ or Peacock to begin training.
                    </div>
                    <br/><br/>
                    <div className={styles.centeredBlock}>
                      <Button to={AppConfig.extensionURL}>
                        Install Extension
                      </Button>
                    </div>
                </>) : (<>
                    <br/><br/>
                  <div className={styles.introHintText}>
                    To train now you can go to:
                  </div>
                  <br /><br />
                  <div className={styles.centeredBlock}>
                    <span className={styles.spaced}>
                        <Button to={AppConfig.disneyPlusURL}>
                            Disney+
                        </Button>
                    </span>
                    <span className={styles.spaced}>
                        <Button to={AppConfig.netflixURL}>
                            Netflix
                        </Button>
                    </span>
                    <span className={styles.spaced}>
                        <Button to={AppConfig.peacockURL}>
                            Peacock
                        </Button>
                    </span>
                  </div>
                </>)}

            </div>
            <div className={styles.buttons}>
                <Button onClick={() => { props.onNextChunk(); }}>
                    <span>Continue</span> <FontAwesomeIcon icon={faArrowRight} />
                </Button>
            </div>

            { testExercisesPopupVisible && (
            <Popup
                title="Preview Exercises"
                modal={true}
                buttons={[
                    {
                        title: <><FontAwesomeIcon icon={faArrowLeft} /> <span>Close</span></>,
                        className: 'gray',
                        onClick: () => { setTestExercisesPopupVisible(false); },
                    },
                ]}
            >
                <EmotionalEpisodeExercisesTester unitSection={props.section} curriculum={props.unit.curriculum} />
            </Popup>
            )}

          </>) : (<>
            <div className={styles.introContainer}>
                <div className={styles.introTitle}>
                    Emotions in Action
                </div>
                <div className={styles.introHintText}>
                    {getNarratives().length > 0 && (<p>
                        This section features the titles below. <b>We
                        recommend that you watch the original version of
                        this content if you haven&apos;t already.</b> This will provide
                        more context for interpreting the characters&apos; feelings
                        and motives.
                    </p>)}
                    {getNarratives().length === 0 && (<p>
                        &quot;Of all the men we meet with, nine parts of ten are what they are,
                        good or evil, useful or not, by their education.&quot; -John Locke
                    </p>)}
                <p></p>
                </div>
                <div className={styles.introNarratives}>
                    { getNarratives().map(
                        (n: INarrative) => (
                          <div key={n.uid}>
                            <div><img src={getNarrativeFilePath(n.uid, n.fileName)} alt={n.title} /></div>
                            { n.preferredSourceName && (<div className={styles.narrativeLinkText}>Watch it on</div>)}
                            { n.preferredSourceName && (
                                <div className={styles.narrativeLinkText}>
                                    <a href={n.preferredSourceUrl} target='_blank' rel='noopener noreferrer'>{n.preferredSourceName}</a>
                                </div>
                            )}
                          </div>
                        ),
                    )}
                </div>
            </div>
            <div className={styles.buttons}>
                { !chunk && (<Loader />) }
                { chunk && (
                    <Button onClick={() => { setIntroVisible(false); }}>
                        <span>Start</span> <FontAwesomeIcon icon={faArrowRight} />
                    </Button>
                )}
            </div>

        </>);
    };

    const renderVideoScreen = () => {
        return !chunk?.exercise.episode.scene?.narrative ? null : (<>
            <div className={styles.videoHintText}>
              <p>Watch the scene from <i><b>{chunk.exercise.episode.scene.narrative.title}</b></i> below, then continue.</p>
              <p></p>
            </div>
            <div className={styles.videoContainer}>
                {scene?.fileName && (
                    <Video
                        url={
                            isAbsoluteUrl(scene?.fileName)
                            ? scene?.fileName
                            : getSceneFilePath(scene?.uid, `${getFileName(scene?.fileName)}/video.m3u8`) || ''}
                        playing={true}
                        controls={true}
                    />
                )}
            </div>
            <div>
                {chunk.exercise.episode.scene.episode && chunk.exercise.episode.participants && (
                    <SceneInfo
                        narrative={chunk.exercise.episode.scene.narrative}
                        scene={chunk.exercise.episode.scene}
                        episode={chunk.exercise.episode.scene.episode}
                        participants={chunk.exercise.episode.participants}
                    />
                )}
            </div>
            <div className={styles.buttons}>
                <Button onClick={() => setVideoVisible(false)}>
                    <span>Continue</span> <FontAwesomeIcon icon={faArrowRight} />
                </Button>
            </div>
        </>);
    };

    return (
        <div className={styles.container}>
            { introVisible && renderIntroScreen() }
            { !introVisible && chunk && (
                <>
                    { videoVisible && scene && renderVideoScreen() }
                    { !videoVisible && (
                        <TestSectionQuestionView
                            unit={props.unit}
                            chunk={chunk}
                            onNextChunk={props.onNextChunk}
                            onPrevChunk={props.onPrevChunk}
                            chunkIndex={props.chunkIndex}
                            section={props.section}
                            user={currentUser}
                        />
                    )}
                </>
            )}
        </div>
    );
}

const TestSectionWithRouter = withRouter(TestSection);
export { TestSectionWithRouter as TestSection };
