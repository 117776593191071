import { FetchResult, gql } from 'apollo-boost';
import { removeFields } from '../../../helpers/common-helpers';
import { IJournal } from '../../../models/journal';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query JournalsForUser($userUid: String) {
        journals_for_user(uid: $userUid) {
            uid,
            content,
            createdAt: created_at,

            author {
                  uid,
                  firstName: first_name,
                  lastName: last_name
            }
        }
    }
`;

export async function getJournals(userUid: string | undefined): Promise<IJournal[]> {
    const result: FetchResult<{ journals_for_user: IJournal[] }> = await graphqlClient.query({
        query: QUERY,
        variables: {userUid},
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return removeFields(result.data.journals_for_user, ['__typename']);
}
