import {
  faArrowRight,
  faBatteryHalf,
  faHeart,
  faSun,
  faTimes,
  faFilm,
  faBook,
  faCircleInfo,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { getUserStats } from '../../../../api/actions/get-user-stats';
import { getUserUnitStats } from '../../../../api/actions/get-user-unit-stats';
import { LinkButton } from '../../../../components/link-button/link-button';
import { Popup } from '../../../../components/popup/popup';
import { getAchievementFilePath } from '../../../../config/app-config';
import { useCurrentUser } from '../../../../contexts/current-user-context';
import { onlyUnique, compareText } from '../../../../helpers/array-helpers';
import { basePerformError } from '../../../../helpers/error-helpers';
import {
  joinPath,
  getParentPath,
  imagesPath,
} from '../../../../helpers/path-helpers';
import { isNotNullOrEmpty } from '../../../../helpers/common-helpers';
import { AchievementTypes } from '../../../../models/enums/achievement-types';
import { IUser } from '../../../../models/user';
import { IUserStats } from '../../../../models/user-stats';
import { IUserUnitStats } from '../../../../models/user-unit-stats';
import { ICurriculum } from '../../../../models/curriculum';
import { UserPlanTypes } from '../../../../models/enums/user-plan-types';
import { UserPlanSectorTypes } from '../../../../models/enums/user-plan-sector-types';
import { ConceptsStatsComponent } from './concepts-stats-component';
import { SkillsStatsComponent } from './skills-stats-component';
import { JournalsComponent } from '../../content/components/journals-component';
import { AppConfig } from '../../../../config/app-config';
import { UserUnitStatsComponent } from './user-unit-stats-component';
import { ClassList } from '../../../classes/class-list';
import { CurriculumsList } from '../../../curriculums/curriculums-list';
import { hasInstitutionalMotivation } from '../../../../utils.js';
import styles from './user-stats-component.module.scss';
import { Button } from '../../../../components/button/button';
import chromeLogo from '../../../../../src/images/chrome.svg';
import edgeLogo from '../../../../../src/images/edge.svg';

interface IProps {
  user: IUser;
  unitStats?: IUserUnitStats[];
  asComponent?: boolean;
  displayedFromPopup?: boolean;
}

export function UserStatsComponent(props: IProps) {
  const currentUser = useCurrentUser();
  const [stats, setStats] = React.useState<IUserStats>();
  const [unitStats, setUnitStats] = React.useState<IUserUnitStats[]>();
  const [visibleSkillsStats, setVisibleSkillsStats] = useState(false);
  const [visibleConceptsStats, setVisibleConceptsStats] = useState(false);
  const [visibleJournals, setVisibleJournals] = useState(false);
  const [showingEducatorPrompt, setShowingEducatorPrompt] = useState(true);
  console.log(
    'currentUser?.extensionInstalled',
    currentUser?.extensionInstalled
  );
  React.useEffect(() => {
    (async () => {
      try {
        setStats(await getUserStats(props.user.uid, undefined));
        // if (currentUser) await getClassInfo(currentUser);
      } catch (err) {
        basePerformError(err);
        return;
      }
    })().catch(() => {
      /** */
    });
  }, [props]);

  const userGroup = React.useMemo(
    () =>
      currentUser?.userGroups?.find(
        (ug) => ug.enabled && ug.curriculums?.some((c) => c.available)
      ),
    [currentUser]
  );
  const pipsAvailable = React.useMemo(() => !!props.user?.canEarnPips, [props]);
  const haveOpenResponses = React.useMemo(
    () =>
      stats?.openResponseCount && stats?.openResponseCount > 0,
    [stats]
  ); 
  const haveSavedSegments = React.useMemo(
    () =>
      stats?.savedEmotionEpisodeCount && stats?.savedEmotionEpisodeCount > 0,
    [stats]
  );
  const haveBookmarks = React.useMemo(
    () => stats?.bookmarkCount && stats?.bookmarkCount > 0,
    [stats]
  );
  const haveJournals = React.useMemo(
    () => stats?.journalCount && stats?.journalCount > 0,
    [stats]
  );
  const setupCallRequired = false; /*React.useMemo(
    () =>
      currentUser?.uid === props.user.uid &&
      currentUser?.userPlan?.type === UserPlanTypes.individual &&
      currentUser?.userPlan?.sector ===
        UserPlanSectorTypes.education_nonprofit &&
      !currentUser?.userGroups?.some(
        (g) => g.parent || g.groupType === 'organization'
      ),
    [currentUser, props]
  );*/

  const hasNonDefaultGroups = React.useMemo(
    () => currentUser?.userGroups?.some((g) => !g.default),
    [currentUser]
  );

  const shouldShowEducatorPrompt = React.useMemo(
    () =>
      currentUser?.uid === props.user.uid &&
      (currentUser?.userPlan?.accessType !== 'full' ||
        currentUser?.userPlan?.free),
    [currentUser, props]
  );

  const achievements = useMemo(
    () =>
      stats?.achievementsStats
        ?.filter((a, i, self) =>
          onlyUnique(a, i, self, (ach) => ach.achievementUid)
        )
        .map((ach) => ({
          uid: ach.achievementUid,
          name: ach.achievementName,
          achievementType: ach.achievementType,
          available: ach.achievementAvailable,
          index: ach.achievementIndex,
          iconFileName: ach.achievementIconFileName,
        }))
        .sort((a1, a2) => a1.index - a2.index),
    [stats?.achievementsStats]
  );

  const ratingCategories = [
    {
      min: 0,
      max: 60,
      name: 'Practice Needed',
      cssClass: styles.practice_needed,
    },
    { min: 60, max: 70, name: 'Fair', cssClass: styles.fair },
    { min: 70, max: 80, name: 'Good', cssClass: styles.good },
    { min: 80, max: 90, name: 'Very Good', cssClass: styles.very_good },
    { min: 90, max: 101, name: 'Excellent', cssClass: styles.excellent },
  ];

  const getAchievementCount = (uid: string) => {
    let count = 0;
    if (!stats?.achievementsStats || stats?.achievementsStats?.length === 0)
      return 0;

    for (const ach of stats?.achievementsStats) {
      if (ach.achievementUid === uid) {
        count = count + ach.count;
      }
    }
    return count;
  };

  const getJournalsUrl = () => {
    const url = location.href;
    const parent = getParentPath(url, 2);
    return joinPath(parent, 'journals');
  };

  const goToDemoLink = () => {
    window.open(AppConfig.scheduleDemoURL);
  };

  const goToScheduleLink = () => {
    window.open(AppConfig.scheduleCallURL);
  };

  const goToEducatorLearnMoreLink = () => {
    window.open(AppConfig.educatorLearnMoreURL);
  };

  const getRedeemUrl = (encryptedUid: string) => {
    const baseURL = joinPath(AppConfig.pipsBaseURL, AppConfig.pipsPartnerID);
    const baseMergeUrl = joinPath(baseURL, 'merge');
    return joinPath(baseMergeUrl, encryptedUid);
    //console.log("redeem url: " + redeemURL);
  };

  const redeemPips = () => {
    if (!currentUser?.encryptedUid) {
      return;
    }
    const encryptedUid = currentUser.encryptedUid || '';
    const redeemURL = getRedeemUrl(encryptedUid);
    window.open(redeemURL);
  };

  const goToPipsInfoURL = () => {
    window.open(AppConfig.pipsInfoURL);
  };

  return (
    <>
      {setupCallRequired && (
        <div className={styles.info}>
          <div className={styles.message}>
            In order to access all of ThinkHumanTV functionality please
            <LinkButton onClick={() => goToScheduleLink()}>
              schedule an onboarding session.
            </LinkButton>
          </div>
        </div>
      )}

      {shouldShowEducatorPrompt &&
        showingEducatorPrompt &&
        currentUser?.extensionInstalled && (
          <div className={styles.info}>
            <div className={styles.message}>
              Are you an educator?
              <LinkButton onClick={() => goToEducatorLearnMoreLink()}>
                Learn more
              </LinkButton>
              about our K12 educator plans, which offer access to K12 curricula,
              educator guides, student licenses and more. Or
              <LinkButton onClick={() => goToDemoLink()}>
                schedule a consultation.
              </LinkButton>
            </div>
            <div className={styles.closeButton}>
              <LinkButton onClick={() => setShowingEducatorPrompt(false)}>
                Dismiss
              </LinkButton>
            </div>
          </div>
        )}

      {!currentUser?.extensionInstalled && (
        <div className="border-solid border border-thin-gray rounded-lg p-4 grid grid-cols-5">
          <div className="col-span-3">
            <div className="text-lg text-th-blue font-semibold leading-7">
              Please install the ThinkHumanTV browser extension
            </div>
            <div className="text-base font-medium leading-6">
              The browser extension is required to use
              <span className="block">ThinkHumanTV on streaming sites</span>
            </div>
            <Button
              className="text-white text-base bg-th-pink rounded-[10px] mt-[22px]"
              to="https://chromewebstore.google.com/detail/thinkhumantv/llmpamjbjeaenkphbfleajgpfdoneefk"
              openInNewTab={true}
            >
              Install Now
            </Button>
          </div>
          <div className="col-span-2 flex justify-center">
            <div className="flex space-x-2 pt-4">
              <a
                href="https://chromewebstore.google.com/detail/thinkhumantv/llmpamjbjeaenkphbfleajgpfdoneefk"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="w-20 h-20 cursor-pointer"
                  src={chromeLogo}
                  alt="Chrome"
                />
              </a>
              <a
                href="https://chromewebstore.google.com/detail/thinkhumantv/llmpamjbjeaenkphbfleajgpfdoneefk"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="w-20 h-20 cursor-pointer"
                  src={edgeLogo}
                  alt="Edge"
                />
              </a>
            </div>
          </div>
        </div>
      )}

      {!!achievements && !!achievements?.length && (
        <div className={styles.topBlock}>
          <div className={styles.stats}>
            <h3>{currentUser?.uid === props.user.uid && 'My '}Achievements</h3>
            <div className={styles.achievementsContainer}>
              {achievements?.map((achievement) => (
                <div key={achievement.uid} className={styles.achievement}>
                  {achievement.achievementType === AchievementTypes.badge && (
                    <div className={styles.achievementIconBadge}>
                      {getAchievementCount(achievement.uid)}
                    </div>
                  )}
                  {!!achievement?.iconFileName && (
                    <img
                      className={styles.achievementIcon}
                      alt={achievement.name}
                      src={joinPath(
                        getAchievementFilePath(achievement.uid),
                        achievement.iconFileName
                      )}
                    />
                  )}
                  <div className={styles.achievementName}>
                    {achievement.name}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {!props.displayedFromPopup &&
        (hasInstitutionalMotivation(currentUser?.motivation) ||
          hasNonDefaultGroups) && <ClassList currentUser={currentUser} />}
      {!props.displayedFromPopup && (
        <CurriculumsList currentUser={currentUser} />
      )}
      <div className={styles.topBlock}>
        <div className={styles.stats}>
          <h3>{currentUser?.uid === props.user.uid && 'My '}Content</h3>
          <div className={styles.statsContainer}>
            {currentUser?.uid === props.user.uid && (
              <div className={styles.statsItem}>
                <div className={styles.statsItemName}>
                  {haveOpenResponses ? stats?.openResponseCount : ''} Open Response(s)
                </div>
                <div className={styles.statsItemVal}>
                  <FontAwesomeIcon icon={faFilm} className={styles.icon} />
                  {haveOpenResponses ? (
                    <LinkButton to="/responses">
                      View <FontAwesomeIcon icon={faArrowRight} />
                    </LinkButton>
                  ) : (
                    '0'
                  )}
                </div>
              </div>
            )}

            {currentUser?.uid === props.user.uid && (
              <div className={styles.statsItem}>
                <div className={styles.statsItemName}>
                  {haveBookmarks ? stats?.bookmarkCount : ''} Marked Moment(s)
                </div>
                <div className={styles.statsItemVal}>
                  <FontAwesomeIcon icon={faFilm} className={styles.icon} />
                  {haveBookmarks ? (
                    <LinkButton to="/moments">
                      View <FontAwesomeIcon icon={faArrowRight} />
                    </LinkButton>
                  ) : (
                    '0'
                  )}
                </div>
              </div>
            )}

            {currentUser?.uid === props.user.uid && (
              <div className={styles.statsItem}>
                <div className={styles.statsItemName}>
                  {haveSavedSegments ? stats?.savedEmotionEpisodeCount : ''}{' '}
                  Saved Training Segment(s)
                </div>
                <div className={styles.statsItemVal}>
                  <FontAwesomeIcon icon={faFilm} className={styles.icon} />
                  {haveSavedSegments ? (
                    <LinkButton to="/saved">
                      View <FontAwesomeIcon icon={faArrowRight} />
                    </LinkButton>
                  ) : (
                    '0'
                  )}
                </div>
              </div>
            )}

            <div className={styles.statsItem}>
              <div className={styles.statsItemName}>
                {haveJournals ? stats?.journalCount : ''} Journal(s)
              </div>
              <div className={styles.statsItemVal}>
                <FontAwesomeIcon icon={faBook} className={styles.icon} />
                {props.asComponent ? (
                  <LinkButton onClick={() => setVisibleJournals(true)}>
                    View <FontAwesomeIcon icon={faArrowRight} />
                  </LinkButton>
                ) : (
                  <LinkButton to="/journals">
                    View <FontAwesomeIcon icon={faArrowRight} />
                  </LinkButton>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.topBlock}>
        <div className={styles.stats}>
          <h3>{currentUser?.uid === props.user.uid && 'My '}Metrics</h3>
          <div className={styles.statsContainer}>
            <div className={styles.statsItem}>
              <div className={styles.statsItemName}>Experience Points</div>
              <div className={styles.statsItemVal}>
                <FontAwesomeIcon icon={faHeart} className={styles.icon} />{' '}
                {stats?.points || 0}
              </div>
            </div>
            <div className={styles.statsItem}>
              <div className={styles.statsItemName}>Curriculum Completed</div>
              <div className={styles.statsItemVal}>
                <FontAwesomeIcon icon={faBatteryHalf} className={styles.icon} />
                {!!stats &&
                  !!stats.totalUnits &&
                  Math.round(
                    ((stats.completedUnits || 0) / (stats.totalUnits || 1)) *
                      100
                  )}
                %
              </div>
            </div>
            <div className={styles.statsItem}>
              <div className={styles.statsItemName}>Streak Length (days)</div>
              <div className={styles.statsItemVal}>
                <FontAwesomeIcon icon={faSun} className={styles.icon} /> 0
              </div>
            </div>
            <div className={styles.statsItem}>
              <div className={styles.statsItemName}>Overall Skill</div>
              <div className={styles.statsItemVal}>
                {!!stats?.achievement?.iconFileName && (
                  <img
                    className={styles.achievementIcon}
                    alt={stats.achievement.name}
                    src={joinPath(
                      getAchievementFilePath(stats.achievement.uid),
                      stats.achievement.iconFileName
                    )}
                  />
                )}
                {stats?.achievement?.name}
              </div>
            </div>

            {pipsAvailable && (
              <div className={styles.statsItem}>
                <div className={styles.statsItemName}>
                  <div>Positive Impact Points (PIPs) Earned</div>
                  <div
                    className={styles.statsItemInfo}
                    onClick={() => goToPipsInfoURL()}
                  >
                    <FontAwesomeIcon icon={faCircleInfo} />
                  </div>
                </div>
                <div className={styles.statsItemVal}>
                  <img
                    src={imagesPath('/pips.png')}
                    alt="pip"
                    className={styles.pipsIcon}
                  />
                  {props.user?.pipsEarned || 0}
                  {!!props.user?.pipsEarned && props.user?.pipsEarned > 0 && (
                    <LinkButton onClick={() => redeemPips()}>
                      Redeem <FontAwesomeIcon icon={faArrowRight} />
                    </LinkButton>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={styles.charts}>
        <div className={styles.chartContainer}>
          <div className={styles.header}>
            <div className={styles.left}>
              <div className={styles.chartTitle}>Skills</div>
              <div className={styles.level}>
                Rating:
                {!!stats &&
                  ((cat) => (
                    <span className={cat?.cssClass}>
                      {' '}
                      {cat?.name}&nbsp; (
                      {stats &&
                        Math.round(
                          ((stats.skillsAnswersCorrect || 0) /
                            (stats.skillsAnswers || 1)) *
                            100
                        )}
                      %)
                    </span>
                  ))(
                    ratingCategories.find(
                      (rc) =>
                        rc.min <=
                          ((stats.skillsAnswersCorrect || 0) /
                            (stats.skillsAnswers || 1)) *
                            100 &&
                        rc.max >
                          ((stats.skillsAnswersCorrect || 0) /
                            (stats.skillsAnswers || 1)) *
                            100
                    )
                  )}
              </div>
            </div>
            {!!userGroup && (
              <div>
                {props.asComponent ? (
                  <LinkButton onClick={() => setVisibleSkillsStats(true)}>
                    Skills Details <FontAwesomeIcon icon={faArrowRight} />
                  </LinkButton>
                ) : (
                  <LinkButton to="/analytics/skills">
                    Skills Details <FontAwesomeIcon icon={faArrowRight} />
                  </LinkButton>
                )}
              </div>
            )}
          </div>
        </div>
        <div className={styles.chartContainer}>
          <div className={styles.header}>
            <div className={styles.left}>
              <div className={styles.chartTitle}>Knowledge</div>
              <div className={styles.level}>
                Rating:&nbsp;
                {!!stats &&
                  ((cat) => (
                    <span className={cat?.cssClass}>
                      {' '}
                      {cat?.name}&nbsp; (
                      {stats &&
                        Math.round(
                          ((stats.conceptsAnswersCorrect || 0) /
                            (stats.conceptsAnswers || 1)) *
                            100
                        )}
                      %)
                    </span>
                  ))(
                    ratingCategories.find(
                      (rc) =>
                        rc.min <=
                          ((stats.conceptsAnswersCorrect || 0) /
                            (stats.conceptsAnswers || 1)) *
                            100 &&
                        rc.max >
                          ((stats.conceptsAnswersCorrect || 0) /
                            (stats.conceptsAnswers || 1)) *
                            100
                    )
                  )}
              </div>
            </div>
            {!!userGroup && (
              <div>
                {props.asComponent ? (
                  <LinkButton onClick={() => setVisibleConceptsStats(true)}>
                    Knowledge Details <FontAwesomeIcon icon={faArrowRight} />
                  </LinkButton>
                ) : (
                  <LinkButton to="/analytics/concepts">
                    Knowledge Details <FontAwesomeIcon icon={faArrowRight} />
                  </LinkButton>
                )}
              </div>
            )}
          </div>
        </div>

        <UserUnitStatsComponent user={props.user} />
      </div>

      {visibleSkillsStats && !!currentUser && (
        <Popup
          title={`Skills stat for ${props.user.firstName} ${props.user.lastName}`}
          onClose={() => setVisibleSkillsStats(false)}
          buttons={[
            {
              title: (
                <>
                  <FontAwesomeIcon
                    icon={faTimes}
                    className={styles.timesIcon}
                  />{' '}
                  <span>CLOSE</span>
                </>
              ),
              onClick: () => setVisibleSkillsStats(false),
              className: 'gray',
            },
          ]}
        >
          <SkillsStatsComponent user={props.user} />
        </Popup>
      )}

      {visibleConceptsStats && !!currentUser && (
        <Popup
          title={`Conceptual Knowledge stats for ${props.user.firstName} ${props.user.lastName}`}
          onClose={() => setVisibleConceptsStats(false)}
          buttons={[
            {
              title: (
                <>
                  <FontAwesomeIcon
                    icon={faTimes}
                    className={styles.timesIcon}
                  />{' '}
                  <span>CLOSE</span>
                </>
              ),
              onClick: () => setVisibleConceptsStats(false),
              className: 'gray',
            },
          ]}
        >
          <ConceptsStatsComponent user={props.user} />
        </Popup>
      )}

      {visibleJournals && !!currentUser && (
        <Popup
          title={`Journals for ${props.user.firstName} ${props.user.lastName}`}
          onClose={() => setVisibleJournals(false)}
          buttons={[
            {
              title: (
                <>
                  <FontAwesomeIcon
                    icon={faTimes}
                    className={styles.timesIcon}
                  />{' '}
                  <span>CLOSE</span>
                </>
              ),
              onClick: () => setVisibleJournals(false),
              className: 'gray',
            },
          ]}
        >
          <JournalsComponent
            user={props.user}
            url={getJournalsUrl()}
            asComponent={true}
          />
        </Popup>
      )}
    </>
  );
}
