import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheck, faCheckCircle, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useMemo } from 'react';
import { toast } from 'react-toastify';
import { addAnswerToUser } from '../../../../api/actions/add-answer-to-user';
import { Button } from '../../../../components/button/button';
import { GroupButtons } from '../../../../components/group-buttons/group-buttons';
import { Video } from '../../../../components/video/video';
import { getCharacterFilePath, getExerciseFilePath, getSceneFilePath } from '../../../../config/app-config';
import { isNullOrEmpty } from '../../../../helpers/common-helpers';
import { getFileName, isAbsoluteUrl } from '../../../../helpers/path-helpers';
import { IEmotionEpisode } from '../../../../models/emotion-episode';
import { IExerciseResponse } from '../../../../models/exercise-response';
import { ILearningChunk } from '../../../../models/learning-chunk';
import { IUnit } from '../../../../models/unit';
import { IUnitSection, UnitSectionTypes } from '../../../../models/unit-section';
import { IUser } from '../../../../models/user';
import { SceneInfo } from '../scene-info';
import { TestSectionEmotionLabelingResponses } from './test-section-emotion-labeling-responses';
import { TestSectionEmotionLabelingResult } from './test-section-emotion-labeling-result';
import { AppConfig } from '../../../../config/app-config';
import styles from './test-section.module.scss';

interface IProps {
    unit: IUnit,
    section: IUnitSection,
    chunkIndex: number,
    chunk: ILearningChunk,
    onNextChunk: () => void,
    onPrevChunk: () => void,
    user?: IUser
}


const MIN_NUMBER_EMOTION_BLEND_LABELING_ANSWERS = 1;
const MAX_NUMBER_EMOTION_BLEND_LABELING_ANSWERS = 3;


export function TestSectionQuestionView(props: IProps) {

    const [playVideo, setPlayVideo] = React.useState(false);
    const [showQuestion, setShowQuestion] = React.useState(false);
    const [showInitialPrompt, setShowInitialPrompt] = React.useState(false);
    const [prevEmotionEpisode, setPrevEmotionEpisode] = React.useState<IEmotionEpisode>();

    const [answerChecked, setAnswerChecked] = React.useState(false);
    const [selectedAnswersUids, setSelectedAnswersUids] = React.useState<string[]>();
    const [selectedAnswers, setSelectedAnswers] = React.useState<IExerciseResponse[]>();
    const [selectedAnswerFeedback, setSelectedAnswerFeedback] = React.useState<string>();

    const { chunk } = props;

    const canPreviewExercises = useMemo(() => props.user?.userGroups?.some(ug => ug.enabled && ug.userPermissions?.length && ug.curriculums?.some(c => c.uid === props.unit.curriculum?.uid)), [props]);
    
    // if scene changed start play video
    React.useEffect(
        () => {
            if(!chunk.exercise?.episode?.scene || chunk.exercise?.episode?.uid === prevEmotionEpisode?.uid) {
                setShowInitialPrompt(true);
                setShowQuestion(true);
            }
            else if(chunk.exercise?.episode?.uid !== prevEmotionEpisode?.uid) {
                setPlayVideo(true);
                setShowInitialPrompt(true);
            }

        },
        [chunk.exercise, prevEmotionEpisode?.uid],
    )

    const clickAnswer = (answers: IExerciseResponse[]) => {
        if(!answerChecked) {
            setSelectedAnswers(answers);
            chunk.exercise.userResponse = answers.map(a => a.uid || a.text);
            setSelectedAnswersUids(chunk.exercise.userResponse);

            if(answers[0]?.feedback) {
                setSelectedAnswerFeedback(answers[0]?.feedback);
              }
        }
    };

    const isSelected = (answer: IExerciseResponse) => chunk.exercise.userResponse?.includes(answer?.uid || answer?.text);

    const getAnswerAdditionalClass = (answer: IExerciseResponse) => !isSelected(answer)
        ? (
            answerChecked && chunk.exercise.correctResponse === answer.text
            ? styles.selected
            : ''
        )
        : (answerChecked && chunk.exercise.correctResponse && chunk.exercise.correctResponse !== selectedAnswersUids?.[0]
            ? styles.selectedInvalid
            : styles.selected
        );

    const onCheck = () => {
        if(chunk.exercise.correctResponse) {
            if(chunk.exercise.correctResponse === selectedAnswersUids?.[0]) {
                toast.success('You are correct!', { position: 'top-center' });
            }
            else {
                toast.error("Oops, that doesn't seem quite right.", { position: 'top-center' });
            }
        }

        //only record answers if we're actually training rather than just previewing
        if(
            AppConfig.videoTrainingEnabled &&
            selectedAnswersUids && (
                chunk.exercise.correctResponse
                || ['emotion_labeling', 'emotion_blend_labeling', 'activation_labeling', 'self_emotion_labeling', 'need_identification'].includes(chunk.exercise.skill?.uid || '')
            )
        ) {
            addAnswerToUser(
                selectedAnswersUids,
                isNullOrEmpty(chunk.exercise.correctResponse) || chunk.exercise.correctResponse === selectedAnswersUids?.[0],
                {
                    skillUid: chunk.exercise.skill?.uid,
                    conceptUid: chunk.exercise.concept?.uid,
                    exerciseType: chunk.exercise.type,
                    characterUid: chunk.exercise.character && chunk.exercise.character.uid,
                    emotionEpisodeUid: chunk.exercise.episode && chunk.exercise.episode.uid,
                },
            ).catch(() => {/** */});
        }

        setAnswerChecked(true);
    };

    const onNext = () => {

        props.onNextChunk();
        setSelectedAnswersUids(undefined);
        setSelectedAnswerFeedback(undefined);
        setAnswerChecked(false);

        setPlayVideo(false);
        setShowInitialPrompt(false);
        setShowQuestion(false);

        setPrevEmotionEpisode(chunk.exercise?.episode);
    };

    const getTotalAnswersCount = () => {
        return chunk.exercise.responses.reduce((acc, el) => acc + (el.answersCount || 0), 1);
    }

    const getCountForCurrentAnswerInPercent = (answer: IExerciseResponse) => {
        return Math.round(
            ((answer.answersCount || 0) + (selectedAnswersUids?.[0] === (answer?.uid || answer?.text) ? 1 : 0)) / (getTotalAnswersCount() || 1) * 100
        );
    }

    const isFeedbackAvailable = () => {
        return (selectedAnswerFeedback !== undefined)
    }

    const getSelectedAnswerFeedbackString = () =>
    {
      return selectedAnswerFeedback !== undefined ? selectedAnswerFeedback : '';
    }

    const onPrevChunk = () => {
        chunk.exercise.userResponse = undefined;
        props.onPrevChunk();
    }

    const minAnswersNumber = chunk.exercise.skill?.uid === 'emotion_blend_labeling' ? MIN_NUMBER_EMOTION_BLEND_LABELING_ANSWERS : 1;
    const maxAnswersNumber = chunk.exercise.skill?.uid === 'emotion_blend_labeling' ? MAX_NUMBER_EMOTION_BLEND_LABELING_ANSWERS : 1;

    return (
        <div className={styles.questionContainer}>
            <div className={styles.questionVideo}>
                {chunk.exercise.episode?.scene?.fileName && (
                    <Video
                        url={
                            isAbsoluteUrl(chunk.exercise.episode.scene.fileName)
                            ? chunk.exercise.episode.scene.fileName
                            : getSceneFilePath(chunk.exercise.episode.scene.uid, `${getFileName(chunk.exercise.episode.scene.fileName)}/video.m3u8`) || ''
                        }
                        //TODO: this logic will may fail for early/long scenes (because the episode start might be greater than the scene start in that case)
                        startPos={chunk.exercise.episode.start >= chunk.exercise.episode.scene.start
                            ? chunk.exercise.episode.start - chunk.exercise.episode.scene.start
                            : chunk.exercise.episode.start
                        }
                        endPos={chunk.exercise.episode.start >= chunk.exercise.episode.scene.start
                            ? chunk.exercise.episode.end - chunk.exercise.episode.scene.start
                            : chunk.exercise.episode.end
                        }
                        playing={playVideo}
                        onEnded={() => { setShowQuestion(true); } }
                    />
                )}

                { chunk.exercise?.episode?.scene?.narrative
                    && chunk.exercise.episode.scene.episode
                    && chunk.exercise.episode.participants && (
                    <SceneInfo
                        narrative={chunk.exercise.episode.scene.narrative}
                        scene={chunk.exercise.episode.scene}
                        episode={chunk.exercise.episode.scene.episode}
                        participants={chunk.exercise.episode.participants}
                    />
                )}
                { chunk.exercise?.fileName && (
                    <img className={styles.sceneImage} src={getExerciseFilePath(chunk.exercise.uid, chunk.exercise.fileName)} alt="." />
                )}
            </div>
            <div className={styles.questionInfoContainer} >
                {chunk.exercise.character && (
                    <div className={styles.characters}>
                        <div className={styles.character}>
                            <img
                                src={getCharacterFilePath(chunk.exercise.character.uid, chunk.exercise.character.fileName)}
                                alt={chunk.exercise.character.name}
                            />
                            Focus: {chunk.exercise.character.name}
                        </div>
                    </div>
                )}
                { chunk.exercise.heading && (
                    <div className={styles.exerciseHeadingText}>
                        {chunk.exercise.heading}
                    </div>
                )}

                { chunk.exercise.initialPrompt && (
                    <div className={`${styles.exerciseInitialPrompt} ${showInitialPrompt || styles.hidden}`}>
                        {chunk.exercise.initialPrompt}
                    </div>
                )}

                <div className={`${styles.question} ${showQuestion || styles.hidden}`}>
                    <div className={styles.questionText}>
                        {chunk.exercise.question}
                    </div>

                    { chunk.exercise.episode && (
                        <div className={styles.questionHintText}>
                        (You can replay this part of the scene by clicking on the video player).
                        </div>
                    )}

                    { chunk.exercise && answerChecked && chunk.exercise.type === 'user_choice'  && ['emotion_labeling', 'self_emotion_labeling', 'emotion_blend_labeling'].includes(chunk.exercise.skill?.uid || '') && (
                      <div className={styles.userChoiceReviewPromptText}>
                        See how your choice compares to other users.
                      </div>
                    )}

                    { chunk.exercise && answerChecked && chunk.exercise.type !== 'user_choice' && isFeedbackAvailable() && (
                      <div className={styles.userChoiceReviewPromptText}>
                        {getSelectedAnswerFeedbackString()}
                      </div>
                    )}

                    <div className={styles.answers}>
                        { ['emotion_labeling', 'self_emotion_labeling', 'emotion_blend_labeling'].includes(chunk.exercise.skill?.uid || '')
                            ? (
                                !answerChecked || !selectedAnswers
                                ? (
                                    <TestSectionEmotionLabelingResponses
                                        minAnswers={minAnswersNumber}
                                        maxAnswers={maxAnswersNumber}
                                        responses={chunk.exercise.responses}
                                        onChange={clickAnswer}
                                    />
                                )
                                : (<TestSectionEmotionLabelingResult responses={chunk.exercise.responses} answers={selectedAnswers} />)
                            )
                            : chunk.exercise.responses.map((answer, i: number) => (
                                <div
                                    key={`answer${i}`}
                                    className={`${styles.answer} ${getAnswerAdditionalClass(answer)}`}
                                    onClick={(AppConfig.videoTrainingEnabled || canPreviewExercises) ? () => clickAnswer([answer]) : undefined}
                                >
                                    { (chunk.exercise.skill?.uid === 'activation_labeling' || chunk.exercise.skill?.uid === 'need_identification') && answerChecked && !!getCountForCurrentAnswerInPercent(answer) && (
                                        <div className={styles.answers_count}>
                                            <div
                                                className={styles.answers_count_line}
                                                style={{ width: `calc(calc(100% - 7rem) / 100 * ${getCountForCurrentAnswerInPercent(answer)})` }}
                                            >
                                            </div>
                                            <div>
                                                {getCountForCurrentAnswerInPercent(answer)}%
                                            </div>
                                        </div>
                                    )}
                                    <div className={styles.answers_value}>
                                        <FontAwesomeIcon icon={isSelected(answer) ? faCheckCircle : faCircle} /> {answer.text}
                                    </div>
                                </div>
                            ))
                        }
                    </div>

                    {!AppConfig.videoTrainingEnabled &&(<>
                        <div className={styles.questionHintText}>
                            (This is a preview. You can click 'Skip' to continue.)
                        </div>
                        <Button className='text large' onClick={onNext}>
                              <span>Skip</span>
                        </Button>
                    </>)}

                </div>
            </div>

            <div className={styles.buttons}>
                <GroupButtons>
                    { props.section.type === UnitSectionTypes.learning && (
                        <Button
                            className="groupButton"
                            disabled={props.chunkIndex <= 0}
                            onClick={onPrevChunk}
                        >
                            <FontAwesomeIcon icon={faChevronLeft} /><span>Previous</span>
                        </Button>
                    )}
                    {(
                            chunk.exercise.correctResponse
                            || ['emotion_labeling', 'emotion_blend_labeling', 'activation_labeling', 'self_emotion_labeling', 'need_identification'].includes(chunk.exercise.skill?.uid || '')
                        )
                        && !answerChecked
                        && (AppConfig.videoTrainingEnabled || !!canPreviewExercises)
                        && (
                            <Button
                                className="groupButton"
                                disabled={minAnswersNumber > (selectedAnswersUids?.length || 0) || (selectedAnswersUids?.length || 0) > maxAnswersNumber}
                                onClick={onCheck}
                            >
                                <span>Check</span><FontAwesomeIcon icon={faCheck} />
                            </Button>
                    )}
                    { ((
                            !chunk.exercise.correctResponse
                            && !['emotion_labeling', 'emotion_blend_labeling', 'activation_labeling', 'self_emotion_labeling', 'need_identification'].includes(chunk.exercise.skill?.uid || '')
                        ) || answerChecked)
                        && (
                        <Button
                            className="groupButton"
                            disabled={!selectedAnswersUids && !chunk.exercise.userResponse}
                            onClick={onNext}
                        >
                            <span>Next</span><FontAwesomeIcon icon={faChevronRight} />
                        </Button>
                    )}
                </GroupButtons>
            </div>
        </div>
    )

}
