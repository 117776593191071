import { FetchResult, gql } from 'apollo-boost';
import { IUser } from '../../models/user';
import { MultiError } from '../../types/multi-error';
import { graphqlClient } from '../graphql-client';

const MUTATION = gql`
    mutation UserConfigAddCharacter($characterUid: String!){
        user_config_add_character (characterUid: $characterUid) {
            success,
            user {
                uid,
                characters {
                    uid
                },
            },
        }
    }
`;

export interface IResultAddCharacter {
    success: boolean;
    user: IUser;
}

export async function addCharacterToUser(characterUid: string): Promise<IUser> {
    const result: FetchResult<{ user_config_add_character: IResultAddCharacter }> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { characterUid },
        errorPolicy: 'all',
    });

    if(!result.data || (result.errors && result.errors.length)) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.user_config_add_character.user;
}
