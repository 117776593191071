export enum UserMotivationTypes {
  prof_development = 'For Professional Development (Educators)',
  educational_interest = 'As a Facilitator/Educator with students (e.g. K12)',
  mental_health = 'As a Clinician/Therapist',
  assignment = 'As a Student or Client (e.g. assigned by instructor)',
  personal_interest = 'On my own to improve my EQ',
  //eq_certification = 'Acquiring an EQ certification',  
  //child_interest = 'Supporting my kid(s) in developing their EQ',
  //social_invitation = 'Recommended by a friend or family member',
  //professional_invitation = 'Recommended by therapist/counselor or educator',
  other = 'Other'
}
