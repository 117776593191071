import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { addEmotionEpisodeToUser } from '../../../api/actions/add-emotion-episode-to-user';
import { getCurrentUser } from '../../../api/actions/get-current-user';
import { getUnit } from '../../../api/actions/get-unit';
import { BackButton } from '../../../components/back-button/back-button';
import { HeaderContent } from '../../../components/header-content/header-content';
import { MainLayout } from '../../../components/layouts/main-layout';
import { LinkButton } from '../../../components/link-button/link-button';
import { Loader } from '../../../components/loader/loader';
import { Progress } from '../../../components/progress/progress';
import { basePerformError } from '../../../helpers/error-helpers';
import { IUnit } from '../../../models/unit';
import { ILearningChunk } from '../../../models/learning-chunk';
import { IUnitSection, UnitSectionTypes } from '../../../models/unit-section';
import { SecuredPage } from '../../base/secured-page';
import { EmotionalSchemaContext } from './emotional-schema/emotional-schema';
import styles from './unit-page.module.scss';
import { LearningSection } from './unit_sections/learning-section';
import { TestSection } from './unit_sections/test-section';
import { UnitFinishSection } from './unit_sections/unit-finish-section';
import { AppConfig } from '../../../config/app-config';
import { joinPath } from '../../../helpers/path-helpers';
import { UserRoles } from '../../../models/user-roles-enum';


export function UnitPage(props: RouteComponentProps<{ curriculumUid: string, unitUid: string, destination: string }>) {

    const [unit, setUnit] = useState<IUnit>();
    const [sectionIndex, setSectionIndex] = useState(0);
    const [chunkIndex, setChunkIndex] = useState(0);

    const [updateTime, updateUpdatedTime] = React.useReducer(() => Date.now(), 0);

    const [startTimestamp] = useState(Date.now());

    const context = React.useContext(EmotionalSchemaContext);

    const getProgress = React.useCallback(() => {
            return (!unit?.unitSections
                    ? { max: 1, value: 0 }
                    : unit?.unitSections.map((sect, index) => {
                        const length = !sect.learningChunks ? 1 : sect.learningChunks.filter(lc => lc.available).length;
                        return ({
                            max: length || 1,
                            value: index < sectionIndex
                                ? (length || 1)
                                : (index === sectionIndex ? chunkIndex : 0),
                        });
                    })
                );

    }, [unit?.unitSections, chunkIndex, sectionIndex]);

    useEffect(() => {
        (async () => {
            try {
                const usr = await getCurrentUser();
                const unitLoaded = await getUnit(props.match.params.unitUid);
                const curriculum_uid = unitLoaded?.curriculum?.uid;

                unitLoaded.unitSections = unitLoaded.unitSections.filter(
                    u => u.available
                        && (
                            (u.type === UnitSectionTypes.practice && !u.learningChunks)
                            || (u.learningChunks && u.learningChunks.some(lc => lc.available))
                        ),
                );

                unitLoaded.unitSections.forEach(us => { us.learningChunks = us.learningChunks?.filter(lc => lc.available); })

                let startSectionIndex = 0;

                const hasNavPermissions = usr.userGroups?.some(ug => ug.enabled && ug.userPermissions?.length && ug.curriculums?.some(c => c.uid === curriculum_uid));
                if((usr.role === UserRoles.admin || usr.role === UserRoles.editor || hasNavPermissions) && unitLoaded.educatorGuide && unitLoaded.educatorGuide.length > 0) {
                  //create a guide section for super users
                  const guideChunk: ILearningChunk = {
                    index: 1,
                    type: UnitSectionTypes.learning,
                    content: unitLoaded.educatorGuide,
                    available: true
                  } as ILearningChunk;
                  const chunks = [guideChunk];

                  const guideSection: IUnitSection = {
                    type: UnitSectionTypes.learning,
                    available: true,
                    learningChunks: chunks
                  } as IUnitSection;

                  unitLoaded.unitSections.push(guideSection);
                  if(props.match.params.destination === 'guide') {
                    startSectionIndex = unitLoaded.unitSections.length - 1;
                  }
                }

                if (props.match.params.destination === 'practice') {
                  startSectionIndex = getPracticeSection(unitLoaded.unitSections);
                }

                updateUpdatedTime();
                setUnit(unitLoaded);
                context?.setFinishedUnit(unitLoaded);

                setSectionIndex(startSectionIndex);
            }
            catch(err) {
                basePerformError(err, props.history);
                return;
            }
        })().catch(() => {/** */});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const section = unit?.unitSections[sectionIndex];

    const getPracticeSection = (sections: IUnitSection[]) => {
      let index = 0
      for (const section of sections) {
        if(section.type === UnitSectionTypes.practice) {
          return index;
        }
        index = index + 1;
      }
      return 0;
    }

    const nextSection = () => {
      const newSectionIndex = sectionIndex + 1;
      setCurrentSection(newSectionIndex);
    }

    const setCurrentSection = (newSectionIndex: number) => {
        setChunkIndex(0);
        setSectionIndex(newSectionIndex);
        //go back to the curriculum view if we finished the unit and aren't going to show the unit completed screen
        if (unit && newSectionIndex > unit.unitSections.length - 1 && !AppConfig.videoTrainingEnabled) {
          props.history.push(joinPath('/curriculum', unit.curriculum?.uid));
        }
    };

    const getNextSectionType = () => unit?.unitSections?.[sectionIndex + 1]?.type;

    const onChangeEmotionEpisode = async (emotionEpisodeUid: string) => {
        await addEmotionEpisodeToUser(emotionEpisodeUid);
    };

    const onNextChunk = () => {
        if( section?.type === UnitSectionTypes.practice
            && section?.learningChunks?.[chunkIndex].exercise?.episode
            && (
                !section?.learningChunks?.[chunkIndex + 1]
                || section?.learningChunks?.[chunkIndex + 1].exercise.episode.uid !== section?.learningChunks?.[chunkIndex].exercise.episode.uid
            )
        ) {
            onChangeEmotionEpisode(section?.learningChunks?.[chunkIndex].exercise.episode.uid).catch(() => {/** */});
        }

        if(!section?.learningChunks?.length || chunkIndex >= section?.learningChunks?.length - 1) {

            nextSection();
        }
        else {
            setChunkIndex(chunkIndex + 1);
        }
    };
    const onPrevChunk = () => {
        if(chunkIndex > 0) {
            setChunkIndex(chunkIndex - 1);
        }
    };

    return (
        <SecuredPage pageName="units" {...props} >
            <MainLayout noPaddings={true}>
                <BackButton>
                    <LinkButton to={`/curriculum${props.match.params.curriculumUid ? '/' + props.match.params.curriculumUid : '' }`}>
                        <FontAwesomeIcon icon={faArrowLeft} /> Back to Units
                    </LinkButton>
                </BackButton>
                <HeaderContent key={`${sectionIndex || 0}_${chunkIndex || 0}`}>
                    <Progress className={styles.topProgress} progress={getProgress()} />
                </HeaderContent>
                { !unit &&
                    <Loader />
                }
                { section && section.type === UnitSectionTypes.learning
                    && (!section?.learningChunks?.[chunkIndex].type || section?.learningChunks?.[chunkIndex].type === UnitSectionTypes.learning)
                    && (
                        <LearningSection
                            learningChunks={section?.learningChunks || []}
                            nextSectionType={getNextSectionType()}
                            onNextChunk={onNextChunk}
                            onPrevChunk={onPrevChunk}
                            chunkIndex={chunkIndex}
                        />
                    )
                }
                { section
                    && (
                        section.type === UnitSectionTypes.practice
                        || (section?.learningChunks?.[chunkIndex].type === UnitSectionTypes.practice)) && (
                        <TestSection
                            unit={unit}
                            section={section}
                            updateUpdatedTime={updateUpdatedTime}
                            nextSectionType={getNextSectionType()}
                            onNextChunk={onNextChunk}
                            onPrevChunk={onPrevChunk}
                            chunkIndex={chunkIndex}
                        />
                    )
                }
                { unit && !section && AppConfig.videoTrainingEnabled && (
                    <UnitFinishSection unit={unit} startTimestamp={startTimestamp} />
                )}
            </MainLayout>
        </SecuredPage>
    );
}
