import React, { useRef, useState } from 'react';

export const PopupManagerContext = React.createContext<{
    addPopup: (popup: any) => string,
    removePopup: (uid: string) => void,
    onUpdate: () => void,
} | undefined>(undefined);

export function PopupManager({ children }: { children: React.ReactElement | Array<React.ReactElement | string | boolean>}) {

    const stack = useRef<{ uid: string, popupComponent: { renderPopup(): any } }[]>([]);
    const [updatedAt, setUpdatedAt] = useState<number>();

    const addPopup = (popupComponent: { renderPopup(): any }) => {
        const uid = `${(new Date()).getTime()}-${Math.random() * 1000000}`;
        stack.current.push({ uid, popupComponent });
        setUpdatedAt((new Date()).getTime());
        return uid;
    }

    const removePopup = (uid: string) => {
        stack.current = stack.current.filter(popup => popup.uid !== uid);
        setUpdatedAt((new Date()).getTime());
    }
    
    const onUpdate = () => {
        setUpdatedAt((new Date()).getTime());
    }

    return (
        <PopupManagerContext.Provider value={{ addPopup, removePopup, onUpdate }} >
            {children}

            {stack.current.map(el => (<React.Fragment key={el.uid}>{el.popupComponent.renderPopup()}</React.Fragment>))}
        </PopupManagerContext.Provider>
    )
}