import { FetchResult, gql } from 'apollo-boost';
import { IUser } from '../../models/user';
import { MultiError } from '../../types/multi-error';
import { graphqlClient } from '../graphql-client';

const MUTATION = gql`
    mutation UserConfigAddNarrative($narrativeUid: String!){
        user_config_add_narrative (narrativeUid: $narrativeUid) {
            success,
            user {
                uid,
                narratives {
                    uid
                },
            },
        }
    }
`;

export interface IResultAddNarrative {
    success: boolean;
    user: IUser;
}

export async function addNarrativeToUser(narrativeUid: string): Promise<IUser> {
    const result: FetchResult<{ user_config_add_narrative: IResultAddNarrative }> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { narrativeUid },
        errorPolicy: 'all',
    });

    if(!result.data || (result.errors && result.errors.length)) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.user_config_add_narrative.user;
}
