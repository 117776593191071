import { useReducer, useEffect } from 'react';
import ReactGA from "react-ga4";

type InitialState = {
  search: string,
  service: string,
  age: string,
  skip: string,
}

const initialState: InitialState = {
  search: '',
  service: '',
  age: '',
  skip: '',
};

type ActionWithPayload = {
  type: 'set_search',
  payload: {
    search: string,
    service: string,
    age: string,
    skip: string,
  },
}

function reducer(state = initialState, action: ActionWithPayload) {
  // not following the linting rule below makes sense in this case
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (action.type) {
    case 'set_search':
      return {
        ...state,
        ...action.payload,
      };
    default:
      throw new Error();
  }
}

const useGoogleAnalytics = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const { search, service, age, skip } = state
    const eventData = `Search: ${search} | Service: ${service} | Age: ${age} | Skip Value: ${skip}`

    state.search.length && ReactGA.event({
      category: 'Search',
      action: eventData,
      label: 'From main Search'
    })
  }, [state])

  return { state, dispatch };
};

export default useGoogleAnalytics;
