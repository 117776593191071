import React, { FC } from 'react';
import { IEmotionEpisode } from '../../models/emotion-episode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSave,
  faLink,
  faEdit,
  faStickyNote,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';

interface SearchTileActionsProps {
  result: IEmotionEpisode;
  handleSaveEpisode: (uid: any, url: any, ee: any) => any;
  copyShareLink: (uid: any) => any;
  setcurrentEpisode: (ee: IEmotionEpisode) => any;
  setaddNotePopup: (value: boolean) => void;
  setnoteText: (ee: string) => any;
}

function classNames(...classes: (false | null | undefined | string)[]) {
  return classes.filter(Boolean).join(' ');
}

export const SearchTileActions: FC<SearchTileActionsProps> = ({
  result,
  handleSaveEpisode,
  copyShareLink,
  setcurrentEpisode,
  setaddNotePopup,
  setnoteText,
}) => (
  <div className="flex space-x-4 text-white">
    <div
      className="relative cursor-pointer group"
      onClick={() =>
        result.mediaUrls &&
        handleSaveEpisode(result.uid, result.mediaUrls[0], result)
      }
    >
      <FontAwesomeIcon
        icon={result.saved ? faTrash : faSave}
        className="h-6 w-6 text-filter-blue"
      />
      <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-black text-white text-sm rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
        {result.saved ? 'Remove' : 'Save'}
      </span>
    </div>

    {result.saved && (
      <div
        className="relative cursor-pointer group"
        onClick={() => {
          setcurrentEpisode(result);
          setaddNotePopup(true);
          setnoteText(result.comment ? result.comment.html : '');
        }}
      >
        <FontAwesomeIcon
          icon={result.comment ? faEdit : faStickyNote}
          className="h-6 w-6 text-filter-blue"
        />
        <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-black text-white text-sm rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
          {result.comment ? 'Edit Note' : 'Add Note'}
        </span>
      </div>
    )}

    <div
      className="relative cursor-pointer group"
      onClick={() => copyShareLink(result.uid)}
    >
      <FontAwesomeIcon icon={faLink} className="h-6 w-6 text-filter-blue" />
      <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-black text-white text-sm rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
        Copy Link
      </span>
    </div>
  </div>
);
