import React, { ReactElement, useCallback, useState } from 'react';
import { IUser } from '../../../../models/user';
import { faExternalLinkAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { compareText, compareNumbersDescending } from '../../../../helpers/array-helpers';
import { secondsToTimeString } from '../../../../helpers/datetime-helpers';
import { SelectField } from '../../../../components/select/select';
import { Button } from '../../../../components/button/button';
import { Loader } from '../../../../components/loader/loader';
import { LinkButton } from '../../../../components/link-button/link-button';
import { IBookmark } from '../../../../models/bookmark';
import { UserRoles } from '../../../../models/user-roles-enum';
import { IDictionary } from '../../../../types/dictionary';
import { basePerformError } from '../../../../helpers/error-helpers';
import { AppConfig } from '../../../../config/app-config';
import * as Yup from 'yup';

import { toast } from 'react-toastify';
import { getBookmarks } from '../../../../api/actions/get-bookmarks';
import { removeSavedEmotionEpisodeFromUser } from '../../../../api/actions/remove-saved-emotion-episode-from-user';
import { emotionEpisodeLink } from '../../../../api/actions/get-emotion-episode-link';
import moment from 'moment';

import styles from './bookmarks-component.module.scss';

interface IProps {
    user: IUser,
}

export function BookmarksComponent(props: IProps) {

  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [segments, setSegments] = useState<IBookmark[]>([]);
  const [error, setError] = React.useState<string>();

  const isSuperUser = React.useMemo(() => props.user && props.user.role && props.user.role !== UserRoles.user, [props]);

    React.useEffect(() => {
      (async () => {
          try {
            //console.log("fetching saved bookmarks for user");
            setSegments(await getBookmarks());
          }
          catch(err) {
              basePerformError(err);
              return;
          }

      })().catch(() => {/** */});
    }, [props]);

    const linkToExternalVideo = async (uid: string) => {
        if(!uid) return;
        setLoading(true);
        try {
            const url = await emotionEpisodeLink(uid, 'bookmark');
            if(url) {
              window.open(url);
            } else {
              console.log("failed to get emotion episode link");
            }
        }
        catch(err) {
            basePerformError(err);
        }
        setLoading(false);
    };

    return (<>
      <div>
        {loading ? (<Loader />) : (<>
            <div className="list-title">
                <div>Moments: <strong>{ (segments?.length) || 0 }</strong></div>
            </div>
            { !segments?.length ? null : (
                <table cellPadding="0" cellSpacing="0" className={`list ${styles.list}`}>
                    <thead>
                        <tr>
                            <th>Description</th>

                            <th>Start</th>
                            <th>End</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            segments?.sort((ee1, ee2) => compareNumbersDescending(ee1, ee2, (ee) => parseInt(ee.createdAt || '0')))
                            .map((ee: IBookmark) => (
                                <tr key={ee.uid}>
                                    <td>
                                        <div className={styles.markContainer}>
                                            <div className={styles.title}>
                                                <div>
                                                  {moment(ee.createdAt).format('MM/DD/YYYY h:mm a')}
                                                </div>
                                                {ee.description ? (<>
                                                  {ee.description}</>) : (<>
                                                  <span>Untitled Moment</span>
                                                  </>)}
                                                {ee.narrativeTitle && (
                                                <div className={styles.narrative}>
                                                    {ee.narrativeTitle}
                                                </div>
                                                )}
                                                {ee.episodeTitle && (
                                                    <div className={styles.episode}>
                                                        {ee.episodeTitle}
                                                    </div>
                                                )}
                                                {ee.service && (
                                                  <div className={styles.service}>
                                                      {ee.service.toUpperCase()}
                                                  </div>
                                                )}
                                            </div>
                                        </div>
                                    </td>

                                    <td>
                                        {secondsToTimeString(ee.start, true)}
                                    </td>
                                    <td>
                                        {secondsToTimeString(ee.end, true)}
                                    </td>
                                    <td>
                                    <LinkButton onClick={() => linkToExternalVideo(ee.uid)} className='green'
                                    >
                                        <FontAwesomeIcon icon={faExternalLinkAlt} /> Go to Clip
                                    </LinkButton><br/>

                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            )}
        </>)}
    </div>
    </>);
}
