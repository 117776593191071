import { ICharacter } from './character';
import { IConcept } from './concept';
import { IEmotionEpisode } from './emotion-episode';
import { IExerciseResponse } from './exercise-response';
import { compareText } from "../helpers/array-helpers";
import { ISkill } from './skill';

export interface IExercise {
    uid: string,
    type: string,
    subtype: string,
    mediaType: string,
    contentReference: string,
    heading: string,
    initialPrompt: string,
    question: string,
    correctResponse: string,
    fileName: string,

    userResponse?: string[],

    responses: IExerciseResponse[],
    episode: IEmotionEpisode,
    character: ICharacter,
    skill?: ISkill,
    concept?: IConcept,
}

export const buildResponseTree = (categories: IExerciseResponse[]) => {
    if(!categories) return categories;

    const roots = categories.filter(ec => !ec.parent)
        .sort((el1, el2) => compareText(el1, el2, (obj: IExerciseResponse) => obj && obj.text));

    //put 'other' at end of response list
    const other = roots.find((item: any) => item.uid == 'other');
    if(other) {
      const index = roots.indexOf(other);
      roots.splice(index, 1);
      roots.push(other);
    }

    roots.forEach(el => buildTreeNode(el, categories));

    return roots;
}

const buildTreeNode = (emotionCategory: IExerciseResponse, categories: IExerciseResponse[]) => {
    
    const ecUid = emotionCategory.uid ? emotionCategory.uid : 'none';
    const children = categories.filter(ec => ec.parent && ec.parent.includes(ecUid))
        .sort((el1, el2) => compareText(el1, el2, (obj: IExerciseResponse) => obj && obj.text));

    if(children && children.length) {
        children.forEach(el => buildTreeNode(el, categories));
        emotionCategory.children = children;
    }
}

