import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { LinkButton } from '../../../components/link-button/link-button';
import { Popup } from '../../../components/popup/popup';
import { getCharacterFilePath } from '../../../config/app-config';
import { IEpisode } from '../../../models/episode';
import { INarrative } from '../../../models/narrative';
import { IParticipant } from '../../../models/participant';
import { IScene } from '../../../models/scene';
import styles from './scene-info.module.scss';

interface IProps {
    participants: IParticipant[];
    narrative: INarrative;
    episode: IEpisode;
    scene: IScene;
}

export function SceneInfo(props: IProps) {

    const [popupVisible, setPopupVisible] = React.useState(false);

    return (<>
        <LinkButton className={styles.link} onClick={() => setPopupVisible(true)}>
            <FontAwesomeIcon icon={faInfoCircle} />Video info
        </LinkButton>
        {popupVisible && (
            <Popup
                title="Video info"
                onClose={() => setPopupVisible(false)}
                className="full"
            >
                <div className={styles.container}>
                    <div className={styles.narrative}>
                        <div className={styles.narrativeTitle}>
                            {props.narrative.title}
                        </div>
                        {props.episode && (<>
                            <div className={styles.episodeInfo}>
                                Season {props.episode.season} Episode {props.episode.numberInSeason}
                            </div>
                            <div className={styles.episodeName}>
                                {props.episode.name}
                            </div>
                        </>)}
                    </div>
                    <div className={styles.characters}>
                        <h3>CHARACTERS</h3>
                        { props.participants && props.participants.map( p => (
                            <div key={p.character.uid} className={styles.character}>
                                <img
                                    src={getCharacterFilePath(p.character.uid, p.character.fileName)}
                                    alt={p.character.name}
                                />
                                <div className={styles.characterInfo}>
                                    <div>
                                        {p.character.name}
                                    </div>
                                    <div className={styles.characterDescription}>
                                        {p.character.description}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </Popup>
        )}
    </>);
}
