import React, { ReactElement, useContext, useState } from 'react';
import { IUser } from '../../../../models/user';
import { Field, Form, Formik, FormikProps } from 'formik';
import { compareText } from '../../../../helpers/array-helpers';
import { SelectField } from '../../../../components/select/select';
import { Button } from '../../../../components/button/button';
import { Loader } from '../../../../components/loader/loader';
import { LinkButton } from '../../../../components/link-button/link-button';
import { IUserGroup } from '../../../../models/user-group';
import { IDictionary } from '../../../../types/dictionary';
import { basePerformError } from '../../../../helpers/error-helpers';
import { AppConfig } from '../../../../config/app-config';
import { addUserToUserGroup } from '../../../../api/actions/user-groups/user-group-user-add';
import { openExtensionUrl } from '../../../../helpers/common-helpers';
import { History } from 'history';
import { useHistory, useLocation } from 'react-router-dom';
import { hasMotivation } from '../../../../utils.js';
import { PopupContext } from '../../../../contexts/popup-context';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import { UserRoles } from '../../../../models/user-roles-enum';
import * as Yup from 'yup';

import { toast } from 'react-toastify';

import styles from './group-select-component.module.scss';
import { set } from 'js-cookie';

interface IForm {
  userGroup: string;
}

interface IProps {
  user?: IUser;
  history?: History;
}

interface LocationState {
  from?: string;
  orgTitle?: string;
}

export function GroupSelectComponent(props: IProps) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [userGroups, setUserGroups] = useState<IUserGroup[]>([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [error, setError] = useState<string | undefined>();
  const location = useLocation<LocationState>();
  const fromClassList = location.state?.from === 'class-list';
  const orgTitle = location.state?.orgTitle;
  const [hasSeenPopup, setHasSeenPopup] = useLocalStorage(
    'orientation-popup',
    false,
    props.user?.uid
  );
  const popupContext = useContext(PopupContext);
  if (!popupContext) throw new Error('Use TwPopup within a <PopupProvider>');
  const { setOpenPopup } = popupContext;

  const hasOrgPermissions =
    props.user?.userGroups?.some(
      (userGroup) =>
        Array.isArray(userGroup.userPermissions) &&
        userGroup.userPermissions.length > 0 &&
        userGroup.groupType === 'organization'
    ) ?? false;

  React.useEffect(() => {
    (async () => {
      try {
        //console.log("fetching available groups for org");
        const orgGroup = props.user?.userGroups?.find(
          (ug) => ug.enabled && ug.groupType === 'organization'
        );

        if (orgGroup) {
          const children = orgGroup.children?.filter(
            (ug) => ug.enabled && ug.unlisted !== true
          );
          setUserGroups(
            children
              ? children.sort((el1, el2) =>
                  compareText(el1, el2, (el) => el.title)
                )
              : []
          );
        }
      } catch (err) {
        basePerformError(err);
        return;
      }
    })().catch(() => {
      /** */
    });
  }, [props]);

  const handleUpdateGroupSubmit = async (
    values: IForm,
    {
      setSubmitting,
      setErrors,
    }: {
      setSubmitting: (status: boolean) => void;
      setErrors: (errors: IDictionary<string>) => void;
    }
  ) => {
    setLoading(true);
    let payment_url;

    try {
      //console.log("submitting group update");
      await addUserToUserGroup(values.userGroup, props.user?.uid);
      //go to profile page
      props.history?.push('/analytics');

      if (!props.user?.extensionInstalled) {
        //prompt user to install extension if they did not sign up via extension
        setTimeout(openExtensionUrl, 3000);
      }
    } catch (err) {
      /**/
      console.log('Error: ', err);
    }
    setSubmitting(false);
    setLoading(false);
  };

  const getValidationSchema = () => {
    return Yup.object({
      userGroup: Yup.string(),
    });
  };

  const groupTitleWithOwnerName = (group: IUserGroup) => {
    return (
      group.title +
      (group.owner && group.owner.role !== UserRoles.admin
        ? ' (' + group.owner.lastName + ')'
        : '')
    );
  };

  const handleSelectChange =
    (
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
      ) => void
    ) =>
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target;
      setFieldValue('userGroup', value);
      if (value === 'to-class-create') {
        history.push('/my-classes/create');
      }
    };

  const renderGroupUpdateForm = ({
    errors,
    touched,
    submitForm,
    values,
    setFieldValue,
  }: FormikProps<IForm>): ReactElement => {
    return (
      <div className={styles.container}>
        <div className={styles.subcontainer}>
          {error && (
            <div className={`${styles.error} ${'errors'}`}>{error}</div>
          )}

          {!error && (
            <Form noValidate>
              <div className="form-item">
                <label>
                  {hasMotivation(props.user?.motivation) ? (
                    <>
                      <div className="text-black mb-12 text-center">
                        <span className="font-medium">
                          Please select or create your class/group{' '}
                          <div className="mt-1">
                            {orgTitle ? ` at ${orgTitle}` : null}
                          </div>{' '}
                        </span>
                        <br />
                        <div className="text-sm pt-6">
                          <span className="font-small">
                            (you can also do this later)
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="text-black mb-12 text-center">
                        <span className="font-medium">
                          Please select your group or class
                        </span>
                        <br />
                        <span className="font-small">
                          (if no groups are listed, you can skip and do this
                          later)
                        </span>
                      </div>
                      {orgTitle ? ` at ${orgTitle}` : ''}
                    </>
                  )}

                  <Field
                    component={SelectField}
                    name="userGroup"
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onChange={handleSelectChange(setFieldValue)}
                    data={[
                      ...userGroups
                        ?.filter(
                          (g: IUserGroup) =>
                            !props.user?.userGroups?.some(
                              (ug) => ug.uid === g.uid
                            ) && !g.expired
                        )
                        ?.sort((a1, a2) =>
                          groupTitleWithOwnerName(a1) >
                          groupTitleWithOwnerName(a2)
                            ? 1
                            : groupTitleWithOwnerName(a1) <
                              groupTitleWithOwnerName(a2)
                            ? -1
                            : 0
                        )
                        .map((a: IUserGroup) => ({
                          uid: a.uid,
                          name: groupTitleWithOwnerName(a),
                        })),
                      ...(hasMotivation(props.user?.motivation) ||
                      hasOrgPermissions
                        ? [
                            {
                              uid: 'to-class-create',
                              name: '+ Create Class/Group',
                            },
                          ]
                        : []),
                    ]}
                  />
                </label>
                <div className="errors">
                  {touched.userGroup && errors.userGroup}
                </div>
              </div>
              <div className="form-buttons single">
                {loading ? (
                  <Loader />
                ) : (
                  <div className="flex flex-col items-center">
                    <Button
                      onClick={() => {
                        if (!hasSeenPopup) {
                          setOpenPopup('signIn');
                          setHasSeenPopup(true);
                        }
                        void submitForm();
                      }}
                      disabled={values.userGroup === ''}
                    >
                      Select
                    </Button>

                    <div
                      className="mt-16 cursor-pointer"
                      onClick={() => {
                        if (!hasSeenPopup) {
                          setOpenPopup('signIn');
                          setHasSeenPopup(true);
                          history.push('/analytics');
                        } else {
                          history.push('/analytics');
                        }
                      }}
                    >
                      {fromClassList ? 'cancel' : 'skip'}
                    </div>
                  </div>
                )}
              </div>
            </Form>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <Formik
        initialValues={{
          userGroup: '',
        }}
        validationSchema={getValidationSchema}
        onSubmit={handleUpdateGroupSubmit}
      >
        {renderGroupUpdateForm}
      </Formik>
    </>
  );
}
