import * as React from 'react';
import styles from './progress.module.scss';

interface IProgress {
    max: number;
    value: number;
}

interface IProps {
    progress: IProgress | IProgress[];
    className?: string;
}

export function Progress(props: IProps) {
    const progress = Array.isArray(props.progress) ? props.progress : [props.progress];
    return (
        <div className={`${styles.progress} ${props.className}`}>
            {progress.map((p: IProgress, i: number) => (
                <div key={i} className={styles.progressElement} style={{ flexBasis: 100 / progress.length + '%' }}>
                    <div className={styles.value} style={{ width: 100 / p.max * p.value + '%'}}></div>
                </div>
            ))}
        </div>
    );
}