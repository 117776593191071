import { FetchResult, gql } from 'apollo-boost';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation userGrantGroupPermissions($userUid: String!, $groupUid: String!){
        user_grant_permission_for_group(user_uid: $userUid, group_uid: $groupUid) {
            success,
            not_logged_in,
            invalid_user_or_group,
            already_granted
        }
    }
`;

interface IResult {
    success: boolean,
    not_logged_in: boolean,
    invalid_user_or_group: boolean,
    already_granted: boolean,
    alread_revoked: boolean,
}

export async function userGrantGroupPermissions(userUid: string, groupUid: string): Promise<IResult> {
    const result: FetchResult<{ user_grant_permission_for_group: IResult }> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: {
            userUid,
            groupUid
        },
        errorPolicy: 'all',
    });

    if(!result.data || (result.errors && result.errors.length)) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.user_grant_permission_for_group;
}
