import * as d3 from "d3";
import * as React from 'react';
import { toast } from 'react-toastify';
import { IExerciseResponse } from '../../../../models/exercise-response';
import styles from './test-section.module.scss';

interface IProps {
    responses: IExerciseResponse[],
    minAnswers: number,
    maxAnswers: number,
    onChange?(answer: IExerciseResponse[]): void,
}

export function TestSectionEmotionLabelingResponses(props: IProps) {

    const container = React.createRef<HTMLDivElement>();
    const answers = React.useRef<IExerciseResponse[]>([]);

    const draw = (data: any, size = 1) => {
        const width = 480;
        const radius = width / (6);
        
        const arc = d3.arc()
            .startAngle((d: any) => d.x0)
            .endAngle((d: any) => d.x1)
            .padAngle((d: any) => Math.min((d.x1 - d.x0) / 2, 0.005))
            .padRadius(radius * 1.5)
            .innerRadius((d: any) => d.y0 * radius)
            .outerRadius((d: any) => Math.max(d.y0 * radius, d.y1 * radius - 1));

        const partition = (data: any) => {
                const root = d3.hierarchy(data)
                    .sum(d => !d.children ? 1 : 0);
                    
                return d3.partition().size([2 * Math.PI, root.height + 1])(root);
            }


        const color = d3.scaleOrdinal(d3.quantize(d3.interpolateRainbow, data.children.length + 1))

        
        const root = partition(data);
        
        root.each((d: any) => d.current = d);
        
        const svg = d3.create("svg")
            .attr("viewBox", [0, 0, width, width] as any)
            .style("font", "10px sans-serif");
        
        const g = svg.append("g")
            .attr("transform", `translate(${width / 2},${width / 2})`);
        
        const path = g.append("g")
            .selectAll("path")
            .data(root.descendants().slice(1))
            .join("path")
            .attr("fill", (d: any) => { while (d.depth > 1) d = d.parent; return color(d.data.text); })
            .attr("fill-opacity", (d: any) => arcVisible(d.current) ? (d.children ? 0.6 : 0.4) : 0)
            .attr("d", (d: any) => arc(d.current));
        
        // path.filter((d: any) => d.children)
        //     .style("cursor", "pointer")
        //     .on("click", clicked1);
        
        path.filter((d: any) => !d.children)
            .style("cursor", "pointer")
            .on("click", (obj: any) => {

                if(props.maxAnswers === 1) {
                    answers.current = [obj.data];
                }
                else if(answers.current.map(a => a.uid).includes(obj.data.uid)) {
                    answers.current = answers.current.filter(a => a.uid !== obj.data.uid);
                }
                else if(answers.current.length < props.maxAnswers) {
                    answers.current.push(obj.data);
                }
                else {
                    toast.error("Maximum number of answers has been given");
                }

                path.filter(d => !answers.current.map(a => a.uid).includes((d.data as any).uid))
                    .classed(styles.selected, false);
                
                path.filter(d => answers.current.map(a => a.uid).includes((d.data as any).uid))
                    .classed(styles.selected, true);

                if(props.onChange) props.onChange(answers.current);
            });
        
        path.append("title")
            .text(d => `${d.ancestors().map((d: any) => d.data.text).reverse().join("")}`);
        
        const label = g.append("g")
            .attr("pointer-events", "none")
            .attr("text-anchor", "middle")
            .style("user-select", "none")
            .selectAll("text")
            .data(root.descendants().slice(1))
            .join("text")
            .attr("dy", "0.35em")
            .attr("fill-opacity", (d: any) => +labelVisible(d.current))
            .attr("transform", (d: any) => labelTransform(d.current))
            .text((d: any) => d.data.text);
        
        const parent = g.append("circle")
            .datum(root)
            .attr("r", radius)
            .attr("fill", "#eeeeee")
            .attr("pointer-events", "all")
            .on("click", clicked)

        const mainLabel = g.append('g')
            .attr("pointer-events", "none")
            .attr("text-anchor", "middle")
            .style("user-select", "none")
            .selectAll("text")
            .data([1])
            .join("text")
            .text((d: any) => 'Back')
            .style('display', 'none');

        
        function clicked(p: any) {
            parent.datum(p.parent || root);

            mainLabel.style('display', p.parent ? '' : 'none');
        
            root.each((d: any) => d.target = {
                x0: Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
                x1: Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
                y0: Math.max(0, d.y0 - p.depth),
                y1: Math.max(0, d.y1 - p.depth)
            });
        
            const t: any = g.transition().duration(750);
        
            // Transition the data on all arcs, even the ones that aren’t visible,
            // so that if this transition is interrupted, entering arcs will start
            // the next transition from the desired position.
            path.transition(t)
                .tween("data", (d: any) => {
                    const i = d3.interpolate(d.current, d.target);
                    return (t: any) => d.current = i(t);
                })
                .filter(function(d: any) {
                    return !!(+(this as any).getAttribute("fill-opacity") || arcVisible(d.target));
                })
                .attr("fill-opacity", (d: any) => arcVisible(d.target) ? (d.children ? 0.6 : 0.4) : 0)
                .attrTween("d", (d: any) => () => arc(d.current)!);
            
            label.filter(function(d: any) {
                    return !!(+(this as any).getAttribute("fill-opacity") || labelVisible(d.target));
                })
                .transition(t)
                .attr("fill-opacity", (d: any) => +labelVisible(d.target))
                .attrTween("transform", (d: any) => () => labelTransform(d.current));
        }
        
        function arcVisible(d: any) {
            return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
        }
        
        function labelVisible(d: any) {
            return d.y1 <= 3 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03;
        }
        
        function labelTransform(d: any) {
            const x = (d.x0 + d.x1) / 2 * 180 / Math.PI;
            const y = (d.y0 + d.y1) / 2 * radius;
            return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
        }
        
        return svg.node();
    }

    const getChildren = (uid: string, data: IExerciseResponse[]) => {
        const elements = data.filter(el => el.parent && el.parent.includes(uid))
            .map(el => ({ ...el, parent: [uid] }));
        return elements && elements.length ? elements : undefined;
    }

    const dataAsTree = (data: IExerciseResponse[]) => {
        const roots = data.filter(el => !el.parent);
        let hasChildren = false;
        roots.forEach(el => {
            const children = getChildren(el.uid!, data);
            if(children && children.length) {
                el.children = [{...el, parent: [el.uid!], children: undefined }, ...children];
                hasChildren = true;
            }
        });

        return { dataTree: { text: '', children: roots }, size: (hasChildren ? 2 : 1) };
    }

    React.useEffect(() => {
        const data = props.responses;
        const { dataTree, size } = dataAsTree(data);
        const svg = draw(dataTree, size);

        if(container.current) {
            container.current.innerHTML = '';
            if(svg) {
                container.current.appendChild(svg);
            }
        }
    }, []);

    return (<>
        {props.maxAnswers > 1 && (
            <div className={styles.requirements}>
                {props.minAnswers !== props.maxAnswers && (
                    <>Please choose at least {props.minAnswers} answers and maximum {props.maxAnswers} answers</>
                )}
                {props.minAnswers === props.maxAnswers && (
                    <>Please choose {props.minAnswers} answers</>
                )}
            </div>
        )}
        <div ref={container}>
        </div>
    </>)
}