import { FetchResult, gql } from 'apollo-boost';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation UserExchangeOneTimeToken {
        user_exchange_one_time_token {
            accessToken: access_token,
        }
    }
`;

export async function exchangeOneTimeToken(): Promise<{ accessToken: string }> {
    const result: FetchResult<{ user_exchange_one_time_token: { accessToken: string }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        errorPolicy: 'all',
    });

    if(!result.data || (result.errors && result.errors.length)) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.user_exchange_one_time_token;
}
