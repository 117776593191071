import { BaseTypes } from '../types';

export interface IErrorInfo {
    message: string,
    code: number,
}

export class MultiError extends Error {
    errors: IErrorInfo[];

    constructor(errors: Readonly<Array<string | Error | IErrorInfo>>) {
        const errorsData: IErrorInfo[] = errors.map( e => {
            let ei: IErrorInfo;
            if(typeof e === BaseTypes.string) {
                try {
                    ei = JSON.parse(e as string);
                }
                catch(e1) {
                    ei = { message: e, code: 0 } as IErrorInfo;
                }
            }
            else if((e as any).message && !(e as any).code) {
                try {
                    ei = JSON.parse((e as any).message as string);
                }
                catch(e1) {
                    ei = { message: (e as Error).message, code: 0 } as IErrorInfo;
                }
            }
            else {
                ei = e as IErrorInfo;
            }
            return ei;
        });
        super(errorsData[0].message);
        this.errors = errorsData;
        console.log(errorsData);
    }

    get code() {
        return this.errors && this.errors.length
            ? (typeof this.errors[0] === BaseTypes.string ? 500 : this.errors[0].code)
            : null;
    }
}
