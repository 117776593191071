import { FetchResult, gql } from 'apollo-boost';
import { IUserGroup } from '../../../models/user-group';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation UserGroupSave($userGroup: UserGroupSaveInput!){
        user_group_save (user_group_data: $userGroup) {
            uid
        }
    }
`;

export async function userGroupSave(userGroup: Partial<IUserGroup>): Promise<string> {
    const result: FetchResult<{ user_group_save: { uid: string }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { userGroup: {
            uid: userGroup.uid,
            title: userGroup.title,
            group_type: userGroup.groupType,
            parent_group: userGroup.parentGroup,
            start_date: userGroup.startDate,
            end_date: userGroup.endDate,
            grade_level: userGroup.gradeLevel,
            max_age_rating: userGroup.maxAgeRating,
            description: userGroup.description,
            address: userGroup.address,
            city: userGroup.city,
            state: userGroup.state,
            zipcode: userGroup.zipCode
        } },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.user_group_save.uid;
}
