import React, { FC } from 'react';
import { IEmotionEpisode } from '../../models/emotion-episode';
import { getCharacterFilePath } from '../../config/app-config';
import { secondsToTimeString } from '../../helpers/datetime-helpers';
import { SearchTileActions } from '../../components/search-tile-actions/search-tile-actions';
import { getNarrativeFilePath } from '../../config/app-config';

import { faHourglassHalf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ResultsListProps {
  results: IEmotionEpisode[];
  getResponder: (ee: IEmotionEpisode) => any;
  getResponderName: (ee: IEmotionEpisode) => any;
  getResponderIntensity: (ee: IEmotionEpisode) => any;
  getResponderEmotions: (ee: IEmotionEpisode) => any;
  getRatingsString: (ee: IEmotionEpisode) => any;
  handleVideoLink: (url: any, uid: any) => any;
  getViewName: (ee: string) => any;
  handleSaveEpisode: (uid: any, url: any, ee: any) => any;
  copyShareLink: (uid: any) => any;
  setcurrentEpisode: (ee: IEmotionEpisode) => any;
  setaddNotePopup: (value: boolean) => void;
  setnoteText: (ee: string) => any;
  narrativeClickedHandler: any;
  characterClickedHandler: any;
  showDetailViewOption: boolean;
  emepDetailView: (ee: string) => any;
}

export const ResultsList: FC<ResultsListProps> = ({
  results,
  getResponder,
  getResponderName,
  getResponderIntensity,
  getResponderEmotions,
  getRatingsString,
  handleVideoLink,
  getViewName,
  handleSaveEpisode,
  copyShareLink,
  setcurrentEpisode,
  setaddNotePopup,
  setnoteText,
  narrativeClickedHandler,
  characterClickedHandler,
  showDetailViewOption,
  emepDetailView,
}) => {
  return (
    <div className="px-2 sm:p-0">
      <div className="mt-5 text-sm text-gray-500 italic">
        {results.length} results found
      </div>
      <ul role="list" className="p-0 mt-5 list-none text-base">
        {results.map((result) => (
          <li
            key={result.uid}
            className="mb-2 p-3 sm:p-2 grid grid-cols-2 sm:grid-cols-5 sm:gap-2 rounded-md border border-solid border-gray-300 sm:justify-items-center sm:align-items-center"
          >
            <div
              id="1"
              className="sm:w-52 p-1 sm:pl-3 h-28 sm:h-36 overflow-hidden order-3 sm:order-1 sm:w-full"
            >
              <img
                className="w-full h-full inline-block object-cover rounded"
                src={getNarrativeFilePath(
                  result.scene?.narrative?.uid,
                  result.scene?.narrative?.fileName
                )}
                alt=""
              />
            </div>
            <div
              id="2"
              className="order-1 sm:order-2 col-span-5 sm:col-span-1 p-3"
            >
              <div
                className={`font-bold mb-2 sm:mb-0 text-filter-blue text-xl sm:text-base sm:text-black ${
                  showDetailViewOption ? 'cursor-pointer underline' : ''
                }`}
                onClick={
                  showDetailViewOption
                    ? () => emepDetailView(result.uid)
                    : () => undefined
                }
              >
                {result.description}
              </div>
              <div className="leading-4 font-semibold sm:leading-normal">
                Emotions:{' '}
                <span className="text-filter-blue">
                  {getResponderEmotions(result)}
                </span>
              </div>
              <div>Intensity: {getResponderIntensity(result)}</div>
              <div
                className="hidden sm:flex items-center pt-2 cursor-pointer"
                onClick={() =>
                  characterClickedHandler(getResponder(result)?.character?.uid)
                }
              >
                <img
                  className="inline-block h-10 w-10 rounded-full mr-2"
                  src={getCharacterFilePath(
                    getResponder(result)?.character?.uid,
                    getResponder(result)?.character?.fileName
                  )}
                  alt={getResponder(result)?.character?.name}
                />
                <div className="text-filter-blue cursor-pointer underline">
                  {getResponderName(result)}
                </div>
              </div>
            </div>
            <div id="3" className="order-3 hidden sm:block p-3">
              <div
                className="underline cursor-pointer"
                onClick={() =>
                  narrativeClickedHandler(result.scene?.narrative?.uid)
                }
              >
                {result.scene?.narrative?.title}
              </div>
              <div>
                {result.scene?.episode &&
                  ` Season ${result.scene.episode.season} : `}
                {result.scene?.episode &&
                  `Episode ${result.scene.episode.numberInSeason}`}
              </div>
              <div>Rating: {getRatingsString(result)}</div>
            </div>
            <div id="4" className="order-2 sm:order-4 p-3">
              <div
                className="sm:hidden font-bold leading-4 sm:leading-normal underline cursor-pointer"
                onClick={() =>
                  narrativeClickedHandler(result.scene?.narrative?.uid)
                }
              >
                {result.scene?.narrative?.title}
              </div>
              <div className="flex items-center sm:block">
                <div>
                  Start <span className="hidden sm:inline-block">time:</span>{' '}
                  {secondsToTimeString(result.start, true)}{' '}
                </div>
                <FontAwesomeIcon
                  className="sm:hidden h-3 px-1"
                  icon={faHourglassHalf}
                />
                <div>
                  <span className="hidden sm:inline-block">Length:</span>{' '}
                  {result.end - result.start}s
                </div>
              </div>
              <div
                className="flex items-center pt-2 sm:hidden cursor-pointer underline"
                onClick={() =>
                  characterClickedHandler(getResponder(result)?.character?.uid)
                }
              >
                <img
                  className="inline-block h-8 w-8 sm:h-10 sm:w-10 rounded-full mr-2"
                  src={getCharacterFilePath(
                    getResponder(result)?.character?.uid,
                    getResponder(result)?.character?.fileName
                  )}
                  alt={getResponder(result)?.character?.name}
                />
                <div className="text-filter-blue">
                  {getResponderName(result)}
                </div>
              </div>
            </div>
            <div
              id="5"
              className="order-5 col-span-5 sm:col-span-1 flex sm:block p-1 sm:pr-3 sm:w-full"
            >
              <div className="flex-1 pr-1 sm:p-0">
                {result.mediaUrls &&
                  result.mediaUrls.map((link) => (
                    <div key={link}>
                      <button
                        onClick={() => handleVideoLink(link, result.uid)}
                        type="button"
                        className="rounded-md cursor-pointer h-12 sm:mb-4 shadow-none border-solid focus:outline-none w-full bg-white px-4.5 py-3 text-sm text-filter-blue border border-filter-blue"
                      >
                        View on {getViewName(link)}
                      </button>
                      <br />
                    </div>
                  ))}
              </div>
              <div className="flex-1 pl-1 sm:p-0">
                <SearchTileActions
                  result={result}
                  handleSaveEpisode={handleSaveEpisode}
                  copyShareLink={copyShareLink}
                  setcurrentEpisode={setcurrentEpisode}
                  setaddNotePopup={setaddNotePopup}
                  setnoteText={setnoteText}
                />
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
