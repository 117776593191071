import React, { Fragment, useRef, useState, useContext } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { PopupContext } from '../../../src/contexts/popup-context';

interface TwPopupProps {
  children?: React.ReactNode;
  btnTxt?: string;
  actionButton?: React.ReactNode;
  icon?: React.ReactNode;
  redirectTo?: () => void;
  width?: string;
  height?: string;
  cancelBtn?: boolean;
  popupName: string;
  onClose?: () => void;
}

export const TwPopup: React.FC<TwPopupProps> = ({
  children,
  actionButton,
  icon,
  width = 'sm:w-full sm:max-w-lg',
  height = 'max-h-full sm:my-8',
  cancelBtn = true,
  popupName,
  onClose,
}) => {
  const cancelButtonRef = useRef(null);
  const popupContext = useContext(PopupContext);
  if (!popupContext) throw new Error('Use TwPopup within a <PopupProvider>');
  const { openPopup, setOpenPopup } = popupContext;
  const open = openPopup === popupName;
  const setOpen = (isOpen: boolean) => {
    setOpenPopup(isOpen ? popupName : null);
    if (!isOpen && onClose) onClose();
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all ${height} ${width} mt-16 sm:mt-20`}
              >
                <div
                  style={{ borderBottom: '1px solid #D9D9D9' }}
                  className="px-4 pt-5 relative flex justify-center"
                >
                  <XMarkIcon
                    className="h-6 w-6 sm:h-8 sm:w-8 font-extralight text-th-blue cursor-pointer absolute top-2 sm:top-5 right-3 sm:right-5"
                    aria-hidden="true"
                    onClick={() => setOpen(false)}
                  />{' '}
                  {icon && (
                    <div className="flex items-center">
                      <div className="h-14 w-14">{icon}</div>
                      <div className="text-2xl pl-4 pb-4">ThinkHumanTV</div>
                    </div>
                  )}
                </div>
                <div className="p-4 sm:p-8">
                  {children}
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    {actionButton}
                    {cancelBtn && (
                      <button
                        type="button"
                        className={`border-0 mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-none ${
                          actionButton ? '' : 'sm:col-start-1 sm:col-end-3'
                        }`}
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
