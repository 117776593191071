import { FetchResult, gql } from 'apollo-boost';
import { ICurriculum } from '../../models/curriculum';
import { MultiError } from '../../types/multi-error';
import { graphqlClient } from '../graphql-client';

const QUERY = gql`
    {
        curricula_all_copies {
            uid,
            name,
            description,
            available,

            units {
                uid,
                name,
                description,
                available,
                finalEmotionModelState: final_emotion_model_state,
            },
        }
    }
`;

export async function getAllCurriculaCopies(): Promise<ICurriculum[]> {
    const result: FetchResult<{ curricula_all_copies: ICurriculum[] }> = await graphqlClient.query({
        query: QUERY,
        errorPolicy: 'all',
    });

    if(!result.data || (result.errors && result.errors.length)) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.curricula_all_copies;
}
