import { ILearningChunk } from './learning-chunk';
import { IUnit } from './unit';
import { IUser } from './user';

export enum UnitSectionTypes {
    learning = 'learning',
    practice = 'practice',
}

export interface IUnitSectionExerciseParam {
    type: string,
    skill: string,
    terminology: string,
}

export interface IUnitSection {
    uid: string,
    name: string,
    description: string,
    index: number,
    type: UnitSectionTypes,
    available?: boolean,
    episodeCount?: number,
    exerciseParams?: IUnitSectionExerciseParam[],

    unit: IUnit,
    learningChunks?: ILearningChunk[],
    completed_by: IUser[],
}
