import { FetchResult, gql } from 'apollo-boost';
import { removeFields } from '../../helpers/common-helpers';
import { IEmotionEpisode } from '../../models/emotion-episode';
import { MultiError } from '../../types/multi-error';
import { graphqlClient } from '../graphql-client';

const QUERY = gql`
    query GetEmotionEpisodesForEpisode($episodeUid: String!){
        emotion_episodes_for_episode(episode_uid: $episodeUid) {
            uid,
            description,
            start,
            end,
            status,
            absoluteStart: absolute_start,
            available,
        }
    }
`;

export async function getEmotionEpisodesForEpisode(episodeUid: string): Promise<IEmotionEpisode[]> {
    const result: FetchResult<{ emotion_episodes_for_episode: IEmotionEpisode[] }> = await graphqlClient.query({
        query: QUERY,
        variables: { episodeUid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return removeFields(result.data.emotion_episodes_for_episode, ['__typename']);
}
