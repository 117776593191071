import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import styles from './loader.module.scss';


export function Loader(props: {text?: string}) {
    return (
        <div className={styles.loader}>
            <FontAwesomeIcon icon={faSpinner} />
            {props.text && (<div>{props.text}</div>)}
        </div>
    );
}
