import { faArrowLeft, faArrowRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { addCharacterToUser } from '../../../api/actions/add-character-to-user';
import { getCharactersByNarratives } from '../../../api/actions/get-characters-by-narratives';
import { getCurrentUser } from '../../../api/actions/get-current-user';
import { removeCharacterFromUser } from '../../../api/actions/remove-character-from-user';
import { setUserConfigurationFinished } from '../../../api/actions/set-user-configuration-finished';
import { Button } from '../../../components/button/button';
import { HorizontalScroller } from '../../../components/horizontal-scroller/horizontall-scroller';
import { LinkButton } from '../../../components/link-button/link-button';
import { Loader } from '../../../components/loader/loader';
import { Popup } from '../../../components/popup/popup';
import { getCharacterFilePath } from '../../../config/app-config';
import { basePerformError } from '../../../helpers/error-helpers';
import { ICharacter } from '../../../models/character';
import { IUser } from '../../../models/user';
import styles from './characters-list.module.scss';

export function CharactersList(props: RouteComponentProps) {
    const [characters, setCharacters] = useState<ICharacter[]>([]);
    const [showFinishPopup, setShowFinishPopup] = useState(false);

    useEffect(() => {
        (async () => {
            let user: IUser;
            try {
                user = await getCurrentUser();
            }
            catch(err) {
                props.history.replace('/login');
                return;
            }

            if(!user.narratives || !user.narratives.length) {
                props.history.push('./narratives');
                return;
            }
            const charactersResult = await getCharactersByNarratives(user.narratives.map(n => n.uid));
            if(user.characters) {
                user.characters.forEach(el => {
                    const character = charactersResult.find(c => c.uid === el.uid);
                    if(character) character.selected = true;
                });
            }
            setCharacters(charactersResult);
        })().catch(() => {/** */});
    }, [props.history]);

    const onItemClick = async (data: ICharacter) => {
        try {
            let user;
            if(data.selected) {
                user = await removeCharacterFromUser(data.uid);
            }
            else {
                user = await addCharacterToUser(data.uid);
            }
            updateCharacters(user);
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };

    const updateCharacters = (user: IUser) => {
        characters.forEach( c => { c.selected = user.characters && user.characters.some(uc => c.uid === uc.uid); });
        setCharacters([...characters]);
    };

    const getSelectedCharacters = () => characters.filter(c => c.selected);

    const onFinish = async () => {
        if(getSelectedCharacters().length) {
            await setUserConfigurationFinished();
            props.history.push('/curriculum');
        }
        else {
            setShowFinishPopup(true);
        }
    };

    const onFinishWithoutCharacters = async () => {
        await setUserConfigurationFinished();
        props.history.push('/curriculum');
    };

    return (
        <>
            <LinkButton to="./narratives"><FontAwesomeIcon icon={faArrowLeft} /> Back</LinkButton>
            <h1>
                Select Your Favorite Characters<br />
                <span>
                    <br />
                    We&apos;ll tailor your experience to see more of the<br />
                    characters you love.
                </span>
            </h1>
            <div className={styles.listContainer}>
                <div className={styles.listSubContainer}>
                    { characters.length
                        ? (<HorizontalScroller
                            items={characters}
                            itemComponent={CharacterItem}
                            itemEvents={{ onClick: onItemClick }}
                            height="280px"
                        />)
                        : (<Loader />)
                    }
                </div>
            </div>
            <div className={styles.buttonContainer}>
                <Button onClick={onFinish} className={getSelectedCharacters().length ? '' : 'orange'} >
                    <span>Finish</span> <FontAwesomeIcon icon={faArrowRight} />
                </Button>
            </div>

            { showFinishPopup && (
                <Popup
                    modal={true}
                    title="No characters chosen."
                    className="orange"
                    onClose={() => setShowFinishPopup(false)}
                    buttons={[
                        {
                            title: (<><FontAwesomeIcon icon={faTimes} /> <span>Cancel</span></>),
                            onClick: () => setShowFinishPopup(false),
                            className: 'gray',
                        },
                        {
                            title: (<><span>Continue</span> <FontAwesomeIcon icon={faArrowRight} /></>),
                            onClick: onFinishWithoutCharacters,
                            className: 'orange',
                        },
                    ]}
                >
                    All the characters in your chosen narratives will be used.<br />
                    <br />
                    (You can always change your preferences later.)
                </Popup>
            )}
        </>
    );
}

function CharacterItem(props: ICharacter & { events: any }) {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(false);
    }, [props.selected]);
    return (
        <div
            className={`${styles.character} ${props.selected ? styles.selected : ''}`}
            onClick={() => { setLoading(true); props.events.onClick(props); }}
        >
            <div className={styles.subcontainer}>
                <img src={getCharacterFilePath(props.uid, props.fileName)} alt={props.name} />
            </div>
            { loading && (<Loader />) }
        </div>
    );
}
