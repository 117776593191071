import React, { useCallback, useEffect, useRef, useState } from 'react';
import { isNullOrEmpty } from '../../helpers/common-helpers';
import { Loader } from '../loader/loader';
import { PageSwitcher } from '../page-switcher/page-switcher';
import styles from './grid.module.scss';

interface IPageData<T> {
    page: number,
    count: number,
    data?: T[],
}

interface IProps<T> {
    pageSize?: number,
    noDataMessage?: string,
    updateFlag?: any, // if need update grid content change this props value
    getPageData(page: number, pageSize: number): Promise<IPageData<T> | undefined>,
    containerRenderer?(content: React.ReactElement, pager: React.ReactElement, reloadData: () => void): React.ReactElement,
    itemRenderer(item: T, index: number, count: number, reloadData: () => void): React.ReactElement,
}

const DEFAULT_PAGE_SIZE = 20;
const DEFAULT_PAGER_SIZE = 10;

export function Grid<T>(props: IProps<T>) {

    const pageSize = props.pageSize || DEFAULT_PAGE_SIZE;

    const count = useRef<number>(0);

    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [data, setData] = useState<T[]>();

    const loadData = useCallback(() => {
        setLoading(true);
        if(page === 1 || page <= Math.ceil(count.current / pageSize)) {
            props.getPageData(page, pageSize)
            .then((data?: IPageData<T>) => {
                count.current = data?.count || 0;

                if(page !== 1 && (page > Math.ceil(count.current / pageSize) || isNullOrEmpty(data?.data))) {
                    setPage(1);
                }
                else {
                    setData(data?.data);
                }

                setLoading(false);
            })
            .catch((err) => {
                if(page !== 1) {
                    setPage(1);
                }
            });
        }
        else {
            setPage(1);
            setLoading(false);
        }
    }, [page, pageSize, props]);

    useEffect(() => {
        loadData();
    }, [loadData, page, pageSize, props]);


    const pageChanged = (newPage: number) => {
        if(newPage !== page) {
            setPage(newPage);
        }
    }

    function renderGrid() {
        return (<>
            {data?.map((el, i) => props.itemRenderer(el, ((page - 1) * pageSize + i), count.current, loadData))}
        </>)
    }

    function renderPager() {
        return (<PageSwitcher size={DEFAULT_PAGER_SIZE} itemsCount={count.current} page={page} pageSize={pageSize} onChange={pageChanged} />)
    }

    return loading ? (<Loader />) : (<>
        {data?.length
            ? (<>
                {props.containerRenderer?.(renderGrid(), renderPager(), loadData)
                || (<>
                    {renderPager()}
                    {renderGrid()}
                    {renderPager()}
                </>)}
            </>)
            : (<div>
                {props.noDataMessage || (<div className={styles.noDataMessage}>THERE ARE NO ITEMS TO SHOW</div>)}
            </div>)
        }
    </>)
}