import React from 'react';
import { getAllConcepts } from '../../../../api/actions/concepts-get-all';
import { getUserScores } from '../../../../api/actions/get-user-scores';
import { CircularProgress } from '../../../../components/circular-progress/circular-progress';
import { basePerformError } from '../../../../helpers/error-helpers';
import { IConcept } from '../../../../models/concept';
import { IScore } from '../../../../models/score';
import { IUser } from '../../../../models/user';
import styles from './concepts-stats-component.module.scss';
import { Popup } from '../../../../components/popup/popup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo, faInfoCircle, faUnlock } from '@fortawesome/free-solid-svg-icons';

interface IProps {
    user: IUser,
}

export function ConceptsStatsComponent(props: IProps) {

    const [concepts, setConcepts] = React.useState<IConcept[]>();


    //created a variable to control when the pop-up appears + what data is associated with it
    const [conceptPopUpInfo, setConceptPopUpInfo ] = React.useState({appears: false, title: "", description: "" })

    React.useEffect(() => {
        (async () => {
            try {
                const concepts = await getAllConcepts();
                const scores = await getUserScores(props.user.uid);
                setConcepts(buildTree(concepts, scores));
            }
            catch(err) {
                basePerformError(err);
                return;
            }
        })().catch(() => {/** */});
    }, [props]);

    const buildTree = (concepts: IConcept[], scores: IScore[]) => {
        const rootSkills = concepts.filter(s => !s.parent).sort((s1, s2) => s1.index - s2.index);
        rootSkills.forEach(
            concept => { concept.score = scores.find(score => score.skill?.uid === concept.uid) || { totalAttempted: 0, totalCorrect: 0 } as IScore; }
        );
        rootSkills.forEach(concept => concept.children = getChildren(concept, concepts, scores));
        rootSkills.forEach(concept => updateScore(concept));
        return rootSkills;
    }

    const getChildren = (concept: IConcept, concepts: IConcept[], scores: IScore[]): IConcept[] => {
        const children = concepts.filter(s => s.parent === concept.uid).sort(s => s.index);
        children.forEach(c => { c.score = scores.find(score => score.concept?.uid === c.uid) || { totalAttempted: 0, totalCorrect: 0 } as IScore; });
        children.forEach(c => c.children = getChildren(c, concepts, scores));
        children.forEach(c => updateScore(c));
        return children;
    }

    const updateScore = (concept: IConcept) => {
        concept.children?.forEach(sc => {
            if(!concept.score) return;
            concept.score.totalAttempted += sc.score?.totalAttempted || 0;
            concept.score.totalCorrect += sc.score?.totalCorrect || 0;
        })
    }

    const getConceptScore = (concept: IConcept) => {

        const score = concept.score;

        return Math.round(((score?.totalCorrect || 0) / (score?.totalAttempted || 1)) * 100);
    };

    const renderSubConcept = (concept: IConcept, level = 1) => (
        <React.Fragment key={concept.uid}>
            <div className={styles.subSkill}>
                {/* Created a new div to house the concept name and the info icon */}
                <div className={styles.subSkillAndIconContainer}>
                {concept.name}
                <FontAwesomeIcon icon={faInfoCircle} className={styles.infoIcon} onClick={() => setConceptPopUpInfo({appears: true, title: concept.name, description: concept.description})}/>
                </div>
                <div>
                    { concept.score?.totalAttempted ? `${getConceptScore(concept)}% (${concept.score.totalCorrect || 0}/${concept.score.totalAttempted || 0})` : '' }
                </div>
            </div>
            <div key={`${concept.uid}_sub`}>
                { concept.children && concept.children.map(c => renderSubConcept(c))}
            </div>
        </React.Fragment>
    )

    return (
        <div className={styles.container}>
            { concepts && concepts.map( concept => (
                <React.Fragment key={concept.uid}>
                    <div className={styles.rootSkill}>
                        {concept.name}
                        <CircularProgress value={getConceptScore(concept)} />
                    </div>
                    { concept.children && concept.children.map(c => renderSubConcept(c)) }
                </React.Fragment>
            ))}
             {/*  Added code below to create the pop up that appears when an info icon is pressed */}
            {conceptPopUpInfo.appears && (
            <Popup
                title={conceptPopUpInfo.title}
                modal={true}
                onClose={() => setConceptPopUpInfo({appears: false, title: "", description: ""})}     
            >
                <div className={styles.rootSkill}>
                        {conceptPopUpInfo.description}
                </div>
            </Popup>
        )}
        </div>
    );
}