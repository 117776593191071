import * as React from 'react';

export const BackButtonContext = React.createContext<IBackButtonManagerState | undefined>(undefined);


interface IBackButtonManagerProps {
    children: any;
}

export interface IBackButtonManagerState {
    backButtonContent?: React.ReactElement;
    setBackButton(backButtonContent?: React.ReactElement): void;
}

export class BackButtonManager extends React.PureComponent<IBackButtonManagerProps, IBackButtonManagerState> {

    constructor(props: IBackButtonManagerProps) {
        super(props);

        this.state = {
            setBackButton: this.setBackButton,
        };
    }

    setBackButton = (backButtonContent?: React.ReactElement) => {
        this.setState({ backButtonContent });
    }

    render() {
        return (
            <BackButtonContext.Provider value={this.state}>
                { this.props.children }
            </BackButtonContext.Provider>
        );
    }
}

interface IProps {
    children: React.ReactElement;
}

export function BackButton(props: IProps) {
    const context = React.useContext(BackButtonContext);

    React.useEffect(() => {
        context?.setBackButton(props.children);
        return () => context?.setBackButton(undefined);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    return (<></>);
}
