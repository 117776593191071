import { useState } from 'react';

  function useLocalStorage<T>(key: string, initialValue: T, uid?: string) {
    const namespacedKey = uid ? `${uid}-${key}` : key;
    const readValue = () => {
    try {
      const item = window.localStorage.getItem(namespacedKey);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.warn(`Error reading localStorage key "${key}":`, error);
      return initialValue;
    }
  };

  const [storedValue, setStoredValue] = useState<T>(readValue);

  const setValue = (value: T | ((val: T) => T)) => {
    try {
      const newValue = value instanceof Function ? value(storedValue) : value;

      setStoredValue(newValue);

      window.localStorage.setItem(namespacedKey, JSON.stringify(newValue));
    } catch (error) {
      console.warn(`Error setting localStorage key "${key}":`, error);
    }
  };

  return [storedValue, setValue] as const;
}

export default useLocalStorage;
