import * as React from 'react';
import { Link } from 'react-router-dom';
import styles from './link-button.module.scss';

interface IProps {
    children: string | React.ReactElement | Array<string | React.ReactElement>,
    onClick?: (ev: React.SyntheticEvent) => void,
    onContextMenu?: (ev: React.SyntheticEvent) => void,
    to?: string,
    className?: string | null,
    disabled?: boolean,
    buttonType?: "button" | "submit" | "reset",
}

export function LinkButton(props: IProps) {
    return props.to
    ? (
        <Link to={props.to} className={`${styles.link} ${props.className || ''}`}>
            {props.children}
        </Link>
    )
    :(
        <button
            onClick={props.disabled ? undefined : props.onClick}
            onContextMenu={props.disabled ? undefined : props.onContextMenu}
            className={`${styles.link} ${props.disabled ? styles.disabled : ''} ${props.className || ''}`}
            type={props.buttonType || 'button'}
        >
            {props.children}
        </button>
    );
}
