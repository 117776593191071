import * as React from 'react';

export const HeaderContentContext = React.createContext<IHeaderContentManagerState | undefined>(undefined);


interface IHeaderContentManagerProps {
    children: any;
}

export interface IHeaderContentManagerState {
    content?: string | React.ReactElement;
    setContent(content?: string | React.ReactElement): void;
}

export class HeaderContentManager extends React.PureComponent<IHeaderContentManagerProps, IHeaderContentManagerState> {

    constructor(props: IHeaderContentManagerProps) {
        super(props);

        this.state = {
            setContent: this.setContent,
        };
    }

    setContent = (content: string | React.ReactElement) => {
        this.setState({ content });
    }

    render() {
        return (
            <HeaderContentContext.Provider value={this.state}>
                { this.props.children }
            </HeaderContentContext.Provider>
        );
    }
}

interface IProps {
    children: string | React.ReactElement;
}

export function HeaderContent(props: IProps) {
    const context = React.useContext(HeaderContentContext);

    React.useEffect(() => {
        context?.setContent(props.children);
        return () => context?.setContent(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    return (<></>);
}
