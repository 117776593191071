import * as d3 from "d3";
import * as React from 'react';
import { isNotNullOrEmpty } from "../../../../helpers/common-helpers";
import { IExerciseResponse } from '../../../../models/exercise-response';
import styles from './test-section.module.scss';

interface IProps {
    responses: IExerciseResponse[],
    answers: IExerciseResponse[],
}


export function TestSectionEmotionLabelingResult(props: IProps) {

    // const log = (d: any, c: any) => {
    //     const r = c(d);
    //     console.log('$$$', d, r);
    //     return r;
    // }

    const container = React.createRef<HTMLDivElement>();

    const draw = (data: IExerciseResponse, totalAnswers: number) => {
        const width = 480;
        const radius = width / (6);

        const arc = d3.arc()
            .startAngle((d: any) => d.x0)
            .endAngle((d: any) => d.x1)
            .padAngle((d: any) => Math.min((d.x1 - d.x0) / 2, 0.005))
            .padRadius(radius * 1.5)
            .innerRadius((d: any) => d.y0 * radius)
            .outerRadius((d: any) => Math.max(d.y0 * radius, d.y1 * radius - 1));

        const partition = (data: IExerciseResponse) => {
                const hierarchy = d3.hierarchy(data)
                    .sum(d => d.answersCount || 0);

                return d3.partition().size([2 * Math.PI, hierarchy.height + 1])(hierarchy);
            }


        const color = d3.scaleOrdinal(d3.quantize(d3.interpolateRainbow, (data.children?.length || 0) + 1));


        const root = partition(data);

        root.each((d: any) => d.current = d);

        const svg = d3.create("svg")
            .attr("viewBox", [0, 0, width, width] as any)
            .style("font", "10px sans-serif");

        const g = svg.append("g")
            .attr("transform", `translate(${width / 2},${width / 2})`);

        const path = g.append("g")
            .selectAll("path")
            .data(root.descendants().slice(1))
            .join("path")
            .attr("fill", (d: any) => { while (d.depth > 1) d = d.parent; return color(d.data.text); })
            .attr("fill-opacity", (d: any) => arcVisible(d.current) ? (d.children ? 0.6 : 0.4) : 0)
            .classed(styles.selected, (d: any) => props.answers.map(a => a.uid).includes(d.data.uid))
            .attr("d", (d: any) => arc(d.current));

        path.filter((d: any) => d.children)
            .style("cursor", "pointer");
            //.on("click", clicked);

        path.append("title")
            .text(d => `${d.ancestors().map((d: any) => d.data.text).reverse().join("")}`);

        const label = g.append("g")
            .attr("pointer-events", "none")
            .attr("text-anchor", "middle")
            .style("user-select", "none")
            .selectAll("text")
            .data(root.descendants().slice(1))
            .join("text")
            .attr("dy", "0.35em")
            .attr("fill-opacity", (d: any) => +labelVisible(d.current))
            .attr("transform", (d: any) => labelTransform(d.current))
            .append("tspan")
            .attr("x", 0)
            .text((d: any) => d.data.text)
            .append("tspan")
            .attr("x", 0)
            .attr("dy", "1.3em")
            .text(
                (d: any) => {
                    return (d.data.children ? '' : ` (${Math.round(d.data.answersCount / totalAnswers * 100)}%)`)
                }
            );

        const parent = g.append("circle")
            .datum(root)
            .attr("r", radius)
            .attr("fill", "#eeeeee")
            .attr("pointer-events", "all")
            .on("click", clicked)

        const mainLabel = g.append('g')
            .attr("pointer-events", "none")
            .attr("text-anchor", "middle")
            .style("user-select", "none")
            .selectAll("text")
            .data([1])
            .join("text")
            .text((d: any) => 'Back')
            .style('display', 'none');

        function clicked(p: any) {
            parent.datum(p.parent || root);

            mainLabel.style('display', p.parent ? '' : 'none');

            root.each((d: any) => d.target = {
                x0: Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
                x1: Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
                y0: Math.max(0, d.y0 - p.depth),
                y1: Math.max(0, d.y1 - p.depth)
            });

            const t: any = g.transition().duration(750);

            // Transition the data on all arcs, even the ones that aren’t visible,
            // so that if this transition is interrupted, entering arcs will start
            // the next transition from the desired position.
            path.transition(t)
                .tween("data", (d: any) => {
                    const i = d3.interpolate(d.current, d.target);
                    return (t: any) => d.current = i(t);
                })
                .filter(function(d: any) {
                    return !!(+(this as any).getAttribute("fill-opacity") || arcVisible(d.target));
                })
                .attr("fill-opacity", (d: any) => arcVisible(d.target) ? (d.children ? 0.6 : 0.4) : 0)
                .attrTween("d", (d: any) => () => arc(d.current)!);

            label.filter(function(d: any) {
                    return !!(+(this as any).getAttribute("fill-opacity") || labelVisible(d.target));
                })
                .transition(t)
                .attr("fill-opacity", (d: any) => +labelVisible(d.target))
                .attrTween("transform", (d: any) => () => labelTransform(d.current));
        }

        function arcVisible(d: any) {
            return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
        }

        function labelVisible(d: any) {
            return d.y1 <= 3 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03;
        }

        function labelTransform(d: any) {
            const x = (d.x0 + d.x1) / 2 * 180 / Math.PI;
            const y = (d.y0 + d.y1) / 2 * radius;
            return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
        }

        return svg.node();
    }

    const getChildren = (uid: string, data: IExerciseResponse[], isCurrAdded: boolean) => {
        let elements = data.filter(el => el.parent && el.parent.includes(uid)
            //&& (props.answers.map(a => a.uid).includes(el.uid) || props.answers.some(a => a.parent?.[0] === uid))
        );
        const answers = elements.filter(el => props.answers.map(a => a.uid).includes(el.uid));
        if(!isCurrAdded && answers.length) {
            isCurrAdded = true;
            answers.forEach(a => {
                a.answersCount = (a.answersCount || 0) + 1;
            });
        }
        elements = elements.filter(el => el.answersCount);
        return { children: (elements?.length ? elements : []), isCurrAdded};
    }

    const dataAsTree = (data: IExerciseResponse[]) => {
        let roots = data.filter(el => !el.parent).map(el => ({...el}));
        let hasChildren = false;
        roots.forEach(el => {
            let isCurrAdded: boolean = false;
            const {children, isCurrAdded: isCurrAddedCh} = getChildren(el.uid!, data, isCurrAdded);
            isCurrAdded = isCurrAddedCh;
            const curr: IExerciseResponse = {
                ...el,
                answersCount: (el.answersCount || 0) + (!isCurrAdded && props.answers.map(a => a.uid).includes(el.uid) ? 1 : 0),
                children: undefined,
            };
            if(!isCurrAdded && props.answers.map(a => a.uid).includes(el.uid)) isCurrAdded = true;
            el.children = [(curr.answersCount ? curr : undefined), ...children].filter(isNotNullOrEmpty);
            // set answers count to 0 for root element for exclude unnecessary space in diagram
            //el.answersCount = 0;
            hasChildren = true;
        });

        roots = roots.filter(el => el.children && el.children.length);

        return { dataTree: { text: '', children: roots }, size: (hasChildren ? 2 : 1) };
    }

    React.useEffect(() => {
        const data: IExerciseResponse[] = JSON.parse(JSON.stringify(props.responses));
        const totalAnswers = data.reduce((acc, el) => acc + (el.answersCount || 0), 0) + props.answers.length;
        const { dataTree } = dataAsTree(data);
        console.log(data.filter(el => el.answersCount).map(el => ({ uid: el.uid, n: el.answersCount})), totalAnswers)
        const svg = draw(dataTree, totalAnswers);

        if(container.current) {
            container.current.innerHTML = '';
            if(svg) {
                container.current.appendChild(svg);
            }
        }
    }, []);

    return (
        <div>
            <div>
              <h2>See how your choice (outlined in orange) compares to labels chosen by other Affectifi users.</h2>
            </div>
            <div ref={container}>
            </div>
        </div>
    )
}
