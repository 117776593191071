import React, { useEffect, useState } from 'react';
import { userGroupPrivateInviteActivate } from '../../api/actions/user-groups/private-invite-activate';
import { Loader } from '../../components/loader/loader';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { PublicPage } from '../base/public-page';
import { MainLayout } from '../../components/layouts/main-layout';
import { LoginComponent } from '../login/login-component';
import { SignUpComponent } from '../signup/signup-component';
import { AppConfig } from '../../config/app-config';
import { MultiError } from '../../types/multi-error';
import { openExtensionUrl } from '../../helpers/common-helpers';

export function InvitePage() {

    const [code] = useState((new URL(window.location.href)).searchParams.get('code') || undefined);

    const history = useHistory();

    const [loading, setLoading] = useState<string | undefined>('Please wait, trying to activate...');
    const [error, setError] = useState<string | undefined>();

    const [email, setEmail] = useState<string | undefined>();
    const [signUpVisible, setSignUpVisible] = useState(false);
    const [loginVisible, setLoginVisible] = useState(false);

    useEffect(() => {
        activateCode();
    }, []);

    const activateCode = async () => {

        const url = '/analytics';

        setSignUpVisible(false);
        setLoginVisible(false);

        if(!code) {
            setLoading(undefined);
            setError('Invalid invite code');
            return;
        }


        try {
            const result = await userGroupPrivateInviteActivate(code);

            if(result.success) {
                const message = result.alreadyAccepted ? 'You have already applied this invite code' : 'Invite code has been successfully applied'
                toast.success(message);
                history.push(url);
                //set timeout for extension url redirect
                setTimeout(openExtensionUrl, 3000);
            }
            else if(result.groupNotExists) {
                const errMessage = 'Group does not exist'
                setError(errMessage);
                toast.error(`ERROR: ${errMessage}`);
            }
            else if(result.notRegistered) {
                setEmail(result.email);
                setSignUpVisible(true);
            }
            else if(result.notLoggedIn) {
                setEmail(result.email);
                setLoginVisible(true);
            }
            else {
                setError('Unknown error');
            }
        }
        catch(err) {
            setError((err as MultiError).message);
            toast.error(`ERROR: ${(err as MultiError).message}`);
        }

        setLoading(undefined);
    }

    return loading ? (<Loader text={loading} />) : (<>
        <PublicPage pageName="invite_page">
            <MainLayout>
                <>
                    {error && (<div className="errors">{error}</div>)}

                    {loginVisible && (
                        <LoginComponent email={email} onSuccessLogin={activateCode} />
                    )}

                    {signUpVisible && (
                        <SignUpComponent email={email} onSuccessSignUp={activateCode} />
                    )}
                </>
            </MainLayout>
        </PublicPage>
    </>);
}
