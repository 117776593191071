import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { BackButton } from '../../../components/back-button/back-button';
import { HeaderContent } from '../../../components/header-content/header-content';
import { MainLayout } from '../../../components/layouts/main-layout';
import { LinkButton } from '../../../components/link-button/link-button';
import { useCurrentUser } from '../../../contexts/current-user-context';
import { SecuredPage } from '../../base/secured-page';
import { ConceptsStatsComponent } from './components/concepts-stats-component';
import styles from './concepts-page.module.scss';

export function ConceptsPage(props: RouteComponentProps) {

    const currentUser = useCurrentUser();

    return (
        <SecuredPage pageName="profile" {...props} >
            <MainLayout>
                <BackButton>
                    <LinkButton to={'/analytics'}>
                        <FontAwesomeIcon icon={faArrowLeft} /> Back to Profile
                    </LinkButton>
                </BackButton>
                <HeaderContent>
                    Concepts
                </HeaderContent>
                <div className={styles.container}>
                    <div className={styles.content}>
                        {currentUser && (<ConceptsStatsComponent user={currentUser} />)}
                    </div>
                </div>
            </MainLayout>
        </SecuredPage>
    );
}
