import { gql } from "apollo-boost";

class Comment_Queries{
    saveComment = gql`
    mutation SaveComment($comment_data: CommentSaveInput!){
        comment_save(comment_data: $comment_data) {
            uid
        }
    }
`;

getComments = `
    query Comments($entityType: String!, $entityUid: String!) {
        user_comments(entity_type: $entityType, entity_uid: $entityUid) {
            uid,
            html,
            createdAt: created_at,

            entityUid: entity_uid,
            parentCommentUid: parent_comment_uid,
            entityType: entity_type,
            resolved: resolved,
            numLikes: num_likes,
            userLiked: user_liked,

            author {
              uid,
              firstName: first_name,
              lastName: last_name
            }
        }
    }
`;

deleteComment = gql`
    mutation DeleteComment($uid: String!){
        comment_delete(uid: $uid) {
            success
        }
    }
`;

likeComment = `
    mutation LikeComment($uid: String!){
        comment_like(uid: $uid) {
            success,
            comment  {
              uid
              numLikes: num_likes
              userLiked: user_liked
            }
        }
    }
`;

unlikeComment = `
    mutation UnlikeComment($uid: String!){
        comment_unlike(uid: $uid) {
            success,
            comment  {
              uid
              numLikes: num_likes
              userLiked: user_liked
            }
        }
    }
`;

}

export default new Comment_Queries()
