import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { CurrentUserContext } from '../../../contexts/current-user-context';
import { getCurrentUser } from '../../../api/actions/get-current-user';
import { toast } from 'react-toastify';
import { inviteCodeActivate } from '../../../api/actions/invite-activation';
import { Button } from '../../../components/button/button';
import { MainLayout } from '../../../components/layouts/main-layout';
import { SecuredPage } from '../../base/secured-page';
import { ProfileMenuComponent } from '../analytics/components/profile-menu-component';
import { MultiError } from '../../../types/multi-error';
import styles from './invite-page.module.scss';

export function GroupInvitePage(props: RouteComponentProps) {

    const [code, setCode] = React.useState<string>();
    const [error, setError] = React.useState<string>();

    const currentUserContext = React.useContext(CurrentUserContext);
    const reloadUser = async () => {
            try {
                const usr = await getCurrentUser();
                currentUserContext?.setState(usr);
                props.history.push('/');
            }
            catch(err) {
                setError((err as MultiError).message);
            }
        }

    const activate = async () => {
        try {
            if(!code) return;
            await inviteCodeActivate(code);
            reloadUser();
            toast.success('Invite Code has been successfully applied to your account.');
        }
        catch(err) {
            setError((err as MultiError).message);
            toast.error((err as MultiError).message);
        }
    }

    return (
        <SecuredPage pageName="profile" {...props} >
            <MainLayout>
                <ProfileMenuComponent>
                    <div className={styles.content}>
                        <h1>Invite Code Activation<br />
                            <span>Please enter your Invite Code.</span>
                        </h1>
                        <input type="text" className={styles.input} onChange={(e) => { setError(undefined); setCode(e.target.value); }} value={code?.toUpperCase()} />
                        <div className="errors">{error}</div>
                        <Button className="orange" onClick={activate} disabled={!code}>
                            <span>Activate</span> <FontAwesomeIcon icon={faChevronRight} />
                        </Button>
                    </div>
                </ProfileMenuComponent>
            </MainLayout>
        </SecuredPage>
    );
}
