import * as React from 'react';
import styles from './block.module.scss';

interface IProps {
    children: string | React.ReactElement | Array<string | React.ReactElement | undefined | boolean> | undefined | boolean,
    className?: string,
}

export function Block(props: IProps) {
    return (<div className={`${styles.block} ${props.className}`}>{props.children}</div>);
}
