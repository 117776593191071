
import { faArrowLeft, faTrashAlt, faUnlock, faLock, faPlus, faEdit, faTimes, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import { Block } from '../../../../components/block/block';
import { AddBreadcrumbsItem } from '../../../../components/shared/breadcrumbs/breadcrumbs';
import { Button } from '../../../../components/button/button';
import { LinkButton } from '../../../../components/link-button/link-button';
import { Loader } from '../../../../components/loader/loader';
import { basePerformError } from '../../../../helpers/error-helpers';
import { getParentPath, joinPath } from '../../../../helpers/path-helpers';
import { compareText } from '../../../../helpers/array-helpers';
import { IAnswer } from '../../../../models/answer';
import { IEpisode } from '../../../../models/episode';
import { IEmotionEpisode } from '../../../../models/emotion-episode';
import { getAnswer } from '../../../../api/actions/answers/answer-get';
import {
  getAbsoluteUrl,
} from '../../../../config/app-config';
import moment from 'moment';
import styles from './response-viewer.module.scss';



export function ResponseViewer (props: RouteComponentProps<{uid: string}>) {

    const [answer, setAnswer] = useState<IAnswer>();


    const loadData = useCallback(async (uid?: string) => {
        try {
            if(uid || props.match.params.uid !== '_') {
                setAnswer(await getAnswer(uid || props.match.params.uid));
            }
            else {
                setAnswer({} as IAnswer);
            }
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    }, [props.history, props.match.params.uid]);

    useEffect(() => {
        loadData().catch(() => {/** */});
    }, []);

    const getEpisodeTitle = (episode: IEpisode) => {
      return (`S${episode.season} : E${episode.numberInSeason} ${episode.name}`);
    }

    const goToUser = async (userUid: string | undefined, isNewWindow = false) => {
        if(!userUid) return;

        const url = '/users/users/' + userUid;
        if(isNewWindow) {
            window.open(url);
        }
        else {
            props.history.push(url);
        }
    }

    const goToEmotionEpisode = async (emep: IEmotionEpisode | undefined, isNewWindow = false) => {
        if (!emep) return;

        let url = getAbsoluteUrl('search');
        url = url + '?segment=' + emep.uid;
        
        if(isNewWindow) {
            window.open(url);
        }
        else {
            props.history.push(url);
        }
    }



	return (<>
        <Block className={styles.viewer}>

            {answer?.uid && (<>

            <div className={styles.dataContainer}>
                <div className={styles.basicInfo}>

                    <div className={styles.basicItem}>
                        <div className={styles.basicItemName}>Segment:</div>
                        <div className={styles.basicItemValue}>{'"' + answer.participant?.emotionEpisode?.description + '"'}</div>
                        <LinkButton
                            onClick={() => goToEmotionEpisode(answer.participant?.emotionEpisode, true)}
                        >
                        <FontAwesomeIcon icon={faExternalLinkAlt} /> View Segment
                        </LinkButton>

                    </div>

                    <div className={styles.basicItem}>
                        <div className={styles.basicItemName}>Skill:</div>
                        <div className={styles.basicItemValue}>{answer.skill?.name}</div>
                    </div>

                    <div className={styles.basicItem}>
                        <div className={styles.basicItemName}>Character Focus:</div>
                        <div className={styles.basicItemValue}>{answer.participant?.character?.name}</div>
                    </div>

                    <div className={styles.basicItem}>
                        <div className={styles.basicItemName}>Submitted Answer:</div>
                        <div className={styles.basicItemValue}>{'"' + answer.answer + '"'}</div>
                    </div>

                    <div className={styles.basicItem}>
                        <div className={styles.basicItemName}>Submitted On:</div>
                        <div className={styles.basicItemValue}>{moment(answer.createdAt).format('MMM DD, YYYY, h:mm a')}</div>
                    </div>

                    <div className={styles.basicItem}>
                        <div className={styles.basicItemName}>Source:</div>
                        <div className={styles.basicItemValue}>{answer.participant?.emotionEpisode?.scene?.narrative?.title + (answer.participant?.emotionEpisode?.scene?.episode ? ', ' + getEpisodeTitle(answer.participant?.emotionEpisode?.scene?.episode) : '')}</div>
                    </div>

                    <div className={styles.basicItem}>
                        <div className={styles.basicItemName}>Upvotes:</div>
                        <div className={styles.basicItemValue}>{answer.upvoteCount}</div>
                    </div>

                    {(answer.flagStatus?.toLowerCase() === 'open' || answer.flagStatus?.toLowerCase() === 'confirmed') &&(
                    <div className={styles.basicItem}>
                        <div className={styles.basicItemName}>Flag Status:</div>
                        <div className={ styles.basicItemValue }>{answer.flagStatus}</div>
                    </div>)}

                </div>         

            { !!answer?.flags?.length && (<>
            <div>
                {'Flags: ' + answer?.flags.length}

            <table cellPadding="0" cellSpacing="0" className={`list ${styles.list}`}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Status</th>
                        <th>Type</th>
                        <th>Flagged On</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        answer?.flags?.sort((c1, c2) => moment(c1.createdAt).diff(c2.createdAt)).map(((flag, i) => (
                            <tr key={flag.uid}>
                                <td>
                                    <div className={styles.markContainer}>
                                        <div className={`${styles.mark} ${flag.status?.toLowerCase() !== 'pending' ? '' : styles.disabled}`}>&nbsp;</div>
                                        <div className={styles.title}>
                                            {i + 1}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    {flag.status}
                                </td>
                                <td>
                                    {flag.type}
                                </td>
                                <td>
                                    {moment.utc(flag.createdAt).format('h:mm a MMM DD, YYYY')}
                                </td>

                            </tr>
                        )))
                    }
                </tbody>
            </table>
            </div>
            </>)}

            <Button className="gray" to={getParentPath(props.match.url, 1)}>
                <FontAwesomeIcon icon={faArrowLeft} /> <span>Back</span>
            </Button>

        </div>

        </>)}
            
        </Block>

    </>);
}