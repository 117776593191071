import { FetchResult, gql } from 'apollo-boost';
import { IUserStats } from '../../models/user-stats';
import { MultiError } from '../../types/multi-error';
import { graphqlClient } from '../graphql-client';

const QUERY = gql`
    query UserGetStatsByUserUid($userUid: String, $profileUid: String){
        user_get_stats_by_user_uid(uid: $userUid, profile: $profileUid) {
            uid,
            firstName: first_name,
            lastName: last_name,
            points,
            totalUnits: total_units,
            completedUnits: completed_units,
            skillsAnswers: skills_answers,
            skillsAnswersCorrect: skills_answers_correct,
            conceptsAnswers: concepts_answers,
            conceptsAnswersCorrect: concepts_answers_correct,
            openResponseCount: open_response_count,
            savedEmotionEpisodeCount: saved_emotion_episode_count,
            bookmarkCount: bookmark_count,
            journalCount: journal_count,

            achievement {
                uid,
                name,
                iconFileName: icon_file_name
            }


            achievementsStats: achievements_stats {
                achievementUid: achievement_uid,
                achievementType: achievement_type,
                achievementName: achievement_name,
                achievementDescription: achievement_description,
                achievementIndex: achievement_index,
                achievementIconFileName: achievement_icon_file_name,
                achievementAvailable: achievement_available,

                curriculumAchievementAvailable: curriculum_achievement_available,

                count,
                stats,

                curriculumUid: curriculum_uid,
                curriculumName: curriculum_name,
            },
        }
    }
`;

export async function getUserStats(userUid?: string, profileUid?: string): Promise<IUserStats> {
    const result: FetchResult<{ user_get_stats_by_user_uid: IUserStats }> = await graphqlClient.query({
        query: QUERY,
        variables: {
            userUid,
            profileUid
        },
        errorPolicy: 'all',
    });

    if(!result.data || (result.errors && result.errors.length)) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    result.data.user_get_stats_by_user_uid.achievementsStats?.forEach(s => {
        s.stats = s.stats && JSON.parse(JSON.stringify(s.stats));
    });

    return result.data.user_get_stats_by_user_uid;
}
