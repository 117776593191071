import * as React from 'react';
import { LinkButton } from '../link-button/link-button';

interface IBreadcrumbsContext {
    breadcrumbs?: IBreadcrumbsItem[],
    add?(title: string, url?: string): string,
    remove?(key?: string): void,
}

interface IBreadcrumbsItem {
    key: string,
    title: string,
    url?: string,
}

export const BreadcrumbsContext = React.createContext<IBreadcrumbsContext>({});

export function BreadcrumbsManager(props: { children: any }) {

    const breadcrumbs = React.useRef<IBreadcrumbsItem[]>([]);
    const [updatedAt, setUpdatedAt] = React.useReducer(() => (new Date()).getTime(), 0);

    const add = React.useCallback((title: string, url?: string) => {
        const newKey = title + (new Date()).getTime().toString() + Math.random().toString();
        breadcrumbs.current.push({
            key: newKey,
            title,
            url,
        });

        setUpdatedAt();

        return newKey;
    }, []);

    const remove = React.useCallback((key: string) => {
        breadcrumbs.current = breadcrumbs.current.filter(b => b.key !== key);

        setUpdatedAt();
    }, []);

    return (
        <BreadcrumbsContext.Provider value={{ breadcrumbs: breadcrumbs.current, add, remove }} >
            {props.children}
        </BreadcrumbsContext.Provider>
    );
}


export function AddBreadcrumbsItem(props: { title: string, url?: string }) {
    const key = React.useRef<string>()

    const breadcrumbsContext = React.useContext(BreadcrumbsContext);

    React.useEffect(() => {
        if(!key.current && breadcrumbsContext.add) {
            key.current = breadcrumbsContext.add(props.title, props.url);
        }

        return () => breadcrumbsContext.remove?.(key.current);
    }, [props.title, props.url]);

    return (<></>);
}


export function Breadcrumbs() {
    return (
        <BreadcrumbsContext.Consumer >
            { (context: IBreadcrumbsContext) => (
                <div className="breadcrumbs">
                    {
                        context.breadcrumbs?.reduce((arr: any[], el: IBreadcrumbsItem, i: number) => {
                            arr.push(i > 0 ? (<span key={`del${i}`}>&nbsp; &raquo; &nbsp;</span>) : (null));
                            arr.push(
                                (context.breadcrumbs?.length || 0) - 1 > i
                                ? <LinkButton to={ el.url } key={`brdcrmbs${i}`} className='breadcrumbsLink'>{ el.title }</LinkButton>
                                : el.title,
                            );
                            return arr;
                        }, [])
                    }
                </div>
            )}
        </BreadcrumbsContext.Consumer>
    );
}
