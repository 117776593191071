import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { addCompletedUnitToUser } from '../../../../api/actions/add-completed-unit-to-user';
import { Button } from '../../../../components/button/button';
import { HeaderContent } from '../../../../components/header-content/header-content';
import { Loader } from '../../../../components/loader/loader';
import { getAchievementFilePath } from '../../../../config/app-config';
import { basePerformError } from '../../../../helpers/error-helpers';
import { imagesPath, joinPath } from '../../../../helpers/path-helpers';
import { IAchievement } from '../../../../models/achievement';
import { AchievementTypes } from '../../../../models/enums/achievement-types';
import { ILearningChunk } from '../../../../models/learning-chunk';
import { IUnit } from '../../../../models/unit';
import { IUnitSection, UnitSectionTypes } from '../../../../models/unit-section';
import { HeaderStatistics } from '../components/header-statistics';
import { EmotionalSchemaContext } from '../emotional-schema/emotional-schema';
import styles from './unit-finish-section.module.scss';


interface IProps extends RouteComponentProps {
    unit: IUnit,
    startTimestamp: number,
}

interface IQuestionsStats {
    questions: number,
    correct: number,
    checked: number,
}

const CORRECT_ANSWER_POINTS = 2;
const INCORRECT_ANSWER_POINTS = 1;

function UnitFinishSection(props: IProps) {

    const [loading, setLoading] = React.useState(true);
    const [achievements, setAchievements] = React.useState<IAchievement[]>();

    const schemaContext = React.useContext(EmotionalSchemaContext);

    const questionsStats = React.useMemo(() => props.unit.unitSections.reduce<IQuestionsStats>(
            (acc: IQuestionsStats, section: IUnitSection) => {
                const stats = section.learningChunks?.filter(lc => lc.type === UnitSectionTypes.practice).reduce<IQuestionsStats>(
                        (sectionStats: IQuestionsStats, chunk: ILearningChunk) => {
                            if(!chunk.exercise) return sectionStats;
                            
                            const isCorrect = !chunk.exercise.correctResponse || chunk.exercise.userResponse?.[0] === chunk.exercise.correctResponse;
                            return {
                                questions: sectionStats.questions + 1,
                                correct: sectionStats.correct + (isCorrect ? 1 : 0),
                                checked: sectionStats.checked + (chunk.exercise.correctResponse ? 1 : 0),
                            };
                        }, { questions: 0, correct: 0, checked: 0 })
                return {
                    questions: acc.questions + (stats?.questions || 0),
                    correct: acc.correct + (stats?.correct || 0),
                    checked: acc.checked + (stats?.checked || 0)
                };
            },
            { questions: 0, correct: 0, checked: 0},
        ),
        [props.unit.unitSections],
    );

    const calculatedPoints = React.useMemo(() => {
        return ((questionsStats.questions - questionsStats.correct) * INCORRECT_ANSWER_POINTS) + (questionsStats.correct * CORRECT_ANSWER_POINTS);
    }, [questionsStats.correct, questionsStats.questions]);

    React.useEffect(() => {
        ( async () => {
            try {
                const { achievements: newAchievements } = await addCompletedUnitToUser(
                    props.unit.uid,
                    Math.round((Date.now() - props.startTimestamp) / 1000),
                    calculatedPoints,
                    questionsStats.questions,
                    questionsStats.correct,
                    questionsStats.checked,
                );
                if(newAchievements) {
                    setAchievements(newAchievements?.sort((a1, a2) => (
                        (a1.achievementType === AchievementTypes.level ? 0 : 1)
                        - (a2.achievementType === AchievementTypes.level ? 0 : 1)
                    )));
                }
            }
            catch(err) {
                basePerformError(err, props.history);
            }
            setLoading(false);
        })().catch(() => {/** */});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDoneClick = () => {
        schemaContext?.setFinishedUnit(props.unit);
        props.history.push(joinPath('/curriculum', props.unit.curriculum?.uid));
    };

    return (<>
        <HeaderContent>
            <HeaderStatistics additionalPoints={calculatedPoints} />
        </HeaderContent>
        <div className={styles.container}>
            <div className={styles.header}>Nice! You&apos;ve completed this unit!</div>
            <img alt="Points" src={imagesPath('/unit/points-ico.png')} />
            {calculatedPoints && (<div className={styles.points}>+{calculatedPoints} points</div>)}
            <div>Way to go - you&apos;re building experience!</div>

            {achievements?.map(achievement => (<div key={achievement.uid} className={styles.achievement}>
                <img
                    className={styles.icon}
                    alt={achievement.name}
                    src={joinPath(getAchievementFilePath(achievement.uid), achievement.iconFileName)}
                />
                <div className={styles.header}>
                    {
                        (achievement.achievementType === AchievementTypes.level
                            ? `You've reached a new level: `
                            : `You've got a new badge: `
                        ) + achievement.name
                    }
                </div>
            </div>))}
            {achievements?.length && (
                <div>Great job!</div>
            )}

            {loading && <Loader />}
            {!loading && (
                <Button onClick={onDoneClick}>
                    <span>Done</span>
                    <FontAwesomeIcon icon={faArrowRight} />
                </Button>
            )}
        </div>
    </>);
}

const UnitFinishSectionWithRouter = withRouter(UnitFinishSection);
export { UnitFinishSectionWithRouter as UnitFinishSection };

