import { FetchResult, gql } from 'apollo-boost';
import { IUserInvite } from '../../models/user-invite';
import { MultiError } from '../../types/multi-error';
import { graphqlClient } from '../graphql-client';

const QUERY = gql`
    query {
        user_get_sent_invites {
            uid,
            inviteeEmail: invitee_email,
            inviteeFirstName: invitee_first_name,
            inviteeLastName: invitee_last_name,
            status,
            relationship,
            lastSentAt: last_sent_at_string,
            acceptedAt: accepted_at,
            expired
        }
    }
`;

export async function getInvitesByUser(): Promise<IUserInvite[]> {
    const result: FetchResult<{ user_get_sent_invites: IUserInvite[]}> = await graphqlClient.query({
        query: QUERY,
        errorPolicy: 'all',
    });

    if(!result.data || (result.errors && result.errors.length)) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.user_get_sent_invites;
}
