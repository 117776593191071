import { FetchResult, gql } from 'apollo-boost';
import { IUser } from '../../models/user';
import { MultiError } from '../../types/multi-error';
import { graphqlClient } from '../graphql-client';

const MUTATION = gql`
    mutation SignUp($user_data: SignUpInput!, $captcha_token: String!){
        sign_up (user_data: $user_data, captcha_token: $captcha_token) {
            accessToken: access_token,

            user {
                uid,
                email,
                confirmedEmail: confirmed_email
                extensionInstalled: extension_installed,

                userGroups: user_groups {
                    uid,
                    title,
                    enabled,
                    groupType: group_type,
                    emailConfirmationRequired: email_confirmation_required
                    curriculums {
                        uid,
                    }

                    children {
                      uid,
                      title,
                      enabled
                    }
                }

            },
        }
    }
`;

export async function signUp(
    captchaToken: string | null,
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    age: number,
    gender?: string,
    motivation?: string[],
    code?: string,
    app?: string,
    source?: string,
    twoStepSignup?: boolean,
    dependentEmail?: string,
    dependentFirstName?: string,
    dependentLastName?: string,
    birthdate?: string,
    pdState?: string,
    ssnDigits?: string
): Promise<{ user: IUser, accessToken: string }> {
    //console.log('captchaToken here <<<< ', captchaToken)
    const result: FetchResult<{ sign_up: { user: IUser, accessToken: string }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: {
            user_data: {
                email,
                password,
                first_name: firstName,
                last_name: lastName,
                age,
                gender,
                motivation,
                code,
                app,
                source,
                two_step_signup: twoStepSignup,
                dependent_email: dependentEmail,
                dependent_first_name: dependentFirstName,
                dependent_last_name: dependentLastName,
                birthdate,
                pd_state: pdState,
                ssn_digits: ssnDigits
            },
            captcha_token: captchaToken
        },
        errorPolicy: 'all',
    });

    if(!result.data || (result.errors && result.errors.length)) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.sign_up;
}
