import React, { useEffect, useState } from 'react';
import { userGroupPrivateInviteActivate } from '../../api/actions/user-groups/private-invite-activate';
import { Loader } from '../../components/loader/loader';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { PublicPage } from '../base/public-page';
import { MainLayout } from '../../components/layouts/main-layout';
import { AppConfig } from '../../config/app-config';
import { Link } from 'react-router-dom';
import styles from './payment-success-page.module.scss';

export function PaymentSuccessPage() {


    const history = useHistory();


    return (<>
        <PublicPage pageName="invite_page">
            <MainLayout>
                <>
                    <div className={styles.container}>
                      <div className={styles.successMessageContainer}>
                        <span className={styles.successMessage}>Payment succeeded. Thank you!</span>
                        <br/><br/>
                        <Link to="/account" className={styles.link}>Return to Account Page</Link>
                      </div>
                    </div>
                </>
            </MainLayout>
        </PublicPage>
    </>);
}
