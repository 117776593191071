import { FetchResult, gql } from 'apollo-boost';
import { ISkill } from '../../models/skill';
import { MultiError } from '../../types/multi-error';
import { graphqlClient } from '../graphql-client';

const QUERY = gql`
    query {
        skills {
            uid,
            parent,
            index,
            name,
            description,
            icon,
        }
    }
`;

export async function getAllSkills(): Promise<ISkill[]> {
    const result: FetchResult<{ skills: ISkill[] }> = await graphqlClient.query({
        query: QUERY,
        errorPolicy: 'all',
    });

    if(!result.data || (result.errors && result.errors.length)) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.skills;
}
