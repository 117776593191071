import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import {
  faPlus,
  faSearch,
  faSortDown,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldProps, FormikProps } from 'formik';
import * as React from 'react';
import { compareText, onlyUnique } from '../../helpers/array-helpers';
import { isNotNullOrEmpty } from '../../helpers/common-helpers';
import { BaseTypes } from '../../types';
import { LinkButton } from '../link-button/link-button';
import styles from './select.module.scss';

interface IPropsBase<T, V> {
  name?: string;
  value?: V | V[];
  multiSelect?: boolean;
  data?: T[];
  emptyTitle?: string;
  disabled?: any;
  hideSearch?: boolean;
  form?: FormikProps<any>;
  className?: string;
  onAddNew?: (str: string) => void;
  valueExtractor?: (el: T) => V;
  titleExtractor?: (el?: T) => string | undefined | null;
  optionRenderer?: (el: T) => React.ReactElement;
  isActive?: (el: T) => boolean;
  childrenExtractor?: (el: T) => T[];
  onChange?(event: {
    target: { name?: string; value?: V };
    currentTarget: { name?: string; value?: V };
  }): void;
  onBlur?: (value: any) => void;
}

type IProps<T, V> = IPropsBase<T, V> extends { multiSelect: true }
  ? IPropsBase<T, V> & { value?: V[] }
  : IPropsBase<T, V> & { value?: V };

export function Select<T, V>(props: IProps<T, V>) {
  const valueExtractor =
    props.valueExtractor ||
    ((x?: any) => (x && typeof x === BaseTypes.object ? x.uid : x));
  const titleExtractor =
    props.titleExtractor ||
    ((x: any) => (x && typeof x === BaseTypes.object ? x.name : x));
  const childrenExtractor = props.childrenExtractor || ((x: any) => x.children);
  const isActive = props.isActive || ((el: any) => !el.children);
  const optionRenderer = props.optionRenderer
    ? props.optionRenderer
    : (el: any) =>
        el.children ? <span>{titleExtractor(el)}</span> : titleExtractor(el);

  const [opened, setOpened] = React.useState<boolean>(false);
  const [searchText, setSearchText] = React.useState<string>('');

  React.useEffect(() => {
    const onClick = () => {
      if (opened) setOpened(false);
    };
    document.documentElement.addEventListener('click', onClick);

    return () => document.documentElement.removeEventListener('click', onClick);
  }, [opened]);

  const getMultipleValues = (): V[] =>
    Array.isArray(props.value) ? props.value : props.value ? [props.value] : [];
  const getSingleValue = (): V | undefined => props.value;

  const onChange = (opt?: T) => {
    if (opt === undefined || !isActive || isActive(opt)) {
      if (!props.multiSelect) {
        setOpened(false);
      }

      const value = opt && valueExtractor(opt);

      let newValue = value;
      if (props.multiSelect) {
        if (getMultipleValues().includes(value)) {
          newValue = getMultipleValues().filter((v) => v !== value);
        } else {
          newValue = [...getMultipleValues(), ...(value ? [value] : [])];
        }

        if (!newValue || !newValue.length) {
          newValue = undefined;
        }
      }

      const target = {
        name: props.name,
        value: newValue,
      };
      const val = {
        target,
        currentTarget: target,
      };
      if (!props.multiSelect && props.onBlur) {
        props.onBlur(val);
      }
      if (props.onChange) {
        props.onChange(val);
      }
    }
  };

  const onFocus = () => {
    if (!props.disabled) setOpened(true);
  };

  const getSelected = (options?: T[]): undefined | T[] => {
    return options
      ?.reduce<T[]>(
        (selected, opt) => [
          ...selected,
          ...((props.multiSelect
            ? getMultipleValues()
            : [getSingleValue()]
          ).includes(valueExtractor(opt))
            ? [opt]
            : []),
          ...(getSelected(childrenExtractor(opt)) || []),
        ],
        []
      )
      .filter((opt, i, self) => onlyUnique(opt, i, self, valueExtractor))
      .sort((o1, o2) => compareText(o1, o2, (o) => titleExtractor(o)));
  };

  const isSelected = (el?: T) =>
    (el === undefined && props.value === undefined) ||
    (el &&
      (props.multiSelect
        ? getMultipleValues().includes(valueExtractor(el))
        : valueExtractor(el) === props.value));

  const renderValue = () => {
    return props.multiSelect
      ? getSelected(props.data)?.map((opt) => (
          <div key={valueExtractor(opt)} className={styles.value}>
            {titleExtractor(opt)}
            <FontAwesomeIcon
              icon={faTimes}
              onClick={(ev) => {
                ev.stopPropagation();
                onChange(opt);
              }}
            />
          </div>
        ))
      : (getSelected(props.data)?.length &&
          titleExtractor(getSelected(props.data)?.[0])) ||
          props.emptyTitle;
  };

  const renderChildren = (key: string, children?: T[]) => {
    if (!children) return null;

    const childrenElements = children
      .map((el) => renderElement(el))
      .filter((el) => el);
    if (!childrenElements.length) return null;

    return (
      <div key={key} className={styles.childrenContainer}>
        {childrenElements}
      </div>
    );
  };

  const renderOptionContainer = (
    content: any,
    el?: T,
    isVisible: boolean = true
  ) => (
    <div
      className={`\
                ${styles.option}\
                ${
                  (el === undefined || !isActive || isActive(el)) &&
                  isVisible &&
                  styles.active
                }\
                ${isSelected(el) && styles.selected}\
            `}
      onClick={() => onChange(el)}
    >
      {content === '' ? <>&nbsp;</> : content}
    </div>
  );

  const renderElement = (el: T) => {
    // If the element's uid is 'to-class-create', render it no matter what
    // @ts-ignore
    if (el.uid && el.uid === 'to-class-create') {
      return (
        <React.Fragment key={`${valueExtractor(el)}${titleExtractor(el)}`}>
          {renderOptionContainer(optionRenderer(el), el, true)}
        </React.Fragment>
      );
    }

    // Existing logic for other elements
    const isVisible = isNotNullOrEmpty(searchText)
      ? titleExtractor(el)?.toLowerCase().includes(searchText.toLowerCase())
      : true;
    const children = renderChildren(
      `${valueExtractor(el)}${titleExtractor(el)}_children`,
      childrenExtractor(el)
    );

    if (!isVisible && !children) return null;

    return (
      <React.Fragment key={`${valueExtractor(el)}${titleExtractor(el)}`}>
        {renderOptionContainer(optionRenderer(el), el, isVisible)}
        {children}
      </React.Fragment>
    );
  };

  const optionsArr = props.data
    ?.map((option) => renderElement(option))
    .filter((o) => o);

  return (
    <div
      className={`selectField ${styles.selectField} ${
        !props.multiSelect && styles.singleSelect
      } ${props.disabled && styles.disabled} ${props.className}`}
    >
      <div onClick={onFocus} className={styles.fieldSubContainer}>
        <div
          className={`${styles.valueContainer} ${
            getSelected(props.data) ? '' : styles.empty
          } ${!props.multiSelect && styles.singleSelect}`}
        >
          {renderValue()}
          &nbsp;
        </div>
        <FontAwesomeIcon icon={faSortDown} />
      </div>
      {opened && (
        <>
          <div
            className={styles.options}
            onClick={(ev) => ev.stopPropagation()}
          >
            {!props.hideSearch && (
              <div className={styles.search}>
                <input
                  type="text"
                  value={searchText}
                  onChange={(ev) => setSearchText(ev.target.value)}
                />
                <FontAwesomeIcon
                  icon={faSearch}
                  className={styles.searchIcon}
                />
                {searchText && (
                  <FontAwesomeIcon
                    icon={faTimes}
                    className={styles.searchClear}
                    onClick={() => setSearchText('')}
                  />
                )}
              </div>
            )}
            {!optionsArr?.length && props.onAddNew && (
              <div className={styles.addButtonContainer}>
                <LinkButton
                  onClick={() => {
                    props.onAddNew?.(searchText);
                    setSearchText('');
                  }}
                  className="warn"
                >
                  <FontAwesomeIcon icon={faPlus} />
                  Add New
                </LinkButton>
              </div>
            )}
            <div className={styles.optionsContainer}>
              {props.emptyTitle !== undefined &&
                renderOptionContainer(props.emptyTitle)}
              {optionsArr}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export function SelectField<T, V>(
  props: FieldProps & Partial<IProps<T, V>>
): React.ReactElement {
  const { field, form, ...params } = props;

  React.useEffect(() => {
    if (form.isSubmitting) {
      form.setFieldTouched(field.name, true);
    }
  }, [field.name, form.isSubmitting]);

  return (
    <Select<T, V>
      name={field.name}
      value={field.value}
      onChange={field.onChange}
      onBlur={field.onBlur}
      form={form}
      {...params}
    />
  );
}
