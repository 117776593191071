import { Field, Form, Formik, FormikProps } from 'formik';
import React, { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { forgotPasswordRequest } from '../../api/actions/forgot-password/forgot-password-request';
import { Button } from '../../components/button/button';
import { MainLayout } from '../../components/layouts/main-layout';
import { Loader } from '../../components/loader/loader';
import { PublicPage } from '../base/public-page';
import { MultiError } from '../../types/multi-error';
import styles from './forgot-password.module.scss';

interface IForm {
    email: string,
}

export function ForgotPasswordPage() {
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (
        values: IForm,
        { setSubmitting }: { setSubmitting: (status: boolean) => void },
    ) => {
        setLoading(true);
        try {
            await forgotPasswordRequest(values.email);

            toast.info('If the entered email exists in the system, you will receive instruction on how to reset your password.');
            toast.success('Operation has been successful.', { autoClose: 10000, });
        }
        catch (err) {
            toast.error((err as MultiError).message);
        }
        setSubmitting(false);
        setLoading(false);
    };

    const getValidationSchema = () => {
        return Yup.object({
            email: Yup.string()
                .label('Email')
                .required()
                .email(),
        });
    };

    const renderLoginForm = ({ errors, touched, submitForm }: FormikProps<IForm>): ReactElement => {
        return (
            <div className={styles.container}>
                <div className={styles.subcontainer}>
                    <h1>
                        Forgot Password<br />
                        <span>
                            <br />
                            To create new password, please enter a valid Email address,<br />
                            you will receive email with instructions how to create a new password.
                        </span>
                    </h1>
                    <Form noValidate >
                        <div className="form-item">
                        </div>
                        <div className="form-item">
                            <label>
                                <div className="form-label required">
                                    Email
                                </div>
                                <Field type="email" name="email" />
                            </label>
                            <div className="errors">{touched.email && errors.email}</div>
                        </div>

                        <div className="form-buttons single">
                            { loading
                            ? (<Loader />)
                            : (<Button onClick={submitForm}><span>Submit</span></Button>)
                            }
                        </div>
                        <div className="form-item">
                            return to <Link to="/login">Login</Link>
                        </div>
                    </Form>
                </div>
            </div>
        );
    };


    return (
        <PublicPage pageName="forgot_password">
            <MainLayout>
                <Formik
                    initialValues={{
                        email: '',
                    }}
                    validationSchema={getValidationSchema}
                    onSubmit={handleSubmit}
                >
                    {renderLoginForm}
                </Formik>
            </MainLayout>
        </PublicPage>
    );
}
