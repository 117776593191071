import React, { createContext, useState, ReactNode } from 'react';

interface PopupContextProps {
  openPopup: string | null;
  setOpenPopup: (popupName: string | null) => void;
}

export const PopupContext = createContext<PopupContextProps | undefined>(
  undefined
);

interface PopupProviderProps {
  children: ReactNode;
}

export const PopupProvider: React.FC<PopupProviderProps> = ({ children }) => {
  const [openPopup, setOpenPopup] = useState<string | null>(null);

  return (
    <PopupContext.Provider value={{ openPopup, setOpenPopup }}>
      {children}
    </PopupContext.Provider>
  );
};
