import React from 'react';
import { getUserScores } from '../../../../api/actions/get-user-scores';
import { getAllSkills } from '../../../../api/actions/skills-get-all';
import { CircularProgress } from '../../../../components/circular-progress/circular-progress';
import { basePerformError } from '../../../../helpers/error-helpers';
import { IScore } from '../../../../models/score';
import { ISkill } from '../../../../models/skill';
import { IUser } from '../../../../models/user';
import styles from './skills-stats-component.module.scss';
import { Popup } from '../../../../components/popup/popup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo, faInfoCircle, faUnlock } from '@fortawesome/free-solid-svg-icons';

interface IProps {
    user: IUser,
}

export function SkillsStatsComponent(props: IProps) {

    
    const [skills, setSkills] = React.useState<ISkill[]>();

    //Added a new const/useState line to determine when the popup would appear
    // Originally had const [scores, setScores] = React.useState<IScore[]>(), but found that tricker to use once it was time to close the pop up
    const [skillPopUpVisible, setSkillPopUpVisible] = React.useState({appears: false, title: "", description: ""})
    

    React.useEffect(() => {
        (async () => {
            try {
                setSkills(buildTree(await getAllSkills(), await getUserScores(props.user.uid)));
            }
            catch(err) {
                basePerformError(err);
                return;
            }
        })().catch(() => {/** */});
    }, [props]);

    const buildTree = (skills: ISkill[], scores: IScore[]) => {
        const rootSkills = skills.filter(s => !s.parent).sort((s1, s2) => s1.index - s2.index);
        rootSkills.forEach(
            skill => { skill.score = scores.find(score => score.skill?.uid === skill.uid) || { totalAttempted: 0, totalCorrect: 0 } as IScore; }
        );
        rootSkills.forEach(skill => skill.children = getChildren(skill, skills, scores));
        rootSkills.forEach(skill => updateScore(skill));
        return rootSkills;
    }

    const getChildren = (skill: ISkill, skills: ISkill[], scores: IScore[]): ISkill[] => {
        const children = skills.filter(s => s.parent === skill.uid).sort(s => s.index);
        children.forEach(s => { s.score = scores.find(score => score.skill?.uid === s.uid) || { totalAttempted: 0, totalCorrect: 0 } as IScore; });
        children.forEach(s => s.children = getChildren(s, skills, scores));
        children.forEach(s => updateScore(s));
        return children;
    }

    const updateScore = (skill: ISkill) => {
        skill.children?.forEach(ss => {
            if(!skill.score) return;
            skill.score.totalAttempted += ss.score?.totalAttempted || 0;
            skill.score.totalCorrect += ss.score?.totalCorrect || 0;
        })
    }

    const getSkillScore = (skill: ISkill) => {

        const score = skill.score;

        return Math.round(((score?.totalCorrect || 0) / (score?.totalAttempted || 1)) * 100);
    };

    const renderSubSkill = (skill: ISkill, level = 1) => (
        <React.Fragment key={skill.uid}>
            <div className={styles.subSkill}>
                {/* Added a new container to hold the skill name and the Font Awesome Icon */}
                <div className={styles.subSkillAndIconContainer}>
                {skill.name}
                <FontAwesomeIcon icon={faInfoCircle} className={styles.infoIcon} onClick={() => setSkillPopUpVisible({appears: true, title: skill.name, description: skill.description})} />
                </div>
                <div>
                    { skill.score?.totalAttempted ? `${getSkillScore(skill)}% (${skill.score.totalCorrect || 0}/${skill.score.totalAttempted || 0})` : '' }
                </div> 
            </div>
            <div key={`${skill.uid}_sub`} className={styles.subSkillContainer}>
                { skill.children && skill.children.map(s => renderSubSkill(s))}
            </div>
        </React.Fragment>
    )

    return (
        <div className={styles.container}>
            { skills && skills.map( skill => (
                <React.Fragment key={skill.uid}>
                    <div className={styles.rootSkill}>
                        {skill.name}
                        <CircularProgress value={getSkillScore(skill)} />
                    </div>
                    { skill.children && skill.children.map(s => renderSubSkill(s)) }
                </React.Fragment>
            ))}
            {/* Added code below to create the pop up that appears when an info icon is pressed */}
            {skillPopUpVisible.appears && (
            <Popup
                title={skillPopUpVisible.title}
                modal={true}
                onClose={() => setSkillPopUpVisible({appears: false, title: "", description: ""})}     
            >
                <div className={styles.rootSkill}>
                        {skillPopUpVisible.description}
                </div>
            </Popup>
        )}
        </div>
    );
}