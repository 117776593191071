import { FetchResult, gql } from 'apollo-boost';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation UserForgotPasswordRequest($email: String!){
        user_forgot_password_request(email: $email) {
            success,
        }
    }
`;

interface IResult {
    success: boolean,
}

export async function forgotPasswordRequest(email: string): Promise<IResult> {
    const result: FetchResult<{ user_forgot_password_request: IResult }> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: {
            email,
        },
        errorPolicy: 'all',
    });

    if(!result.data || (result.errors && result.errors.length)) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.user_forgot_password_request;
}
