import * as React from 'react';
import { Popup } from '../../../../components/popup/popup';
import { getUnitFilePath } from '../../../../config/app-config';
import { IUnit } from '../../../../models/unit';
import styles from './emotional-schema.module.scss';

export const EmotionalSchemaContext = React.createContext<IHeaderContentManagerState | undefined>(undefined);


interface IHeaderContentManagerProps {
    children: any;
}

export interface IHeaderContentManagerState {
    finishedUnit?: IUnit;
    setFinishedUnit(unit?: IUnit): void;
}

export class EmotionalSchemaManager extends React.PureComponent<IHeaderContentManagerProps, IHeaderContentManagerState> {

    constructor(props: IHeaderContentManagerProps) {
        super(props);

        this.state = {
            setFinishedUnit: this.setFinishedUnit,
        };
    }

    setFinishedUnit = (unit: IUnit) => {
        this.setState({ finishedUnit: unit });
    }

    render() {
        return (
            <EmotionalSchemaContext.Provider value={this.state}>
                { this.props.children }
            </EmotionalSchemaContext.Provider>
        );
    }
}

interface IProps {
    units: IUnit[];
}

export function EmotionalSchema(props: IProps) {
    const [emotionModelInPopupVisible, setEmotionModelInPopupVisible] = React.useState(false);
    const [prevCompletedUnitWithSchema, setPrevCompletedUnitWithSchema] = React.useState<IUnit>();
    const [lastCompletedUnitWithSchema, setLastCompletedUnitWithSchema] = React.useState<IUnit>();

    const context = React.useContext(EmotionalSchemaContext);

    React.useEffect(() => {
        (async () => {
            let prevCompletedWithSchema: IUnit | undefined;
            let lastCompletedWithSchema: IUnit | undefined;
            props.units.filter((u: IUnit) => u.completed).forEach((u: IUnit) => {
                if(u.finalEmotionModelState) {
                    prevCompletedWithSchema = lastCompletedWithSchema;
                    lastCompletedWithSchema = u;
                }
            });
            setLastCompletedUnitWithSchema(lastCompletedWithSchema);
            if(context?.finishedUnit && lastCompletedWithSchema && context.finishedUnit.uid === lastCompletedWithSchema.uid) {
                context.setFinishedUnit(undefined);
                setPrevCompletedUnitWithSchema(prevCompletedWithSchema);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);


    return (<>
        <div className={styles.finalEmotionModelState} onClick={() => setEmotionModelInPopupVisible(true)}>
            <div className={styles.schema}>
              <div className={styles.imgContainer}>
                    <img
                        src={!lastCompletedUnitWithSchema ? "https://s3.amazonaws.com/resource.affectifi.com/app/img/learning/Event-Person-Cloud.jpg" :
                          getUnitFilePath(
                            lastCompletedUnitWithSchema.uid,
                            lastCompletedUnitWithSchema.finalEmotionModelState,
                        )}
                        alt="Emotion System"
                    />
                </div>
                { prevCompletedUnitWithSchema && (
                    <div className={styles.imgContainer}>
                        <img
                            src={getUnitFilePath(
                                prevCompletedUnitWithSchema.uid,
                                prevCompletedUnitWithSchema.finalEmotionModelState,
                            )}
                            alt="Emotion System"
                        />
                    </div>
                )}
            </div>
        </div>
        { emotionModelInPopupVisible && (
            <Popup
                title="Emotion System Model"
                onClose={() => setEmotionModelInPopupVisible(false)}
                className="full"
            >
                <img
                    className={styles.emotionModel}
                    src={!lastCompletedUnitWithSchema ? "https://s3.amazonaws.com/resource.affectifi.com/app/img/learning/Event-Person-Cloud.jpg" :
                      getUnitFilePath(
                        lastCompletedUnitWithSchema.uid,
                        lastCompletedUnitWithSchema.finalEmotionModelState,
                    )}
                    alt="Emotion System"
                />
            </Popup>
        )}
    </>);
}
