import { Field, Form, Formik, FormikProps } from 'formik';
import Cookies, { set } from 'js-cookie';
import moment from 'moment';
import React, {
  ReactElement,
  useState,
  useRef,
  useEffect,
  useContext,
} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { signUp } from '../../api/actions/signup-action';
import { Button } from '../../components/button/button';
import { LinkButton } from '../../components/link-button/link-button';
import { Loader } from '../../components/loader/loader';
import { Popup } from '../../components/popup/popup';
import { SelectField } from '../../components/select/select';
import { AppConfig } from '../../config/app-config';
import { CurrentUserContext } from '../../contexts/current-user-context';
import { GenderTypes } from '../../models/enums/gender-types';
import { UserMotivationTypes } from '../../models/enums/user-motivation-types';
import { IDictionary } from '../../types/dictionary';
import { MultiError } from '../../types/multi-error';
import { IUser } from '../../models/user';
import { getCurrentUser } from '../../api/actions/get-current-user';
import { Link, useHistory } from 'react-router-dom';
import styles from './signup-page.module.scss';

import {
  hasMotivation,
  hasInstitutionalMotivation,
  hasPDMotivation,
} from '../../utils.js';

interface IForm {
  server?: string;
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  age: number | null;
  gender: string;
  motivation: string;
  dependentEmail: string;
  dependentFirstName: string;
  dependentLastName: string;
  birthdateMonth: string;
  birthdateDay: string;
  birthdateYear: string;
  birthdate: string;
  pdState: string;
  ssnDigits: string;
}

interface IProps {
  groupInviteCode?: string;
  email?: string;
  source?: string;
  onSuccessSignUp?(userId: string): void;
  loginLinkCallback?: any;
  introMessage?: string;
  extendedForm?: boolean;
  handleToggle?: () => void;
  destination?: string;
}

export function SignUpComponent(props: IProps) {
  const mountedRef = useRef(true);
  Cookies.remove(AppConfig.tokenName);

  const currentUserContext = React.useContext(CurrentUserContext);
  currentUserContext?.setState(undefined);

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [userAge, setUserAge] = useState(0);
  const [userPDState, setUserPDState] = useState<string | null>(null);
  const [userSSNState, setUserSSNState] = useState<string | null>(null);
  const [termsVisible, setTermsVisible] = useState(false);
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);

  const states = [
    { uid: 'AL', name: 'AL' },
    { uid: 'AK', name: 'AK' },
    { uid: 'AZ', name: 'AZ' },
    { uid: 'AR', name: 'AR' },
    { uid: 'AS', name: 'AS' },
    { uid: 'CA', name: 'CA' },
    { uid: 'CO', name: 'CO' },
    { uid: 'CT', name: 'CT' },
    { uid: 'DE', name: 'DE' },
    { uid: 'DC', name: 'DC' },
    { uid: 'FL', name: 'FL' },
    { uid: 'GA', name: 'GA' },
    { uid: 'GU', name: 'GU' },
    { uid: 'HI', name: 'HI' },
    { uid: 'ID', name: 'ID' },
    { uid: 'IL', name: 'IL' },
    { uid: 'IN', name: 'IN' },
    { uid: 'IA', name: 'IA' },
    { uid: 'KS', name: 'KS' },
    { uid: 'KY', name: 'KY' },
    { uid: 'LA', name: 'LA' },
    { uid: 'ME', name: 'ME' },
    { uid: 'MD', name: 'MD' },
    { uid: 'MA', name: 'MA' },
    { uid: 'MI', name: 'MI' },
    { uid: 'MN', name: 'MN' },
    { uid: 'MS', name: 'MS' },
    { uid: 'MS', name: 'MS' },
    { uid: 'MO', name: 'MO' },
    { uid: 'MT', name: 'MT' },
    { uid: 'NE', name: 'NE' },
    { uid: 'NV', name: 'NV' },
    { uid: 'NH', name: 'NH' },
    { uid: 'NJ', name: 'NJ' },
    { uid: 'NM', name: 'NM' },
    { uid: 'NY', name: 'NY' },
    { uid: 'NC', name: 'NC' },
    { uid: 'ND', name: 'ND' },
    { uid: 'MP', name: 'MP' },
    { uid: 'OH', name: 'OH' },
    { uid: 'OK', name: 'OK' },
    { uid: 'OR', name: 'OR' },
    { uid: 'PA', name: 'PA' },
    { uid: 'PR', name: 'PR' },
    { uid: 'RI', name: 'RI' },
    { uid: 'SC', name: 'SC' },
    { uid: 'SD', name: 'SD' },
    { uid: 'TN', name: 'TN' },
    { uid: 'TX', name: 'TX' },
    { uid: 'TT', name: 'TT' },
    { uid: 'UT', name: 'UT' },
    { uid: 'VT', name: 'VT' },
    { uid: 'VA', name: 'VA' },
    { uid: 'VI', name: 'VI' },
    { uid: 'WA', name: 'WA' },
    { uid: 'WV', name: 'WV' },
    { uid: 'WI', name: 'WI' },
    { uid: 'WY', name: 'WY' },
  ];

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const handleAgeChange = (value: number) => {
    setUserAge(value);
  };

  const handleMotivationChange = (value: string) => {
    console.log('motivation changed to: ' + value);
    setUserPDState(value);
  };

  const handleStateChange = (value: string) => {
    console.log('state changed to: ' + value);
    setUserSSNState(value);
  };

  const goToTerms = () => {
    window.open(AppConfig.termsURL);
  };

  const login = () => {
    if (!props.loginLinkCallback) {
      history.replace('/login');
    } else {
      props.loginLinkCallback();
    }
  };

  const handleCaptcha = (value: string | null) => {
    setCaptchaToken(value);
  };

  const handleSubmit = async (
    values: IForm,
    {
      setSubmitting,
      setErrors,
    }: {
      setSubmitting: (status: boolean) => void;
      setErrors: (errors: IDictionary<string>) => void;
    }
  ) => {
    setLoading(true);
    try {
      const defaultGroupCode = AppConfig.signupGroupCode;
      //here only use the default code if there is no invite code or email specified in the props (the email would indicate an invite)
      const groupCode =
        props.groupInviteCode || props.email
          ? props.groupInviteCode
          : defaultGroupCode;
      const twoStepSignup = props.email ? true : false;
      const app =
        props.source && props.source === 'ext' ? 'chrome_extension' : undefined;
      const source =
        props.source && props.source === 'share' ? 'share' : undefined;

      const constructBirthdate = () => {
        const year = Number(values.birthdateYear);
        const month = Number(values.birthdateMonth) - 1;
        const day = Number(values.birthdateDay);

        const selectedDate = new Date(year, month, day);
        return selectedDate.toISOString();
      };
      values.birthdate = constructBirthdate();

      const userResponse = await signUp(
        captchaToken,
        values.email,
        values.password,
        values.firstName,
        values.lastName,
        values.age!,
        values.gender,
        [values.motivation],
        groupCode,
        app,
        source,
        twoStepSignup,
        values.dependentEmail,
        values.dependentFirstName,
        values.dependentLastName,
        values.birthdate,
        values.pdState,
        values.ssnDigits
      );
      if (userResponse.user.uid) {
        Cookies.set(AppConfig.tokenName, userResponse.accessToken, {
          domain: new URL(location.href).hostname,
          expires: moment().add(180, 'days').toDate(),
        });

        toast.success('User account has been successfully created.');

        let destination = '/analytics';

        const isMemberOfAnOrgWithChildren = userResponse.user.userGroups?.some(
          (ug) =>
            ug.enabled &&
            ug.groupType === 'organization' &&
            ug.children &&
            ug.children.length > 0
        );

        const isMemberOfAnOrgRequiresEmailComfirmation =
          userResponse.user.userGroups?.some(
            (ug) => ug.enabled && ug.emailConfirmationRequired
          );

        if (props.destination) {
          destination = props.destination;
        } else if (
          isMemberOfAnOrgRequiresEmailComfirmation &&
          (userResponse.user.confirmedEmail === null ||
            userResponse.user.confirmedEmail !== userResponse.user.email)
        ) {
          destination = '/onboarding-confirm-email';
        } else if (isMemberOfAnOrgWithChildren) {
          destination = '/group-select';
        } else if (hasPDMotivation([values.motivation])) {
          /**do nothing: leave destination unchanged and go directly to the account page**/
        } else if (hasInstitutionalMotivation([values.motivation])) {
          destination = '/org-select';
        }

        props.onSuccessSignUp?.(destination);
      }
    } catch (err) {
      let errorMessage;
      if ((err as MultiError).errors) {
        errorMessage = (err as MultiError).errors
          .map((e) => (typeof e === 'string' ? e : e.message))
          .join('\n');
      } else {
        errorMessage = err;
      }
      if (mountedRef.current) {
        setErrors({
          server: errorMessage as string,
        });
      }
    }
    if (mountedRef.current) {
      setSubmitting(false);
      setLoading(false);
    }
  };

  const getValidationSchema = () => {
    return Yup.object({
      email: Yup.string().label('Email').ensure().trim().required().email(),
      password: Yup.string()
        .label('Password')
        .ensure()
        .trim()
        .required()
        .min(8)
        .max(50),
      confirmPassword: Yup.string()
        .label('Confirm Password')
        .ensure()
        .trim()
        .oneOf([Yup.ref('password'), ''], 'Passwords should match')
        .required(),
      firstName: Yup.string()
        .label('First Name')
        .ensure()
        .matches(/^[a-zA-Z\s]+$/, 'Name should only use letters and spaces')
        .trim()
        .required()
        .min(2)
        .max(50),
      lastName: Yup.string()
        .label('Last Name')
        .ensure()
        .matches(/^[a-zA-Z\s]+$/, 'Name should only use letters and spaces')
        .trim()
        .required()
        .min(2)
        .max(50),
      motivation: Yup.string().label('Motivation').required(),
      pdState:
        userPDState == 'prof_development'
          ? Yup.string().label('PD State').required().min(2)
          : Yup.string().label('PD State').ensure().trim(),
      ssnDigits:
        userSSNState == 'NY'
          ? Yup.string()
              .label('Last 4 Digits of SSN')
              .required()
              .min(4)
              .max(4)
              .matches(/^[0-9\s]+$/, 'SSN can only be numbers')
          : Yup.string().label('Last 4 Digits of SSN').ensure().trim(),
      age: Yup.number().label('Age').min(12).max(100),
      gender: Yup.string().label('Gender'),
      dependentEmail: Yup.string()
        .label('Dependent Email')
        .ensure()
        .trim()
        .email(),
      dependentFirstName: Yup.string()
        .label('Dependent First Name')
        .ensure()
        .trim()
        .max(50),
      dependentLastName: Yup.string()
        .label('Dependent Last Name')
        .ensure()
        .trim()
        .max(50),
      birthdateMonth: Yup.string().label('Birthdate Month').required(),
      birthdateDay: Yup.string().label('Birthdate Day').required(),
      birthdateYear: Yup.string().label('Birthdate Year').required(),
    });
  };

  const renderLoginForm = ({
    errors,
    touched,
    values,
    handleChange,
    submitForm,
  }: FormikProps<IForm>): ReactElement => {
    return (
      <div className={styles.container}>
        <div className={styles.subcontainer}>
          <h1>
            ThinkHumanTV
            <br />
            {!props.introMessage ? (
              <>
                <span>
                  <br />
                  Boost emotional skills and wellness <br />
                  while watching Netflix, Disney+ and Peacock.
                </span>
              </>
            ) : (
              <>
                <span>
                  <br />
                  {props.introMessage}
                </span>
              </>
            )}
          </h1>

          <Form noValidate>
            <div className="form-item">
              <div className="errors">{errors.server}</div>
            </div>
            <div className="form-item">
              <label>
                <div className="form-label required">Email</div>
                <Field type="email" name="email" disabled={!!props.email} />
              </label>
              <div className="errors">{touched.email && errors.email}</div>
            </div>
            <div className="form-item">
              <label>
                <div className="form-label required">Password</div>
                <Field type="password" name="password" />
              </label>
              <div className="errors">
                {touched.password && errors.password}
              </div>
            </div>
            <div className="form-item">
              <label>
                <div className="form-label required">Confirm Password</div>
                <Field type="password" name="confirmPassword" />
              </label>
              <div className="errors">
                {touched.confirmPassword && errors.confirmPassword}
              </div>
            </div>
            <div className="form-item">
              <label>
                <div className="form-label required">First Name</div>
                <Field type="text" name="firstName" />
              </label>
              <div className="errors">
                {touched.firstName && errors.firstName}
              </div>
            </div>
            <div className="form-item">
              <label>
                <div className="form-label required">Last Name</div>
                <Field type="text" name="lastName" />
              </label>
              <div className="errors">
                {touched.lastName && errors.lastName}
              </div>
            </div>
            <div className="form-item">
              <div className="form-label required">Birthdate</div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Field
                  component={SelectField}
                  name="birthdateMonth"
                  data={Array.from({ length: 12 }, (_, i) => ({
                    uid: `${i + 1}`,
                    name: moment().month(i).format('MMMM'),
                  }))}
                />
                <Field
                  component={SelectField}
                  name="birthdateDay"
                  data={Array.from({ length: 31 }, (_, i) => ({
                    uid: `${i + 1}`,
                    name: `${i + 1}`,
                  }))}
                />
                <Field
                  component={SelectField}
                  name="birthdateYear"
                  data={Array.from(
                    { length: moment().year() - 1900 },
                    (_, i) => ({
                      uid: `${moment().year() - i}`,
                      name: `${moment().year() - i}`,
                    })
                  )}
                />
              </div>
              <div className="errors">
                {touched.birthdateMonth && errors.birthdateMonth && (
                  <div>{errors.birthdateMonth}</div>
                )}
                {touched.birthdateDay && errors.birthdateDay && (
                  <div>{errors.birthdateDay}</div>
                )}
                {touched.birthdateYear && errors.birthdateYear && (
                  <div>{errors.birthdateYear}</div>
                )}
              </div>
            </div>
            <div className="form-item">
              <label>
                <div className="form-label required">I plan to use THTV…</div>
                <Field
                  component={SelectField}
                  name="motivation"
                  emptyTitle=""
                  onChange={(e: any) => {
                    handleChange(e);
                    handleMotivationChange(e.target.value);
                  }}
                  data={Object.keys(UserMotivationTypes).map((t: string) => ({
                    uid: t,
                    name: UserMotivationTypes[t],
                  }))}
                />
              </label>
              <div className="errors">
                {touched.motivation && errors.motivation}
              </div>
            </div>

            {values.motivation === 'prof_development' && (
              <div className="form-item">
                <label>
                  <div className="form-label required">
                    State Where PD Credit Hours Will Be Applied
                  </div>
                  <Field
                    component={SelectField}
                    name="pdState"
                    emptyTitle=""
                    onChange={(e: any) => {
                      handleChange(e);
                      handleStateChange(e.target.value);
                    }}
                    data={states}
                  />
                </label>
                <div className="errors">
                  {touched.pdState && errors.pdState}
                </div>
              </div>
            )}

            {values.pdState === 'NY' && (
              <div className="form-item">
                <label>
                  <div className="form-label required">
                    Last 4 Digits of SSN (required for NYS certifications)
                  </div>
                  <Field type="text" name="ssnDigits" />
                </label>
                <div className="errors">
                  {touched.ssnDigits && errors.ssnDigits}
                </div>
              </div>
            )}

            {props.extendedForm && (
              <div>
                <div className="form-item">
                  <label>
                    <div className="form-label required">Age</div>
                    <Field
                      type="number"
                      name="age"
                      onKeyUp={(e: any) => handleAgeChange(e.target.value)}
                    />
                  </label>
                  <div className="errors">{touched.age && errors.age}</div>
                </div>
                <div className="form-item">
                  <label>
                    <div className="form-label">Gender</div>
                    <Field
                      component={SelectField}
                      name="gender"
                      data={Object.keys(GenderTypes).map((t) => ({
                        uid: t,
                        name: GenderTypes[t],
                      }))}
                      hideSearch={true}
                    />
                  </label>
                  <div className="errors">
                    {touched.gender && errors.gender}
                  </div>
                </div>
              </div>
            )}

            {AppConfig.inviteOnSignupEnabled && userAge >= 30 && (
              <div>
                <div className="form-item">
                  If you would like to sign up a minor/dependent (such as one of
                  your kids), please provide their email and name, and they will
                  receive an invite. You will also be able to invite them later.
                  <br />
                  <br />
                  <label>
                    <div className="form-label">Dependent&apos;s Email</div>
                    <Field type="email" name="dependentEmail" />
                  </label>
                  <div className="errors">
                    {touched.dependentEmail && errors.dependentEmail}
                  </div>
                </div>

                <div className="form-item">
                  <label>
                    <div className="form-label">
                      Dependent&apos;s First Name
                    </div>
                    <Field type="text" name="dependentFirstName" />
                  </label>
                  <div className="errors">
                    {touched.dependentFirstName && errors.dependentFirstName}
                  </div>
                </div>
                <div className="form-item">
                  <label>
                    <div className="form-label">Dependent&apos;s Last Name</div>
                    <Field type="text" name="dependentLastName" />
                  </label>
                  <div className="errors">
                    {touched.dependentLastName && errors.dependentLastName}
                  </div>
                </div>
              </div>
            )}

            <div className="form-item">
              By signing up for ThinkHumanTV, you agree to the following
              <LinkButton
                onClick={() => goToTerms()}
                className={`${styles.termsButton} warn`}
              >
                Terms and Conditions
              </LinkButton>
            </div>

            <div className="form-buttons single">
              <ReCAPTCHA
                sitekey="6Ld4W44pAAAAAMpYSAbsxfUATsLv1y_dK3AUw5PD"
                onChange={handleCaptcha}
              />
            </div>

            <div className="form-buttons single">
              {loading ? (
                <Loader />
              ) : (
                <Button
                  disabled={captchaToken ? false : true}
                  onClick={submitForm}
                >
                  Sign Up
                </Button>
              )}
            </div>

            <div className="form-buttons single">
              <LinkButton
                onClick={() => {
                  if (props.handleToggle) {
                    props.handleToggle();
                  } else {
                    login();
                  }
                }}
              >
                Login
              </LinkButton>
            </div>
          </Form>
        </div>
      </div>
    );
  };

  return (
    <>
      <Formik
        initialValues={{
          email: props.email
            ? props.email
            : AppConfig.isDebug
            ? AppConfig.testUserEmail
            : '',
          password: '',
          confirmPassword: '',
          firstName: '',
          lastName: '',
          gender: '',
          age: userAge > 0 ? userAge : 18,
          motivation: '',
          dependentEmail: '',
          dependentFirstName: '',
          dependentLastName: '',
          birthdateMonth: '',
          birthdateDay: '',
          birthdateYear: '',
          birthdate: '',
          pdState: '',
          ssnDigits: '',
        }}
        validationSchema={getValidationSchema}
        onSubmit={handleSubmit}
      >
        {renderLoginForm}
      </Formik>

      {termsVisible && (
        <Popup
          title="Terms and Conditions"
          onClose={() => setTermsVisible(false)}
          buttons={[
            {
              title: 'Close',
              onClick: () => setTermsVisible(false),
            },
          ]}
        >
          <div className={styles.termsTextContainer}>
            Terms and conditions here.
            <br />
          </div>
        </Popup>
      )}
    </>
  );
}
