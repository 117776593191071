import { FetchResult, gql } from 'apollo-boost';
import { MultiError } from '../../types/multi-error';
import { graphqlClient } from '../graphql-client';

const MUTATION = gql`
    mutation UserStatsAddAnswer($answer_data: AnswerResultInput!){
        user_stats_answer_result_save(answer_data: $answer_data) {
            success,
        }
    }
`;

export async function addAnswerToUser(
    answers: string[],
    isCorrect: boolean,
    additionalData: {
        skillUid?: string,
        conceptUid?: string,
        exerciseType: string,
        characterUid: string,
        emotionEpisodeUid: string,
    }
)
: Promise<{ success: boolean }> {
    const result: FetchResult<{ user_stats_answer_result_save: { success: boolean } }> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: {
            answer_data: {
                answers: answers,
                correct: isCorrect,
                skill_uid: additionalData.skillUid,
                concept_uid: additionalData.conceptUid,
                exercise_type: additionalData.exerciseType,
                character_uid: additionalData.characterUid,
                emotion_episode_uid: additionalData.emotionEpisodeUid,
            },
        },
        errorPolicy: 'all',
    });

    if(!result.data || (result.errors && result.errors.length)) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.user_stats_answer_result_save;
}
