import { FetchResult, gql } from 'apollo-boost';
import { removeFields } from '../../helpers/common-helpers';
import { IEmotionEpisode } from '../../models/emotion-episode';
import { MultiError } from '../../types/multi-error';
import { graphqlClient } from '../graphql-client';
import useGoogleAnalytics from '../../hooks/useGoogleAnalytics';

const QUERY = gql`
    query emotionEpisodesSearch($text: String, $emotion_episode: String, $skip: Int,$limit: Int,$service: String, $age: Int){
        emotion_episodes_search(text: $text, emotion_episode: $emotion_episode, skip: $skip,limit: $limit,service: $service, max_age: $age) {
            uid,
            description,
            start,
            end,
            absoluteStart: absolute_start,
            mediaUrls: media_urls,
            available,
            saved,
            comment {
                uid,
                html,
                entity_uid,
            },
            participants {
              uid,

              character {
                uid,
                name,
                importance,
                gender,
                shortName: short_name,
                fileName: file_name,
              }

              emotions {
                uid,
                description,
                intensity,
                category {
                    uid,
                    label,
                }
              }
            },

            contentTags: content_tags {
                uid,
                name,
                tagType: tag_type
            },
            scene {
                uid,
                description,

                narrative {
                    uid,
                    title,
                    minAgeRating: min_age_rating,
                    externalReferences: external_references {
                      serviceName: service_name
                    }
                    fileName: file_name
                }

                episode {
                    uid,
                    name,
                    season,
                    numberInSeason: number_in_season,
                }
            }
        }
    }
`;


export async function emotionEpisodesSearch(
    // eslint-disable-next-line @typescript-eslint/ban-types
    text: string | undefined | null, emotion_episode: string | undefined | null, skip: number, limit: number, service: string | undefined, age: number, disp: Function): Promise<IEmotionEpisode[]> {
    disp({ type: 'set_search', payload: { search: text ? text : '', emotion_episode: emotion_episode ? emotion_episode : '', service: service === undefined? '':service, age: age === undefined ? ' ' : age.toString(), skip: skip.toString() } })
    const result: FetchResult<{ emotion_episodes_search: IEmotionEpisode[] }> = await graphqlClient.query({
        query: QUERY,
        variables: { text, emotion_episode, skip, limit, service, age },
        errorPolicy: 'all',
    });

    if (!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    if (result.data.emotion_episodes_search)
        return removeFields(result.data.emotion_episodes_search, ['__typename']);
    else return []
}
