import React, { CSSProperties } from 'react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Combobox } from '@headlessui/react';
import { IListResult } from '../../models/list-result';
import { IUserGroup } from '../../models/user-group';
import { useHistory } from 'react-router-dom';

type ComboBoxProps = {
  orgs: IListResult<IUserGroup> | null;
  selectedOrg: string;
  setInputText: (textInput: string) => any;
  setSelectedOrg: (selectedOrg: string) => any;
  style?: CSSProperties;
  showCreateNew?: boolean;
};

function classNames(...classes: (string | boolean)[]) {
  return classes.filter(Boolean).join(' ');
}

export const ComboBox: React.FC<ComboBoxProps> = ({
  orgs,
  selectedOrg,
  setInputText,
  setSelectedOrg,
  style,
  showCreateNew,
}) => {
  const history = useHistory();
  return (
    <Combobox
      className="w-full"
      as="div"
      value={selectedOrg}
      onChange={setSelectedOrg}
    >
      <Combobox.Label className="hidden block text-sm font-medium leading-6 text-gray-900">
        Enter your school name
      </Combobox.Label>
      <div className="relative mt-2">
        <Combobox.Input
          className={`w-full rounded-md bg-white text-base pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-0 focus:ring-transparent sm:leading-6 custom ${
            style ? '' : 'py-3'
          }`}
          onChange={(e) => setInputText(e.target.value)}
          placeholder="Enter your organization name"
          style={style}
        />
        <Combobox.Button className="custom custom-btn absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        <Combobox.Options className="absolute z-10 mt-1 pl-0 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {orgs &&
            orgs.data &&
            orgs.data.length > 0 &&
            orgs.data.map((org) => (
              <Combobox.Option
                key={org.uid}
                value={org.title}
                className={({ active }) =>
                  classNames(
                    'relative cursor-pointer select-none py-2 pl-3 pr-9 list-none',
                    active
                      ? 'bg-gray-100 text-gray-900 list-none'
                      : 'text-gray-900'
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames(
                        'block truncate',
                        selected && 'font-semibold'
                      )}
                    >
                      {org.title}
                    </span>

                    {selected && (
                      <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-gray-900">
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          {showCreateNew !== false && (
            <Combobox.Option
              value="+ create a new school"
              // eslint-disable-next-line sonarjs/no-identical-functions
              className={({ active }) =>
                classNames(
                  'relative cursor-pointer select-none py-2 pl-3 pr-9 list-none',
                  active
                    ? 'bg-gray-100 text-gray-900 list-none'
                    : 'text-gray-900'
                )
              }
            >
              {({ active, selected }) => (
                <>
                  <span
                    onClick={() => history.push('/org/_')}
                    className={classNames(
                      'block truncate',
                      selected && 'font-semibold'
                    )}
                  >
                    + create a new organization
                  </span>
                </>
              )}
            </Combobox.Option>
          )}
        </Combobox.Options>
      </div>
    </Combobox>
  );
};
