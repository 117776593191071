import Cookies from 'js-cookie';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AppConfig } from '../../config/app-config';
import { useUser } from '../../contexts/user-context';

interface IProps extends RouteComponentProps {
  pageName: string;
  children: string | React.ReactElement | Array<string | React.ReactElement>;
}

export function SecuredPage(props: IProps) {
  const { user } = useUser();

  React.useEffect(() => {
    // Check if token exists
    if (!Cookies.get(AppConfig.tokenName)) {
      props.history.push('/login');
    }

    // check to ensure the check in the following code block needs to run at all
    // note the this assumes a user only has one group of type organization
    if (user && user.userGroups) {
      const orgGroup = user.userGroups.find(
        (group) => group.groupType === 'organization'
      );
      if (orgGroup?.emailConfirmationRequired !== true) return;
    }

    if (
      user &&
      (user.confirmedEmail === null || user.email !== user.confirmedEmail)
    ) {
      props.history.push('/onboarding-confirm-email');
    }
  }, [props.history, user]);

  return <div className={`page ${props.pageName}`}>{props.children}</div>;
}
