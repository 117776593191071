import { FetchResult, gql } from 'apollo-boost';
import { MultiError } from '../../types/multi-error';
import { graphqlClient } from '../graphql-client';

const MUTATION = gql`
    mutation processShareLink($uid: String!){
        user_process_share_link(uid: $uid) {
            success,
            notLoggedIn: not_logged_in,
            noExtension: no_extension,
            invalidId: invalid_id,
            url
        }
    }
`;

interface IResult {
    success: boolean,
    notLoggedIn: boolean,
    noExtension: boolean,
    invalidId: boolean,
    url?: string
}

export async function processShareLink(uid: string): Promise<IResult> {
    const result: FetchResult<{ user_process_share_link: IResult }> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: {
            uid,
        },
        errorPolicy: 'all',
    });

    if(!result.data || (result.errors && result.errors.length)) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.user_process_share_link;
}
