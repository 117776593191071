import * as React from 'react';
import ReactPlayer from 'react-player';

interface IProps {
    url: string,
    playing?: boolean,
    startPos?: number,
    endPos?: number,
    controls?: boolean,
    onSeek?(seconds: number): void,
    onProgress?(state: { played: number, playedSeconds: number, loaded: number, loadedSeconds: number }): void,
    onEnded?(): void,
    onPause?(): void,
}

interface IState {
    playing: boolean,
    shouldStop?: boolean,
}

export class Video extends React.Component<IProps, IState> {

    isPrepared: boolean = false;

    player = React.createRef<ReactPlayer>();

    constructor(props: IProps) {
        super(props);
        this.state = { playing: props.playing || false };
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        if(prevProps.startPos !== this.props.startPos || prevProps.endPos !== this.props.endPos) {
            this.seekToStart();
        }
        if(prevProps.playing !== this.props.playing) {
            if(this.props.playing) this.play();
            else this.pause();
        }
    }

    render() {
        const url = this.props.url;

        return (
            <div style={{ width: '100%', height: '100%'}} onClick={this.onClick}>
                <ReactPlayer
                    ref={this.player}
                    width="100%"
                    height="100%"
                    playing={this.state.playing}
                    controls={this.props.controls}
                    url={this.isYoutubeLink() ? `${url}&t=${this.props.startPos}s` : url}
                    onReady={this.onReady}
                    onProgress={this.onProgress}
                    onSeek={this.props.onSeek}
                    onEnded={this.onEnded}
                    muted={this.state.shouldStop}
                    loop={false}

                    config={{
                        youtube: {
                            onUnstarted: this.onUnstarted,
                            playerVars: {
                                autoplay: false,
                            }
                        },
                    }}
                />
            </div>
        );
    }

    private onClick = () => {
        if(this.props.controls) return;

        if(this.state.playing) {
            this.pause();
        }
        else {
            this.play();
        }
    }

    private isYoutubeLink() {
        return this.props.url.includes('https://www.youtube.com/watch?');
    }

    private onReady = () => {

        if(!this.isPrepared) {
            this.isPrepared = true;
            this.seekToStart();
        }

    }

    private onProgress = (info: { played: number, playedSeconds: number, loaded: number, loadedSeconds: number }) => {
        if(this.props.onProgress) {
            this.props.onProgress(info);
        }

        if(this.props.endPos && info.playedSeconds >= this.props.endPos) {
            this.onEnded();
        }
    }

    private pause() {
        this.setState({ playing: false });

        if(this.props.onPause) {
            this.props.onPause();
        }
    }

    private play() {
        this.setState({ playing: false }, () => {
            this.setState({ playing: true });
        });
    }

    private onEnded = () => {
        this.pause();
        this.seekToStart();

        if(this.props.onEnded) {
            this.props.onEnded();
        }
    }

    private onUnstarted = () => {
        //
    }

    private seekToStart = () => {
        if(this.player.current && this.props.startPos !== null && this.props.startPos !== undefined && !this.isYoutubeLink()) {
            this.player.current.seekTo(this.props.startPos, 'seconds');
        }
    }
}
