import { FetchResult, gql } from 'apollo-boost';
import { IUnit } from '../../models/unit';
import { MultiError } from '../../types/multi-error';
import { graphqlClient } from '../graphql-client';

const QUERY = gql`
    query Units($curriculumUid: String!) {
        units(curriculumUid: $curriculumUid) {
            uid,
            name,
            index,
            description,
            available,
            hasEducatorGuide: has_educator_guide,
            worksheet,
            accessibleWithCurrentPlan: accessible_with_current_plan,
            finalEmotionModelState: final_emotion_model_state,
            iconFileName: icon_file_name,
            type,

            unitSections: unit_sections {
                uid,
                name,
                description,
                type,
                available,
            }
        }
    }
`;

export async function getUnits(curriculumUid: string): Promise<IUnit[]> {
    const result: FetchResult<{ units: IUnit[] }> = await graphqlClient.query({
        query: QUERY,
        variables: { curriculumUid },
        errorPolicy: 'all',
    });

    if(!result.data || (result.errors && result.errors.length)) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.units;
}
