import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { getCurrentUser } from '../../../api/actions/get-current-user';
import { MainLayout } from '../../../components/layouts/main-layout';
import { CurrentUserContext } from '../../../contexts/current-user-context';
import { basePerformError } from '../../../helpers/error-helpers';
import { SecuredPage } from '../../base/secured-page';
import { ProfileMenuComponent } from '../analytics/components/profile-menu-component';
import { UserAccountComponent } from './components/user-account-component';
import styles from './account-page.module.scss';

export function AccountPage(props: RouteComponentProps) {


    const currentUserContext = React.useContext(CurrentUserContext);
    React.useEffect(() => {
        (async () => {
            try {
                const usr = await getCurrentUser();
                currentUserContext?.setState(usr);

            }
            catch(err) {
                basePerformError(err, props.history);
                return;
            }
        })().catch(() => {/** */});
    }, [props]);

    return (
        <SecuredPage pageName="account" {...props} >
            <MainLayout>
                <ProfileMenuComponent>
                    {currentUserContext?.state?.uid && (
                        <UserAccountComponent user={currentUserContext?.state} />
                    )}
                </ProfileMenuComponent>
            </MainLayout>
        </SecuredPage>
    );
}
