import { faUser } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Cookies from 'js-cookie';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { logout } from '../../api/actions/logout-action';
import { AppConfig } from '../../config/app-config';
import { IUser } from '../../models/user';
import {
  IMultiLevelMenuItem,
  MultiLevelMenu,
} from '../multi-level-menu/multi-level-menu';
import { useUser } from '../../contexts/user-context';
import { Link } from 'react-router-dom';
import { UserRoles } from '../../models/user-roles-enum';
import styles from './current-user.module.scss';

interface CurrentUserProps extends RouteComponentProps {
  user: IUser;
  onlyLogout?: boolean;
}

function CurrentUser(props: CurrentUserProps) {
  const { setUser } = useUser();
  const logOut = async () => {
    //console.log("Key =>",AppConfig.tokenName)
    try {
      await logout().then((res) => {
        Cookies.remove(AppConfig.tokenName, {
          domain: window.location.hostname,
        });
        localStorage.removeItem('user');
        setUser(null);
        document.location.href = '/login';
      });
    } catch {
      /** */
    }
  };

  const getTextContent = () => {
    if (props.user?.role === UserRoles.admin || props.user?.role === UserRoles.editor)
      return (props.user?.firstName + ' ' + props.user?.lastName);
    else if(props.user?.userPlan?.accessType !== 'full') {
      return (<Link to='/account'>Upgrade</Link>);
    }
  }

  const filterMenuItems = (items: IMultiLevelMenuItem[]) => {
    if (props.onlyLogout) {
      return items.filter((item) => item.title === 'Logout');
    }

    const filtered = items;

    if (props.user.userPlan && props.user.userPlan.accessType === 'full') {
      filtered.splice(3, 1);
    }
    if (
      !props.user.numInvitesAllowed ||
      props.user.numInvitesAllowed === 0 ||
      !props.user.userPlan ||
      !props.user.hasCurricula
    ) {
      filtered.splice(2, 1);
    }
    return filtered;
  };

  const menuItems: IMultiLevelMenuItem[] = [
    {
      title: 'Profile',
      onClick: () => props.history.push('/analytics'),
    },
    {
      title: 'Account',
      onClick: () => props.history.push('/account'),
    },
    {
      title: 'Invite Others',
      onClick: () => props.history.push('/send-invites'),
    },
    {
      title: 'Enter Code',
      onClick: () => props.history.push('/invite-code'),
    },
    {
      title: 'Support',
      onClick: () => window.open(AppConfig.supportURL),
    },
    {
      title: 'Logout',
      onClick: logOut,
    },
  ];

  return (
    <div className={styles.container}>
      {getTextContent()}
      <div className={`multi-level-menu-container multi-level-menu-to-left`}>
        <div className={styles.user}>
          <FontAwesomeIcon icon={faUser} />
        </div>
        <MultiLevelMenu items={filterMenuItems(menuItems)} />
      </div>
    </div>
  );
}

const CurrentUserWithRouter = withRouter(CurrentUser);
export { CurrentUserWithRouter as CurrentUser };
