import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Cookies from 'js-cookie';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { AppConfig } from '../../config/app-config';
import { useCurrentUser } from '../../contexts/current-user-context';
import { imagesPath } from '../../helpers/path-helpers';
import { BaseTypes } from '../../types';
import { BackButtonContext } from '../back-button/back-button';
import { HeaderContentContext } from '../header-content/header-content';
import { CurrentUser } from '../user/current-user';
import styles from './main-layout.module.scss';
import { Search } from './search';
import useScreenWidth from '../../hooks/useScreenWidth';
import { useLocation } from 'react-router-dom';

interface IProps extends RouteComponentProps {
  noPaddings?: boolean;
  hideSearch?: boolean;
  hideMenu?: boolean;
  children:
    | Array<React.ReactElement | string | undefined | false>
    | React.ReactElement
    | string;
}
const SEARCH_MINIMIZE_TIMEOUT = 4000;

function MainLayout(props: IProps) {
  const screenWidth = useScreenWidth();
  const location = useLocation();
  const currentUser = useCurrentUser();
  const backButtonContext = React.useContext(BackButtonContext);
  const headerContentContext = React.useContext(HeaderContentContext);
  const isActive = (path: string) =>
    new RegExp(`^${path}`, 'ig').exec(document.location.pathname);

  const [searchActive, setSearchActive] = React.useState(false);
  console.log('searchActive ', searchActive);
  const minimizeSearchTimeout = React.useRef<NodeJS.Timeout>();
  const maximizeSearch = React.useCallback(() => {
    if (minimizeSearchTimeout.current) {
      clearTimeout(minimizeSearchTimeout.current);
    }
    setSearchActive(true);
  }, []);
  const minimizeSearch = React.useCallback(
    (immediately?: boolean) => {
      if (minimizeSearchTimeout.current) {
        clearTimeout(minimizeSearchTimeout.current);
      }

      if (!searchActive) return;

      if (immediately === true) {
        setSearchActive(false);
        return;
      }

      minimizeSearchTimeout.current = setTimeout(() => {
        setSearchActive(false);
      }, SEARCH_MINIMIZE_TIMEOUT);
    },
    [searchActive]
  );

  const showSearch = () => {
    if (!currentUser) {
      return (
        <div className={styles.search}>
          <Search
            isMaximized={searchActive}
            onFocus={maximizeSearch}
            onBlur={minimizeSearch}
          />{' '}
          <div className={styles.mobile_search}>
            <ul>
              {searchActive && (
                <>
                  <li></li>
                  <li>
                    <FontAwesomeIcon
                      icon={faTimes}
                      className={styles.menuBtn}
                      onClick={() => minimizeSearch(true)}
                    />
                  </li>{' '}
                </>
              )}
            </ul>
          </div>
        </div>
      );
    }
  };

  return (
    <div
      className={`${styles.main} ${currentUser ? styles.authenticated : ''}`}
    >
      <div className={styles.header}>
        {!searchActive && (
          <div className={styles.userContainer}>
            {currentUser && (
              <CurrentUser user={currentUser} onlyLogout={props.hideMenu} />
            )}
          </div>
        )}

        {!searchActive && (
          <>
            {currentUser &&
              (!headerContentContext?.content ? (
                <h1 className="text-center">ThinkHumanTV</h1>
              ) : typeof headerContentContext.content === BaseTypes.string ? (
                <h1>{headerContentContext.content}</h1>
              ) : (
                <div className={styles.headerContainer}>
                  {headerContentContext.content}
                </div>
              ))}
          </>
        )}

        {!currentUser && !headerContentContext?.content && ''}

        <div className={styles.searchContainer}>
          <div className={styles.menuContainer}>
            {!props.hideMenu &&
              (screenWidth > 565 || location.pathname === '/search') &&
              !searchActive &&
              (backButtonContext?.backButtonContent ? (
                backButtonContext.backButtonContent
              ) : (
                <div
                  className={styles.logoContainer}
                  onClick={() => props.history.push('/analytics')}
                >
                  <img
                    alt="ThinkHumanTV"
                    src={imagesPath('/logo.png')}
                    className={styles.logo}
                  />
                  ThinkHumanTV
                </div>
              ))}
            {!props.hideSearch && showSearch()}
            {/* <div className={styles.mobile_search}>
              <ul>
                {searchActive && (
                  <>
                    <li></li>
                    <li>
                      <FontAwesomeIcon
                        icon={faTimes}
                        className={styles.menuBtn}
                        onClick={() => minimizeSearch(true)}
                      />
                    </li>
                  </>
                )}
              </ul>
            </div> */}
          </div>
        </div>
      </div>
      <div
        className={`${styles.body} ${
          props.noPaddings ? styles.noPaddings : ''
        }`}
      >
        {props.children}
      </div>
    </div>
  );
}

const MainLayoutWithRouter = withRouter(MainLayout);
export { MainLayoutWithRouter as MainLayout };
